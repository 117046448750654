import {Autocomplete, TextField} from "@mui/material";
import {defined, ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Inbox, useInboxesApi} from "../api";
import {useLocalization} from "../i18n";
import {notBlank} from "../util";

interface AuditorSelectProps {
	inbox: Inbox;
	value: string | null;
	onChange: (value: string | null) => any;
	required?: boolean;
	fullWidth?: boolean;
}

export function AuditorSelect(props: Readonly<AuditorSelectProps>) {
	const {inbox, value, onChange, required, fullWidth} = props;
	const {t} = useLocalization();
	const {getInboxAuditors} = useInboxesApi();

	const {loading, error, result: auditors} = useAsync(getInboxAuditors, [inbox.id]);

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return (
		<Autocomplete
			renderInput={props => (
				<TextField required={required} label={t("invoices.details.auditorSelectHint")} {...props} />
			)}
			fullWidth={fullWidth}
			loading={loading}
			options={auditors?.map(auditor => auditor.email).filter(defined)
				?? []}
			value={value}
			onChange={(_, value) => onChange(value)}
			getOptionLabel={email => {
				const auditor = auditors?.find(audit => audit.email == email);
				return [auditor?.lastname, auditor?.firstname].filter(notBlank).join(", ");
			}}
			autoSelect
			autoHighlight
		/>
	);
}
