import {Masonry} from "@mui/lab";
import {Box, Card, CardContent, CardHeader, Skeleton, Typography} from "@mui/material";
import {TemporalFormat, TemporalRangeFormat, tryParsePlainDate} from "@variocube/app-ui";
import React from "react";
import {Info, InfoDisplayType} from "../api";
import {useInfos} from "../context/auth";

export interface InfoboxesProps {
	displayType: InfoDisplayType;
	columns?: number;
}

export function Infoboxes({displayType, columns = 1}: Readonly<InfoboxesProps>) {
	const infos = useInfos(displayType);
	return (
		<Masonry columns={columns} spacing={2}>
			{infos.map(info => <InfoCard key={info.id} info={info} />)}
		</Masonry>
	);
}

interface InfoCardProps {
	info?: Info;
}

function InfoCard({info}: Readonly<InfoCardProps>) {
	const start = tryParsePlainDate(info?.start);
	const end = tryParsePlainDate(info?.end);
	return (
		<Card>
			<CardHeader title={info ? info.subject : <Skeleton />} />
			{info
				? (
					<CardContent>
						<div
							dangerouslySetInnerHTML={{
								__html: info.body ?? "",
							}}
						/>
						<Box mt={2}>
							<Typography variant="body2" color="textSecondary">
								{(start && end)
									? <TemporalRangeFormat from={start} until={end} />
									: (
										<TemporalFormat
											value={start}
										/>
									)}
							</Typography>
						</Box>
					</CardContent>
				)
				: <Skeleton />}
		</Card>
	);
}
