import {Alert, AlertTitle, Button, Card, Grid, Stack} from "@mui/material";
import {TextField, View, ViewHeader} from "@variocube/app-ui";
import React, {type FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {useSignupApi} from "../../api";
import {Typography} from "../../component/typography";
import {useLocalization} from "../../i18n";

export function IdForm() {
	const {identifyEmail} = useSignupApi();
	const {t} = useLocalization();
	const navigate = useNavigate();

	const [email, setEmail] = useState("");

	const {loading, error, execute} = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();

		const {federatedUrl, domain} = await identifyEmail(email);

		if (federatedUrl) {
			location.href = federatedUrl;
		}
		else if (domain) {
			navigate(`/signup/${domain.toLowerCase()}/${email}`);
		}
	});

	return (
		<View maxWidth="sm">
			<ViewHeader
				title={t("signup.title")}
				subTitle={t("signup.enterEmailToContinue")}
			/>
			<Card component="form" onSubmit={execute} sx={{p: 2}}>
				<Grid container>
					<Grid item xs>
						<TextField
							label={t("common.email")}
							value={email}
							onChange={setEmail}
							fullWidth
						/>
					</Grid>
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={loading}
							size="large"
							sx={{height: "100%"}}
						>
							{t("actions.next")}
						</Button>
					</Grid>
				</Grid>
			</Card>
			{error && (
				<Stack spacing={2}>
					<Alert severity="error">
						<AlertTitle>{t("signup.userExisted.message", {email})}</AlertTitle>
						<Typography>{t("signup.userExisted.hint")}</Typography>
					</Alert>
					<Stack
						direction="row"
						justifyContent="space-between"
					>
						<Button variant="contained" color="primary" component={RouterLink} to="/auth">
							{t("signup.backToLogin")}
						</Button>
						<Button
							variant="contained"
							color="inherit"
							component={RouterLink}
							to={`/lost-password?email=${email}`}
						>
							{t("signup.resetPassword")}
						</Button>
					</Stack>
				</Stack>
			)}
		</View>
	);
}
