import {Stack} from "@mui/material";
import {ErrorBoundary, NotFound} from "@variocube/app-ui";
import React, {Fragment, type PropsWithChildren} from "react";
import {Navigate, useLocation} from "react-router";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Footer} from "./component/footer";
import {Navigation} from "./component/navigation";
import {AuthProvider, isOebb, useAuthContext} from "./context/auth";
import {HelpTextContainer} from "./help";
import {StorageLocalizationProvider} from "./i18n";
import {Auth} from "./pages/auth";
import {Logout} from "./pages/logout";
import {LostPassword} from "./pages/lost-password";
import {ProcessToken} from "./pages/process-token";
import {ResetPassword} from "./pages/reset-password";
import {CreditorSignup, IdForm} from "./pages/signup";
import {ConfirmSignup} from "./pages/signup/confirm";
import {routes} from "./routes";
import {ThemeProvider} from "./theme";

export function App() {
	return (
		<ThemeProvider>
			<StorageLocalizationProvider>
				<ErrorBoundary>
					<BrowserRouter>
						<AuthProvider>
							<Shell>
								<AppRoutes />
							</Shell>
						</AuthProvider>
					</BrowserRouter>
				</ErrorBoundary>
			</StorageLocalizationProvider>
		</ThemeProvider>
	);
}

function AppRoutes() {
	return (
		<Routes>
			<Route index element={<Index />} />
			<Route path="/auth" element={<Auth />} />
			<Route path="/auth/process-token" element={<ProcessToken />} />
			<Route path="/logout" element={<Logout />} />
			<Route path="/signup" element={<IdForm />} />
			<Route path="/signup/creditor/:email" element={<CreditorSignup />} />
			<Route path="/signup/confirm" element={<ConfirmSignup />} />
			<Route path="/password/lost" element={<LostPassword />} />
			<Route path="/password/reset/:email" element={<ResetPassword />} />

			{Object.entries(routes).map(([path, element]) => (
				<Route
					key={path}
					path={path}
					element={
						<Authenticated>
							{element}
						</Authenticated>
					}
				/>
			))}

			<Route
				path="*"
				element={
					<NotFound
						errorMsg="Seite nicht gefunden"
						pathMsg="Folgender Pfad wurde aufgerufen:"
						backToHomeMsg="Zur Startseite"
					/>
				}
			/>
		</Routes>
	);
}

function Authenticated({children}: Readonly<PropsWithChildren<{}>>) {
	const {user} = useAuthContext();
	const location = useLocation();
	if (!user) {
		return <Navigate to={"/auth?next=" + location.pathname + location.search + location.hash} />;
	}
	else {
		if (
			(location.pathname.includes("superuser") && user.role !== "SuperUser")
			|| (location.pathname.includes("admin") && !["OebbAdministrator", "SuperUser"].includes(user.role))
		) {
			return (
				<NotFound
					errorMsg="Seite nicht gefunden"
					pathMsg="Folgender Pfad wurde aufgerufen:"
					backToHomeMsg="Zur Startseite"
				/>
			);
		}

		return <Fragment>{children}</Fragment>;
	}
}

function Index() {
	const {user} = useAuthContext();
	if (!user) {
		return <Navigate to={"/auth"} />;
	}
	if (isOebb(user) && (!user.phone || user.worksAt.length == 0)) {
		return <Navigate to={"/missing-user-data"} />;
	}
	return <Navigate to={"/dashboard"} />;
}

function Shell({children}: PropsWithChildren<unknown>) {
	const location = useLocation();
	const showWorldMap = location.pathname.includes("/auth") || location.pathname.endsWith("/dashboard");

	return (
		<Stack
			direction="column"
			sx={{
				minHeight: "100vh",
			}}
		>
			<Navigation />
			<HelpTextContainer showWorldMap={showWorldMap}>
				{children}
			</HelpTextContainer>
			<Footer />
		</Stack>
	);
}
