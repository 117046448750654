import {Alert, AlertTitle, Typography} from "@mui/material";
import React from "react";
import {DocumentType} from "../api";
import {useLocalization} from "../i18n";

interface UnsupportedFileTypeAlertProps {
	supported: DocumentType[];
}

export function UnsupportedFileTypeAlert({supported}: Readonly<UnsupportedFileTypeAlertProps>) {
	const {t} = useLocalization();
	return (
		<Alert severity="error">
			<AlertTitle>{t("invoices.new.unsupportedFileType.invoiceTitle")}</AlertTitle>
			<Typography paragraph>
				{t("invoices.new.unsupportedFileType.supportedFileTypes")}
			</Typography>
			<ul>
				{supported.map(type => (
					<li key={type.id}>{type.displayName} ({type.fileExtension}) [{type.mimeType}]</li>
				))}
			</ul>
		</Alert>
	);
}
