import {TimeToLeave} from "@mui/icons-material";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import React from "react";
import {type InvoiceBusinessCase} from "../../../api";
import {LabeledText} from "../../../component/labeled-text";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {localizeNumber, notNull} from "../../../util";

export function CarDetails({businessCase}: { businessCase: InvoiceBusinessCase }) {
	const {t, language} = useLocalization();
	return (
		<Card>
			<CardHeader
				title={<Typography startAdornment={<TimeToLeave />}>{t("invoices.details.carBusiness")}</Typography>}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<LabeledText label={t("invoices.details.carTypeModel")} text={businessCase.carType} />
					</Grid>
					<Grid item xs={12} md={4}>
						<LabeledText label={t("invoices.details.carLicensePlate")} text={businessCase.licensePlate} />
					</Grid>
					<Grid item xs={12} md={2}>
						<LabeledText
							label={t("invoices.details.mileageKm")}
							text={notNull(businessCase.mileageKm)
								? localizeNumber(businessCase.mileageKm as number, language)
								: ""}
						/>
					</Grid>
					<Grid item xs={12} md={3}>
						<LabeledText
							label={t("invoices.details.refueledLiter")}
							text={notNull(businessCase.refueledLiter)
								? localizeNumber(businessCase.refueledLiter as number, language)
								: ""}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
