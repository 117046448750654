import {Card, CardHeader, Collapse} from "@mui/material";
import React, {ReactNode} from "react";

interface WizardStepCardProps {
	active: boolean;
	completed: boolean;
	activeChildren: ReactNode;
	completedChildren: ReactNode;
	title: ReactNode;
}

export function WizardStepCard(props: Readonly<WizardStepCardProps>) {
	const {active, completed, title, activeChildren, completedChildren} = props;
	return (
		<Collapse in={active || completed} mountOnEnter unmountOnExit>
			<Card>
				<CardHeader title={title} />
				<Collapse in={active}>
					{activeChildren}
				</Collapse>
				<Collapse in={completed}>
					{completedChildren}
				</Collapse>
			</Card>
		</Collapse>
	);
}
