import {Chip, Grid} from "@mui/material";
import {Filter, Select, TextField} from "@variocube/app-ui";
import React, {useState} from "react";
import {USER_ROLES, UserFilter, UserRole} from "../../api";
import {FilterForm} from "../../component/filter-form";
import {useLocalization} from "../../i18n";

interface UserFilterBarProps {
	value: UserFilter;
	onChange: (value: UserFilter) => void;
}

export function UserFilterBar({value, onChange}: UserFilterBarProps) {
	const {s, t} = useLocalization();

	const [name, setName] = useState(value.name ?? "");
	const [email, setEmail] = useState(value.email ?? "");
	const [role, setRole] = useState<UserRole | undefined>(value.role);

	async function handleSubmit() {
		onChange({
			name,
			email,
			role,
		});
	}

	function handleReset() {
		onChange({
			name: "",
			email: "",
			role: undefined,
		});
	}

	return (
		<Filter
			labels={s("filter")}
			label={t("filter.title")}
			onClear={handleReset}
			active={[
				value.name && (
					<Chip
						label={`${t("common.name")}: ${value.name}`}
						onDelete={() => onChange({...value, name: ""})}
					/>
				),
				value.email && (
					<Chip
						label={`${t("common.email")}: ${value.email}`}
						onDelete={() => onChange({...value, email: ""})}
					/>
				),
				value.role && (
					<Chip
						label={`${t("users.role")}: ${value.role}`}
						onDelete={() => onChange({...value, role: undefined})}
					/>
				),
			]}
		>
			<FilterForm onApply={handleSubmit} onReset={handleReset}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<TextField
							label={t("common.name")}
							fullWidth
							value={name}
							onChange={setName}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							label={t("common.email")}
							fullWidth
							value={email}
							onChange={setEmail}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Select
							label={t("users.role")}
							fullWidth
							options={USER_ROLES}
							value={role}
							onChange={setRole}
							renderLabel={s("users.roles")}
						/>
					</Grid>
				</Grid>
			</FilterForm>
		</Filter>
	);
}
