import {BusinessCenter} from "@mui/icons-material";
import {
	Card,
	CardContent,
	CardHeader,
	Hidden,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
} from "@mui/material";
import {CurrencyFormat} from "@variocube/app-ui";
import React from "react";
import {Invoice} from "../../../api";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {notNull} from "../../../util";

export function BusinessCases({invoice}: { invoice: Invoice }) {
	const {t} = useLocalization();
	return (
		<Card>
			<CardHeader
				title={
					<Typography startAdornment={<BusinessCenter />}>{t("invoices.details.businessCases")}</Typography>
				}
			/>
			<CardContent sx={{p: "0 !important"}}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t("common.name")}</TableCell>
							<Hidden smDown>
								<TableCell>{t("invoices.details.businessCasesDetails")}</TableCell>
								<TableCell align="right">{t("invoices.details.tips")}</TableCell>
							</Hidden>
							<TableCell align="right">{t("common.gross")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{invoice.businessCases?.map(c => (
							<TableRow key={c.id}>
								<TableCell title={`Sachkonto: ${c.code}}, Nummer: ${c.id}`}>
									<Typography>{c.name}</Typography>
									<Hidden smUp>
										<Typography component="em">{c.description}</Typography>
									</Hidden>
								</TableCell>
								<Hidden smDown>
									<TableCell>
										<Typography>{c.description}</Typography>
									</TableCell>
									<TableCell>
										{notNull(c.tip) && (
											<Typography justifyContent="flex-end" noWrap>
												<CurrencyFormat
													value={invoice.tip}
													currency={invoice.currency ?? "EUR"}
												/>
											</Typography>
										)}
									</TableCell>
								</Hidden>
								<TableCell>
									<Typography justifyContent="flex-end" noWrap>
										<CurrencyFormat value={invoice.gross} currency={invoice.currency ?? "EUR"} />
									</Typography>
									{notNull(c.tip) && (
										<Hidden smUp>
											<Typography
												noWrap
												title={t("invoices.details.tips")}
												justifyContent="flex-end"
											>
												<CurrencyFormat
													value={invoice.tip}
													currency={invoice.currency ?? "EUR"}
												/>
											</Typography>
										</Hidden>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<Hidden smUp>
								<TableCell>
									<Typography gutterBottom>{t("invoices.details.instructionAmount")}</Typography>
								</TableCell>
							</Hidden>
							<Hidden smDown>
								<TableCell colSpan={3}>
									{invoice.invoiceType == "ExpensesInvoice" && (
										<Typography justifyContent="flex-end">
											{t("invoices.details.instructionAmount")}
										</Typography>
									)}
								</TableCell>
							</Hidden>
							<TableCell>
								{notNull(invoice.gross) && (
									<Typography noWrap justifyContent="flex-end">
										<CurrencyFormat value={invoice.gross} currency={invoice.currency ?? "EUR"} />
									</Typography>
								)}
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</CardContent>
		</Card>
	);
}
