import DeleteIcon from "@mui/icons-material/Delete";
import {ConfirmButton} from "@variocube/app-ui";
import React from "react";
import {useLocalization} from "../i18n";

interface DeleteButtonProps {
	title: string;
	confirmMessage: string;
	onDelete: () => Promise<any>;
}

export function DeleteButton({title, confirmMessage, onDelete}: Readonly<DeleteButtonProps>) {
	const {t} = useLocalization();
	return (
		<ConfirmButton
			title={title}
			cancel={t("actions.cancel")}
			onConfirm={onDelete}
			variant="outlined"
			icon={<DeleteIcon />}
			color="error"
		>
			{confirmMessage}
		</ConfirmButton>
	);
}
