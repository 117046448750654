import {Autocomplete, Chip, TextField} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {useLocalization} from "../i18n";

export interface OrderNumbersFieldProps {
	value: string[];
	onChange: (value: string[]) => any;
	disabled?: boolean;
}

export function OrderNumbersField({value, onChange, disabled}: Readonly<OrderNumbersFieldProps>) {
	const {t} = useLocalization();

	const [inputValue, setInputValue] = useState("");
	const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

	useEffect(() => {
		const parts = inputValue.split(/\s+/);
		if (parts.length > 1) {
			const last = parts.pop()!;
			setInputValue(last);
			onChange([...value, ...parts]);
		}
	}, [inputValue, value]);

	useEffect(() => {
		inputRef?.setCustomValidity(value.every(isValid) ? "" : t("invoices.details.orderNumbersInvalid"));
	}, [value]);

	function handleEdit(index: number) {
		setInputValue(value[index]);
		onChange(value.filter((_, i) => i != index));
	}

	return (
		<Autocomplete
			freeSolo
			multiple
			autoSelect
			renderInput={props => (
				<TextField
					label={t("invoices.details.orderNumbers")}
					inputRef={setInputRef}
					error={!inputRef?.validity.valid}
					helperText={inputRef?.validity.valid && inputRef?.validationMessage}
					{...props}
				/>
			)}
			value={value}
			onChange={(_, value) => onChange(value)}
			options={[]}
			inputValue={inputValue}
			onInputChange={(_, value) => setInputValue(value)}
			renderTags={(value, getTagProps) => (
				<Fragment>
					{value.map((orderNumber, index) => (
						<Chip
							label={orderNumber}
							color={isValid(orderNumber) ? "default" : "error"}
							clickable
							onClick={() => handleEdit(index)}
							{...getTagProps({index})}
						/>
					))}
				</Fragment>
			)}
			disabled={disabled}
			fullWidth
		/>
	);
}

function isValid(orderNumber: string) {
	return Boolean(orderNumber.match(/(43|44|45|46|49|80|93)\d\d\d\d\d\d\d\d/));
}
