import {AssignmentOutlined} from "@mui/icons-material";
import {Card, CardContent, CardHeader, Divider, Grid} from "@mui/material";
import React from "react";
import {Invoice} from "../../../api";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";

export function InvoiceRejected({invoice}: { invoice: Invoice }) {
	const {t} = useLocalization();
	return (
		<Card>
			<CardHeader
				title={
					<Typography startAdornment={<AssignmentOutlined/>}>{t("invoices.details.rejected")}</Typography>
				}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>{invoice.oebbRejectReason}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Divider/>
					</Grid>
					<Grid item xs={12}>
						<Typography color="textSecondary" fontStyle="italic">
							{t("invoices.details.rejectedHint")}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
