import {Box, CardContent, CardHeader, Grid, LinearProgress, Stack} from "@mui/material";
import {Checkbox, EditForm, ErrorAlert, RadioGroup, TextField} from "@variocube/app-ui";
import React, {useEffect, useMemo, useState} from "react";
import zxcvbn from "zxcvbn";
import {User, UserMutation, UserRole} from "../../api";
import {Typography} from "../../component/typography";
import {isCreditor, useUser} from "../../context/auth";
import {useLocalization} from "../../i18n";
import {notBlank} from "../../util";

interface UserFormProps {
	loading: boolean;
	user?: User;
	onSave: (data: UserMutation) => Promise<void>;
	onCancel?: () => void;
}

const OEBB_ROLES: UserRole[] = [
	"SuperUser",
	"OebbAdministrator",
	"OebbUser",
	"OebbApiUser",
];

export function UserForm({loading, user, onSave, onCancel}: UserFormProps) {
	const {t, s} = useLocalization();

	const loggedInUser = useUser();

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [role, setRole] = useState<UserRole>(isCreditor(loggedInUser) ? "CreditorUser" : "OebbUser");
	const [manageAuditors, setManageAuditors] = useState(false);

	const [invalidPassword, setInvalidPassword] = useState<string>();

	useEffect(() => {
		if (user !== undefined) {
			setFirstname(user.firstname ?? "");
			setLastname(user.lastname ?? "");
			setEmail(user.email ?? "");
			setPhone(user.phone ?? "");
			setRole(user.role);
			setManageAuditors(user.canManageAuditors ?? false);
		}
	}, [user]);

	async function handleSave() {
		setInvalidPassword(undefined);
		if (notBlank(password) && percentage < 60) {
			setInvalidPassword(t("signup.weakPassword"));
			return;
		}

		await onSave({
			firstname,
			lastname,
			email,
			phone,
			password,
			role,
			canManageAuditors: manageAuditors,
		});
	}

	const {level, percentage} = useMemo(
		() => {
			if (!notBlank(password)) return {percentage: 0};
			switch (zxcvbn(password).score) {
				case 0: {
					return {level: t("passwordStrength.risky"), percentage: 20};
				}
				case 1: {
					return {level: t("passwordStrength.weak"), percentage: 40};
				}
				case 2: {
					return {level: t("passwordStrength.medium"), percentage: 60};
				}
				case 3: {
					return {level: t("passwordStrength.strong"), percentage: 80};
				}
				case 4: {
					return {level: t("passwordStrength.secured"), percentage: 100};
				}
				default: {
					return {percentage: 0};
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[password],
	);

	return (
		<EditForm loading={loading} labels={s("actions")} onSave={handleSave} onCancel={onCancel}>
			<CardHeader title={t("users.contactData")} />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("common.firstName")}
							fullWidth
							value={firstname}
							onChange={setFirstname}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("common.lastName")}
							fullWidth
							value={lastname}
							onChange={setLastname}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("common.email")}
							required
							fullWidth
							value={email}
							onChange={setEmail}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("common.phone")}
							fullWidth
							value={phone}
							onChange={setPhone}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("auth.password")}
							required={user === undefined}
							fullWidth
							type="password"
							value={password}
							onChange={setPassword}
						/>
						<Box my={1} />
						<LinearProgress
							variant="determinate"
							value={percentage}
							color={percentage < 60
								? "error"
								: (percentage > 79 ? "success" : "warning")}
						/>
						{level !== undefined && (
							<Typography
								sx={{
									color: percentage < 60
										? "red"
										: (percentage > 79 ? "green" : "orange"),
								}}
							>
								<strong>{level}</strong>
							</Typography>
						)}
					</Grid>
					<Grid item xs={12}>
						{OEBB_ROLES.includes(role)
							? (
								<Stack
									direction="column"
									gap={2}
								>
									<RadioGroup
										label={t("users.role")}
										value={role}
										onChange={setRole}
										options={OEBB_ROLES}
										renderLabel={s("users.roles")}
									/>
									<Checkbox
										label={t("users.manageAuditors")}
										value={manageAuditors}
										onChange={setManageAuditors}
									/>
								</Stack>
							)
							: (!isCreditor(loggedInUser) && <Typography>{t("users.nonOebb")}</Typography>)}
					</Grid>
					{invalidPassword !== undefined && (
						<Grid item xs={12}>
							<ErrorAlert error={invalidPassword} />
						</Grid>
					)}
				</Grid>
			</CardContent>
		</EditForm>
	);
}
