import {useLocalization} from "../../i18n";
import {
	BreadcrumbItem,
	Breadcrumbs,
	ConfirmIconButton,
	DataTable,
	DataTableColumn,
	EditForm,
	Now,
	PlainDate,
	PlainDatePicker,
	TextField,
	useFlag,
	View,
	ViewHeader
} from "@variocube/app-ui";
import React, {useMemo, useState} from "react";
import {
	Alert,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	Dialog,
	DialogTitle,
	Grid,
	Stack,
	Typography
} from "@mui/material";
import {useAuthContext} from "../../context/auth";
import {InboxRef, useIndexesApi, User, UserRef} from "../../api";
import {InboxBadge} from "../../component/inbox-badge";
import {getUserDisplayName} from "../../utils/user";
import {useAsync} from "react-async-hook";
import {DeleteOutline} from "@mui/icons-material";

export function OebbUserSettings() {
	const {t} = useLocalization();
	const {user, reloadAuth} = useAuthContext();
	const {
		updateUserVacation,
		deleteUserVacation,
		getUserProxies,
		getProxiedUsers,
		addProxiedUser,
		deleteProxiedUser,
	} = useIndexesApi();

	const {result: proxies} = useAsync(getUserProxies, []);
	const {result: proxied} = useAsync(getProxiedUsers, []);

	async function handleUpdateUserVacation(from: PlainDate, until: PlainDate) {
		await updateUserVacation({
			from: from.toJSON(),
			until: until.toJSON(),
		});
		await reloadAuth();
	}

	async function handleClearUserVacation() {
		await deleteUserVacation();
		await reloadAuth();
	}

	async function handleAddProxied(email: string) {
		await addProxiedUser(email);
		await reloadAuth();
	}

	async function handleDeleteProxied(userId: number) {
		await deleteProxiedUser(userId);
		await reloadAuth();
	}

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.admin")}</BreadcrumbItem>
				<BreadcrumbItem>{t("navigation.userSettings")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("navigation.userSettings")}/>

			{user && (
				<div>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<Stack
								direction="column"
								spacing={2}
							>
								<Card>
									<CardHeader title="ÖBB User"/>
									<CardContent>
										<Typography>{t("settings.oebbUserHint")}</Typography>
									</CardContent>
								</Card>
								<OutOfOffice
									user={user}
									onUpdate={handleUpdateUserVacation}
									onClear={handleClearUserVacation}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Stack
								direction="column"
								spacing={2}
							>
								<InboxTable
									title={t("users.mailboxes.worksAt.title")}
									inboxes={user.worksAt}
								/>
								<InboxTable
									title={t("inboxes.plural")}
									inboxes={user.inboxes}
								/>
								<ProxyTable
									title={t("users.proxies.proxiedBy")}
									users={proxied ?? []}
									onAdd={handleAddProxied}
									onDelete={handleDeleteProxied}
								/>
								<ProxyTable
									title={t("users.proxies.title")}
									users={proxies ?? []}
								/>
							</Stack>
						</Grid>
					</Grid>
				</div>
			)}
		</View>
	)
}

interface OutOfOfficeProps {
	user: User;
	onUpdate: (from: PlainDate, until: PlainDate) => Promise<void>;
	onClear: () => Promise<void>;
}

function OutOfOffice({user, onUpdate, onClear}: OutOfOfficeProps) {
	const {t, s} = useLocalization();

	const [from, setFrom] = useState<PlainDate | null>(user.vacation?.from ? PlainDate.from(user.vacation.from) : null);
	const [until, setUntil] = useState<PlainDate | null>(user.vacation?.until ? PlainDate.from(user.vacation.until) : null);

	const isActive = useMemo(() => {
		if (user.vacation?.from && user.vacation.until) {
			const now = Now.plainDateISO();
			return PlainDate.compare(now, PlainDate.from(user.vacation.from)) > -1
				&& PlainDate.compare(now, PlainDate.from(user.vacation.until)) < 1
		}
		return false;
	}, [user.vacation])

	async function handleSave() {
		if (from && until) {
			await onUpdate(from, until);
		}
	}

	return (
		<EditForm
			loading={false}
			onSave={handleSave}
			labels={s("actions")}
		>
			<CardHeader title="Out of Office"/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<PlainDatePicker
							fullWidth required
							label="From"
							value={from}
							onChange={setFrom}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<PlainDatePicker
							fullWidth required
							label="Until"
							minDate={from ?? undefined}
							value={until}
							onChange={setUntil}
						/>
					</Grid>
					{isActive && (
						<Grid item xs={12}>
							<Alert
								severity="info"
								action={(
									<Button color="inherit" onClick={onClear}>Clear</Button>
								)}
							>
								Out of office is currently active.
							</Alert>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</EditForm>
	)
}

interface InboxTableProps {
	title: string;
	inboxes: InboxRef[];
}

function InboxTable({title, inboxes}: InboxTableProps) {
	const {t} = useLocalization();
	const columns = useMemo<DataTableColumn<InboxRef>[]>(() => [
		{
			field: "name",
			label: t("inboxes.singular"),
			component: ({row}) => (
				<Typography variant="body1">
					{row.name}
				</Typography>
			)
		},
		{
			field: "businessCenterAddress",
			label: "BCA",
			component: ({row}) => (
				<Chip label={row.businessCenterAddress}/>
			)
		},
		{
			field: "badges",
			label: "Infos",
			component: ({row}) => (
				<InboxBadge inbox={row}/>
			)
		},
	], [t]);

	return (
		<Card>
			<CardHeader title={title}/>
			<DataTable
				columns={columns}
				rows={inboxes}
				loading={false}
			/>
		</Card>
	)
}

interface ProxyTableProps {
	title: string;
	users: UserRef[];
	onDelete?: (userId: number) => Promise<void>;
	onAdd?: (email: string) => Promise<void>;
}

function ProxyTable({title, users, onDelete, onAdd}: ProxyTableProps) {
	const {t, s} = useLocalization();

	const [email, setEmail] = useState("");
	const [add, setAdd, clearAdd] = useFlag(false);

	const columns = useMemo<DataTableColumn<UserRef>[]>(() =>
			[
				{
					field: "name",
					label: t("common.name"),
					component: ({row}) => (
						<Typography variant="body1">
							{getUserDisplayName(row)}
						</Typography>
					)
				},
				{
					field: "email",
					label: t("common.email"),
					component: ({row}) => (
						<Typography variant="body1">
							{row.email}
						</Typography>
					)
				},
				{
					field: "phone",
					label: t("common.phone"),
					component: ({row}) => (
						<Typography variant="body1">
							{row.phone}
						</Typography>
					)
				},
				{
					field: "action",
					label: "",
					component: ({row}) => (
						<div>
							{onDelete && (
								<ConfirmIconButton
									title={t("users.proxies.revoke.title")}
									cancel={t("actions.cancel")}
									onConfirm={() => onDelete(row.id)}
									icon={<DeleteOutline/>}
									color="error"
								>
									{t("users.proxies.revoke.prompt")}
								</ConfirmIconButton>
							)}
						</div>
					)
				}
			]
		, [t]);

	async function handleAdd() {
		if (onAdd && email) {
			await onAdd(email);
		}
	}

	return (
		<>
			<Card>
				<CardHeader title={title}/>
				<DataTable
					columns={columns}
					rows={users}
					loading={false}
				/>
				{onAdd && (
					<CardActions>
						<Button color="secondary" variant="outlined"
								onClick={setAdd}>{t("users.proxies.assign")}</Button>
					</CardActions>
				)}
			</Card>
			<Dialog open={add} fullWidth maxWidth="sm">
				<DialogTitle>{t("users.proxies.assign")}</DialogTitle>
				<EditForm
					loading={false}
					onSave={handleAdd}
					onCancel={clearAdd}
					labels={s("actions")}
				>
					<Grid container spacing={2} padding={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth required
								value={email}
								onChange={setEmail}
								type="email"
								label={t("common.email")}
							/>
						</Grid>
					</Grid>
				</EditForm>
			</Dialog>
		</>
	)
}
