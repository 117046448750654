import {Autocomplete} from "@mui/material";
import {ErrorAlert, TextField} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Inbox, Outbox, useInboxesApi} from "../api";
import {useLocalization} from "../i18n";

interface OutboxSelectProps {
	value: Outbox | null;
	onChange: (outbox: Outbox | null) => any;
	inbox: Inbox;
	required?: boolean;
	fullWidth?: boolean;
}

export function OutboxSelect(props: Readonly<OutboxSelectProps>) {
	const {value, onChange, inbox, required, fullWidth} = props;
	const {t} = useLocalization();
	const {getInboxOutboxes} = useInboxesApi();

	const {loading, error, result} = useAsync(getInboxOutboxes, [inbox.id]);

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return (
		<Autocomplete
			fullWidth={fullWidth}
			renderInput={props => (
				<TextField
					label={t("invoices.routing.outbox")}
					required={required}
					{...props}
				/>
			)}
			loading={loading}
			options={result ?? []}
			renderOption={(props, outbox) => <li {...props}>{outbox.name}</li>}
			getOptionLabel={outbox => outbox.name ?? ""}
			value={value}
			onChange={(_, value) => onChange(value)}
		/>
	);
}
