import {TypeOf} from "zod";
import {InfoMutation, useApiClient} from "./client";
import {get_QueryInfos} from "./client.gen";

export type InfoQuery = TypeOf<get_QueryInfos["parameters"]>["query"];

export function useInfoApi() {
	const api = useApiClient();
	return {
		queryInfos: (query: InfoQuery) => api.get("/api/v2/infos", {query}),
		getInfo: (id: number) => api.get("/api/v2/infos/{id}", {path: {id}}),
		createInfo: (mutation: InfoMutation) => api.post("/api/v2/infos", {body: mutation}),
		updateInfo: (id: number, mutation: InfoMutation) => api.put("/api/v2/infos/{id}", {path: {id}, body: mutation}),
		deleteInfo: (id: number) => api.delete("/api/v2/infos/{id}", {path: {id}}),
	};
}
