import {CopyAll, Delete} from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	FormLabel,
	Grid,
	IconButton,
	LinearProgress,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import {alpha} from "@mui/system/colorManipulator";
import {defined, ErrorAlert, TextField} from "@variocube/app-ui";
import React from "react";
import {useDropzone} from "react-dropzone";
import {Inbox} from "../../../../api";
import {BcaSelect} from "../../../../component/bca-select";
import {FilePreview} from "../../../../component/file-preview";
import {OrderNumbersField} from "../../../../component/order-numbers-field";
import {UnsupportedFileTypeAlert} from "../../../../component/unsupported-file-type-alert";
import {useLocalization} from "../../../../i18n";
import {AttachmentItem} from "../../attachment-item";
import {InvoiceDuplicates} from "../duplicates";
import {InvoiceFile} from "../types";

interface InvoiceCardProps {
	invoice: InvoiceFile;
	onDelete: () => void;
	onChangeInboxId: (inboxId: number | undefined, inbox: Inbox | null) => void;
	onChangeContactOebb: (contactOebb: string) => void;
	onChangeOrderNumbers: (orderNumber: string[]) => void;
	onChangeAdditionalInfo: (additionalInfo: string) => void;
	onApplyToOther: () => void;
	onAddAttachments: (files: ReadonlyArray<File>) => void;
	onDeleteAttachment: (attachment: File) => void;
}

export function InvoiceCard(props: Readonly<InvoiceCardProps>) {
	const {
		invoice,
		onDelete,
		onChangeInboxId,
		onChangeContactOebb,
		onChangeOrderNumbers,
		onChangeAdditionalInfo,
		onApplyToOther,
		onAddAttachments,
		onDeleteAttachment,
	} = props;

	const {t} = useLocalization();

	const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
		useFsAccessApi: false,
		multiple: true,
		onDrop: onAddAttachments,
		noClick: true,
		noDragEventsBubbling: true,
	});

	const theme = useTheme();

	return (
		<Card
			{...getRootProps()}
			sx={{
				border: `1px solid ${isDragActive ? theme.palette.secondary.main : undefined}`,
				backgroundColor: isDragActive
					? alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity)
					: undefined,
			}}
		>
			<input {...getInputProps()} />
			<CardHeader
				title={invoice.file.name}
				action={
					<Stack spacing={1} direction="row">
						<IconButton title={t("invoices.new.creditor.applyToOtherInvoices")} onClick={onApplyToOther}>
							<CopyAll />
						</IconButton>
						<IconButton
							color="error"
							onClick={onDelete}
							title={t("actions.delete")}
						>
							<Delete />
						</IconButton>
					</Stack>
				}
			/>

			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<FilePreview file={invoice.file} />
					</Grid>
					<Grid item xs={12} sm={6} md={8}>
						<Stack spacing={2}>
							<BcaSelect
								label={t("invoices.recipient")}
								required
								value={invoice.inboxId}
								onChange={onChangeInboxId}
							/>
							<TextField
								label={t("invoices.details.contactPerson")}
								fullWidth
								value={invoice.contactOebb}
								onChange={onChangeContactOebb}
							/>
							<OrderNumbersField
								value={invoice.orderNumbers}
								onChange={onChangeOrderNumbers}
							/>
							<TextField
								label={t("invoices.details.additionalInformation")}
								fullWidth
								multiline
								rows={2}
								value={invoice.additionalInfo}
								onChange={onChangeAdditionalInfo}
							/>

							<Box>
								<FormLabel>{t("invoices.details.attachments")}</FormLabel>
								{invoice.attachments.length == 0 && (
									<Typography variant="body2" color="textSecondary" paragraph>
										{t("invoices.new.creditor.noAttachments")}
									</Typography>
								)}
								<Box>
									<Grid container spacing={2}>
										{invoice.attachments.map(attachment => (
											<Grid item key={attachment.file.name} xs={6} sm={3}>
												<AttachmentItem
													attachment={attachment}
													onDelete={() =>
														onDeleteAttachment(attachment.file)}
												/>
											</Grid>
										))}
										{invoice.attachments.filter(attachment => attachment.error).map(attachment => (
											<Grid key={"error-" + attachment.file.name} item xs={12}>
												<ErrorAlert error={attachment.error} />
											</Grid>
										))}
									</Grid>
								</Box>
								<Box>
									<Button color="secondary" onClick={open} variant="outlined">
										{t("invoices.details.addAttachments")}
									</Button>
								</Box>
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</CardContent>
			{invoice.duplicates && <InvoiceDuplicates duplicates={invoice.duplicates} />}
			{invoice.error && <ErrorAlert error={invoice.error} />}
			{defined(invoice.supported) && !invoice.supported && (
				<UnsupportedFileTypeAlert supported={invoice.inbox?.assignedInvoiceDocumentTypes ?? []} />
			)}
			{invoice.attachments.some(attachment => defined(attachment.supported) && !attachment.supported) && (
				<UnsupportedFileTypeAlert supported={invoice.inbox?.assignedAttachmentDocumentTypes ?? []} />
			)}
			<LinearProgress
				variant="determinate"
				value={(invoice.progress ?? 0) * 100}
				color={getInvoiceColor(invoice)}
			/>
		</Card>
	);
}

export function getInvoiceColor(invoice: InvoiceFile) {
	if (
		invoice.error
		|| invoice.duplicates
		|| (defined(invoice.supported) && !invoice.supported)
		|| invoice.attachments.some(attachment => defined(attachment.supported) && !attachment.supported)
	) {
		return "error";
	}
	if (invoice.checksum) {
		return "success";
	}
	return "secondary";
}
