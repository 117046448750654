import {Invoice, User} from "../api";
import {hasRoles} from "../context/auth";

export function canAuditInvoice(user?: User, invoice?: Invoice) {
	return Boolean(
		// invoice must be defined
		invoice
			// user must be defined
			&& user
			// user is SuperUser or OebbAdministrator, or OebbUser that is the list of auditors
			&& (
				hasRoles(user, ["SuperUser", "OebbAdministrator"])
				|| (hasRoles(user, ["OebbUser"]) && user.email && invoice.auditors?.includes(user.email))
				|| user.auditsInboxes.find(inbox => inbox.id == invoice.inbox.id)
			)
			// invoice was not audited yet
			&& !invoice.audited
			// invoice was not rejected by öbb
			&& !invoice.oebbRejected
			// invoices of creditor types must have an outbox and the inbox must require audit
			&& (
				(invoice.invoiceType != "CreditorInvoice" && invoice.invoiceType != "CreditorCreditNote")
				|| (invoice.outbox && invoice.inbox?.requiresAudit)
			),
	);
}

export function canAudit(user?: User) {
	return hasRoles(user, ["SuperUser"])
		|| (hasRoles(user, ["OebbAdministrator", "OebbUser"]) && user && user.auditsInboxes.length > 0);
}
