import {Business} from "@mui/icons-material";
import {Card, CardContent, CardHeader, type CardProps as MuiCardProperties} from "@mui/material";
import React from "react";
import {type InvoiceDebitor} from "../../../api";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {notBlank} from "../../../util";

export function Debitor({debitor, CardProps}: { debitor: InvoiceDebitor; CardProps?: MuiCardProperties }) {
	const {t} = useLocalization();
	return (
		<Card {...CardProps}>
			<CardHeader title={<Typography startAdornment={<Business />}>{t("debitor.singular")}</Typography>} />
			<CardContent>
				<Typography>{debitor.name}</Typography>
				<Typography>{debitor.streetAndHouseNo}</Typography>
				<Typography>{debitor.zip} {debitor.city}</Typography>
				<Typography>{debitor.countryCode}</Typography>
				{notBlank(debitor.vat) && <Typography>UST-ID: {debitor.vat}</Typography>}
			</CardContent>
		</Card>
	);
}
