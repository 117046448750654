import {Box, CardContent, Collapse, FormHelperText, Stack} from "@mui/material";
import {RadioGroup, TextField} from "@variocube/app-ui";
import React, {useState} from "react";
import {Country} from "../../../../../component/country";
import {CountrySelect} from "../../../../../component/country-select";
import {LabeledText} from "../../../../../component/labeled-text";
import {ExpenseInvoiceBusinessCase} from "../../types";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

export function DetailStep({active, completed, invoice, onChange}: Readonly<StepProps>) {
	function change(index: number, change: Partial<ExpenseInvoiceBusinessCase>) {
		onChange({
			businessCases: invoice.businessCases?.map((businessCase, i) =>
				index == i ? ({...businessCase, ...change}) : businessCase
			),
		});
	}
	const hasCaseNumber = invoice.businessCases?.some(businessCase => businessCase.caseNumber);
	const canHaveTrip = invoice.businessCases?.some(businessCase => businessCase.trip);

	const [trip, setTrip] = useState<"Ja" | "Nein">();

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title="Weitere Angaben"
			activeChildren={
				<CardContent>
					<Stack spacing={2}>
						<CountrySelect
							label="Ausstellungsland"
							value={invoice.countryCode}
							onChange={countryCode => onChange({countryCode})}
							required
						/>
						{invoice.businessCases?.map((businessCase, index) => (
							<TextField
								key={businessCase.id}
								label={`Angaben zum Geschäftsfall bzw. Grund der Bargeldauslage für ${businessCase.name}`}
								fullWidth
								required
								value={businessCase.userDescription}
								onChange={userDescription => change(index, {userDescription})}
							/>
						))}
						{hasCaseNumber && (
							<Box>
								<TextField
									label="Fallnummer"
									value={invoice.caseNumber}
									required={hasCaseNumber}
									onChange={caseNumber => onChange({caseNumber})}
									inputProps={{
										pattern: "[0-9]{6}",
									}}
								/>
								<FormHelperText>
									Bitte geben Sie die Fallnummer laut Antrag HR Portal "APP Bildschirmbrille" ein
									(z.B.: 241234)!
								</FormHelperText>
							</Box>
						)}
						{canHaveTrip && (
							<Stack spacing={2}>
								<RadioGroup
									label="Bewirtung im Rahmen einer Dienstreise?"
									value={trip}
									onChange={setTrip}
									options={["Ja", "Nein"]}
									row
								/>
								<Collapse in={trip == "Ja"}>
									<Box>
										<TextField
											label="Reisenummer (Trip-ID)"
											value={invoice.tripId}
											onChange={tripId => onChange({tripId})}
											inputProps={{
												pattern: "[0-9]{6}",
											}}
										/>
										<FormHelperText>
											Bitte geben Sie die Reisenummer (Trip-ID) an.
										</FormHelperText>
									</Box>
								</Collapse>
							</Stack>
						)}
					</Stack>
				</CardContent>
			}
			completedChildren={
				<CardContent>
					<Stack direction="row" gap={2} flexWrap="wrap">
						<LabeledText label="Ausstellungsland" text={<Country countryCode={invoice.countryCode} />} />
						{invoice.businessCases?.map((businessCase, index) => (
							<LabeledText
								key={index}
								label={`Angaben zu ${businessCase.name}`}
								text={businessCase.userDescription}
							/>
						))}
						{hasCaseNumber && <LabeledText label="Fallnummer" text={invoice.caseNumber} />}
						{invoice.tripId && <LabeledText label="Reisenummer (Trip-ID)" text={invoice.tripId} />}
					</Stack>
				</CardContent>
			}
		/>
	);
}
