import {Autocomplete} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import {code, codes} from "currency-codes";
import React from "react";
import {useLocalization} from "../i18n";

interface CurrencySelectProps {
	label: string;
	value?: string;
	required?: boolean;
	onChange: (value: string | undefined) => void;
}

export function CurrencySelect(props: Readonly<CurrencySelectProps>) {
	const {label, value, required, onChange} = props;
	const {t} = useLocalization();

	const currencies = codes().sort((a, b) => getGroup(a) - getGroup(b));

	return (
		<Autocomplete
			options={currencies}
			value={value}
			groupBy={currency => getGroupName(getGroup(currency))}
			getOptionLabel={currency => `${currency} - ${code(currency)?.currency}`}
			renderInput={(parameters) => (
				<TextField
					placeholder={t("hint.select")}
					required={required}
					label={label}
					{...parameters}
				/>
			)}
			onChange={(_, value) => onChange(value ?? undefined)}
			autoSelect
			autoHighlight
		/>
	);
}

function getGroup(code: string) {
	switch (code) {
		case "EUR":
			return 0;
		case "USD":
		case "HUF":
		case "CZK":
		case "CHF":
		case "GBP":
			return 1;
		default:
			return 2;
	}
}

function getGroupName(group: 0 | 1 | 2) {
	switch (group) {
		case 0:
			return "Meistens";
		case 1:
			return "Manchmal";
		case 2:
			return "Selten";
	}
}
