import {Box, Paper} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import Highlight from "react-highlight";
import {Link as RouterLink} from "react-router-dom";
import {useInvoicesApi} from "../../api";
import {Typography} from "../../component/typography";
import {useLocalization} from "../../i18n";
import {notBlank} from "../../util";
import {useNumericParam} from "../../utils/useParam";

import "../../../../../node_modules/highlight.js/styles/stackoverflow-light.css";

export function InvoiceXml() {
	const {t, e} = useLocalization();
	const id = useNumericParam("id");
	const {getInvoice, getInvoiceXml} = useInvoicesApi();

	const {
		result: [
			invoice,
			xml,
		] = [],
		error,
		loading,
	} = useAsync(async () =>
		Promise.all([
			getInvoice(id),
			getInvoiceXml(id),
		]), [id]);

	return (
		<View
			{...{loading, error}}
		>
			<Breadcrumbs>
				<BreadcrumbLink component={RouterLink} to="/invoices">{t("invoices.plural")}</BreadcrumbLink>
				<BreadcrumbLink component={RouterLink} to={`/invoices/${id}`}>
					{t("invoices.details.title")} {id}
				</BreadcrumbLink>
				<BreadcrumbItem>XML</BreadcrumbItem>
			</Breadcrumbs>
			<Typography variant="h1">XML</Typography>
			<Paper
				component={Highlight}
				className="xml"
				variant="outlined"
				sx={{
					backgroundColor: "white !important",
				}}
			>
				{xml?.content}
			</Paper>
			<Box my={1} />
			{invoice && (
				<>
					{notBlank(invoice.barcode) && (
						<Typography color="textSecondary" justifyContent="flex-end">
							Dateiname/Barcode Transfer: {invoice.barcode}
						</Typography>
					)}
				</>
			)}
		</View>
	);
}
