import {Chip} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {InvoiceSummary} from "../api";
import {Typography} from "./typography";
import {InvoiceTypeIcon} from "../pages/invoice/invoice-type-icon";

export function InvoiceLinkChip({id, invoiceType}: Pick<InvoiceSummary, "id" | "invoiceType">) {
	return (
		<Chip
			variant="outlined"
			clickable
			component={RouterLink}
			to={`/invoices/${id}`}
			color="secondary"
			sx={{pl: 1}}
			icon={<InvoiceTypeIcon invoiceType={invoiceType}/>}
			label={
				<Typography fontFamily="Jetbrains Mono" variant="body1">
					{id}
				</Typography>
			}
		/>
	);
}
