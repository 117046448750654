import AddIcon from "@mui/icons-material/Add";
import {Button, ButtonGroup, Chip, Link, Typography} from "@mui/material";
import {DataTable, DataTableColumn, View, ViewHeader} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {Inbox, useInboxesApi} from "../../../api";
import {InboxBadge} from "../../../component/inbox-badge";
import {useLocalization} from "../../../i18n";

export function InboxList() {
	const {t} = useLocalization();
	const {queryInboxes} = useInboxesApi();
	const query = useMemo(() => ({}), []);
	const {result, loading, error} = useAsync(queryInboxes, [query]);

	const columns = useMemo<DataTableColumn<Inbox>[]>(() => [
		{
			field: "name",
			label: t("inboxes.singular"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/superuser/mailbox/${row.id}`} color="secondary">
						{row.name}
					</Link>
				</Typography>
			),
		},
		{
			field: "businessCenterAddress",
			label: "BCA",
			component: ({row}) => <Chip label={row.businessCenterAddress} />,
		},
		{
			field: "badges",
			label: "Infos",
			component: ({row}) => <InboxBadge inbox={row} />,
		},
	], [t]);

	return (
		<View>
			<ViewHeader
				title={t("users.mailboxes.inboxes.title")}
				actions={
					<ButtonGroup variant="outlined" disableElevation>
						<Button
							component={RouterLink}
							to="/superuser/mailbox/new"
							startIcon={<AddIcon />}
							color="inherit"
						>
							{t("inboxes.create.title")}
						</Button>
					</ButtonGroup>
				}
			/>
			<DataTable
				columns={columns}
				rows={result ?? []}
				loading={loading}
				error={error}
			/>
		</View>
	);
}
