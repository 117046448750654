import {FormatListBulleted} from "@mui/icons-material";
import {
	Card,
	CardContent,
	CardHeader,
	Hidden,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
} from "@mui/material";
import {TemporalFormat, tryParsePlainDate} from "@variocube/app-ui";
import React, {Fragment, useMemo} from "react";
import {Invoice, type InvoiceDate, type InvoiceLine, type InvoiceReference, type InvoiceText} from "../../../api";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {formatAmount, localizeNumber, notNull} from "../../../util";

export function InvoicePositions({invoice}: { invoice: Invoice }) {
	const {t, language} = useLocalization();
	return (
		<Card>
			<CardHeader
				title={
					<Typography startAdornment={<FormatListBulleted/>}>
						{t("invoices.details.invoicePositions")}
					</Typography>
				}
			/>
			<CardContent sx={{p: "0 !important"}}>
				<Table>
					<TableHead>
						<TableRow>
							<Hidden smDown>
								<TableCell>#</TableCell>
							</Hidden>
							<TableCell>{t("invoices.details.positionText")}</TableCell>
							<TableCell>{t("common.quantity")}</TableCell>
							<TableCell>{t("common.net")}</TableCell>
							<Hidden smDown>
								<TableCell align="right">{t("common.discount")}</TableCell>
								<TableCell align="right">{t("common.tax")}</TableCell>
								<TableCell align="right">{t("common.gross")}</TableCell>
							</Hidden>
						</TableRow>
					</TableHead>
					<TableBody>
						{(invoice.lines ?? []).map(l => (
							<Fragment key={l.position}>
								<InvoiceLineRow invoiceLine={l}/>
								<InvoiceLineQty invoiceLine={l}/>
								<InvoiceLineDates invoiceLine={l} dates={invoice.dates}/>
								<InvoiceLineReferences invoiceLine={l} references={invoice.references}/>
								<InvoiceLineTexts invoiceLine={l} texts={invoice.texts}/>
							</Fragment>
						))}
					</TableBody>
					<Hidden smDown>
						<TableFooter>
							{notNull(invoice.net) && (
								<TableRow>
									<TableCell colSpan={6}>
										<Typography justifyContent="flex-end" noWrap>{t("common.net")}</Typography>
									</TableCell>
									<TableCell>
										<Typography justifyContent="flex-end" noWrap>
											{formatAmount(invoice.net as number, language)}
										</Typography>
									</TableCell>
								</TableRow>
							)}
							{notNull(invoice.tax) && (
								<TableRow>
									<TableCell colSpan={6}>
										<Typography justifyContent="flex-end" noWrap>USt.:</Typography>
									</TableCell>
									<TableCell>
										<Typography justifyContent="flex-end" noWrap>
											{formatAmount(invoice.tax as number, language)}
										</Typography>
									</TableCell>
								</TableRow>
							)}
							{notNull(invoice.gross) && (
								<TableRow>
									<TableCell colSpan={6}>
										<Typography justifyContent="flex-end" noWrap>{t("common.gross")}</Typography>
									</TableCell>
									<TableCell>
										<Typography justifyContent="flex-end" noWrap>
											{formatAmount(invoice.gross as number, language)}
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Hidden>
				</Table>
			</CardContent>
		</Card>
	);
}

function InvoiceLineRow({invoiceLine}: { invoiceLine: InvoiceLine }) {
	const {language} = useLocalization();
	return (
		<TableRow>
			<Hidden smDown>
				<TableCell>
					<Typography>{invoiceLine.position}</Typography>
				</TableCell>
			</Hidden>
			<TableCell>
				<Typography>{invoiceLine.articleNo}</Typography>
				<Typography>{invoiceLine.text}</Typography>
			</TableCell>
			<TableCell sx={{verticalAlign: "top"}}>
				<Typography justifyContent="flex-end" noWrap>
					{invoiceLine.units}{" "}
					{notNull(invoiceLine.qty) && localizeNumber(invoiceLine.qty as number, language)}
				</Typography>
			</TableCell>
			<TableCell sx={{verticalAlign: "top"}}>
				{notNull(invoiceLine.net) && (
					<Typography justifyContent="flex-end" noWrap>
						{formatAmount(invoiceLine.net as number, language)}
					</Typography>
				)}
			</TableCell>
			<Hidden smDown>
				<TableCell sx={{verticalAlign: "top"}}>
					{notNull(invoiceLine.discount) && (
						<Typography justifyContent="flex-end" noWrap>
							{formatAmount(invoiceLine.discount as number, language)}
						</Typography>
					)}
				</TableCell>
				<TableCell sx={{verticalAlign: "top"}}>
					{notNull(invoiceLine.tax) && (
						<Typography justifyContent="flex-end" noWrap>
							{formatAmount(invoiceLine.tax as number, language)}
						</Typography>
					)}
					{notNull(invoiceLine.taxRate) && (
						<Typography justifyContent="flex-end" noWrap>
							{localizeNumber(invoiceLine.taxRate as number, language)}%
						</Typography>
					)}
				</TableCell>
				<TableCell sx={{verticalAlign: "top"}}>
					{notNull(invoiceLine.gross) && (
						<Typography justifyContent="flex-end" noWrap>
							{formatAmount(invoiceLine.gross as number, language)}
						</Typography>
					)}
				</TableCell>
			</Hidden>
		</TableRow>
	);
}

function InvoiceLineQty({invoiceLine}: { invoiceLine: InvoiceLine }) {
	const {t, language} = useLocalization();
	return (invoiceLine.qty ?? 0) > 1
		? (
			<TableRow>
				<Hidden smDown>
					<TableCell/>
				</Hidden>
				<TableCell colSpan={3}>
					<Typography gutterBottom>{t("invoices.details.positionSinglePrice")}</Typography>
					<Typography noWrap>
						{t("common.net")}: {formatAmount(invoiceLine.singleUnit.net, language)}
						&nbsp;-&nbsp;
						{t("common.gross")}: {formatAmount(invoiceLine.singleUnit.gross, language)}
					</Typography>
				</TableCell>
				<Hidden smDown>
					<TableCell colSpan={3}/>
				</Hidden>
			</TableRow>
		)
		: <div/>;
}

function InvoiceLineDates({invoiceLine, dates = []}: { invoiceLine: InvoiceLine; dates?: InvoiceDate[] }) {
	const invoiceDates = useMemo(() => dates.filter(d => d.line === invoiceLine.position), [invoiceLine, dates]);
	return invoiceDates.length > 0
		? (
			<TableRow>
				<Hidden smDown>
					<TableCell/>
				</Hidden>
				<TableCell colSpan={3}>
					{invoiceDates.map(d => (
						<Typography gutterBottom key={d.label}>
							{d.label}
							<br/>
							<TemporalFormat value={tryParsePlainDate(d.date)}/>
						</Typography>
					))}
				</TableCell>
				<Hidden smDown>
					<TableCell colSpan={3}/>
				</Hidden>
			</TableRow>
		)
		: <div/>;
}

function InvoiceLineReferences({invoiceLine, references = []}: {
	invoiceLine: InvoiceLine;
	references?: InvoiceReference[];
}) {
	const invoiceReferences = useMemo(() => references.filter(r => r.line === invoiceLine.position), [
		invoiceLine,
		references,
	]);
	return invoiceReferences.length > 0
		? (
			<TableRow>
				<Hidden smDown>
					<TableCell/>
				</Hidden>
				<TableCell colSpan={3}>
					{invoiceReferences.map(r => (
						<Typography gutterBottom key={r.label}>
							{r.label}: {r.reference}
						</Typography>
					))}
				</TableCell>
				<Hidden smDown>
					<TableCell colSpan={3}/>
				</Hidden>
			</TableRow>
		)
		: <div/>;
}

function InvoiceLineTexts({invoiceLine, texts = []}: { invoiceLine: InvoiceLine; texts?: InvoiceText[] }) {
	const invoiceTexts = useMemo(() => texts.filter(t => t.line === invoiceLine.position), [invoiceLine, texts]);
	return invoiceTexts.length > 0
		? (
			<TableRow>
				<Hidden smDown>
					<TableCell/>
				</Hidden>
				<TableCell colSpan={3}>
					{invoiceTexts.map(t => (
						<Typography gutterBottom key={t.label}>
							{t.label}: {t.text}
						</Typography>
					))}
				</TableCell>
				<Hidden smDown>
					<TableCell colSpan={3}/>
				</Hidden>
			</TableRow>
		)
		: <div/>;
}
