import {CloudDownload} from "@mui/icons-material";
import {Button, Card, CardActions} from "@mui/material";
import React from "react";
import {Invoice} from "../../../api";

interface InvoicePreviewProps {
	invoice: Invoice;
}

export function InvoicePreview({invoice}: Readonly<InvoicePreviewProps>) {
	const previewUrl = `/media/invoice/${invoice.id}/preview.png?scale=1.0`;
	const fileUrl = `/media/invoice/${invoice.id}/${invoice.originalFilename}`;

	return (
		<Card>
			<img src={previewUrl} alt={invoice.originalFilename} style={{display: "block", width: "100%"}} />
			<CardActions>
				<Button
					component="a"
					href={fileUrl}
					startIcon={<CloudDownload />}
					color="secondary"
					sx={{
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{invoice.originalFilename}
				</Button>
			</CardActions>
		</Card>
	);
}
