import {Card, CardContent, CardHeader, Divider, Grid} from '@mui/material';
import {FireExtinguisher} from "@mui/icons-material";
import React from 'react';
import {Typography} from '../../../component/typography';
import {useLocalization} from '../../../i18n';

export function Emergency({emergencyJustification}: { emergencyJustification: string }) {
    const {t} = useLocalization();
    return (
        <Card>
            <CardHeader title={(
                <Typography startAdornment={<FireExtinguisher />}>{t('invoices.details.emergency')}</Typography>
            )} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography fontStyle="italic">{emergencyJustification}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            color="textSecondary"
                            textAlign="justify"
                        >
                            {t('invoices.details.emergencyHint')}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
