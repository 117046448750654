import {createLocalizationContext} from "@variocube/app-ui";
import {registerLocale} from "i18n-iso-countries";
import de from "i18n-iso-countries/langs/de.json";
import en from "i18n-iso-countries/langs/en.json";
import hu from "i18n-iso-countries/langs/hu.json";

export const {
	StorageLocalizationProvider,
	useLocalization,
} = createLocalizationContext<typeof import("./en.json")>({
	load: lang => import(`./${lang}.json`),
	missing: window.location.hostname === "localhost" ? key => `??${key}??` : "fallback",
	fallback: "en",
});

export const SUPPORTED_LANGUAGES = [
	{language: "de", displayName: "Deutsch"},
	{language: "en", displayName: "English"},
	{language: "hu", displayName: "Magyar"},
] as const;

registerLocale(en);
registerLocale(de);
registerLocale(hu);

export type TFunc = ReturnType<typeof useLocalization>["t"];

export * from "i18n-iso-countries";
