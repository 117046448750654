import {Box, Button, Chip, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, ErrorAlert, View, ViewHeader} from "@variocube/app-ui";
import React, {FormEvent} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {FilePreview} from "../../../../component/file-preview";
import {useLocalization} from "../../../../i18n";
import {InvoiceFile} from "../types";

interface UploadInvoiceSummaryViewProps {
	invoices: InvoiceFile[];
	onBack: () => any;
	onSubmit: () => Promise<any>;
}

export function UploadInvoiceSummaryView({invoices, onBack, onSubmit}: Readonly<UploadInvoiceSummaryViewProps>) {
	const {t} = useLocalization();

	const submit = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();
		await onSubmit();
	});

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbLink component={RouterLink} to="/invoices">{t("invoices.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{t("invoices.new.creditor.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={t("invoices.new.creditor.title")}
				subTitle={t("invoices.new.creditor.submit")}
			/>
			<Paper>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t("invoices.singular")}</TableCell>
							<TableCell>{t("invoices.recipient")}</TableCell>
							<TableCell>{t("invoices.details.orderNumbers")}</TableCell>
							<TableCell>{t("invoices.details.additionalInformation")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{invoices.map(invoice => (
							<TableRow key={invoice.checksum}>
								<TableCell width={240}>
									<FilePreview file={invoice.file}/>
								</TableCell>
								<TableCell>
									<Box>{invoice.inbox?.name}</Box>
									<Box>{invoice.contactOebb}</Box>
								</TableCell>
								<TableCell>
									{invoice.orderNumbers.map(orderNumber => (
										<Chip key={orderNumber} label={orderNumber}/>
									))}
								</TableCell>
								<TableCell>
									{invoice.additionalInfo}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
			{submit.error && <ErrorAlert error={submit.error}/>}
			<form onSubmit={submit.execute}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Button color="inherit" variant="outlined" fullWidth onClick={onBack} disabled={submit.loading}>
							{t("actions.back")}
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button type="submit" variant="contained" fullWidth disabled={submit.loading}>
							{t("invoices.new.creditor.title")}
						</Button>
					</Grid>
				</Grid>
			</form>
		</View>
	);
}
