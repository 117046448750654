import React, {useState} from "react";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardHeader, Dialog,
	DialogTitle, Grid, IconButton, Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@mui/material";
import {useLocalization} from "../../../i18n";
import {DeleteOutline, EditOutlined} from "@mui/icons-material";
import {Checkbox, ConfirmIconButton, EditForm, TextField, useFlag} from "@variocube/app-ui";
import {Outbox, OutboxMutation} from "../../../api";

interface OutboxTableProps {
	outboxes: Outbox[];
	onCreate: (mutation: OutboxMutation) => Promise<void>;
	onUpdate: (id: number, mutation: OutboxMutation) => Promise<void>;
	onRemove: (id: number) => Promise<void>;
}

export function OutboxTable({outboxes, onCreate, onUpdate, onRemove}: OutboxTableProps) {
	const {t, s} = useLocalization();

	const [editMode, setEditMode, clearEditMode] = useFlag(false);

	const [name, setName] = useState("");
	const [bca, setBca] = useState("");
	const [flag, setFlag] = useState("");
	const [requiredOcr, setRequiredOcr] = useState(false);
	const [outboxId, setOutboxId] = useState<number|null>(null);

	function handleOutboxChange(outbox: Outbox|undefined) {
		setOutboxId(outbox?.id ?? null);
		setName(outbox?.name ?? "");
		setBca(outbox?.bca ?? "");
		setFlag(outbox?.flag ?? "");
		setRequiredOcr(outbox?.attachmentsRequireOcr ?? false);
		if (outbox !== undefined) {
			setEditMode();
		}
	}

	async function handleSubmit() {
		const mutation: OutboxMutation = {
			name,
			bca,
			flag,
			attachmentsRequireOcr: requiredOcr
		};
		if (outboxId) {
			await onUpdate(outboxId, mutation);
		} else {
			await onCreate(mutation);
		}
		handleOutboxChange(undefined);
		clearEditMode();
	}

	return (
		<>
			<Card>
				<CardHeader title={t("inboxes.outboxes.title")}/>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>{t('common.name')}</TableCell>
							<TableCell>BCA</TableCell>
							<TableCell>{t("inboxes.outboxes.flag")}</TableCell>
							<TableCell width={10}>&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{outboxes
							.sort((o1, o2) => o1.name.localeCompare(o2.name))
							.map(o => (
							<TableRow key={o.id}>
								<TableCell>{o.name}</TableCell>
								<TableCell>{o.bca}</TableCell>
								<TableCell>{o.flag}</TableCell>
								<TableCell>
									<Stack
										direction="row"
										gap={1}
									>
										<IconButton
											onClick={() => handleOutboxChange(o)}
										>
											<EditOutlined />
										</IconButton>
										<ConfirmIconButton
											title={t("inboxes.outboxes.delete.title")}
											cancel={t("actions.cancel")}
											onConfirm={() => onRemove(o.id)}
											icon={<DeleteOutline/>}
											color="error"
										>
											{t("inboxes.outboxes.delete.prompt")}
										</ConfirmIconButton>
									</Stack>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<CardActions>
					<Button color="secondary" onClick={setEditMode}>{t("inboxes.outboxes.create.title")}</Button>
				</CardActions>
			</Card>
			<Dialog open={editMode} onClose={clearEditMode} fullWidth maxWidth="sm">
				<DialogTitle>{t("inboxes.outboxes.create.title")}</DialogTitle>
				<EditForm
					loading={false}
					onSave={handleSubmit}
					onCancel={clearEditMode}
					labels={s("actions")}
				>
					<Box
						p={3}
					>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									fullWidth required
									label={t('common.name')}
									value={name}
									onChange={setName}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="BCA"
									value={bca}
									onChange={setBca}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label={t('inboxes.outboxes.flag')}
									value={flag}
									onChange={setFlag}
								/>
							</Grid>
							<Grid item xs={12}>
								<Checkbox
									label={t("inboxes.outboxes.requiredOcr")}
									value={requiredOcr}
									onChange={setRequiredOcr}
								/>
							</Grid>
						</Grid>
					</Box>

				</EditForm>
			</Dialog>
		</>
	)
}


