import React, {useMemo} from "react";
import {Link as RouterLink} from "react-router-dom";
import {
	DataTable,
	DataTableColumn,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
	View,
	ViewHeader
} from "@variocube/app-ui";
import {Chip, Link, Typography} from "@mui/material";
import {useLocalization} from "../../../i18n";
import {CreditorFilterBar} from "./filter";
import {CreditFilter, Creditor, useCreditorsApi} from "../../../api";
import {useAsync} from "react-async-hook";

const DefaultFilter: CreditFilter = {
	companyName: "",
};

function useCreditorFilter() {
	return useStorage<CreditFilter>("CreditorFilter", DefaultFilter);
}

function useCreditorPage() {
	return useDataTableStorage("CreditorList", {
		sortField: "company.companyName",
		sortDirection: "asc",
	});
}

export function CreditorList() {
	const {t} = useLocalization();
	const {queryCreditors} = useCreditorsApi();

	const [filter, setFilter] = useCreditorFilter();
	const storage = useCreditorPage();
	const pageable = useSpringPageable(storage);

	const query = useMemo(() => ({...filter, ...pageable}), [filter, pageable]);
	const {result, loading, error} = useAsync(queryCreditors, [query]);

	const {rows, page} = useSpringPage(result);

	const columns = useMemo<DataTableColumn<Creditor>[]>(() => [
		{
			field: "company.companyName",
			label: t("creditors.singular"),
			sortable: true,
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/admin/creditor/edit/${row.id}`} color="secondary">
						{row.company.companyName}
					</Link>
				</Typography>
			),
		},
		{
			field: "checkManually",
			sortable: true,
			label: t("creditors.checkManually"),
			component: ({row}) => (
				<Chip
					color={row.checkManually ? "success" : "default"}
					label={row.checkManually ? t("common.yes") : t("common.no")}
				/>
			),
		},
		{
			field: "enabled",
			sortable: true,
			label: t("creditors.enabled"),
			component: ({row}) => (
				<Chip
					color={row.enabled ? "success" : "error"}
					label={row.enabled ? t("common.yes") : t("common.no")}
				/>
			),
		},
	], [t]);

	return (
		<View>
			<ViewHeader
				title={t("creditors.plural")}
			/>
			<CreditorFilterBar
				value={filter}
				onChange={setFilter}
			/>
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				{...storage}
			/>
		</View>
	)
}
