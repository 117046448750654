import {Box, Button, Stack} from "@mui/material";
import React, {FormEvent, PropsWithChildren} from "react";
import {useLocalization} from "../i18n";

interface FilterFormProps {
	onApply: () => void;
	onReset?: () => void;
}

export function FilterForm({onApply, onReset, children}: Readonly<PropsWithChildren<FilterFormProps>>) {
	const {t} = useLocalization();
	function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		onApply();
	}
	return (
		<form onSubmit={handleSubmit}>
			<Box p={2}>
				<Stack spacing={2}>
					<Box>
						{children}
					</Box>
					<Stack spacing={2} direction="row">
						<Button color="primary" type="submit" variant="contained">
							{t("filter.apply")}
						</Button>
						{onReset && (
							<Button color="inherit" onClick={onReset} variant="outlined">
								{t("filter.reset")}
							</Button>
						)}
					</Stack>
				</Stack>
			</Box>
		</form>
	);
}
