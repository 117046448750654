import {Select} from "@variocube/app-ui";
import React from "react";
import {Language} from "../api";
import {useLocalization} from "../i18n";

interface LanguageSelectProps {
	value: Language;
	onChange: (value: Language) => void;
	fullWidth?: boolean;
}

export function LanguageSelect({value, onChange, fullWidth}: Readonly<LanguageSelectProps>) {
	const {s, t} = useLocalization();

	return (
		<Select
			label={t("infoboxes.language")}
			value={value}
			onChange={onChange}
			options={["de", "en", "hu"]}
			renderLabel={s("languages")}
			fullWidth={fullWidth}
		/>
	);
}
