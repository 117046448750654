import {Alert, AlertTitle, Stack, Typography} from "@mui/material";
import {DataTable} from "@variocube/app-ui";
import React from "react";
import {InvoiceSummary} from "../../../api";
import {useLocalization} from "../../../i18n";
import {useDuplicateInvoiceColumns} from "../columns";

interface InvoiceDuplicatesProps {
	duplicates: InvoiceSummary[];
}

export function InvoiceDuplicates({duplicates}: InvoiceDuplicatesProps) {
	const {t} = useLocalization();
	const duplicateColumns = useDuplicateInvoiceColumns();
	return (
		<Stack spacing={2}>
			<Alert severity="error">
				<AlertTitle>{t("invoices.new.duplicate.title")}</AlertTitle>
				<Typography>{t("invoices.new.duplicate.description")}</Typography>
			</Alert>
			<DataTable
				columns={duplicateColumns}
				rows={duplicates}
			/>
		</Stack>
	);
}
