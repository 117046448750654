import {CompanyForm, Signup, useApiClient} from "./client";

export const COMPANY_FORMS: CompanyForm[] = [
	"SinglePerson",
	"OpenOrganisation",
	"LimitedPartnership",
	"ConstitutedCivil",
	"LimitedLiability",
	"IncorporatedCompany",
	"Cooperative",
	"PublicCorporation",
];

export function useSignupApi() {
	const api = useApiClient();
	return {
		identifyEmail: (email: string) => api.post("/api/v2/signup", {body: {email}}),
		signup: (request: Signup) => api.post("/api/v2/signup/creditor", {body: request}),
		confirm: (token: string) =>
			api.post(`/api/v2/signup/{token}`, {
				path: {token},
			}),
	};
}
