import {Delete} from "@mui/icons-material";
import {Box, IconButton, LinearProgress, Typography} from "@mui/material";
import {defined} from "@variocube/app-ui";
import React from "react";
import {FilePreview} from "../../component/file-preview";
import {AttachmentFile} from "./create/types";

interface AttachmentItemProps {
	attachment: AttachmentFile;
	onDelete: () => void;
}

export function AttachmentItem({attachment, onDelete}: Readonly<AttachmentItemProps>) {
	return (
		<Box position="relative">
			<FilePreview file={attachment.file}/>
			<LinearProgress
				variant="determinate"
				color={getColor(attachment)}
				value={(attachment.progress ?? 0) * 100}
			/>
			<Typography variant="caption" textAlign="center">
				{attachment.file.name}
			</Typography>
			<IconButton
				color="error"
				onClick={onDelete}
				sx={{
					position: "absolute",
					top: 0,
					right: 0,
				}}
			>
				<Delete/>
			</IconButton>
		</Box>
	);
}

function getColor(attachment: AttachmentFile) {
	if (attachment.error || (defined(attachment.supported) && !attachment.supported)) {
		return "error";
	}
	if (attachment) {
		return "success";
	}
	return "secondary";
}
