import {
	Alert,
	AlertTitle,
	CardContent,
	Collapse,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import {CurrencyFormat, defined, NumberField, RadioGroup} from "@variocube/app-ui";
import React, {Fragment, useEffect, useMemo} from "react";
import {CurrencySelect} from "../../../../../component/currency-select";
import {useLocalization} from "../../../../../i18n";
import {notNull} from "../../../../../util";
import {ExpenseInvoiceBusinessCase} from "../../types";
import {parseQrCode} from "../parse-qr-code";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

export const AmountNumberFormat = new Intl.NumberFormat(undefined, {
	minimumFractionDigits: 2,
});

function getCashTipValue(cashTip: undefined | boolean) {
	if (defined(cashTip)) {
		return cashTip ? "bar" : "unbar";
	}
}

export function AmountStep(
	{active, completed, invoice, onChange}: Readonly<StepProps>,
) {
	const {language} = useLocalization();

	const currencySymbol = invoice.creditCardNumber
		? invoice.currency && getCurrencySymbol(invoice.currency, language)
		: "€";

	const currencySymbolTip = (invoice.creditCardNumber && invoice.tipCurrency)
		? getCurrencySymbol(invoice.tipCurrency, language)
		: currencySymbol;

	function change(index: number, change: Partial<ExpenseInvoiceBusinessCase>) {
		onChange({
			businessCases: invoice.businessCases?.map((businessCase, i) =>
				index == i ? ({...businessCase, ...change}) : businessCase
			),
		});
	}

	const showTip = invoice.businessCases?.some(businessCase => businessCase.allowTip);

	const eurozone = EUROZONE.includes(invoice.countryCode ?? "AT");

	const {gross: qrCodeGross} = useMemo(() => parseQrCode(invoice.qrCode), [invoice.qrCode]);

	useEffect(() => {
		if (invoice.businessCases?.length == 1 && !invoice.businessCases[0].amount && qrCodeGross) {
			console.info("QR code could be read and there is a single business case. Pre-filling amount", qrCodeGross);
			change(0, {amount: qrCodeGross});
		}
	}, [invoice, qrCodeGross]);

	const amounts = invoice.businessCases?.map(businessCase => businessCase.amount).filter(defined).filter(notNull);

	const totalGross = amounts?.length == invoice.businessCases?.length
		? amounts?.reduce((a, b) => a + b, 0)
		: undefined;

	const tips = invoice.businessCases?.map(businessCase => businessCase.tip).filter(defined).filter(notNull);

	const totalTip = tips?.length == invoice.businessCases?.length
		? tips?.reduce((a, b) => a + b, 0)
		: undefined;

	const qrCodeMismatch = defined(qrCodeGross)
		&& defined(totalGross)
		&& qrCodeGross != totalGross
		&& (!defined(totalTip) || qrCodeGross != totalGross + totalTip);

	const canHaveCashTip = Boolean(invoice.creditCardNumber) && defined(totalTip) && totalTip > 0;

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title={(invoice.businessCases?.length ?? 0) > 1 ? "Beträge" : "Betrag"}
			activeChildren={
				<Fragment>

					<Table>
						<TableBody>
							{invoice.creditCardNumber && (
								<TableRow>
									<TableCell></TableCell>
									<TableCell>
										<CurrencySelect
											label="Währung Betrag"
											required
											value={invoice.currency}
											onChange={currency => onChange({currency})}
										/>
									</TableCell>
									<TableCell>
										{showTip && (
											<CurrencySelect
												label="Währung Trinkgeld"
												required
												value={invoice.tipCurrency}
												onChange={tipCurrency => onChange({tipCurrency})}
											/>
										)}
									</TableCell>
								</TableRow>
							)}
							{invoice.businessCases?.map((businessCase, index) => (
								<TableRow key={businessCase.id}>
									<TableCell>{businessCase.name}</TableCell>
									<TableCell>
										<NumberField
											label="Betrag"
											numberFormat={AmountNumberFormat}
											numberValue={businessCase.amount ?? null}
											onChangeNumber={amount => change(index, {amount})}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">{currencySymbol}</InputAdornment>
												),
											}}
											error={qrCodeMismatch}
										/>
									</TableCell>
									{businessCase.allowTip && (
										<TableCell>
											<NumberField
												label="Trinkgeld"
												numberFormat={AmountNumberFormat}
												numberValue={businessCase.tip ?? null}
												onChangeNumber={tip => change(index, {tip})}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															{currencySymbolTip}
														</InputAdornment>
													),
												}}
											/>
										</TableCell>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
					<Collapse in={canHaveCashTip}>
						<CardContent>
							<RadioGroup
								label="Wie wurde das Trinkgeld bezahlt?"
								required={canHaveCashTip}
								value={getCashTipValue(invoice.cashTip)}
								onChange={value => onChange({cashTip: value == "bar"})}
								options={["bar", "unbar"]}
							/>
						</CardContent>
					</Collapse>
					<Collapse in={qrCodeMismatch}>
						<Alert severity="error">
							<AlertTitle>Betrag stimmt nicht mit Beleg überein</AlertTitle>
							Der eingegebene Betrag stimmt nicht dem auf dem Beleg erkannten Betrag überein. Bitte prüfen
							Sie Ihre Eingabe.
						</Alert>
					</Collapse>
					{showTip && (
						<Alert severity="info">
							Das Trinkgeld muss auf der Barrechnung angedruckt sein bzw. handschriftlich vermerkt werden.
						</Alert>
					)}
					{!invoice.creditCardNumber && !eurozone && (
						<Alert severity="warning">
							<AlertTitle>Bitte geben Sie den Betrag in Euro ein</AlertTitle>
							Verwenden Sie den tatsächlichen Umrechnungskurs zu dem Sie in die Fremdwährung gewechselt
							haben (ggf. Beleg anhängen).
						</Alert>
					)}
				</Fragment>
			}
			completedChildren={
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Geschäftsfall</TableCell>
							<TableCell>Betrag</TableCell>
							{showTip
								&& <TableCell>Trinkgeld</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{invoice.businessCases?.map((businessCase) => (
							<TableRow key={businessCase.id}>
								<TableCell>{businessCase.name}</TableCell>
								<TableCell>
									<CurrencyFormat value={businessCase.amount} currency={invoice.currency ?? "EUR"}/>
								</TableCell>
								{showTip && (
									<TableCell>
										<CurrencyFormat value={businessCase.tip}
														currency={invoice.tipCurrency ?? invoice.currency ?? "EUR"}/>
									</TableCell>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			}
		/>
	);
}

export const EUROZONE = [
	"AT",
	"BE",
	"HR",
	"CY",
	"EE",
	"FI",
	"FR",
	"DE",
	"GR",
	"IE",
	"IT",
	"LV",
	"LT",
	"LU",
	"MT",
	"NL",
	"PT",
	"SK",
	"SI",
	"ES",
];

function getCurrencySymbol(currency: string, locale: string) {
	return new Intl.NumberFormat(locale, {style: "currency", currency})
		.formatToParts(1)
		.find(x => x.type === "currency")?.value;
}
