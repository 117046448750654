import {CardContent, Grid, InputAdornment, Stack} from "@mui/material";
import {Checkbox, IntegerNumberFormat, NumberField, TextField} from "@variocube/app-ui";
import React from "react";
import {LabeledText} from "../../../../../component/labeled-text";
import {ExpenseInvoiceFile} from "../../types";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

export function CarStep(
	{active, completed, invoice, onChange}: Readonly<StepProps>,
) {
	function change(change: Partial<ExpenseInvoiceFile["car"]>) {
		onChange({car: {...invoice.car, ...change}});
	}

	const isRefueledLiter = Boolean(
		invoice?.businessCases?.some(businessCase => businessCase.name?.toLowerCase().includes("betankung")),
	);

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title="KFZ"
			activeChildren={
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Checkbox
								value={invoice.car?.inputTaxDeductible ?? false}
								onChange={inputTaxDeductible => change({inputTaxDeductible})}
								label="Das Fahrzeug ist vorsteuerabzugsberechtigt"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="Kennzeichen Dienstfahrzeug"
								required
								fullWidth
								value={invoice.car?.licensePlate ?? ""}
								onChange={licensePlate => change({licensePlate})}
								inputProps={{
									pattern: "W-?[A-Za-z0-9]+",
								}}
								helperText={invoice.car?.licensePlate && !invoice.car?.licensePlate?.startsWith("W")
									&& "Ungültiges Kennzeichen. Firmen-KFZ sind in Wien zugelassen."}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								label="KFZ-Typ/Modell"
								fullWidth
								value={invoice.car?.carType ?? ""}
								onChange={carType => change({carType})}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<NumberField
								label="Kilometerstand"
								required
								fullWidth
								numberFormat={IntegerNumberFormat}
								numberValue={invoice.car?.mileageKm ?? null}
								onChangeNumber={mileageKm => change({mileageKm})}
								InputProps={{
									endAdornment: <InputAdornment position="end">km</InputAdornment>,
								}}
							/>
						</Grid>
						{isRefueledLiter && (
							<Grid item xs={12} sm={6}>
								<NumberField
									label="Betankung (Liter)"
									fullWidth
									required={isRefueledLiter}
									numberFormat={IntegerNumberFormat}
									numberValue={invoice.car?.refuelLiter ?? null}
									onChangeNumber={refuelLiter => change({refuelLiter})}
									InputProps={{
										endAdornment: <InputAdornment position="end">l</InputAdornment>,
									}}
								/>
							</Grid>
						)}
					</Grid>
				</CardContent>
			}
			completedChildren={
				<CardContent>
					<Stack gap={2} direction="row" flexWrap="wrap">
						<LabeledText label="Kennzeichen Dienstfahrzeug" text={invoice.car?.licensePlate} />
						{invoice.car?.carType && <LabeledText label="KFZ-Typ/Modell" text={invoice.car?.carType} />}
						{invoice.car?.mileageKm && <LabeledText label="Kilometerstand" text={invoice.car?.mileageKm} />}
						{invoice.car?.refuelLiter && <LabeledText label="Betankung" text={invoice.car?.refuelLiter} />}
					</Stack>
				</CardContent>
			}
		/>
	);
}
