import {CardContent, FormLabel, Grid} from "@mui/material";
import {EditForm, PlainDate, PlainDatePicker, RadioGroup, Selector, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {Audience, Info, InfoDisplayType, InfoMutation, Language} from "../../../api";
import {LanguageSelect} from "../../../component/language-select";
import {NoteEditor} from "../../../component/note-editor";
import {useLocalization} from "../../../i18n";
import {notBlank} from "../../../util";

interface InfoBoxFormProps {
	loading?: boolean;
	info?: Info;
	onSave: (data: InfoMutation) => Promise<void>;
	onCancel: () => void;
}

export function InfoBoxForm({loading, info, onSave, onCancel}: Readonly<InfoBoxFormProps>) {
	const {s, t} = useLocalization();

	const [subject, setSubject] = useState("");
	const [displayType, setDisplayType] = useState<InfoDisplayType>("Dashboard");
	const [audience, setAudience] = useState<Audience>();
	const [start, setStart] = useState<PlainDate | null>(null);
	const [end, setEnd] = useState<PlainDate | null>(null);
	const [lang, setLang] = useState<Language>("de");
	const [body, setBody] = useState("");

	useEffect(() => {
		if (info !== undefined) {
			setSubject(info.subject ?? "");
			setDisplayType(info.displayType ?? "Dashboard");
			setAudience(info.audience);
			setLang(info.language ?? "de");
			setBody(info.body ?? "");

			if (notBlank(info.start)) {
				setStart(PlainDate.from(info.start));
			}
			if (notBlank(info.end)) {
				setEnd(PlainDate.from(info.end));
			}
		}
	}, [info]);

	function handleSave() {
		return onSave({
			subject,
			displayType,
			audience,
			start: start?.toJSON() ?? "",
			end: end?.toJSON() ?? "",
			language: lang as any,
			body,
		});
	}

	return (
		<EditForm loading={loading ?? false} onSave={handleSave} onCancel={onCancel} labels={s("actions")}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<FormLabel>{t("infoboxes.topic")}</FormLabel>
						<TextField
							fullWidth
							size="small"
							required
							value={subject}
							onChange={setSubject}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<FormLabel>{t("infoboxes.displayType")}</FormLabel>
						<Selector
							fullWidth
							size="small"
							required
							value={displayType}
							onChange={v => setDisplayType(v as InfoDisplayType)}
							options={[
								{label: "LoginForm", value: "LoginForm"},
								{label: "Dashboard", value: "Dashboard"},
							]}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<RadioGroup
							label={t("infoboxes.audience")}
							value={audience}
							onChange={setAudience}
							options={["All", "Oebb", "Creditor"]}
							renderLabel={s("infoboxes.audiences")}
						/>
					</Grid>
					<Grid item xs={12} sm={6} display="flex" flexDirection="column">
						<FormLabel>{t("infoboxes.start")}</FormLabel>
						<PlainDatePicker required size="small" value={start} onChange={setStart} />
					</Grid>
					<Grid item xs={12} sm={6} display="flex" flexDirection="column">
						<FormLabel>{t("infoboxes.end")}</FormLabel>
						<PlainDatePicker size="small" value={end} onChange={setEnd} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<LanguageSelect value={lang} onChange={setLang} fullWidth />
					</Grid>
					<Grid item xs={12}>
						<FormLabel>Infotext</FormLabel>
						<NoteEditor text={body} onChange={setBody} />
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}
