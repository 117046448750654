import {ApiError} from "@variocube/app-ui";
import {TempFile} from "./client.gen";

export interface UploadFileOptions {
	onProgress: (progress: number) => void;
	onError: (error: ApiError | TypeError) => void;
	onSuccess: (tempFile: TempFile) => void;
}

export function useFileApi() {
	return {
		uploadFile: (file: File, options: UploadFileOptions) => {
			const {onProgress, onError, onSuccess} = options;
			const xhr = new XMLHttpRequest();
			xhr.upload.addEventListener("progress", (event) => {
				if (event.lengthComputable) {
					console.log("upload progress:", event.loaded / event.total);
					if (onProgress) {
						onProgress(event.loaded / event.total);
					}
				}
			});
			xhr.addEventListener("loadend", () => {
				if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 300) {
					try {
						onSuccess(JSON.parse(xhr.responseText));
					} catch (error) {
						onError(new TypeError("Error parsing response"));
					}
				} else {
					onError(new ApiError(xhr.status, "Error uploading file"));
				}
			});
			xhr.open("POST", "/api/v2/files", true);
			// xhr.setRequestHeader("Content-Type", "multipart/form-data");

			const formData = new FormData();
			formData.append("file", file);
			xhr.send(formData);
		},
	};
}
