import {Invoice, User} from "../api";
import {hasRoles} from "../context/auth";
import {isInvoiceManager} from "./isInvoiceManager";

export function canRouteInvoice(user?: User, invoice?: Invoice) {
	return Boolean(
		// user must be defined
		user
		// invoice must be defined
		&& invoice
		// invoice must be not routed yet
		&& !invoice.routed
		// invoice must be not rejected by öbb
		&& !invoice.oebbRejected
		// invoice type must be creditor invoice type or invoice must have been audited
		&& (invoice.invoiceType == "CreditorInvoice" || invoice.invoiceType == "CreditorCreditNote"
			|| invoice.audited)
		// user must be invoice manager
		&& isInvoiceManager(user, invoice),
	);
}

export function canRoute(user?: User) {
	return hasRoles(user, ["SuperUser"])
		|| (hasRoles(user, ["OebbAdministrator", "OebbUser"]) && user && user.inboxes.length > 0);
}
