import React, {type ReactElement} from 'react';
import {Typography as MuiTypography,type TypographyProps as MuiTypographyProperties} from '@mui/material';

type TypographyProperties = MuiTypographyProperties & {
	startAdornment?: ReactElement;
	endAdornment?: ReactElement;
}

export function Typography({startAdornment, endAdornment, children, sx = {}, ...properties}: TypographyProperties) {
	return (
		<MuiTypography
			{...properties}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				// justifyContent: 'center',
				alignItems: 'center',
				gap: 1.5,
				...sx,
			}}
		>
			{startAdornment !== undefined && startAdornment}
			{children}
			{endAdornment !== undefined && endAdornment}
		</MuiTypography>
	);
}
