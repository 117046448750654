import {Group} from "@mui/icons-material";
import {Card, CardContent, CardHeader, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";
import {type InvoiceParticipant} from "../../../api";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";

export function Participants({participants}: { participants: InvoiceParticipant[] }) {
	const {t} = useLocalization();
	return (
		<Card>
			<CardHeader
				title={<Typography startAdornment={<Group />}>{t("invoices.details.participants")}</Typography>}
			/>
			<CardContent sx={{p: "0 !important"}}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t("common.name")}</TableCell>
							<TableCell>{t("common.company")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{participants.map(p => (
							<TableRow key={p.name}>
								<TableCell>{p.name}</TableCell>
								<TableCell>{p.company}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
}
