import {AssignmentOutlined} from "@mui/icons-material";
import {Card, CardContent, CardHeader, Link} from "@mui/material";
import React from "react";
import {Invoice} from "../../../api";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {formatAmount, notNull} from "../../../util";

export function InvoiceSummaryCard({invoice}: { invoice: Invoice }) {
	const {t, e, language} = useLocalization();

	return (
		<Card>
			<CardHeader
				title={<Typography startAdornment={<AssignmentOutlined />}>{t("invoices.details.summary")}</Typography>}
			/>
			<CardContent>
				<Typography justifyContent="center" gutterBottom>
					{e("invoices.invoiceStates", invoice.invoiceState)}
				</Typography>

				{!notNull(invoice.audited) && (invoice.auditors ?? []).length > 0 && (
					<>
						<Typography color="textSecondary" textAlign="center" gutterBottom>
							{t("invoices.details.auditHint")}
						</Typography>
						{invoice.auditorUsers?.map(a => (
							<Typography key={a.email} color="textSecondary" justifyContent="center" gutterBottom>
								{a.firstname} {a.lastname}
								<br />
								<Link href={`mailto:${a.email}`}>{a.email}</Link>
							</Typography>
						))}
					</>
				)}

				{invoice.invoiceType == "ExpensesInvoice" && notNull(invoice.gross) && (
					<Typography color="textSecondary" textAlign="center" gutterBottom>
						{t("invoices.details.amountTransferText", {
							amount: formatAmount(invoice.gross as number, language),
						})}
					</Typography>
				)}

				<Typography color="textSecondary" textAlign="center" gutterBottom>
					<strong>
						{t("common.hint")}:
						<br />
						{t("invoices.details.keepOriginHint")}
					</strong>
				</Typography>
			</CardContent>
		</Card>
	);
}
