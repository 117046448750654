import {Box} from "@mui/material";
import React from "react";
import {Logo} from "./logo";

export function FullScreenLoading() {
	return (
		<Box
			position="fixed"
			left={0}
			top={0}
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			width="100%"
			height="100vh"
		>
			<Logo color="textSecondary" pulse width={160} />
		</Box>
	);
}
