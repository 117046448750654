import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {UserMutation, useUserApi} from "../../api";
import {useLocalization} from "../../i18n";
import {UserForm} from "./form";

export function UserCreator() {
	const navigate = useNavigate();
	const {t} = useLocalization();
	const {createUser} = useUserApi();

	async function handleSave(mutation: UserMutation) {
		const {id} = await createUser(mutation);
		navigate(`/admin/user/edit/${id}`);
	}

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.admin")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../users`}>{t("users.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{t("users.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("users.create.title")} />
			<UserForm
				loading={false}
				onSave={handleSave}
				onCancel={() => navigate("../users")}
			/>
		</View>
	);
}
