import React from "react";
import {CreditorEdit} from "./pages/admin/creditor/edit";
import {CreditorList} from "./pages/admin/creditor/list";
import {InfoBoxCreator} from "./pages/admin/infobox/creator";
import {InfoBoxEdit} from "./pages/admin/infobox/edit";
import {InfoBoxList} from "./pages/admin/infobox/list";
import {AdminMaintenance} from "./pages/admin/maintenance";
import {AdminSettings} from "./pages/admin/settings";
import {StatisticsView} from "./pages/admin/statistics/view";
import {AuditLogs} from "./pages/auditlogs/list";
import {Dashboard} from "./pages/dashboard";
import {HelpTextCreate} from "./pages/help-text/create";
import {HelpTextEdit} from "./pages/help-text/edit";
import {HelpTextList} from "./pages/help-text/list";
import {InboxAuditorsDetails} from "./pages/inbox-auditor/details";
import {InboxAuditorList} from "./pages/inbox-auditor/list";
import {InvoiceDetails, InvoiceExport, InvoiceList, InvoiceXml} from "./pages/invoice";
import {CreditorInvoiceCreate} from "./pages/invoice/create/creditor/creditor-invoice-create";
import {ExpenseInvoiceCreate} from "./pages/invoice/create/expense/expense-invoice-create";
import {MissingUserData} from "./pages/missing-user-data";
import {CreditCardList} from "./pages/settings/credit-cards/list";
import {BusinessCaseList} from "./pages/superuser/business-case/list";
import {DocumentTypeCreator} from "./pages/superuser/doctype/creator";
import {DocumentTypeEdit} from "./pages/superuser/doctype/edit";
import {DocumentTypeList} from "./pages/superuser/doctype/list";
import {InboxCreator} from "./pages/superuser/inbox/creator";
import {InboxEdit} from "./pages/superuser/inbox/edit";
import {InboxList} from "./pages/superuser/inbox/list";
import {UserSettings} from "./pages/user-settings/user-settings";
import {UserCreator} from "./pages/user/creator";
import {UserEdit} from "./pages/user/edit";
import {UserList} from "./pages/user/list";

export const routes = {
	"/user-settings": <UserSettings />,
	"/missing-user-data": <MissingUserData />,

	"/dashboard": <Dashboard />,

	// OEBB Administrator
	"/admin/infobox/": <InfoBoxList />,
	"/admin/infobox/new": <InfoBoxCreator />,
	"/admin/infobox/:id": <InfoBoxEdit />,
	"/admin/creditor": <CreditorList />,
	"/admin/creditor/edit/:id": <CreditorEdit />,
	"/admin/inbox-auditors": <InboxAuditorList />,
	"/admin/inbox-auditors/:id": <InboxAuditorsDetails />,
	"/admin/settings": <AdminSettings />,
	"/admin/statistics": <StatisticsView />,

	// Super User
	"/superuser/mailbox": <InboxList />,
	"/superuser/mailbox/new": <InboxCreator />,
	"/superuser/mailbox/:id": <InboxEdit />,
	"/superuser/doctype": <DocumentTypeList />,
	"/superuser/doctype/new": <DocumentTypeCreator />,
	"/superuser/doctype/:id": <DocumentTypeEdit />,
	"/superuser/business-cases": <BusinessCaseList />,
	"/superuser/help-texts": <HelpTextList />,
	"/superuser/help-texts/new": <HelpTextCreate />,
	"/superuser/help-texts/:id": <HelpTextEdit />,
	"/superuser/maintenance": <AdminMaintenance />,
	"/superuser/auditlogs": <AuditLogs />,
	"/superuser/credit-cards": <CreditCardList />,

	"/invoices": <InvoiceList />,
	"/invoices/export": <InvoiceExport />,
	"/invoices/new/creditor": <CreditorInvoiceCreate />,
	"/invoices/new/expense": <ExpenseInvoiceCreate type="expense" />,
	"/invoices/new/credit-card": <ExpenseInvoiceCreate type="credit-card" />,
	"/invoices/:id": <InvoiceDetails />,
	"/invoices/:id/xml": <InvoiceXml />,

	"/users": <UserList />,
	"/users/new": <UserCreator />,
	"/users/:id": <UserEdit />,
};
