import {Link} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {HelpTextMutation, Language, useHelpTextApi} from "../../api";
import {useLocalization} from "../../i18n";
import {HelpTextForm} from "./form";

export function HelpTextCreate() {
	const {language, t} = useLocalization();
	const {createHelpText} = useHelpTextApi();
	const [search] = useSearchParams();
	const navigate = useNavigate();

	async function handleSave(helpTextMutation: HelpTextMutation) {
		await createHelpText(helpTextMutation);
		navigate(-1);
	}

	const helpText = useMemo(() => {
		return {
			helpKey: search.get("helpKey") ?? "",
			language: language as Language,
		};
	}, [search]);

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to="/help-texts">{t("helpTexts.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{t("helpTexts.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("helpTexts.create.title")} />
			<HelpTextForm
				helpText={helpText}
				onSave={handleSave}
			/>
		</View>
	);
}
