import {defined} from "@variocube/app-ui";
import {Person, User} from "./api";

export function notBlank(value: string | null | undefined): value is string {
	return value !== undefined && value !== null && value.length > 0;
}

export function notNull<T>(value: T | undefined | null): value is T {
	return value !== undefined && value !== null;
}

export function formatAmount(amount: number | undefined, lang: string) {
	if (!defined(amount)) {
		return "";
	}
	if (lang === "de") return `€ ${amount.toFixed(2).replace(".", ",")}`;
	return `€ ${amount.toFixed(2)}`;
}

export function localizeNumber(number: number, lang: string) {
	const pattern = /(\d)()(?=(\d{3})+(?!\d))/g; // thousands point denotation
	if (lang === "de") {
		return number.toFixed(2)
			.replace(".", ",")
			.replaceAll(pattern, "$1.$2");
	}
	return number.toFixed(2);
}

export function humanFileSize(bytes: number, si: boolean = false, dp: number = 0): string {
	let b = bytes;
	const thresh = si ? 1000 : 1024;

	if (Math.abs(b) < thresh) {
		return `${bytes} B`;
	}

	const units = si
		? ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
		: ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	let u = -1;
	const r = 10 ** dp;

	do {
		b /= thresh;
		u += 1;
	}
	while (Math.round(Math.abs(b) * r) / r >= thresh && u < units.length - 1);

	return `${b.toFixed(dp)} ${units[u]}`;
}

export function getUserDisplayString(user: User) {
	return (`${user.firstname} ${user.lastname}`.trim() || user.email) ?? "";
}

export function getPersonDisplayString(person: Person) {
	return [person.title, person.lastname, person.firstname, person.postTitle].join(" ");
}
