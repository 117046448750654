import {ReportingSetting} from "../../../api/reporting";
import {useLocalization} from "../../../i18n";
import React, {useState} from "react";
import {Autocomplete, Chip, Grid} from "@mui/material";
import {Filter, Select, TextField} from "@variocube/app-ui";
import {useAsync} from "react-async-hook";
import {Creditor, useCreditorsApi} from "../../../api";
import {FilterForm} from "../../../component/filter-form";
import _ from "lodash";

interface ReportingSettingBarProps {
	value: ReportingSetting;
	onChange: (value: ReportingSetting) => void;
}

export function ReportingSettingBar({value, onChange}: ReportingSettingBarProps) {
	const {s, t, e} = useLocalization();
	const {queryCreditors} = useCreditorsApi();

	const [search, setSearch] = useState("");
	const [timeFrame, setTimeFrame] = useState(value.timeFrame ?? "Last24h");
	const [creditor, setCreditor] = useState<Creditor|null>(null);

	const handleSearchInput = _.debounce(setSearch, 500);

	const {result: creditors} = useAsync(() =>
			queryCreditors({ companyName: search, size: 25 })
				.then(p => p.content)
		, [search]);

	async function handleSubmit() {
		onChange({
			timeFrame,
			creditorId: creditor?.id ?? undefined
		})
	}

	return (
		<Filter
			labels={s('filter')}
			label={t('filter.title')}
			active={[
				<Chip
					label={`${t('reporting.timeframe')}: ${e("reporting.timeframes", value.timeFrame)}`}
				/>,
				creditor && (
					<Chip
						label={`${t('creditors.singular')}: ${creditor.company.companyName}`}
						onDelete={() => setCreditor(null)}
					/>
				),
			]}
		>
			<FilterForm onApply={handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Select
							fullWidth
							label={t("reporting.timeframe")}
							options={[
								"Last24h",
								"LastWeek",
								"LastMonth",
								"LastYear",
								"LastTwoYears",
								"AllTime",
							]}
							value={timeFrame}
							onChange={setTimeFrame}
							renderLabel={s("reporting.timeframes")}
						/>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Autocomplete
							onInputChange={(_, v) => handleSearchInput(v)}
							renderInput={params => <TextField {...params} label={t("creditors.singular")} helperText={t("reporting.selectAllCreditorsHint")} />}
							options={creditors ?? []}
							value={creditor}
							onChange={(_, v) => setCreditor(v)}
							getOptionLabel={o => o.company.companyName ?? "" + o.id}
						/>
					</Grid>
				</Grid>
			</FilterForm>
		</Filter>
	)
}
