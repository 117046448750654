import {SpringPageable} from "@variocube/app-ui";
import {TypeOf} from "zod";
import {get_QueryUsers, useApiClient, UserMutation, UserProxyMutation, UserRole} from "./client";

export type UserQuery = TypeOf<get_QueryUsers["parameters"]>["query"];
export type UserFilter = Omit<UserQuery, keyof SpringPageable>;

export const USER_ROLES: UserRole[] = [
	"SuperUser",
	"OebbAdministrator",
	"OebbUser",
	"OebbApiUser",
	"CreditorAdministrator",
	"CreditorUser",
];

export function useUserApi() {
	const api = useApiClient();
	return {
		queryUsers: (query: UserQuery) => api.get("/api/v2/users", {query}),
		getUser: (id: number) => api.get("/api/v2/users/{id}", {path: {id}}),
		createUser: (mutation: UserMutation) => api.post("/api/v2/users", {body: mutation}),
		updateUser: (id: number, mutation: UserMutation) => api.put("/api/v2/users/{id}", {path: {id}, body: mutation}),
		deleteUser: (id: number) => api.delete("/api/v2/users/{id}", {path: {id}}),
		queryUserInboxes: (id: number) => api.get("/api/v2/users/{id}/inboxes", {path: {id}}),
		updateUserInboxes: (id: number, inboxIds: number[]) =>
			api.put("/api/v2/users/{id}/inboxes", {path: {id}, body: inboxIds}),
		deleteUserInbox: (id: number, inboxId: number) =>
			api.delete("/api/v2/users/{id}/inboxes/{inboxId}", {path: {id, inboxId}}),
		queryUserWorksAt: (id: number) => api.get("/api/v2/users/{id}/works-at", {path: {id}}),
		updateUserWorksAt: (id: number, inboxIds: number[]) =>
			api.put("/api/v2/users/{id}/works-at", {path: {id}, body: inboxIds}),
		deleteUserWorksAt: (id: number, inboxId: number) =>
			api.delete("/api/v2/users/{id}/works-at/{inboxId}", {path: {id, inboxId}}),
		queryUserProxies: (id: number) => api.get("/api/v2/users/{id}/proxies", {path: {id}}),
		queryProxiedUser: (id: number) => api.get("/api/v2/users/{id}/proxied", {path: {id}}),
		addUserProxy: (id: number, mutation: UserProxyMutation) =>
			api.put("/api/v2/users/{id}/proxies", {path: {id}, body: mutation}),
		deleteUserProxy: (id: number, proxyId: number) =>
			api.delete("/api/v2/users/{id}/proxies/{proxyId}", {path: {id, proxyId}}),
		impersonateUser: (id: number) => api.post("/api/v2/users/{id}/impersonate", {path: {id}}),
	};
}
