import React, {useMemo} from "react";
import {
	DataTable,
	DataTableColumn, Instant, TemporalFormat,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
	View,
	ViewHeader
} from "@variocube/app-ui";
import {useLocalization} from "../../i18n";
import {AuditLogFilterBar} from "./filter";
import {AuditLogFilter, useAuditLogsApi} from "../../api/audit";
import {useAsync} from "react-async-hook";
import {AuditLog} from "../../api";
import {Typography} from "@mui/material";
import {getUserDisplayName} from "../../utils/user";

const DefaultFilter: AuditLogFilter = {
	userEmail: "",
	types: [],
}

function useAuditLogFilter() {
	return useStorage<AuditLogFilter>("AuditLogFilter", DefaultFilter);
}

function useAuditLogPage() {
	return useDataTableStorage("AuditLogList", {
		sortField: "created",
		sortDirection: "desc"
	});
}

export function AuditLogs() {
	const {t} = useLocalization();
	const {queryAuditLogs} = useAuditLogsApi();

	const [filter, setFilter] = useAuditLogFilter();
	const storage = useAuditLogPage();
	const pageable = useSpringPageable(storage);

	const query = useMemo(() => ({...filter, ...pageable}), [filter, pageable]);
	const {result, loading, error} = useAsync(queryAuditLogs, [query]);

	const {rows, page} = useSpringPage(result);

	const columns = useMemo<DataTableColumn<AuditLog>[]>(() => [
		{
			field: "created",
			label: t("audit.datetime"),
			component: ({row}) => (
				<TemporalFormat value={Instant.from(row.created as string)} />
			)
		},
		{
			field: "userProfile",
			label: t("users.singular"),
			component: ({row}) => (
				<Typography>{row.user ? getUserDisplayName(row.user) : "Unknown"}</Typography>
			)
		},
		{
			field: "subject",
			label: "Text",
			component: ({row}) => (
				<Typography>{row.subject}</Typography>
			)
		},
		{
			field: "body",
			label: "Message",
			component: ({row}) => (
				<Typography>{row.body}</Typography>
			)
		},
	], [t])

	return (
		<View>
			<ViewHeader
				title={t("audit.title")}
			/>
			<AuditLogFilterBar
				value={filter}
				onChange={setFilter}
			/>
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				{...storage}
			/>
		</View>
	)
}
