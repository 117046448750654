import {AttachFile} from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Link,
	useTheme,
} from "@mui/material";
import {alpha} from "@mui/system/colorManipulator";
import {ErrorAlert, useFlag} from "@variocube/app-ui";
import React, {useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {useDropzone} from "react-dropzone";
import {Invoice} from "../../../api";
import {useFileApi} from "../../../api/files";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {AttachmentItem} from "../attachment-item";
import {AttachmentFile} from "../create/types";

interface AttachmentCardProperties {
	invoice: Invoice;
	showAdd?: boolean;
	onAdd: (files: AttachmentFile[]) => Promise<any>;
}

export function AttachmentCard({invoice, showAdd = false, onAdd}: Readonly<AttachmentCardProperties>) {
	const {t} = useLocalization();
	const [dialogOpen, setDialogOpen, clearDialogOpen] = useFlag(false);
	const {uploadFile} = useFileApi();
	const [attachments, setAttachments] = useState<AttachmentFile[]>([]);

	const {loading, error, execute} = useAsyncCallback(onAdd);

	const theme = useTheme();

	async function handleAddAttachments() {
		await execute(attachments);
		clearDialogOpen();
	}

	function updateAttachment(attachment: File, update: Partial<AttachmentFile>) {
		setAttachments(prev => prev.map(a => a.file === attachment ? {...a, ...update} : a));
	}

	function addFiles(files: File[]) {
		setAttachments(prev => [...prev, ...files.map(file => ({file}))]);
		for (const attachment of files) {
			uploadFile(attachment, {
				onProgress: progress => updateAttachment(attachment, {progress}),
				onSuccess: tempFile => updateAttachment(attachment, tempFile),
				onError: error => updateAttachment(attachment, {error}),
			});
		}
	}

	function handleDelete(file: File) {
		setAttachments(prev => prev.filter(attachment => attachment.file != file));
	}

	const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
		useFsAccessApi: false,
		multiple: true,
		onDrop: addFiles,
		noClick: true,
	});

	return (
		<Card sx={{height: "100%"}}>
			<CardHeader
				title={<Typography startAdornment={<AttachFile />}>{t("invoices.details.attachments")}</Typography>}
			/>
			<CardContent>
				<Grid container spacing={2}>
					{(invoice.attachments ?? []).map((a, index) => (
						<Grid key={a.id} item xs={6}>
							<Box
								component="img"
								src={`/media/attachment/${invoice.id}/${index}/preview.png?scale=1.0`}
								width="100%"
								maxWidth="100%"
								border={1}
								borderColor={theme.palette.divider}
							/>
							<Link color="secondary" href={`/media/attachment/${invoice.id}/${index}`} target="_blank">
								{a.originalFilename}
							</Link>
						</Grid>
					))}
				</Grid>
			</CardContent>
			{showAdd && (
				<CardActions>
					<Button color="secondary" onClick={setDialogOpen}>
						{t("invoices.details.addAttachments")}
					</Button>
				</CardActions>
			)}
			<Dialog open={dialogOpen} onClose={clearDialogOpen} maxWidth="md" fullWidth>
				<Box
					{...getRootProps()}
					border={`1px solid ${isDragActive ? theme.palette.secondary.main : "transparent"}`}
					bgcolor={isDragActive
						? alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity)
						: undefined}
				>
					<DialogTitle>
						<Typography variant="h5">{t("invoices.details.addAttachments")}</Typography>
						<Typography color="textSecondary">
							Dateien hinzufügen oder per Drag & Drop hier ablegen
						</Typography>
					</DialogTitle>

					<DialogContent {...getRootProps()}>
						<input {...getInputProps()} />
						<Grid container spacing={2} sx={{minHeight: 240}}>
							{attachments.map(attachment => (
								<Grid key={attachment.file.name} item xs={6} sm={4} md={3}>
									<AttachmentItem
										attachment={attachment}
										onDelete={() => handleDelete(attachment.file)}
									/>
								</Grid>
							))}

							<Grid item xs={6} sm={4} md={3}>
								<Button
									color="secondary"
									variant="outlined"
									onClick={open}
									size="large"
									sx={{height: "100%"}}
								>
									Dateien auswählen
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
					{error && (
						<DialogContent>
							<ErrorAlert error={error} />
						</DialogContent>
					)}
					<DialogActions>
						<Button color="primary" disabled={loading} onClick={handleAddAttachments}>
							{t("invoices.details.addAttachments")}
						</Button>
						<Button color="inherit" onClick={clearDialogOpen}>
							{t("actions.cancel")}
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
		</Card>
	);
}
