import React, {useEffect, useState} from "react";
import {Creditor, CreditorMutation} from "../../../api";
import {useLocalization} from "../../../i18n";
import {Checkbox, countries, CountrySelect, EditForm, Select, TextField} from "@variocube/app-ui";
import {CardContent, Grid, MenuItem, Stack} from "@mui/material";
import { Typography } from "../../../component/typography";

interface CreditorFormProps {
	loading?: boolean;
	creditor?: Creditor;
	onSave: (data: CreditorMutation) => Promise<void>;
	onCancel: () => void;
}

export function CreditorForm({loading, creditor, onSave, onCancel}: CreditorFormProps) {
	const {t, s} = useLocalization();

	const [companyName, setCompanyName] = useState("");
	const [street, setStreet] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");
	const [checkManually, setCheckManually] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const [sapCreditorNumber, setSapCreditorNumber] = useState("");
	const [voxelSupportsReverseCharge, setVoxelSupportsReverseCharge] = useState(false);

	useEffect(() => {
		if (creditor !== undefined) {
			setCompanyName(creditor.company.companyName ?? "");
			setStreet((creditor.address?.street ?? "") + " " + (creditor.address?.houseNo ?? ""));
			setZipCode(creditor.address?.zip ?? "");
			setCity(creditor.address?.city ?? "");
			setCountry(creditor.address?.country ?? "");
			setCheckManually(creditor.checkManually);
			setEnabled(creditor.enabled);
			setSapCreditorNumber(creditor.sapCreditorNumber ?? '');
			setVoxelSupportsReverseCharge(creditor.voxelSupportsReverseCharge);
		}
	}, [creditor]);

	function handleSave() {
		return onSave({
			companyName,
			street,
			zipCode,
			city,
			country,
			checkManually,
			enabled,
			sapCreditorNumber,
			voxelSupportsReverseCharge
		});
	}

	return (
		<EditForm
			loading={loading ?? false}
			onSave={handleSave}
			onCancel={onCancel}
			labels={s("actions")}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h5">{t('creditors.companyData')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={t('common.name')}
							size="small"
							required
							value={companyName}
							onChange={setCompanyName}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label={t('signup.street')}
							size="small"
							required
							value={street}
							onChange={setStreet}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							fullWidth
							label={t('signup.zip')}
							size="small"
							required
							value={zipCode}
							onChange={setZipCode}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							fullWidth
							label={t('signup.city')}
							size="small"
							required
							value={city}
							onChange={setCity}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Select
							fullWidth
							required
							size="small"
							label={t('signup.country')}
							value={country}
							onChange={setCountry}
							options={countries.map(c => c.code)}
							renderLabel={o => countries.find(c => c.code === o)?.label ?? o}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5">{t('creditors.settings')}</Typography>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							fullWidth
							label={t('creditors.sapCreditorNumber')}
							size="small"
							value={sapCreditorNumber}
							onChange={setSapCreditorNumber}
						/>
					</Grid>
					<Grid item xs={12}>
						<Stack
							direction="column"
						>
							<Checkbox
								label={t('creditors.enabled')}
								value={enabled}
								onChange={setEnabled}
							/>
							<Checkbox
								label={t('creditors.checkManually')}
								value={checkManually}
								onChange={setCheckManually}
							/>
							<Checkbox
								label={t('creditors.voxelReverseChange')}
								value={voxelSupportsReverseCharge}
								onChange={setVoxelSupportsReverseCharge}
							/>
						</Stack>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	)
}
