import {AuditLogFilter} from "../../api/audit";
import {useLocalization} from "../../i18n";
import React, {useEffect, useState} from "react";
import {Checkbox, Filter, PlainDate, PlainDatePicker, TemporalFormat, TextField} from "@variocube/app-ui";
import {Button, Chip, Divider, Grid, Stack} from "@mui/material";
import {notBlank} from "../../util";
import {FilterForm} from "../../component/filter-form";
import {AuditLogType} from "../../api";

const AUDIT_TYPES: AuditLogType[] = [
	"CreditorSignup",
	"UserCreated",
	"UserEdited",
	"UserActivated",
	"UserDeactivated",
	"UserDeleted",
	"UserLogin",
	"UserLogout",
	"UserSessionTimeout",
	"LoginFailed",
	"InboxCreated",
	"InboxEdited",
	"InboxDeleted",
	"OutboxCreated",
	"OutboxEdited",
	"OutboxDeleted",
	"ExpenseReportUploaded",
	"InvoiceUploaded",
	"InvoiceDuplicated",
	"InvoiceRouted",
	"InvoiceForwarded",
	"InvoiceOcrSuccess",
	"InvoiceOcrFailure",
	"InvoiceProcessed",
	"InvoiceRejected",
	"InvoicePayedOut",
	"InvoiceRetentionDeleted",
	"EmailConfirmed",
	"MaintenanceTask",
]

const AUDIT_TYPES_SHORTCUT: Record<string, AuditLogType[]> = {
	"userActions": [
		"UserCreated",
		"UserEdited",
		"UserActivated",
		"UserDeactivated",
		"UserDeleted",
		"UserLogin",
		"UserLogout",
		"UserSessionTimeout",
		"LoginFailed",
	],
	"sftpActions": [
		"InvoiceForwarded",
		"InvoiceOcrSuccess",
		"InvoiceOcrFailure",
	],
	"userUploads": [
		"ExpenseReportUploaded",
		"InvoiceUploaded",
	]
}

interface AuditLogFilterBarProps {
	value: AuditLogFilter;
	onChange: (value: AuditLogFilter) => void;
}

export function AuditLogFilterBar({value, onChange}: AuditLogFilterBarProps) {
	const {s, t} = useLocalization();

	const [userEmail, setUserEmail] = useState("");
	const [types, setTypes] = useState<AuditLogType[]>([]);
	const [createdAfter, setCreatedAfter] = useState<PlainDate|null>(null);
	const [createdBefore, setCreatedBefore] = useState<PlainDate|null>(null);

	useEffect(() => {
		setUserEmail(value.userEmail ?? "");
		setTypes(value.types ?? []);
		setCreatedAfter(notBlank(value.createdAfter) ? PlainDate.from(value.createdAfter) : null);
		setCreatedBefore(notBlank(value.createdBefore) ? PlainDate.from(value.createdBefore) : null);
	}, [value]);

	function handleTypesChange(type: AuditLogType, checked: boolean) {
		if (checked && !types.includes(type)) {
			setTypes(types.concat(type));
		}
		else if (!checked) {
			setTypes(types.filter(value => value !== type));
		}
	}

	function handleShortcutSelection(shortcut: keyof typeof AUDIT_TYPES_SHORTCUT) {
		setTypes(AUDIT_TYPES_SHORTCUT[shortcut]);
	}

	function handleSubmit() {
		onChange({
			userEmail,
			types,
			createdAfter: createdAfter?.toJSON() ?? undefined,
			createdBefore: createdBefore?.toJSON() ?? undefined,
		});
	}

	function handleReset() {
		onChange({
			userEmail: "",
			types: [],
			createdAfter: undefined,
			createdBefore: undefined
		});
	}

	return (
		<Filter
			labels={s("filter")}
			label={t("filter.title")}
			onClear={handleReset}
			active={[
				value.userEmail && (
					<Chip
						label={`${t('users.singular')} (${t('common.email')}): ${value.userEmail}`}
						onDelete={() => onChange({...value, userEmail: ''})}
					/>
				),
				(value.types ?? []).length > 0 && (
					<Chip
						label={`${t('audit.types')}: ${(value.types ?? []).length}`}
						onDelete={() => onChange({...value, types: []})}
					/>
				),
				createdAfter && (
					<Chip
						label={(
							<>
								{t("audit.createdAfter")}: <TemporalFormat value={createdAfter} />
							</>
						)}
						onDelete={() => onChange({...value, createdAfter: undefined})}
					/>
				),
				createdBefore && (
					<Chip
						label={(
							<>
								{t("audit.createdBefore")}: <TemporalFormat value={createdBefore} />
							</>
						)}
						onDelete={() => onChange({...value, createdBefore: undefined})}
					/>
				)
			]}
		>
			<FilterForm
				onApply={handleSubmit}
				onReset={handleReset}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={`${t("users.singular")} (${t("common.email")})`}
							value={userEmail}
							onChange={setUserEmail}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Stack
							direction="row"
							gap={2}
							justifyContent="center"
						>
							<Button
								size="small"
								color="secondary"
								variant="outlined"
								onClick={() => handleShortcutSelection("userActions")}
							>
								{t("audit.userActions")}
							</Button>
							<Button
								size="small"
								color="secondary"
								variant="outlined"
								onClick={() => handleShortcutSelection("sftpActions")}
							>
								{t("audit.sftpActions")}
							</Button>
							<Button
								size="small"
								color="secondary"
								variant="outlined"
								onClick={() => handleShortcutSelection("userUploads")}
							>
								{t("audit.userUploads")}
							</Button>
						</Stack>
					</Grid>
					{AUDIT_TYPES.map(type => (
						<Grid key={type} item xs={12} sm={3}>
							<Checkbox
								label={type}
								value={types.includes(type)}
								onChange={v => handleTypesChange(type, v)}
							/>
						</Grid>
					))}
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} sm={6}>
						<PlainDatePicker
							fullWidth
							label={t("audit.createdAfter")}
							value={createdAfter}
							onChange={setCreatedAfter}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<PlainDatePicker
							fullWidth
							label={t("audit.createdBefore")}
							value={createdBefore}
							onChange={setCreatedBefore}
						/>
					</Grid>
				</Grid>
			</FilterForm>
		</Filter>
	)
}
