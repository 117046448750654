import {TooltipChip} from "./tooltip-chip";
import {Stack} from "@mui/material";
import React from "react";
import {InboxRef} from "../api";
import {useLocalization} from "../i18n";

export function InboxBadge({inbox}: { inbox: InboxRef }) {
	const {t} = useLocalization();
	return (
		<Stack
			direction="row"
			gap={1}
		>
			{inbox.availableForExpenses && <TooltipChip label="BK" tooltip={t('inboxes.availableForExpenses')} />}
			{inbox.availableForWorksAt && <TooltipChip label="AB" tooltip={t('inboxes.availableForWorksAt')} />}
			{inbox.hidden && <TooltipChip label="VA" tooltip={t('inboxes.hidden')} />}
			{inbox.hiddenForCreditors && <TooltipChip label="VK" tooltip={t('inboxes.hiddenForCreditors')} />}
		</Stack>
	)
}
