import {TypeOf} from "zod";
import {get_QueryInboxes, InboxMutation, OutboxMutation, useApiClient} from "./client";

export type InboxQuery = TypeOf<get_QueryInboxes["parameters"]>["query"];

export function useInboxesApi() {
	const api = useApiClient();
	return {
		queryInboxes: (query: InboxQuery) => api.get("/api/v2/inboxes", {query}),
		getInbox: (id: number) => api.get("/api/v2/inboxes/{id}", {path: {id}}),
		createInbox: (mutation: InboxMutation) => api.post("/api/v2/inboxes", {body: mutation}),
		updateInbox: (id: number, mutation: InboxMutation) =>
			api.put("/api/v2/inboxes/{id}", {
				path: {id},
				body: mutation,
			}),
		deleteInbox: (id: number) => api.delete("/api/v2/inboxes/{id}", {path: {id}}),
		updateInboxInvoicesDocTypes: (id: number, documentTypeIds: number[]) =>
			api.put("/api/v2/inboxes/{id}/invoices-doc-types", {
				path: {id},
				body: documentTypeIds,
			}),
		updateInboxAttachmentDocTypes: (id: number, documentTypeIds: number[]) =>
			api.put("/api/v2/inboxes/{id}/attachments-doc-types", {
				path: {id},
				body: documentTypeIds,
			}),
		deleteInboxInvoicesDocTypes: (id: number, documentTypeId: number) =>
			api.delete("/api/v2/inboxes/{id}/invoices-doc-types/{documentTypeId}", {
				path: {
					id,
					documentTypeId,
				},
			}),
		deleteInboxAttachmentDocTypes: (id: number, documentTypeId: number) =>
			api.delete("/api/v2/inboxes/{id}/attachments-doc-types/{documentTypeId}", {
				path: {
					id,
					documentTypeId,
				},
			}),
		getInboxManagers: (id: number) => api.get("/api/v2/inboxes/{id}/managers", {path: {id}}),
		getInboxAuditors: (id: number) => api.get("/api/v2/inboxes/{id}/auditors", {path: {id}}),
		getInboxOutboxes: (id: number) => api.get("/api/v2/inboxes/{id}/outboxes", {path: {id}}),
		addInboxInvoiceManager: (id: number, userId: number) =>
			api.put("/api/v2/inboxes/{id}/invoice-managers/{userId}", {
				path: {
					id,
					userId,
				},
			}),
		addInboxAuditor: (id: number, userId: number) =>
			api.put("/api/v2/inboxes/{id}/auditors/{userId}", {
				path: {
					id,
					userId,
				},
			}),
		deleteInboxManager: (id: number, userId: number) =>
			api.delete("/api/v2/inboxes/{id}/invoice-managers/{userId}", {
				path: {
					id,
					userId,
				},
			}),
		deleteInboxAuditor: (id: number, userId: number) =>
			api.delete("/api/v2/inboxes/{id}/auditors/{userId}", {
				path: {
					id,
					userId,
				},
			}),
		createInboxOutbox: (id: number, mutation: OutboxMutation) =>
			api.post("/api/v2/inboxes/{id}/outboxes", {
				path: {id},
				body: mutation,
			}),
		updateInboxOutbox: (id: number, outboxId: number, mutation: OutboxMutation) =>
			api.put("/api/v2/inboxes/{id}/outboxes/{outboxId}", {
				path: {
					id,
					outboxId,
				},
				body: mutation,
			}),
		deleteInboxOutbox: (id: number, outboxId: number) =>
			api.delete("/api/v2/inboxes/{id}/outboxes/{outboxId}", {
				path: {
					id,
					outboxId,
				},
			}),
		queryInboxAuditors: (id: number) => api.get("/api/v2/inboxes/{id}/inbox-auditors", {path: {id}}),
		deleteInboxAuditors: (id: number) => api.delete("/api/v2/inboxes/{id}/inbox-auditors", {path: {id}}),
	};
}
