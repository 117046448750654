import {useDocumentTypesApi} from "../../../api/document-types";
import {useLocalization} from "../../../i18n";
import {useAsync} from "react-async-hook";
import {useMemo} from "react";
import {DataTable, DataTableColumn, View, ViewHeader} from "@variocube/app-ui";
import {DocumentType} from "../../../api";
import {Button, ButtonGroup, Chip, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import React from "react";
import AddIcon from "@mui/icons-material/Add";

export function DocumentTypeList() {
	const {t} = useLocalization();
	const {queryDocumentTypes} = useDocumentTypesApi();

	const {result, loading, error} = useAsync(queryDocumentTypes, []);

	const columns = useMemo<DataTableColumn<DocumentType>[]>(() => [
		{
			field: "displayName",
			label: t("common.name"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/superuser/doctype/${row.id}`} color="secondary">
						{row.displayName}
					</Link>
				</Typography>
			)
		},
		{
			field: "fileExtension",
			label: t("documentTypes.fileExtension"),
			component: ({row}) => (
				<Chip label={row.fileExtension} />
			)
		},
		{
			field: "mimeType",
			label: "MIME-Type",
			component: ({row}) => (
				<Chip label={row.mimeType} />
			)
		}
	], [t])

	return (
		<View>
			<ViewHeader
				title={t("navigation.settingsGroup.documentTypes")}
				actions={
					<ButtonGroup variant="outlined" disableElevation>
						<Button
							component={RouterLink}
							to="/superuser/doctype/new"
							startIcon={<AddIcon />}
							color="inherit"
						>
							{t("documentTypes.create.title")}
						</Button>
					</ButtonGroup>
				}
			/>
			<DataTable
				columns={columns}
				rows={result ?? []}
				loading={loading}
				error={error}
			/>
		</View>
	)
}
