import React, {useMemo, useState} from "react";
import {
	ConfirmButton,
	DataTable,
	DataTableColumn,
	EditForm,
	PlainDate,
	PlainDatePicker, TemporalFormat,
	View,
	ViewHeader
} from "@variocube/app-ui";
import {useLocalization} from "../../i18n";
import {
	Box,
	Button,
	Dialog, DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	Link, Table, TableBody, TableCell,
	TableHead, TableRow,
	Typography
} from "@mui/material";
import {useMaintenanceApi} from "../../api/maintenance";
import {useAsync} from "react-async-hook";
import {Invoice, ScanService} from "../../api";
import {Link as RouterLink} from "react-router-dom";
import {getUserDisplayName} from "../../utils/user";
import {formatAmount, notBlank, notNull} from "../../util";
import {InvoiceStateChip} from "../invoice/invoice-state-chip";

const MAINTENANCE_REQUESTS = [
	"readScanpointFiles",
	"uploadScanpointFiles",
	"cleanupAuditlog",
	"cleanupInvoices",
	"deleteStaleDocuments",
	"remindAuditors",
	"edifactSftpRead",
]

export function AdminMaintenance() {
	const {t, e, s, language} = useLocalization();
	const {
		readScanpointFiles,
		uploadScanpointFiles,
		cleanupAuditlog,
		cleanupInvoices,
		deleteStaleDocuments,
		remindAuditors,
		edifactSftpRead,
		readSftpFolder,
		uploadIndexFile,
		queryAwaitingUploadInvoices
	} = useMaintenanceApi();

	const {result: invoices, loading, error} = useAsync(queryAwaitingUploadInvoices, []);

	const [date, setDate] = useState<PlainDate|null>(null);

	const [scanService, setScanService] = useState<ScanService|null>(null);
	const {result: listing} = useAsync(async () => {
		if (!scanService) return null;
		return readSftpFolder(scanService);
	}, [scanService])

	async function handleMaintenanceRequest(request: string) {
		switch (request) {
			case "readScanpointFiles":
				await readScanpointFiles();
				break;
			case "uploadScanpointFiles":
				await uploadScanpointFiles();
				break;
			case "cleanupAuditlog":
				await cleanupAuditlog();
				break;
			case "cleanupInvoices":
				await cleanupInvoices();
				break;
			case "deleteStaleDocuments":
				await deleteStaleDocuments();
				break;
			case "remindAuditors":
				await remindAuditors();
				break;
			case "edifactSftpRead":
				await edifactSftpRead();
				break;
		}
	}

	async function handleUploadIndexFile() {
		if (date !== null) {
			await uploadIndexFile(date.toJSON());
		}
	}

	function getPayeeDisplayName(invoice: Invoice) {
		if (invoice.invoiceType === "CreditorInvoice" || invoice.invoiceType === "CreditorCreditNote") {
			if (notBlank(invoice.invoiceCreditor?.name)) {
				return invoice.invoiceCreditor?.name;
			}
			else if (invoice.creditor?.creditorType === "ServiceCreditor") {
				return "<siehe Beleg>";
			}
			return invoice.creditor?.companyName;
		}
		return getUserDisplayName(invoice.user);
	}

	function getBusinessCaseDisplayName(invoice: Invoice) {
		return (invoice.businessCases ?? [])
			.map(c => c.description)
			.join(", ");
	}

	function getGrossDisplayString(invoice: Invoice) {
		return notNull(invoice.gross)
			? formatAmount(invoice.gross, language)
			: "<lt. Beleg>";
	}

	const columns = useMemo<DataTableColumn<Invoice>[]>(() => [
		{
			field: 'slip',
			label: t("invoices.slip"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/invoices/${row.id}`} color="secondary">
						{row.id}
					</Link>
				</Typography>
			)
		},
		{
			field: 'submitter',
			label: t("invoices.submitter"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/invoices/${row.id}`} color="secondary">
						{getPayeeDisplayName(row)}
					</Link>
				</Typography>
			)
		},
		{
			field: 'recipient',
			label: t("invoices.recipient") + ' (BCA)',
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/invoices/${row.id}`} color="secondary">
						{row.inbox?.name}
					</Link>
				</Typography>
			)
		},
		{
			field: 'attachment',
			label: t("invoices.details.attachments"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/invoices/${row.id}`} color="secondary">
						{row.invoiceType === 'CreditorInvoice' && (
							<>
								<span>{row.originalFilename}</span>
								{(row.attachments ?? []).length > 0 && <span>{row.attachments?.length} {t('invoices.details.attachments')}</span>}
							</>
						)}
						{row.invoiceType === 'ExpensesInvoice' && (
							<>
								<span>{getBusinessCaseDisplayName(row)}</span>
								{(row.attachments ?? []).length > 0 && <span>{row.attachments?.length} {t('invoices.details.attachments')}</span>}
							</>
						)}
					</Link>
				</Typography>
			)
		},
		{
			field: 'gross',
			label: t("common.amount"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/invoices/${row.id}`} color="secondary">
						{getGrossDisplayString(row)}
					</Link>
				</Typography>
			)
		},
		{
			field: 'submittedAt',
			label: t("invoices.submittedDate"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/invoices/${row.id}`} color="secondary">
						{notBlank(row.submitted) && <TemporalFormat value={PlainDate.from(row.submitted)} />}
					</Link>
				</Typography>
			)
		},
		{
			field: 'submittedAt',
			label: t("invoices.submittedDate"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/invoices/${row.id}`} color="secondary">
						<InvoiceStateChip invoiceState={row.invoiceState} />
					</Link>
				</Typography>
			)
		},
	], [t]);

	return (
		<View>
			<ViewHeader
				title={t("navigation.settingsGroup.maintenance")}
			/>
			<div>
				<Grid container spacing={2}>
					{MAINTENANCE_REQUESTS.map(request => (
						<Grid key={request} item xs={12} sm={6} md={3}>
							<ConfirmButton
								fullWidth
								variant="outlined"
								color="inherit"
								title={e("maintenance", request)}
								onConfirm={() => handleMaintenanceRequest(request)}
								confirmTitle={t("common.yes")}
								cancel={t("actions.cancel")}
							>
								{t("maintenance.promptMessage")}
							</ConfirmButton>
						</Grid>
					))}
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<Button
							fullWidth
							variant="outlined"
							color="inherit"
							onClick={() => setScanService("SkipOCR")}
						>
							{t("maintenance.oebbUploadDirectory")}
						</Button>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<Button
							fullWidth
							variant="outlined"
							color="inherit"
							onClick={() => setScanService("ScanPoint")}
						>
							{t("maintenance.scanpointUploadDirectory")}
						</Button>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<Button
							fullWidth
							variant="outlined"
							color="inherit"
							onClick={() => setScanService("Exela")}
						>
							{t("maintenance.exelaUploadDirectory")}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<EditForm
							loading={false}
							onSave={handleUploadIndexFile}
							labels={s("maintenance.indexForm")}
						>
							<Box p={2}>
								<Typography variant="h4" gutterBottom>Index-File</Typography>
								<PlainDatePicker
									required
									fullWidth
									label={t("common.date")}
									value={date}
									onChange={setDate}
								/>
							</Box>
						</EditForm>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4" gutterBottom>{t("maintenance.awaitingUpload")}</Typography>

						<DataTable
							columns={columns}
							rows={invoices ?? []}
							loading={loading}
							error={error}
						/>
					</Grid>
				</Grid>
			</div>
			<Dialog
				fullWidth maxWidth="sm"
				open={!!listing}
				onClose={() => setScanService(null)}
			>
				<DialogTitle>{scanService}: upload</DialogTitle>
				<DialogContent>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t("invoices.filename")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{listing?.map(file => (
								<TableRow key={file}>
									<TableCell>{file}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setScanService(null)}>{t("actions.close")}</Button>
				</DialogActions>
			</Dialog>
		</View>
	)
}
