import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Alert, AlertTitle, Box, Button, ButtonGroup, Chip, Grid, Skeleton, Stack} from "@mui/material";
import {
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumbs,
	TemporalFormat,
	tryParseInstant,
	View,
	ViewHeader,
} from "@variocube/app-ui";
import React, {Fragment, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {type InvoiceCreditor, type InvoiceDebitor, type InvoiceParticipant, useInvoicesApi} from "../../api";
import {CopyLinkIconButton} from "../../component/CopyLinkIconButton";
import {Typography} from "../../component/typography";
import {hasRoles, useAuthContext} from "../../context/auth";
import {useLocalization} from "../../i18n";
import {notBlank, notNull} from "../../util";
import {canAuditInvoice} from "../../utils/canAudit";
import {canRouteInvoice} from "../../utils/canRoute";
import {isInvoiceManager} from "../../utils/isInvoiceManager";
import {useNumericParam} from "../../utils/useParam";
import {Administration} from "./details/administration";
import {AttachmentCard} from "./details/attachmentCard";
import {InvoiceAudit} from "./details/audit";
import {BusinessCases} from "./details/business-cases";
import {CarDetails} from "./details/car-details";
import {Creditor} from "./details/creditor";
import {Debitor} from "./details/debitor";
import {Emergency} from "./details/emergency";
import {InvoiceHistory} from "./details/history";
import {Overview} from "./details/overview";
import {Participants} from "./details/participants";
import {InvoicePositions} from "./details/positions";
import {InvoicePreview} from "./details/preview";
import {References} from "./details/references";
import {InvoiceRejected} from "./details/rejected";
import {InvoiceRouting} from "./details/routing";
import {InvoiceSummaryCard} from "./details/summary";
import {InvoiceStateChip} from "./invoice-state-chip";

import {AttachmentFile} from "./create/types";
import {useInvoiceNavigator} from "./useInvoiceNavigator";

export function InvoiceDetails() {
	const {t} = useLocalization();
	const id = useNumericParam("id");
	const {
		getInvoice,
		getInvoiceDuplicates,
		getIfNr,
		getHistory,
		addInvoiceAttachments,
	} = useInvoicesApi();
	const {user} = useAuthContext();

	const {getInvoiceNavigator} = useInvoiceNavigator();

	const {result: {prevId, nextId} = {}} = useAsync(getInvoiceNavigator, [id]);

	const {
		result: [
			invoice,
			duplicates,
			ifnr = "",
			history,
		] = [],
		loading,
		error,
		execute: reload,
	} = useAsync(() =>
		Promise.all([
			getInvoice(id),
			getInvoiceDuplicates(id),
			getIfNr(id),
			getHistory(id),
		]), [id]);

	const isExpense = invoice?.invoiceType === "ExpensesInvoice" || invoice?.invoiceType == "CreditCardInvoice";

	const showInvoiceSummary = isExpense && invoice?.submittedBy?.id == user?.id;

	const showAudit = canAuditInvoice(user, invoice);
	const showRouting = !showAudit && canRouteInvoice(user, invoice);

	const showEmergency = notBlank(invoice?.emergencyJustification);

	const showRejected = notBlank(invoice?.oebbRejected) && notBlank(invoice?.oebbRejectReason);
	const showAdministrative = hasRoles(user, ["SuperUser"]);

	const superUserCanAudit = showAudit
		&& notNull(user)
		&& !invoice?.auditorUsers?.find(a => a.email === user?.email);

	/* DETAILS COLUMN */
	const defaultDates = (invoice?.dates ?? []).filter(d => d.line === 0);
	const defaultReferences = (invoice?.references ?? []).filter(d => d.line === 0);
	const defaultTexts = (invoice?.texts ?? []).filter(d => d.line === 0);
	const hasHeadReference = notNull(invoice?.invoiceDate?.date)
		|| notBlank(invoice?.invoiceNumber?.reference)
		|| notBlank(invoice?.deliveryTerms)
		|| notNull(invoice?.paymentTermsType)
		|| defaultDates.length > 0
		|| defaultReferences.length > 0
		|| defaultTexts.length > 0;
	const showBusinessCases = isExpense && (invoice?.businessCases?.length ?? 0) > 0;
	const carBusinessCases = invoice?.businessCases?.filter(c => notBlank(c.licensePlate)) ?? [];
	const showParticipants = isExpense
		&& (invoice?.numberOfParticipants ?? 0) > 0
		&& (invoice?.participants ?? []).length > 0;
	const showLines = (invoice?.lines ?? []).length > 0;

	const canAddAttachments = isInvoiceManager(user, invoice)
		&& invoice?.invoiceState === "SUBMITTED"
		&& invoice?.inbox?.allowAddingAttachments;

	const canViewAttachments = canAddAttachments
		|| Boolean(!invoice?.filesDeleted && invoice?.attachments?.length);

	const title = useMemo(() => {
		if (invoice) {
			if (invoice.businessCases?.length) {
				return invoice.businessCases?.map(bc => bc.description).join(", ");
			}

			if (invoice.tags?.includes("EntschaedigungszahlungenMitKontierungsfile")) {
				return "Entschädigungszahlung";
			}

			if (invoice.invoiceNumber?.reference) {
				return invoice.invoiceNumber?.reference;
			}

			if (invoice.originalFilename) {
				return invoice.originalFilename;
			}

			return t("invoices.details.title");
		}
	}, [invoice, t]);

	async function handleAddAttachment(attachments: AttachmentFile[]) {
		await addInvoiceAttachments(
			id,
			attachments.map(({file, checksum}) => ({
				contentType: file.type,
				filename: file.name,
				checksum: checksum!,
			})),
		);
		await reload();
	}

	return (
		<View loading={loading} error={error} maxWidth="xl">
			<Breadcrumbs>
				<BreadcrumbLink component={RouterLink} to="/invoices">{t("invoices.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{invoice && t(`invoices.invoiceTypes.${invoice.invoiceType}`)} {id}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={loading ? <Skeleton width={200} /> : title}
				titleVariant="h2"
				titleAdornment={invoice && <InvoiceStateChip invoiceState={invoice.invoiceState} />}
				subTitle={loading
					? <Skeleton width={400} />
					: (
						<Stack spacing={1} direction="row">
							<Box>{invoice && t(`invoices.invoiceTypes.${invoice.invoiceType}`)}</Box>
							{invoice?.invoiceCreditor && (
								<Box>
									{invoice?.invoiceCreditor.name}
								</Box>
							)}
							<Fragment>{invoice?.tags?.map(tag => <Chip key={tag} label={tag} />)}</Fragment>
						</Stack>
					)}
				actions={
					<Stack direction="row" spacing={2}>
						<CopyLinkIconButton />
						<ButtonGroup>
							<Button
								variant="outlined"
								color="inherit"
								disabled={prevId === undefined}
								component={RouterLink}
								to={`/invoices/${prevId}`}
								startIcon={<ChevronLeftIcon />}
							>
								{t("invoices.prev")}
							</Button>
							<Button
								variant="outlined"
								color="inherit"
								disabled={nextId === undefined}
								component={RouterLink}
								to={`/invoices/${nextId}`}
								endIcon={<ChevronRightIcon />}
							>
								{t("invoices.next")}
							</Button>
						</ButtonGroup>
					</Stack>
				}
			/>
			{invoice?.filesDeleted && (
				<Alert severity="warning">
					<AlertTitle>{t("invoices.details.archived.title")}</AlertTitle>
					<Typography>{t("invoices.details.archived.message")}</Typography>
					<Typography>
						{t("invoices.details.archived.archiveDate")}:{" "}
						<TemporalFormat value={tryParseInstant(invoice.filesDeleted)} />
					</Typography>
				</Alert>
			)}
			<Box>
				{invoice && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={8}>
							<Grid container spacing={2}>
								{!invoice?.filesDeleted && (
									<Grid item xs={12} md={6}>
										<InvoicePreview invoice={invoice} />
									</Grid>
								)}
								<Grid item xs={12} md={6}>
									<Overview
										invoice={invoice}
										duplicates={duplicates}
									/>
								</Grid>
								{invoice.invoiceCreditor && (
									<Grid item xs={12} md={6}>
										<Creditor
											creditor={invoice.invoiceCreditor as InvoiceCreditor}
											CardProps={{sx: {height: "100%"}}}
										/>
									</Grid>
								)}
								{invoice.invoiceDebitor && (
									<Grid item xs={12} md={6}>
										<Debitor
											debitor={invoice.invoiceDebitor as InvoiceDebitor}
											CardProps={{sx: {height: "100%"}}}
										/>
									</Grid>
								)}
								{canViewAttachments && (
									<Grid item xs={12} md={6}>
										<AttachmentCard
											invoice={invoice}
											showAdd={canAddAttachments}
											onAdd={handleAddAttachment}
										/>
									</Grid>
								)}
								{hasHeadReference && (
									<Grid item xs={12}>
										<References
											{...{
												invoice,
												defaultDates,
												defaultReferences,
												defaultTexts,
											}}
										/>
									</Grid>
								)}
								{showBusinessCases && (
									<Grid item xs={12}>
										<BusinessCases
											invoice={invoice}
										/>
									</Grid>
								)}
								{carBusinessCases.map(c => (
									<Grid key={c.id} item xs={12}>
										<CarDetails
											businessCase={c}
										/>
									</Grid>
								))}
								{showParticipants && (
									<Grid item xs={12}>
										<Participants
											participants={invoice.participants as InvoiceParticipant[]}
										/>
									</Grid>
								)}
								{showLines && (
									<Grid item xs={12}>
										<InvoicePositions
											invoice={invoice}
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Grid container spacing={2}>
								{showInvoiceSummary && (
									<Grid item xs={12}>
										<InvoiceSummaryCard invoice={invoice} />
									</Grid>
								)}

								{showAudit && (
									<Grid item xs={12}>
										<InvoiceAudit
											invoice={invoice}
											ifnr={ifnr}
										/>
									</Grid>
								)}

								{showEmergency && (
									<Grid item xs={12}>
										<Emergency
											emergencyJustification={invoice.emergencyJustification as string}
										/>
									</Grid>
								)}

								{showRouting && (
									<Grid item xs={12}>
										<InvoiceRouting
											invoices={[invoice]}
										/>
									</Grid>
								)}

								{showRejected && (
									<Grid item xs={12}>
										<InvoiceRejected invoice={invoice} />
									</Grid>
								)}

								<Grid item xs={12}>
									<InvoiceHistory history={history} />
								</Grid>

								{showAdministrative && (
									<Grid item xs={12}>
										<Administration
											invoice={invoice}
											superUserCanAudit={superUserCanAudit}
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				)}
			</Box>
			<Box>
				{invoice?.barcode && (
					<Typography color="textSecondary" variant="body2">
						Dateiname/Barcode Transfer: {invoice.barcode}
					</Typography>
				)}
			</Box>
		</View>
	);
}
