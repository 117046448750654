import {Grid} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {CreditorInboxMutation, CreditorMutation, useCreditorsApi} from "../../../api";
import {useLocalization} from "../../../i18n";
import {useNumericParam} from "../../../utils/useParam";
import {CreditorInboxes} from "./creditor-inboxes";
import {CreditorForm} from "./form";

export function CreditorEdit() {
	const navigate = useNavigate();
	const {t} = useLocalization();
	const {getCreditor, updateCreditor, updateCreditorInboxes, deleteCreditorInbox, addCreditorInbox} =
		useCreditorsApi();

	const id = useNumericParam("id");

	const {loading, error, result, execute: refresh} = useAsync(getCreditor, [id]);

	async function handleSave(mutation: CreditorMutation) {
		if (result !== undefined) {
			await updateCreditor(result.id, mutation);
			navigate("../admin/creditor");
		}
	}

	async function handleSaveCreditorInboxes(mutations: CreditorInboxMutation[]) {
		if (result !== undefined) {
			await updateCreditorInboxes(result.id, mutations);
			await refresh(result.id);
		}
	}

	async function handleRemoveCreditorInbox(inboxId: number) {
		if (result !== undefined) {
			await deleteCreditorInbox(result.id, inboxId);
			await refresh(result.id);
		}
	}

	async function handleAddCreditorInbox(mutation: CreditorInboxMutation) {
		if (result !== undefined) {
			await addCreditorInbox(result.id, mutation);
			await refresh(result.id);
		}
	}

	return (
		<View {...{loading, error}}>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.admin")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../admin/creditor`}>{t("creditors.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{result?.company.companyName}</BreadcrumbItem>
				<BreadcrumbItem>{t("common.edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={result?.company.companyName}
			/>
			<div>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<CreditorForm
							loading={loading}
							creditor={result}
							onSave={handleSave}
							onCancel={() => navigate("../admin/creditor")}
						/>
					</Grid>
					<Grid item xs={12}>
						<CreditorInboxes
							inboxes={result?.inboxes ?? []}
							onAdd={handleAddCreditorInbox}
							onDelete={handleRemoveCreditorInbox}
							onEdit={handleSaveCreditorInboxes}
						/>
					</Grid>
				</Grid>
			</div>
		</View>
	);
}
