import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {InfoMutation, useInfoApi} from "../../../api";
import {DeleteButton} from "../../../component/delete-button";
import {useLocalization} from "../../../i18n";
import {useNumericParam} from "../../../utils/useParam";
import {InfoBoxForm} from "./form";

export function InfoBoxEdit() {
	const navigate = useNavigate();
	const {t} = useLocalization();
	const {getInfo, updateInfo, deleteInfo} = useInfoApi();

	const id = useNumericParam("id");

	const {loading, error, result} = useAsync(getInfo, [id]);

	async function handleDelete() {
		await deleteInfo(id);
		navigate("../admin/infobox/", {replace: true});
	}

	async function handleSave(mutation: InfoMutation) {
		await updateInfo(id, mutation);
		navigate("../admin/infobox/");
	}

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../admin/infobox/`}>{t("infoboxes.title")}</BreadcrumbLink>
				<BreadcrumbItem>{result?.subject}</BreadcrumbItem>
				<BreadcrumbItem>{t("common.edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={result?.subject}
				actions={
					<DeleteButton
						title={t("infoboxes.delete.title")}
						confirmMessage={t("infoboxes.delete.message")}
						onDelete={handleDelete}
					/>
				}
			/>
			<InfoBoxForm
				loading={loading}
				info={result}
				onSave={handleSave}
				onCancel={() => navigate("../admin/infobox/")}
			/>
		</View>
	);
}
