import {Add} from "@mui/icons-material";
import {Button, Link, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, DataTable, DataTableColumn, View, ViewHeader} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {HelpText} from "../../api";
import {useHelpTextApi} from "../../api/help-texts";
import {useLocalization} from "../../i18n";

const QUERY = {};

export function HelpTextList() {
	const {t} = useLocalization();

	const {queryHelpTexts} = useHelpTextApi();

	const {loading, error, result = []} = useAsync(queryHelpTexts, [QUERY]);

	const columns = useMemo<DataTableColumn<HelpText>[]>(() => [
		{
			field: "subject",
			sortable: true,
			label: t("helpTexts.subject"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`${row.id}`} color="secondary">
						{row.subject}
					</Link>
				</Typography>
			),
		},
		{
			field: "helpKey",
			sortable: true,
			label: t("helpTexts.helpKey"),
		},
		{
			field: "language",
			sortable: true,
			label: t("infoboxes.language"),
		},
		{
			field: "viewInApplication",
			sortable: true,
			label: t("infoboxes.application"),
		},
	], [t]);

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbItem>{t("helpTexts.plural")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={t("helpTexts.plural")}
				actions={
					<Button color="primary" startIcon={<Add />} variant="outlined" component={RouterLink} to="new">
						{t("helpTexts.create.title")}
					</Button>
				}
			/>
			<DataTable
				loading={loading}
				error={error}
				rows={result}
				columns={columns}
			/>
		</View>
	);
}
