import {Delete} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	CardContent,
	Collapse,
	Grid,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {IntegerNumberFormat, NumberField, RemoveIcon, TextField} from "@variocube/app-ui";
import React, {Fragment, useEffect, useMemo} from "react";
import {LabeledText} from "../../../../../component/labeled-text";
import {AttachmentItem} from "../../../attachment-item";
import {Participant} from "../../types";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

interface ParticipantStepProps extends StepProps {
	onSelectParticipantFile: () => any;
}

export function ParticipantStep(
	{active, completed, invoice, onChange, onSelectParticipantFile}: Readonly<ParticipantStepProps>,
) {
	const numberOfManualParticipants = useMemo(
		() => invoice.participants?.filter(participant => participant.name).length,
		[invoice],
	);

	useEffect(() => {
		onChange({numberOfParticipants: numberOfManualParticipants});
	}, [numberOfManualParticipants]);

	function changeNumberOfParticipants(numberOfParticipants: number | undefined) {
		onChange({numberOfParticipants});
	}

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title="Teilnehmer"
			activeChildren={
				<CardContent>
					<Stack spacing={2}>
						<Alert severity="info">
							<AlertTitle>
								Teilnehmerliste hochladen oder erfassen
							</AlertTitle>
							<Typography paragraph>
								Wenn eine Teilnehmerliste als Datei vorliegt, können Sie diese per Drop & Drop hochladen
								oder auf «Teilnehmerliste hinzufügen» klicken.
							</Typography>
							<Typography paragraph>
								Alternativ können Sie die Teilnehmer unten eingeben.
							</Typography>
						</Alert>
						{!invoice.participantFile && (
							<Button
								variant="outlined"
								size="large"
								color="secondary"
								disabled={(numberOfManualParticipants ?? 0) > 0}
								fullWidth
								onClick={onSelectParticipantFile}
							>
								Teilnehmerliste hinzufügen
							</Button>
						)}
						<Collapse in={Boolean(invoice.participantFile)}>
							<Box>
								{invoice.participantFile && (
									<AttachmentItem
										attachment={invoice.participantFile}
										onDelete={() => onChange({participantFile: undefined})}
									/>
								)}
							</Box>
						</Collapse>
						<Collapse in={!invoice.participantFile} mountOnEnter unmountOnExit>
							<ParticipantListEdit
								value={invoice.participants ?? []}
								onChange={participants => onChange({participants})}
							/>
						</Collapse>
						<Stack gap={2} direction="row" alignItems="center" justifyContent="center">
							<IconButton
								color="secondary"
								onClick={() => changeNumberOfParticipants((invoice.numberOfParticipants ?? 0) - 1)}
								disabled={(invoice.numberOfParticipants ?? 0) <= 1}
							>
								<RemoveIcon/>
							</IconButton>
							<NumberField
								label="Anzahl Teilnehmer"
								numberFormat={IntegerNumberFormat}
								numberValue={invoice.numberOfParticipants ?? null}
								onChangeNumber={value => changeNumberOfParticipants(value ?? undefined)}
								required
								error={!invoice.numberOfParticipants}
								helperText={!invoice.numberOfParticipants
									&& "Es muss mindestens einen Teilnehmer geben."}
							/>
							<IconButton
								color="secondary"
								onClick={() => changeNumberOfParticipants((invoice.numberOfParticipants ?? 0) + 1)}
							>
								<AddIcon/>
							</IconButton>
						</Stack>
					</Stack>
				</CardContent>
			}
			completedChildren={
				<Fragment>
					<CardContent>
						<Stack direction="row" gap={2} flexWrap="wrap">
							<LabeledText label="Anzahl Teilnehmer" text={invoice.numberOfParticipants}/>
							{invoice.participantFile && (
								<LabeledText label="Teilnehmerliste" text={invoice.participantFile.file.name}/>
							)}
						</Stack>
					</CardContent>
					{!invoice.participantFile && (
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Firma</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{invoice.participants?.map((participant, index) => (
									<TableRow key={index}>
										<TableCell>{participant.name}</TableCell>
										<TableCell>{participant.company}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					)}
				</Fragment>
			}
		/>
	);
}

interface ParticipantListEditProps {
	value: Participant[];
	onChange: (participants: Participant[]) => any;
}

function ParticipantListEdit({value, onChange}: Readonly<ParticipantListEditProps>) {
	function change(index: number, key: "name" | "company", newValue: string) {
		const next = [...value];
		next[index] = {
			...value[index],
			[key]: newValue,
		};
		onChange(next);
	}

	function handleDelete(deleteIndex: number) {
		onChange(value.filter((_, index) => index != deleteIndex));
	}

	const valuePlusEmpty = [...value, {name: "", company: ""}];

	return (
		<Grid container spacing={2}>
			{valuePlusEmpty.map((participant, index) => (
				<>
					<Grid item xs={10} sm={5}>
						<TextField
							label="Name"
							fullWidth
							value={participant.name}
							onChange={name => change(index, "name", name)}
						/>
					</Grid>
					<Grid item xs={10} sm={5}>
						<TextField
							label="Firma"
							fullWidth
							value={participant.company}
							onChange={name => change(index, "company", name)}
						/>
					</Grid>
					<Grid item xs={2}>
						<IconButton
							title="Teilnehmer entfernen"
							onClick={() => handleDelete(index)}
							color="error"
						>
							<Delete/>
						</IconButton>
					</Grid>
				</>
			))}
		</Grid>
	);
}
