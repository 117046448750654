import {Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, DataTable, DataTableColumn, View, ViewHeader} from "@variocube/app-ui";
import React, {useMemo, useState} from "react";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {CreditCard, useCreditCardApi} from "../../../api";
import {FileUploadButton} from "../../../component/file-upload-button";
import {useLocalization} from "../../../i18n";
import {getUserDisplayName} from "../../../utils/user";

export function CreditCardList() {
	const {t} = useLocalization();
	const {queryCreditCards, exportCreditCards, importCreditCards} = useCreditCardApi();

	const {result, loading, error, execute} = useAsync(queryCreditCards, []);
	const [importError, setImportError] = useState<any>();

	const columns = useMemo<DataTableColumn<CreditCard>[]>(() => [
		{
			field: "cardNumber",
			label: t("creditCards.cardNumber"),
			component: ({row}) => <Typography>{row.cardNumber}</Typography>,
		},
		{
			field: "user.lastname",
			label: t("users.singular"),
			component: ({row}) => <Typography>{getUserDisplayName(row.user)}</Typography>,
		},
		{
			field: "user.email",
			label: t("common.email"),
			component: ({row}) => <Typography>{row.user.email}</Typography>,
		},
	], [t]);

	function clearImportError() {
		setImportError(undefined);
	}

	const importAction = useAsyncCallback(async (file: File) => {
		clearImportError();
		try {
			await importCreditCards(file);
			await execute();
		}
		catch (error) {
			setImportError(error);
		}
	});

	async function handleExportCreditCards() {
		await exportCreditCards();
	}

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbItem>{t("navigation.settingsGroup.creditCards")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={t("navigation.settingsGroup.creditCards")}
				actions={
					<ButtonGroup variant="outlined" disableElevation>
						<Button
							variant="outlined"
							color="inherit"
							onClick={handleExportCreditCards}
						>
							{t("creditCards.export")}
						</Button>
						<FileUploadButton
							label={t("creditCards.import")}
							disabled={importAction.loading}
							onUpload={importAction.execute}
							variant="outlined"
							color="inherit"
						/>
					</ButtonGroup>
				}
			/>
			<DataTable
				columns={columns}
				rows={result ?? []}
				loading={loading || importAction.loading}
				error={error}
			/>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={importError}
				onClose={clearImportError}
			>
				<DialogTitle>{t("creditCards.importError")}</DialogTitle>
				<DialogContent>
					<Typography whiteSpace="pre-wrap">
						{importError?.details}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={clearImportError}>{t("actions.close")}</Button>
				</DialogActions>
			</Dialog>
		</View>
	);
}
