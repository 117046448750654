import React, {useEffect} from "react";
import {notBlank} from "../util";
import {useFlag} from "@variocube/app-ui";

import 'summernote';
import 'summernote/dist/summernote.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';

interface NoteEditorProps {
	text?: string;
	onChange?: (text: string) => void;
}

let editor: JQuery<HTMLElement>;

export function NoteEditor({text, onChange}: NoteEditorProps) {

	const [edited, setEdited] = useFlag(false);

	useEffect(() => {
		while (true) {
			if (document.getElementById('summernote') !== null) {
				editor = $('#summernote');
				editor.summernote({
					callbacks: {
						onChange: v => {
							if (onChange !== undefined) onChange(v);
							setEdited()
						}
					}
				});
				break;
			}
		}
		return () => {
			editor.summernote('destroy');
		}
	}, []);

	useEffect(() => {
		if (editor !== undefined) {
			if (notBlank(text) && !edited) {
				editor.summernote('code', text);
			}
		}
	}, [text]);

	return (
		<div id="summernote"></div>
	)
}
