import {Alert, CardContent, CardHeader, Divider, Grid, MenuItem, Stack} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, EditForm, TextField, View, ViewHeader} from "@variocube/app-ui";
import React, {useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {COMPANY_FORMS, CompanyForm, Creditor, useIndexesApi} from "../../api";
import {PasswordInput} from "../../component/password-input";
import {useAuthContext} from "../../context/auth";
import {useLocalization} from "../../i18n";
import {InboxTable} from "../user/inbox-table";

export function CreditorUserSettings() {
	const {t} = useLocalization();
	const {getCurrentCreditor} = useIndexesApi();

	const {result: creditor, execute: refresh} = useAsync(getCurrentCreditor, []);

	async function handleRefresh() {
		await refresh();
	}

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.admin")}</BreadcrumbItem>
				<BreadcrumbItem>{t("navigation.userSettings")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("navigation.userSettings")}/>

			<div>
				<Grid container spacing={2}>
					{creditor && (
						<Grid item xs={12} sm={6}>
							<CompanyEdit creditor={creditor}/>
						</Grid>
					)}
					<Grid item xs={12} sm={6}>
						<Stack
							direction="column"
							spacing={2}
						>
							<ContactDataEdit/>
							<PasswordEdit/>
						</Stack>
					</Grid>
					{creditor && (
						<Grid item xs={12}>
							<InboxEdit
								creditor={creditor}
								onUpdate={handleRefresh}
							/>
						</Grid>
					)}
				</Grid>
			</div>
		</View>
	);
}

function CompanyEdit({creditor}: { creditor: Creditor }) {
	const {t, e, s} = useLocalization();
	const {updateCreditor} = useIndexesApi();

	const [legalForm, setLegalForm] = useState<CompanyForm>("SinglePerson");
	const [company, setCompany] = useState("");
	const [vat, setVat] = useState("");
	const [companyNr, setCompanyNr] = useState("");

	const [street, setStreet] = useState("");
	const [houseNo, setHouseNo] = useState("");
	const [zip, setZip] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");

	useEffect(() => {
		if (creditor) {
			setLegalForm(creditor.company.form ?? "SinglePerson");
			setCompany(creditor.company.companyName ?? "");
			setVat(creditor.company.vatIdentification ?? "");
			setCompanyNr(creditor.company.commercialRegister ?? "");

			setStreet(creditor.address?.street ?? "");
			setHouseNo(creditor.address?.houseNo ?? "");
			setZip(creditor.address?.zip ?? "");
			setCity(creditor.address?.city ?? "");
			setCountry(creditor.address?.country ?? "");
		}
	}, [creditor]);

	async function handleSave() {
		await updateCreditor({
			legalForm,
			companyName: company,
			vatIdNumber: vat,
			commercialRegisterNumber: companyNr,
			street,
			houseNo,
			zip,
			city,
			country,
		});
	}

	return (
		<EditForm
			loading={false}
			onSave={handleSave}
			labels={s("actions")}
		>
			<CardHeader title={t("common.company")}/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							select
							fullWidth
							required
							label={t("signup.legalForm")}
							value={legalForm}
							onChange={v => {
								setLegalForm(v as CompanyForm);
							}}
						>
							{COMPANY_FORMS
								.map(v => (
									<MenuItem key={v} value={v}>
										{e("signup.legalForms", v)}
									</MenuItem>
								))}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							required
							label={t("signup.companyName")}
							value={company}
							onChange={setCompany}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="VAT"
							placeholder="Umsatzsteuer-Identifikationsnummer"
							value={vat}
							onChange={setVat}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label="FN"
							placeholder="Firmenbuchnummer"
							value={companyNr}
							onChange={setCompanyNr}
						/>
					</Grid>
					<Grid item xs={12}>
						<Divider/>
					</Grid>
					<Grid item xs={9}>
						<TextField
							fullWidth
							label={t("signup.streetName")}
							value={street}
							onChange={setStreet}
						/>
					</Grid>
					<Grid item xs={3}>
						<TextField
							fullWidth
							label={t("signup.houseNo")}
							value={houseNo}
							onChange={setHouseNo}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							fullWidth
							label={t("signup.zip")}
							value={zip}
							onChange={setZip}
						/>
					</Grid>
					<Grid item xs={12} sm={8}>
						<TextField
							fullWidth
							label={t("signup.city")}
							value={city}
							onChange={setCity}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={t("signup.country")}
							value={country}
							onChange={setCountry}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}

function ContactDataEdit() {
	const {t, s} = useLocalization();
	const {user} = useAuthContext();
	const {updateContactPerson} = useIndexesApi();

	const [firstName, setFirstName] = useState(user?.contactPerson?.firstname ?? "");
	const [lastName, setLastName] = useState(user?.contactPerson?.lastname ?? "");
	const [phone, setPhone] = useState(user?.contactPerson?.phone ?? "");

	async function handleSave() {
		if (user) {
			await updateContactPerson({
				firstname: firstName,
				lastname: lastName,
				phone,
				email: user.email,
			});
		}
	}

	return (
		<EditForm
			loading={false}
			onSave={handleSave}
			labels={s("actions")}
		>
			<CardHeader title={t("users.contactData")}/>
			<CardContent>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							label={t("common.firstName")}
							value={firstName}
							onChange={setFirstName}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							label={t("common.lastName")}
							value={lastName}
							onChange={setLastName}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							fullWidth
							label={t("common.email")}
							value={user?.email}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label={t("common.phone")}
							value={phone}
							onChange={setPhone}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}

function PasswordEdit() {
	const {t, s} = useLocalization();
	const {updatePassword} = useIndexesApi();

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [invalidPassword, setInvalidPassword] = useState<string>();

	const [strength, setStrength] = useState(0);

	async function handleSave() {
		setInvalidPassword(undefined);
		if (strength < 60) {
			setInvalidPassword(t("signup.weakPassword"));
			return;
		}
		if (password !== confirmPassword) {
			setInvalidPassword(t("signup.passwordMismatch"));
			return;
		}

		await updatePassword(password);
		setPassword("");
		setConfirmPassword("");
	}

	return (
		<EditForm
			loading={false}
			onSave={handleSave}
			labels={s("actions")}
		>
			<CardHeader title={t("auth.password")}/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<PasswordInput
							required
							value={password}
							onChange={setPassword}
							onStrengthChange={setStrength}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							required
							label={t("signup.confirmPassword")}
							type="password"
							value={confirmPassword}
							onChange={setConfirmPassword}
						/>
					</Grid>
					{invalidPassword && (
						<Grid item xs={12}>
							<Alert severity="error">{invalidPassword}</Alert>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</EditForm>
	);
}

function InboxEdit({creditor, onUpdate}: { creditor: Creditor; onUpdate: () => Promise<void> }) {
	const {t} = useLocalization();
	const {queryCreditorInboxes, updateCreditorInboxes, deleteCreditorInboxes} = useIndexesApi();
	const {result: allInboxes} = useAsync(queryCreditorInboxes, []);

	const options = useMemo(() => {
		return (allInboxes ?? [])
			.filter(i => !creditor.inboxes?.find(c => c.inbox.id === i.id));
	}, [allInboxes, creditor.inboxes]);

	async function handleAddInboxes(ids: number[]) {
		await updateCreditorInboxes(ids);
		await onUpdate();
	}

	async function handleRemoveInbox(inboxId: number) {
		await deleteCreditorInboxes(inboxId);
		await onUpdate();
	}

	return (
		<InboxTable
			title={t("creditors.favouriteInboxes.title")}
			inboxes={creditor.inboxes?.map(i => i.inbox) ?? []}
			filter="FAVORITE_ASSIGNMENT"
			onAdd={handleAddInboxes}
			onRemove={handleRemoveInbox}
			assignLabel={t("creditors.favouriteInboxes.assign")}
		/>
	);
}
