import {LinearProgress, Stack, useTheme} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useLocalization} from "../i18n";
import {usePasswordStrength} from "../utils/usePasswordStrength";
import {Typography} from "./typography";

interface PasswordInputProps {
	required?: boolean;
	value?: string;
	onChange?: (password: string) => void;
	onStrengthChange?: (percentage: number) => void;
}

export function PasswordInput({required, value, onChange, onStrengthChange}: PasswordInputProps) {
	const {t} = useLocalization();

	const [password, setPassword] = useState("");

	const theme = useTheme();

	useEffect(() => {
		setPassword(value ?? "");
	}, [value]);

	const {level, percentage} = usePasswordStrength(password);

	useEffect(() => {
		if (onStrengthChange) onStrengthChange(percentage);
	}, [percentage]);

	function handleChange(value: string) {
		setPassword(value);
		if (onChange) onChange(value);
	}

	return (
		<Stack
			direction="column"
			gap={1}
		>
			<TextField
				required={required}
				fullWidth
				label={t("signup.password")}
				type="password"
				value={password}
				onChange={handleChange}
			/>
			<LinearProgress
				variant="determinate"
				value={percentage}
				color={getColor(percentage)}
			/>
			<Typography color={theme.palette[getColor(percentage)].main} variant="caption">
				{level}
			</Typography>
		</Stack>
	);
}

function getColor(percentage: number) {
	if (percentage < 60) {
		return "error";
	}
	if (percentage < 79) {
		return "warning";
	}
	return "success";
}
