import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {InfoMutation, useInfoApi} from "../../../api";
import {useLocalization} from "../../../i18n";
import {InfoBoxForm} from "./form";

export function InfoBoxCreator() {
	const navigate = useNavigate();
	const {t} = useLocalization();
	const {createInfo} = useInfoApi();

	async function handleSave(mutation: InfoMutation) {
		const {id} = await createInfo(mutation);
		navigate(`../admin/infobox/${id}`);
	}

	return (
		<View loading={false}>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../admin/infobox/`}>{t("infoboxes.title")}</BreadcrumbLink>
				<BreadcrumbItem>{t("infoboxes.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("infoboxes.create.title")} />
			<InfoBoxForm
				onSave={handleSave}
				onCancel={() => navigate("../admin/infobox/")}
			/>
		</View>
	);
}
