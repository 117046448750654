import React from "react";
import {Chip, Tooltip} from "@mui/material";

export function TooltipChip({label, tooltip}: { label: string, tooltip: string }) {
	return (
		<Tooltip title={tooltip} placement="top">
			<Chip label={label} />
		</Tooltip>
	)
}
