import {ScanService, useApiClient} from "./client";

export function useMaintenanceApi() {
	const api = useApiClient();
	return {
		readScanpointFiles: () => api.put("/api/v2/maintenance/read-scanpoint-files"),
		uploadScanpointFiles: () => api.put("/api/v2/maintenance/upload-scanpoint-files"),
		cleanupAuditlog: () => api.put("/api/v2/maintenance/cleanup-auditlog"),
		cleanupInvoices: () => api.put("/api/v2/maintenance/cleanup-invoices"),
		deleteStaleDocuments: () => api.put("/api/v2/maintenance/delete-stale-documents"),
		remindAuditors: () => api.put("/api/v2/maintenance/remind-auditors"),
		edifactSftpRead: () => api.put("/api/v2/maintenance/read-edifact-sftp"),
		readSftpFolder: (service: ScanService) => api.get("/api/v2/maintenance/read-sftp-folder", {query: { service }}),
		uploadIndexFile: (date: string) => api.post("/api/v2/maintenance/upload-index-file", {query: { date }}),
		queryAwaitingUploadInvoices: () => api.get("/api/v2/maintenance/awaiting-upload-invoices"),
	}
}
