import {CardContent, LinearProgress} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import React from "react";
import {useSetting} from "../../../../../context/auth";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

export function EmergencyStep({active, completed, invoice, onChange}: Readonly<StepProps>) {
	const emergencyMinimumCharacters = useSetting("emergencyMinimumCharacters") ?? 50;

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title="Gefahr im Verzug"
			activeChildren={
				<CardContent>
					<TextField
						label={`Begründung (min. ${emergencyMinimumCharacters} Zeichen)`}
						fullWidth
						multiline
						rows={4}
						value={invoice.emergencyJustification}
						onChange={emergencyJustification => onChange({emergencyJustification})}
						required
						validate={value => {
							if (value.length < emergencyMinimumCharacters) {
								return `Geben Sie mindestens ${emergencyMinimumCharacters} Zeichen ein.`;
							}
						}}
					/>
					<LinearProgress
						variant="determinate"
						value={Math.min(
							100,
							100 * (invoice.emergencyJustification ?? "").length / emergencyMinimumCharacters,
						)}
						color={(invoice.emergencyJustification ?? "").length < emergencyMinimumCharacters
							? "primary"
							: "success"}
					/>
				</CardContent>
			}
			completedChildren={<CardContent>{invoice.emergencyJustification}</CardContent>}
		/>
	);
}
