import {AltRoute} from "@mui/icons-material";
import {Alert, Button, Card, CardContent, CardHeader, Collapse, LinearProgress, Stack} from "@mui/material";
import {defined, ErrorAlert, RadioGroup, TextField, useFlag} from "@variocube/app-ui";
import React, {type FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Invoice, InvoiceSummary, Outbox, useInvoicesApi} from "../../../api";
import {AuditorSelect} from "../../../component/auditor-select";
import {BcaSelect} from "../../../component/bca-select";
import {OrderNumbersField} from "../../../component/order-numbers-field";
import {OutboxSelect} from "../../../component/outbox-select";
import {Typography} from "../../../component/typography";
import {useReloadInvoiceCount, useSetting} from "../../../context/auth";
import {useLocalization} from "../../../i18n";

interface InvoiceRoutingProperties {
	invoices: Invoice[];
	onRouted?: () => any;
}

export function inSameInbox(invoices: (Invoice | InvoiceSummary)[]) {
	const [first, ...others] = invoices;
	return first && others.every(invoice => invoice.inbox.id == first.inbox.id);
}

function validateAndGetFirst(invoices: Invoice[]) {
	const [first] = invoices;
	if (!first || !inSameInbox(invoices)) {
		throw new Error("Invalid set of invoices for batch routing.");
	}
	return first;
}

export function InvoiceRouting({invoices, onRouted}: Readonly<InvoiceRoutingProperties>) {
	const {s, t} = useLocalization();
	const {routeInvoice, rejectInvoice, forwardInvoice} = useInvoicesApi();
	const reloadInvoiceCount = useReloadInvoiceCount();

	const firstInvoice = validateAndGetFirst(invoices);

	const [progress, setProgress] = useState<number>();
	const [success, setSuccess] = useFlag(false);

	const [rejectReason, setRejectReason] = useState("");
	const [additionalInformation, setAdditionalInformation] = useState(firstInvoice.additionalInformation);
	const [orderNumbers, setOrderNumbers] = useState<string[]>(firstInvoice.orderNumbers);
	const [auditor, setAuditor] = useState<string | null>(null);
	const [outbox, setOutbox] = useState<Outbox | null>(null);
	const [decision, setDecision] = useState<"Reject" | "Accept" | "Forward">();
	const [forwardInbox, setForwardInbox] = useState<number | undefined>();

	const includingCheck = firstInvoice.invoiceType === "CreditorInvoice"
		&& firstInvoice.inbox?.approvalIncludesInvoiceCheck;

	const rejectMinimumCharacters = useSetting("rejectMinimumCharacters");
	const featureOrderNumbers = useSetting("orderNumbers");

	const submit = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();

		setProgress(0);

		for (let i = 0; i < invoices.length; i++) {
			const invoice = invoices[i];

			if (decision == "Reject") {
				await rejectInvoice(invoice.id, {
					rejectReason,
				});
			}
			else if (decision == "Accept" && outbox) {
				await routeInvoice(invoice.id, {
					outboxId: outbox.id,
					orderNumbers,
					additionalInformation,
					auditor: auditor ?? undefined,
				});
			}
			else if (decision == "Forward" && defined(forwardInbox)) {
				await forwardInvoice(invoice.id, {
					inboxId: forwardInbox,
					additionalInformation,
				});
				setSuccess();
			}

			setProgress(100 * (i + 1) / invoices.length);
		}
		setProgress(100);
		setSuccess();
		if (onRouted) {
			onRouted();
		}
		await reloadInvoiceCount();
	});

	return (
		<Card
			component="form"
			onSubmit={submit.execute}
		>
			<CardHeader
				title={
					<Typography startAdornment={<AltRoute />}>
						{includingCheck
							? t("invoices.details.approvalAndCheckTitle")
							: t("invoices.details.approvalTitle")}
					</Typography>
				}
			/>

			<Collapse in={!success}>
				<CardContent>
					<RadioGroup
						label={t("invoices.routing.decision")}
						value={decision}
						onChange={setDecision}
						options={["Reject", "Accept", "Forward"]}
						renderLabel={s("invoices.routing.decisions")}
					/>
				</CardContent>
				<Collapse in={decision == "Reject"}>
					<CardContent>
						<TextField
							fullWidth
							multiline
							required={decision == "Reject"}
							rows={8}
							value={rejectReason}
							onChange={setRejectReason}
							label={t("invoices.details.rejectReason", {minChar: rejectMinimumCharacters})}
						/>
					</CardContent>
				</Collapse>
				<Collapse in={decision == "Forward"}>
					<CardContent>
						<BcaSelect
							label={t("invoices.routing.inbox")}
							required={decision == "Forward"}
							value={forwardInbox}
							onChange={setForwardInbox}
						/>
					</CardContent>
				</Collapse>
				<Collapse in={decision == "Accept"}>
					<CardContent>
						<Stack spacing={2}>
							<OutboxSelect
								value={outbox}
								onChange={setOutbox}
								inbox={firstInvoice.inbox}
								required={decision == "Accept"}
								fullWidth
							/>

							{firstInvoice.inbox?.requiresAudit && (
								<AuditorSelect
									fullWidth
									required
									inbox={firstInvoice.inbox}
									value={auditor}
									onChange={setAuditor}
								/>
							)}
							{featureOrderNumbers === true && (
								<OrderNumbersField
									value={orderNumbers}
									onChange={setOrderNumbers}
								/>
							)}
						</Stack>
					</CardContent>
				</Collapse>
				<CardContent>
					<TextField
						label={t("invoices.details.additionalInformation")}
						fullWidth
						multiline
						rows={4}
						value={additionalInformation}
						onChange={setAdditionalInformation}
					/>
				</CardContent>
				<CardContent>
					<Button
						fullWidth
						variant="contained"
						color="primary"
						disabled={submit.loading || !decision}
						type="submit"
					>
						{decision == "Forward" ? t("actions.redirect") : t("actions.save")}
					</Button>
				</CardContent>
				{submit.error && (
					<CardContent>
						<ErrorAlert error={submit.error} />
					</CardContent>
				)}
			</Collapse>
			<Collapse in={success}>
				<CardContent>
					<Alert severity="success">
						{decision === "Accept" && (
							<Typography>
								{includingCheck && t("invoices.details.routeingApproveAndCheckMessage")}
								{!includingCheck && t("invoices.details.routeingApproveMessage")}
							</Typography>
						)}
						{decision === "Reject" && <Typography>{t("invoices.details.routingRejectMessage")}</Typography>}
						{decision == "Forward" && <Typography>{t("invoices.details.routingForwardMessage")}
						</Typography>}
					</Alert>
				</CardContent>
			</Collapse>
			{defined(progress) && <LinearProgress variant="determinate" value={progress} color="secondary" />}
		</Card>
	);
}
