import {Receipt} from "@mui/icons-material";
import {Box, BoxProps, Typography, useTheme} from "@mui/material";
import React, {useMemo} from "react";
import {Document, Page, pdfjs} from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.mjs",
	import.meta.url,
).toString();

interface FilePreviewProps {
	file: File;
}

export function FilePreview({file}: Readonly<FilePreviewProps>) {
	if (file.type.startsWith("application/pdf")) {
		return <PdfFilePreview file={file} />;
	}
	if (file.type.startsWith("image/")) {
		return <ImageFilePreview file={file} />;
	}
	return (
		<PreviewBox>
			<Box height={240} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
				<Receipt color="disabled" fontSize="large" />
				<Typography variant="body2" color="textSecondary">
					Keine Vorschau verfügbar.
				</Typography>
			</Box>
		</PreviewBox>
	);
}

function PreviewBox({children, ...props}: BoxProps) {
	const theme = useTheme();
	return (
		<Box border={1} borderColor={theme.palette.divider} {...props}>
			{children}
		</Box>
	);
}

function PdfFilePreview({file}: Readonly<FilePreviewProps>) {
	return (
		<PreviewBox
			sx={{
				"& .preview-canvas": {
					minWidth: "auto !important",
					minHeight: "auto !important",
					"& > canvas": {
						width: "auto !important",
						height: "auto !important",
						maxWidth: "100%",
						maxHeight: "100%",
					},
				},
			}}
		>
			<Document
				className="preview-doc"
				file={file}
			>
				<Page renderTextLayer={false} renderAnnotationLayer={false} pageNumber={1} className="preview-canvas" />
			</Document>
		</PreviewBox>
	);
}

function ImageFilePreview({file}: Readonly<FilePreviewProps>) {
	const url = useMemo(() => URL.createObjectURL(file), [file]);
	return (
		<PreviewBox>
			<img
				src={url}
				alt={file.name}
				style={{display: "block", width: "100%"}}
			/>
		</PreviewBox>
	);
}
