import {OpenInNew} from "@mui/icons-material";
import {Button, ButtonGroup} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {useInboxesApi} from "../../api";
import {DeleteButton} from "../../component/delete-button";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {InboxAuditorEmailList} from "./email-list";
import {InboxAuditorImportForm} from "./import-form";

export function InboxAuditorsDetails() {
	const {t, s} = useLocalization();
	const {getInbox, queryInboxAuditors, deleteInboxAuditors} = useInboxesApi();

	const id = useNumericParam("id");

	const {result: inbox} = useAsync(getInbox, [id]);
	const {loading, error, result, execute: refresh} = useAsync(queryInboxAuditors, [id]);

	async function handleDeleteInboxAuditors() {
		await deleteInboxAuditors(id);
		await refresh(id);
	}

	async function handleImportAuditors(file: File) {
		const formData = new FormData();
		formData.append("file", file);

		const response = await fetch(`/api/v2/inboxes/${id}/inbox-auditors`, {
			method: "POST",
			body: formData,
		});

		if (response.status !== 201) {
			throw new Error("Invalid uploaded data.");
		}

		await refresh(id);
	}

	return (
		<View
			{...{loading, error}}
		>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../inbox-auditors`}>
					{t("navigation.adminGroup.auditors")}
				</BreadcrumbLink>
				<BreadcrumbItem>{inbox?.name}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={inbox?.name}
				actions={
					<ButtonGroup variant="outlined" disableElevation>
						<Button
							component="a"
							href={`/api/v2/inboxes/${id}/inbox-auditors/export`}
							target="_blank"
							startIcon={<OpenInNew />}
							color="inherit"
						>
							{t("actions.export")}
						</Button>
						<DeleteButton
							title={t("inboxes.inboxAuditors.delete.title")}
							confirmMessage={t("inboxes.inboxAuditors.delete.message")}
							onDelete={handleDeleteInboxAuditors}
						/>
					</ButtonGroup>
				}
			/>
			<InboxAuditorImportForm onFileUpload={handleImportAuditors} />
			{result !== undefined && <InboxAuditorEmailList auditors={result} />}
		</View>
	);
}
