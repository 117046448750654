import {Close, DeleteOutline, EditOutlined, SaveOutlined} from "@mui/icons-material";
import {
	Button,
	Card,
	CardActions,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import {ConfirmIconButton, EditForm, TextField, useFlag} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {CreditorInbox, CreditorInboxMutation} from "../../../api";
import {BcaSelect} from "../../../component/bca-select";
import {useLocalization} from "../../../i18n";

interface CreditorInboxesProps {
	inboxes: CreditorInbox[];
	onAdd: (mutation: CreditorInboxMutation) => Promise<void>;
	onDelete: (id: number) => Promise<void>;
	onEdit: (mutations: CreditorInboxMutation[]) => Promise<void>;
}

export function CreditorInboxes({inboxes, onAdd, onDelete, onEdit}: CreditorInboxesProps) {
	const {s, t} = useLocalization();

	const [assignMode, setAssignMode, clearAssignMode] = useFlag(false);
	const [editMode, setEditMode, clearEditMode] = useFlag(false);

	const [inboxMutations, setInboxMutations] = useState<CreditorInboxMutation[]>([]);
	const [inboxId, setInboxId] = useState<number>();
	const [debitorNumber, setDebitorNumber] = useState("");

	function mapMutation(data: CreditorInbox[]) {
		setInboxMutations(data.map(i => ({
			inboxId: i.inbox.id,
			debitorNumber: i.debitorNumber,
		})));
	}

	useEffect(() => {
		mapMutation(inboxes);
	}, [inboxes]);

	function enableEditMode() {
		mapMutation(inboxes);
		setEditMode();
	}

	function handleDebitorNumberChange(inboxId: number, debitorNumber: string) {
		setInboxMutations(inboxMutations.map(m =>
			(m.inboxId === inboxId)
				? {inboxId, debitorNumber}
				: m
		));
	}

	async function handleSave() {
		await onEdit(inboxMutations);
		clearEditMode();
	}

	async function handleAddInbox() {
		await onAdd({
			inboxId: inboxId as number,
			debitorNumber,
		});
		clearAssignMode();
		setInboxId(undefined);
		setDebitorNumber("");
	}

	return (
		<>
			<Card>
				<CardHeader
					title={t("users.mailboxes.inboxes.title")}
					action={
						<IconButton color="secondary" onClick={editMode ? clearEditMode : enableEditMode}>
							{editMode ? <Close/> : <EditOutlined/>}
						</IconButton>
					}
				/>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>{t("users.mailboxes.inboxes.title")} (BCA)</TableCell>
							<TableCell width="40%">{t("creditors.debitorNumber")}</TableCell>
							<TableCell width={30}>&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{inboxes
							.sort((a, b) => (a.inbox.name ?? "").localeCompare(b.inbox.name ?? ""))
							.map(i => (
								<TableRow key={i.inbox.id}>
									<TableCell>{i.inbox.name}</TableCell>
									<TableCell>
										{!editMode && i.debitorNumber}
										{editMode && (
											<TextField
												fullWidth
												size="small"
												value={inboxMutations.find(m => m.inboxId == i.inbox.id)?.debitorNumber
													?? ""}
												onChange={v => handleDebitorNumberChange(i.inbox.id, v)}
											/>
										)}
									</TableCell>
									<TableCell>
										<ConfirmIconButton
											disabled={editMode}
											title={t("users.mailboxes.revoke.title")}
											cancel={t("actions.cancel")}
											onConfirm={() => onDelete(i.inbox.id)}
											icon={<DeleteOutline/>}
											color="error"
										>
											{t("users.mailboxes.revoke.prompt")}
										</ConfirmIconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
				<CardActions>
					{!editMode && (
						<Button
							disabled={editMode}
							color="secondary"
							onClick={setAssignMode}
						>
							{t("users.mailboxes.inboxes.assign")}
						</Button>
					)}
					{editMode && (
						<>
							<Button
								variant="outlined"
								color="primary"
								startIcon={<SaveOutlined/>}
								onClick={handleSave}
							>
								{t("actions.save")}
							</Button>
							<Button variant="outlined" color="inherit" startIcon={<Close/>} onClick={clearEditMode}>
								{t("actions.cancel")}
							</Button>
						</>
					)}
				</CardActions>
			</Card>
			<Dialog open={assignMode} fullWidth maxWidth="sm">
				<EditForm
					loading={false}
					onSave={handleAddInbox}
					onCancel={clearAssignMode}
					labels={s("actions")}
				>
					<DialogTitle>{t("users.mailboxes.inboxes.assign")}</DialogTitle>
					<DialogContent>
						<Grid container spacing={3} mt={1}>
							<Grid item xs={12} sm={6}>
								<BcaSelect
									value={inboxId}
									onChange={setInboxId}
									label={t("users.mailboxes.inboxes.title")}
									required
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									size="small"
									label={t("creditors.debitorNumber")}
									value={debitorNumber}
									onChange={setDebitorNumber}
								/>
							</Grid>
						</Grid>
					</DialogContent>
				</EditForm>
			</Dialog>
		</>
	);
}
