import {Business} from "@mui/icons-material";
import {Card, CardContent, CardHeader, type CardProps as MuiCardProperties} from "@mui/material";
import React from "react";
import {type InvoiceCreditor} from "../../../api";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {notBlank} from "../../../util";

export function Creditor({creditor, CardProps}: { creditor: InvoiceCreditor; CardProps?: MuiCardProperties }) {
	const {t} = useLocalization();
	return (
		<Card {...CardProps}>
			<CardHeader title={<Typography startAdornment={<Business />}>{t("creditors.singular")}</Typography>} />
			<CardContent>
				<Typography>{creditor.name}</Typography>
				<Typography>{creditor.streetAndHouseNo}</Typography>
				<Typography>{creditor.zip} {creditor.city}</Typography>
				<Typography>{creditor.countryCode}</Typography>
				{notBlank(creditor.vat) && <Typography>UST-ID: {creditor.vat}</Typography>}
				{notBlank(creditor.iban) && <Typography>IBAN: {creditor.iban}</Typography>}
				{notBlank(creditor.bic) && <Typography>BIC: {creditor.bic}</Typography>}
				{notBlank(creditor.bank) && <Typography>Bank: {creditor.bank}</Typography>}
			</CardContent>
		</Card>
	);
}
