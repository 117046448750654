import React, {useMemo, useState} from "react";
import {DataTable, DataTableColumn, EditForm, TextField, View, ViewHeader} from "@variocube/app-ui";
import {Setting, useSettingsApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useAsync} from "react-async-hook";
import {Chip, Dialog, DialogTitle, IconButton, Stack, Typography} from "@mui/material";
import {EditOutlined} from "@mui/icons-material";

export function AdminSettings() {
	const {t, s} = useLocalization();
	const {listSettings, updateSetting} = useSettingsApi();

	const {result, loading, error, execute: refresh} = useAsync(listSettings, []);

	const [select, setSelect] = useState<Setting>();
	const [value, setValue] = useState("");

	const columns = useMemo<DataTableColumn<Setting>[]>(() => [
		{
			field: "key",
			label: "Key",
			component: ({row}) => (
				<Chip label={row.key} />
			)
		},
		{
			field: "description",
			label: t("common.description"),
			component: ({row}) => (
				<span>{row.description}</span>
			)
		},
		{
			field: "action",
			label: "",
			component: ({row}) => (
				<IconButton onClick={() => handleSelect(row)}><EditOutlined /></IconButton>
			)
		}
	], [t])

	function handleSelect(setting: Setting|undefined) {
		setSelect(setting);
		setValue(setting?.value ?? "");
	}

	async function handleSave() {
		if (select !== undefined) {
			await updateSetting(select.key as string, value);
			await refresh();
		}
	}

	return (
		<View>
			<ViewHeader
				title={t("navigation.settingsGroup.settings")}
			/>
			<DataTable
				columns={columns}
				rows={result ?? []}
				loading={loading}
				error={error}
			/>
			<Dialog
				open={select !== undefined}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>{t("common.edit")}</DialogTitle>
				<EditForm
					loading={false}
					onSave={handleSave}
					onCancel={() => handleSelect(undefined)}
					labels={s("actions")}
				>
					<Stack
						p={2}
						direction="column"
						alignItems="flex-start"
						spacing={2}
					>
						<Chip label={select?.key} />
						<Typography>{select?.description}</Typography>
						<TextField
							fullWidth
							label={t("common.value")}
							value={value}
							onChange={setValue}
						/>
					</Stack>
				</EditForm>
			</Dialog>
		</View>
	)
}
