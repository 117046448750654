import {Inbox} from "../api";

export function isValidDocumentType(inbox?: Inbox, file?: File) {
	return file && inbox?.assignedInvoiceDocumentTypes.map(documentType => documentType.mimeType).includes(file.type);
}

export function isValidAttachmentType(inbox?: Inbox, file?: File) {
	return file
		&& inbox?.assignedAttachmentDocumentTypes.map(documentType => documentType.mimeType).includes(file.type);
}
