import {Autocomplete} from "@mui/material";
import {ErrorAlert, TextField} from "@variocube/app-ui";
import React, {ReactNode, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {useInboxesApi, useInvoicesApi} from "../api";
import {useLocalization} from "../i18n";

interface InboxAuditorSingleSelectProps {
	label?: ReactNode;
	value: string | null;
	onChange: (value: string | null) => any;
	inboxId?: number;
	required?: boolean;
	fullWidth?: boolean;
	disabled?: boolean;
}

function useAuditors(inboxId: number | undefined) {
	const {queryInboxAuditors} = useInboxesApi();
	const {listAuditorHistory} = useInvoicesApi();

	const {loading, error, result} = useAsync(
		(inboxId?: number) => inboxId ? queryInboxAuditors(inboxId) : Promise.resolve([]),
		[inboxId],
	);

	const {result: history} = useAsync(listAuditorHistory, []);

	const auditors = useMemo(() => {
		return result?.map(auditor => ({
			...auditor,
			recentlyUsed: Boolean(history?.includes(auditor.email)),
		})).sort((a1, a2) => (a1.recentlyUsed ? 1 : 0) - (a2.recentlyUsed ? 1 : 0));
	}, [result, history]);
	return {loading, error, auditors};
}

export function InboxAuditorSingleSelect(props: Readonly<InboxAuditorSingleSelectProps>) {
	const {label, value, onChange, inboxId, required, fullWidth, disabled} = props;
	const {t} = useLocalization();
	const {loading, error, auditors} = useAuditors(inboxId);

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return (
		<Autocomplete
			loading={loading}
			options={auditors ?? []}
			disabled={disabled}
			renderInput={(parameters) => (
				<TextField
					placeholder={t("hint.select")}
					required={required}
					label={label}
					{...parameters}
				/>
			)}
			groupBy={auditor => auditor.recentlyUsed ? "Kürzlich verwendet" : "Alle"}
			value={auditors?.filter(auditor => value == auditor.email).pop() ?? null}
			onChange={(_, value) => onChange(value?.email ?? null)}
			fullWidth={fullWidth}
			getOptionLabel={auditor => auditor.email}
			autoSelect
			autoHighlight
		/>
	);
}

interface InboxAuditorMultiSelectProps {
	label?: ReactNode;
	value: string[];
	onChange: (value: string[]) => any;
	inboxId?: number;
	required?: boolean;
	fullWidth?: boolean;
	disabled?: boolean;
}

export function InboxAuditorMultiSelect(props: Readonly<InboxAuditorMultiSelectProps>) {
	const {label, value, onChange, inboxId, required, fullWidth, disabled} = props;
	const {t} = useLocalization();
	const {loading, error, auditors} = useAuditors(inboxId);

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return (
		<Autocomplete
			loading={loading}
			options={auditors ?? []}
			disabled={disabled}
			multiple
			renderInput={(parameters) => (
				<TextField
					placeholder={t("hint.select")}
					required={required}
					label={label}
					{...parameters}
					inputProps={{
						...parameters.inputProps,
						required: required && value.length == 0,
					}}
				/>
			)}
			groupBy={auditor => auditor.recentlyUsed ? "Kürzlich verwendet" : "Alle"}
			value={auditors?.filter(auditor => value.includes(auditor.email)) ?? []}
			onChange={(_, value) => {
				onChange(value.map(auditor => auditor.email));
			}}
			fullWidth={fullWidth}
			getOptionLabel={auditor => auditor.email}
			autoSelect
			autoHighlight
		/>
	);
}
