import React, {type FormEvent, type PropsWithChildren, useState} from 'react';
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useLocalization} from '../i18n';

interface ModalFormProperties<T> {
    open: boolean;
    title: string;
    onSubmit: (event_: FormEvent<T>) => Promise<void>;
    onCancel: () => void;
    submitLabel?: string;
    cancelLabel?: string;
}

export function ModalForm<T extends HTMLDivElement>({open, onCancel, title, onSubmit, submitLabel, cancelLabel, children}: PropsWithChildren<ModalFormProperties<T>>) {
    const {t} = useLocalization();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();

    function handleSubmit(event_: FormEvent<T>) {
        if (!loading) {
            setError(undefined);
            setLoading(true);
            onSubmit(event_)
                .catch(setError)
                .finally(() => { setLoading(false); });
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            component="form"
            onSubmit={handleSubmit}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}

                {error && (
                    <Alert severity="error">
                        {error.message ?? 'Error'}
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button disableElevation variant="outlined" color="secondary" onClick={onCancel}>{cancelLabel ?? t('actions.cancel')}</Button>
                <Button disableElevation variant="contained" color="primary" type="submit">{submitLabel ?? t('actions.submit')}</Button>
            </DialogActions>
        </Dialog>
    );
}
