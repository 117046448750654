import {Chip, ChipProps} from "@mui/material";
import React from "react";
import {InvoiceState} from "../../api";
import {useLocalization} from "../../i18n";

interface InvoiceStateDisplayProps extends Omit<ChipProps, "label" | "color"> {
	invoiceState: InvoiceState;
}

export function InvoiceStateChip(props: Readonly<InvoiceStateDisplayProps>) {
	const {invoiceState, ...chipProps} = props;
	const {e} = useLocalization();

	return (
		<Chip
			label={e("invoices.invoiceStates", invoiceState)}
			color={getColor(invoiceState)}
			{...chipProps}
		/>
	);
}

function getColor(invoiceState: InvoiceState) {
	switch (invoiceState) {
		case "SUBMITTED":
		case "CHECKED":
		case "AUDITED":
		case "ARCHIVED":
			return "default";

		case "OEBB_REJECTED":
		case "OCR_FAILED":
		case "ERROR":
			return "error";

		case "ROUTED":
		case "UPLOADED":
		case "OCR_SUCCESSFUL":
			return "warning";

		case "PAYED_OUT":
		case "PROCESSED":
			return "success";

		default:
			return invoiceState satisfies never;
	}
}
