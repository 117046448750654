import {Chip, Grid} from "@mui/material";
import {
	Filter,
	IntegerNumberFormat,
	NumberField,
	PlainDatePicker,
	Select,
	TextField,
	tryParsePlainDate,
	useStorage,
} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {INVOICE_STATES, INVOICE_TYPES, InvoiceFilter, InvoiceState, InvoiceType, useInboxesApi} from "../../api";
import {BcaSelect} from "../../component/bca-select";
import {FilterForm} from "../../component/filter-form";
import {TagSelect} from "../../component/tag-select";
import {useLocalization} from "../../i18n";

export function useInvoiceFilter() {
	return useStorage<InvoiceFilter>("InvoiceFilter", {});
}

interface InvoiceFilterProps {
	value: InvoiceFilter;
	onChange: (value: InvoiceFilter) => void;
}

export function InvoiceFilterBar({value, onChange}: Readonly<InvoiceFilterProps>) {
	const {s, t} = useLocalization();

	const [type, setType] = useState<InvoiceType | undefined>(value.invoiceType);
	const [inboxId, setInboxId] = useState(value.inboxId);
	const [state, setState] = useState<InvoiceState | undefined>(value.invoiceState);

	const [filename, setFilename] = useState(value.filename ?? "");
	const [creditorName, setCreditorName] = useState(value.creditorName ?? "");
	const [email, setEmail] = useState(value.email ?? "");
	const [tags, setTags] = useState<string[]>(value.tags ?? []);
	const [amountFrom, setAmountFrom] = useState(value.amountFrom ?? null);
	const [amountTo, setAmountTo] = useState(value.amountTo ?? null);
	const [invoiceIds, setInvoiceIds] = useState(value.invoiceIds);

	const [submittedAfter, setSubmittedAfter] = useState(tryParsePlainDate(value.submittedAfter));
	const [submittedBefore, setSubmittedBefore] = useState(tryParsePlainDate(value.submittedBefore));

	const {getInbox} = useInboxesApi();
	const {result: inbox} = useAsync((inboxId?: number) => inboxId ? getInbox(inboxId) : Promise.resolve(undefined), [
		inboxId,
	]);

	useEffect(() => {
		setTags(value.tags ?? []);
	}, [value.tags]);

	async function handleSubmit() {
		onChange({
			invoiceType: type,
			filename,
			inboxId,
			creditorName,
			email,
			submittedAfter: submittedAfter?.toString(),
			submittedBefore: submittedBefore?.toString(),
			amountFrom: amountFrom ?? undefined,
			amountTo: amountTo ?? undefined,
			invoiceState: state ?? undefined,
			invoiceIds,
			tags,
		});
	}

	function handleReset() {
		onChange({});
	}

	return (
		<Filter
			labels={s("filter")}
			label={t("filter.title")}
			onClear={handleReset}
			active={[
				value.invoiceState && (
					<Chip
						label={t(`invoices.invoiceStates.${value.invoiceState}`)}
						onDelete={() => onChange({...value, invoiceState: undefined})}
					/>
				),
				value.invoiceType && (
					<Chip
						label={t(`invoices.invoiceTypes.${value.invoiceType}`)}
						onDelete={() => onChange({...value, invoiceType: undefined})}
					/>
				),
				value.submittedOrOwnedBy && (
					<Chip
						label={t("invoices.invoiceViews.Mine")}
						onDelete={() => onChange({...value, submittedOrOwnedBy: undefined})}
					/>
				),
				value.auditRequestedFrom && (
					<Chip
						label={t("invoices.invoiceViews.Audit")}
						onDelete={() => onChange({...value, auditRequestedFrom: undefined})}
					/>
				),
				value.awaitingAudit && (
					<Chip
						label={t("invoices.invoiceViews.AwaitingAudit")}
						onDelete={() => onChange({...value, awaitingAudit: undefined})}
					/>
				),
				value.awaitingRouting && (
					<Chip
						label={t("invoices.invoiceViews.AwaitingRouting")}
						onDelete={() => onChange({...value, awaitingRouting: undefined})}
					/>
				),
				value.filename && (
					<Chip
						label={`${t("invoices.filename")}: ${value.filename}`}
						onDelete={() => onChange({...value, filename: undefined})}
					/>
				),
				value.inboxId && (
					<Chip
						label={`${t("invoices.recipient")}: ${inbox?.name ?? value.inboxId}`}
						onDelete={() => onChange({...value, inboxId: undefined})}
					/>
				),
				value.creditorName && (
					<Chip
						label={`${t("invoices.creditorName")}: ${value.creditorName}`}
						onDelete={() => onChange({...value, creditorName: undefined})}
					/>
				),
				value.email && (
					<Chip
						label={`${t("common.email")}: ${value.email}`}
						onDelete={() => onChange({...value, email: undefined})}
					/>
				),
				// todo: date format
				value.submittedBefore && (
					<Chip
						label={`${t("invoices.submittedBefore")}: ${value.submittedBefore}`}
						onDelete={() => onChange({...value, submittedBefore: undefined})}
					/>
				),
				// todo: date format
				value.submittedAfter && (
					<Chip
						label={`${t("invoices.submittedAfter")}: ${value.submittedAfter}`}
						onDelete={() => onChange({...value, submittedAfter: undefined})}
					/>
				),
				// todo: number format
				value.amountFrom && (
					<Chip
						label={`${t("invoices.amountFrom")}: ${value.amountFrom}`}
						onDelete={() => onChange({...value, amountFrom: undefined})}
					/>
				),
				// todo: number format
				value.amountTo && (
					<Chip
						label={`${t("invoices.amountTo")}: ${value.amountTo}`}
						onDelete={() => onChange({...value, amountTo: undefined})}
					/>
				),
				value.invoiceIds && (
					<Chip
						label={`${t("invoices.invoiceIds")}: ${value.invoiceIds}`}
						onDelete={() => onChange({...value, invoiceIds: undefined})}
					/>
				),
				value.tags?.map(tag => (
					<Chip
						key={tag}
						label={`${tag}`}
						onDelete={() => onChange({...value, tags: value.tags?.filter(t => t != tag)})}
					/>
				)),
			]}
		>
			<FilterForm onApply={handleSubmit} onReset={handleReset}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Select
							label={t("invoices.invoiceType")}
							fullWidth
							options={["All", ...INVOICE_TYPES] as const}
							value={type ?? "All"}
							onChange={type => setType(type == "All" ? undefined : type)}
							renderLabel={s("invoices.invoiceTypes")}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("invoices.filename")}
							fullWidth
							value={filename}
							onChange={setFilename}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<BcaSelect
							label={t("invoices.recipient")}
							value={inboxId}
							onChange={setInboxId}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("invoices.creditorName")}
							fullWidth
							value={creditorName}
							onChange={setCreditorName}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("common.email")}
							fullWidth
							value={email}
							onChange={setEmail}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<PlainDatePicker
							label={t("invoices.submittedAfter")}
							fullWidth
							value={submittedAfter}
							onChange={setSubmittedAfter}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<PlainDatePicker
							label={t("invoices.submittedBefore")}
							fullWidth
							value={submittedBefore}
							onChange={setSubmittedBefore}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<NumberField
							label={t("invoices.amountFrom")}
							fullWidth
							numberFormat={IntegerNumberFormat}
							numberValue={amountFrom}
							onChangeNumber={setAmountFrom}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<NumberField
							label={t("invoices.amountTo")}
							fullWidth
							numberFormat={IntegerNumberFormat}
							numberValue={amountTo}
							onChangeNumber={setAmountTo}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Select
							label={t("common.status")}
							fullWidth
							options={["All", ...INVOICE_STATES]}
							value={state ?? "All"}
							onChange={state => setState(state == "All" ? undefined : state)}
							renderLabel={s("invoices.invoiceStates")}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("invoices.invoiceIds")}
							fullWidth
							value={invoiceIds}
							onChange={setInvoiceIds}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TagSelect value={tags} onChange={setTags} fullWidth/>
					</Grid>
				</Grid>
			</FilterForm>
		</Filter>
	);
}
