import {SpringPageable} from "@variocube/app-ui";
import {TypeOf} from "zod";
import {CreditorInboxMutation, CreditorMutation, get_QueryCreditors, useApiClient} from "./index";

export type CreditorQuery = TypeOf<get_QueryCreditors["parameters"]>["query"];
export type CreditFilter = Omit<CreditorQuery, keyof SpringPageable>;

export function useCreditorsApi() {
	const api = useApiClient();
	return {
		getCurrent: () => api.get("/api/v2/creditors/current"),
		queryCreditors: (query: CreditorQuery) => api.get("/api/v2/creditors", {query}),
		getCreditor: (id: number) => api.get("/api/v2/creditors/{id}", {path: {id}}),
		updateCreditor: (id: number, mutation: CreditorMutation) =>
			api.put("/api/v2/creditors/{id}", {path: {id}, body: mutation}),
		updateCreditorInboxes: (id: number, mutations: CreditorInboxMutation[]) =>
			api.put("/api/v2/creditors/{id}/inboxes", {path: {id}, body: mutations}),
		deleteCreditorInbox: (id: number, inboxId: number) =>
			api.delete("/api/v2/creditors/{id}/inboxes/{inboxId}", {path: {id, inboxId}}),
		addCreditorInbox: (id: number, mutation: CreditorInboxMutation) =>
			api.post("/api/v2/creditors/{id}/inboxes", {path: {id}, body: mutation}),
	};
}
