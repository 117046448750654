import {CardContent} from "@mui/material";
import {defined, RadioGroup} from "@variocube/app-ui";
import React from "react";
import {useUser} from "../../../../../context/auth";
import {getUserDisplayName} from "../../../../../utils/user";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

export function SubmitOnBehalfStep({active, completed, invoice, onChange}: Readonly<StepProps>) {
	const user = useUser();

	const representedUser = user.represents.find(r => r.id == invoice.onBehalfOfUserId) ?? user;

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title="Beleg einreichen für"
			activeChildren={
				<CardContent>
					{user.represents.length > 0 && (
						<RadioGroup
							label="Beleg einreichen für:"
							value={representedUser.email}
							onChange={value =>
								onChange({
									onBehalfOfUserId: user.represents.find(r => r.email == value)?.id ?? user.id,
								})}
							options={[user.email, ...user.represents.map(r => r.email)].filter(defined)}
							renderLabel={value =>
								value == user.email
									? "Mich selbst"
									: getUserDisplayName(user.represents.find(r => r.email == value))}
						/>
					)}
				</CardContent>
			}
			completedChildren={<CardContent>{representedUser.displayName}</CardContent>}
		/>
	);
}
