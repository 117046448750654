import {Theme, useTheme} from "@mui/material";
import React from "react";

interface LogoProps {
	color?: "primary" | "textSecondary";
	pulse?: boolean;
	width?: number;
}

function getColor(theme: Theme, color: "primary" | "textSecondary") {
	if (color == "primary") {
		return theme.palette.primary.main;
	}
	if (color == "textSecondary") {
		return theme.palette.text.secondary;
	}
}

export function Logo({color = "primary", pulse, width}: LogoProps) {
	const theme = useTheme();

	const fill = getColor(theme, color);
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="80px"
			height="30px"
			viewBox="0 0 80 30"
			style={{
				animation: pulse ? "1s infinite alternate-reverse pulse-icon ease-in-out" : undefined,
				width,
				height: "auto",
			}}
		>
			<g>
				<polygon
					style={{fill}}
					points="11.149,11.246 17.56,11.246 25.432,2.625 19.209,2.625"
				/>
				<g>
					<path
						style={{fill}}
						d="M43.423,23.317h-5.997v-6.335h7.011c1.05,0,2.923,0,2.923,2.886C47.359,23.317,45.11,23.317,43.423,23.317 M37.426,6.936h6.224c1.648,0,3.111,0.638,3.111,2.85c0,2.061-1.274,2.887-3.524,2.887h-5.811V6.936z M49.459,14.283c1.612-1.387,2.25-3.637,2.25-5.098c0-1.049-0.413-6.561-9.072-6.561H32.253v25.003h11.396c1.461,0,4.347-0.148,5.997-1.613c2.286-2.059,2.698-4.085,2.698-5.319C52.345,17.583,50.92,15.557,49.459,14.283"
					/>
					<path
						style={{fill}}
						d="M68.876,23.317h-5.998v-6.335h7.047c1.014,0,2.925,0,2.925,2.886C72.85,23.317,70.526,23.317,68.876,23.317 M62.878,6.936h6.223c1.65,0,3.075,0.638,3.075,2.85c0,2.061-1.237,2.887-3.488,2.887h-5.81V6.936z M74.91,14.283c1.839-1.387,2.25-3.637,2.25-5.098c0-1.049-0.412-6.561-9.108-6.561H57.707v25.003h11.394c1.425,0,4.349-0.148,5.959-1.613c2.325-2.022,2.737-4.085,2.737-5.319C77.798,17.583,76.335,15.557,74.91,14.283"
					/>
					<path
						style={{fill}}
						d="M17.784,2.4c-1.312-0.412-2.661-0.638-4.086-0.638c-7.459,0-13.494,5.886-13.494,13.345c0,7.161,6.035,13.194,13.494,13.194c7.271,0,13.307-6.033,13.307-13.194c0-3.411-1.236-6.485-3.336-8.846l-3.561,3.785c1.087,1.387,1.761,3.149,1.761,5.061c0,4.349-3.748,8.061-8.171,8.061c-4.611,0-8.322-3.712-8.322-8.061c0-4.572,3.711-8.209,8.322-8.209L17.784,2.4z"
					/>
				</g>
			</g>
		</svg>
	);
}
