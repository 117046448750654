import {defined} from "@variocube/app-ui";

export function parseQrCode(code?: string) {
	if (!code) {
		return {};
	}

	const [
		, // padding
		, // format
		, // pos
		, // receipt
		, // date
		gross20,
		gross10,
		gross13,
		gross0,
		gross19,
	] = code.split("_");

	const grossAmounts = [gross20, gross10, gross13, gross0, gross19]
		.map(parseAmount)
		.filter(defined);

	return {
		gross: getTotalGross(grossAmounts),
	};
}

function getTotalGross(grossAmounts: number[]) {
	if (grossAmounts.length > 0) {
		return grossAmounts.reduce((a, b) => a + b, 0);
	}
}

function parseAmount(str: string | undefined) {
	if (str) {
		const num = Number.parseFloat(str.replace(",", "."));
		if (num) {
			return num;
		}
	}
}
