import {DeleteOutline} from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardActions,
	CardHeader,
	Chip,
	Dialog,
	DialogTitle,
	Link,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import {ConfirmIconButton, EditForm, TextField, useFlag} from "@variocube/app-ui";
import _ from "lodash";
import React, {useState} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {User, useUserApi} from "../../../api";
import {useLocalization} from "../../../i18n";
import {notBlank} from "../../../util";
import {getUserDisplayName} from "../../../utils/user";

interface InboxUserTableProps {
	title: string;
	users: User[];
	onAdd: (id: number) => Promise<void>;
	onRemove: (id: number) => Promise<void>;
	assignLabel: string;
}

export function InboxUserTable({title, users, onAdd, onRemove, assignLabel}: InboxUserTableProps) {
	const {t, s} = useLocalization();
	const {queryUsers} = useUserApi();

	const [editMode, setEditMode, clearEditMode] = useFlag(false);
	const [search, setSearch] = useState("");
	const [selected, setSelected] = useState<User | null>(null);

	const handleSearchInput = _.debounce(setSearch, 500);

	const {result: options} = useAsync(() =>
		queryUsers({name: search, size: 25})
			.then(p => p.content), [search]);

	async function handleSubmit() {
		if (selected !== null) {
			await onAdd(selected.id);
			clearEditMode();
			setSelected(null);
		}
	}

	return (
		<>
			<Card>
				<CardHeader title={title} />
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>{t("common.name")}</TableCell>
							<TableCell>{t("common.email")}</TableCell>
							<TableCell>{t("users.role")}</TableCell>
							<TableCell>{t("common.active")}</TableCell>
							<TableCell width={10}>&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users
							.sort((u1, u2) =>
								(getUserDisplayName(u1) ?? "").localeCompare(getUserDisplayName(u2) ?? "")
							)
							.map(u => (
								<TableRow key={u.id}>
									<TableCell>
										<Link component={RouterLink} to={`/admin/user/edit/${u.id}`} color="secondary">
											{getUserDisplayName(u)}
										</Link>
									</TableCell>
									<TableCell>
										<Link href={`mailto:${u.email}`} color="secondary">{u.email}</Link>
									</TableCell>
									<TableCell>{u.role}</TableCell>
									<TableCell>
										<Chip
											color={u.locked ? "error" : "success"}
											label={u.locked ? t("common.no") : t("common.yes")}
										/>
									</TableCell>
									<TableCell>
										<ConfirmIconButton
											title={t("inboxes.users.delete.title")}
											cancel={t("actions.cancel")}
											onConfirm={() => onRemove(u.id)}
											icon={<DeleteOutline />}
											color="error"
										>
											{t("inboxes.users.delete.prompt")}
										</ConfirmIconButton>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
				<CardActions>
					<Button color="secondary" onClick={setEditMode}>{assignLabel}</Button>
				</CardActions>
			</Card>
			<Dialog open={editMode} onClose={clearEditMode} fullWidth maxWidth="sm">
				<DialogTitle>{assignLabel}</DialogTitle>
				<EditForm
					loading={false}
					onSave={handleSubmit}
					onCancel={clearEditMode}
					labels={s("actions")}
				>
					<Box
						p={3}
					>
						<Autocomplete
							onInputChange={(_, v) => handleSearchInput(v)}
							renderInput={params => <TextField {...params} required label={t("users.singular")} />}
							options={options ?? []}
							value={selected}
							onChange={(_, v) => setSelected(v)}
							getOptionLabel={o => {
								const labels = [
									[o.lastname, o.firstname].filter(notBlank).join(", "),
									o.email,
								];
								return labels
									.filter(notBlank)
									.join(" - ");
							}}
						/>
					</Box>
				</EditForm>
			</Dialog>
		</>
	);
}
