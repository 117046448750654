import {get_QueryReportingData, useApiClient} from "./client";
import {TypeOf} from "zod";

export type ReportingSetting = TypeOf<get_QueryReportingData["parameters"]>["query"];

export function useReportingApi() {
	const api = useApiClient();
	return {
		queryReportingData: (query: ReportingSetting) => api.get("/api/v2/reporting/data", {query})
	}
}
