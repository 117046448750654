import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Card,
	CardContent,
	Collapse,
	Grid,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import {TextField, useStorage, View} from "@variocube/app-ui";
import React, {FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import {useAuthApi} from "../api/auth";
import {Infoboxes} from "../component/infoboxes";
import {useAuthContext} from "../context/auth";
import {useLocalization} from "../i18n";

export function Auth() {
	const {t} = useLocalization();
	const {getLogin} = useAuthApi();
	const navigate = useNavigate();
	const {reloadAuth} = useAuthContext();
	const [search] = useSearchParams();

	const [email, setEmail] = useStorage("login.email", "");
	const [password, setPassword] = useState("");
	const [local, setLocal] = useState(false);

	const [passwordRef, setPasswordRef] = useState<HTMLInputElement | null>(null);

	const submit = useAsyncCallback(async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (local) {
			const formData = new FormData(e.currentTarget);
			const response = await fetch("/login", {
				method: "post",
				body: formData,
				headers: {
					accept: "application/json",
				},
			});
			if (!response.ok) {
				throw new Error("Login failed.");
			}
			await reloadAuth();
			navigate(search.get("next") ?? "/", {replace: true});
		}
		else {
			const {loginFlow, redirectUrl} = await getLogin(email, location.origin);
			if (loginFlow == "Federated" && redirectUrl) {
				window.location.href = redirectUrl;
			}
			else {
				setLocal(true);
				if (passwordRef) {
					passwordRef.focus();
				}
			}
		}
	});

	return (
		<View>
			<Typography variant="h1">
				{t("auth.title")}
			</Typography>
			<Typography variant="body1">
				{t("auth.message")}
			</Typography>
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<Card>
							<CardContent>
								<form onSubmit={submit.execute}>
									<Stack spacing={2}>
										<TextField
											name="username"
											type="email"
											label={t("auth.email")}
											value={email}
											onChange={setEmail}
											required
											fullWidth
											autoFocus
										/>
										<Collapse in={local}>
											<Stack spacing={2}>
												<TextField
													name="password"
													type="password"
													label={t("auth.password")}
													value={password}
													onChange={setPassword}
													required={local}
													fullWidth
													inputProps={{ref: setPasswordRef}}
												/>
												<Link component={RouterLink} to="/password/lost">
													{t("lost-password")}
												</Link>
											</Stack>
										</Collapse>
										<Button
											color="primary"
											variant="contained"
											type="submit"
											size="large"
											fullWidth
											disabled={submit.loading}
										>
											{t(local ? "auth.login" : "actions.next")}
										</Button>
									</Stack>
								</form>
							</CardContent>
							<Collapse in={Boolean(submit.error)}>
								<Alert severity="error">
									<AlertTitle>{t("auth.loginFailed.title")}</AlertTitle>
									{t("auth.loginFailed.message")}
								</Alert>
							</Collapse>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6} md={8}>
						<Infoboxes displayType="LoginForm" columns={2} />
					</Grid>
				</Grid>
			</Box>
		</View>
	);
}
