import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockIcon from "@mui/icons-material/Lock";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {Alert, AlertTitle, Box, Button, Collapse, Grid, LinearProgress, Paper, Stack} from "@mui/material";
import {ErrorAlert, Select, TextField, useFlag, View, ViewHeader} from "@variocube/app-ui";
import React, {type FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {COMPANY_FORMS, CompanyForm, useSignupApi} from "../../api";
import {Typography} from "../../component/typography";
import {useLocalization} from "../../i18n";
import {useParam} from "../../utils/useParam";
import {usePasswordStrength} from "../../utils/usePasswordStrength";

export function CreditorSignup() {
	const {t, s} = useLocalization();
	const {signup} = useSignupApi();

	const [legalForm, setLegalForm] = useState<CompanyForm>("SinglePerson");
	const [company, setCompany] = useState("");
	const [vat, setVat] = useState("");
	const [companyNr, setCompanyNr] = useState("");

	const [street, setStreet] = useState("");
	const [zip, setZip] = useState("");
	const [city, setCity] = useState("");
	const [country, setCountry] = useState("");

	const [title, setTitle] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phone, setPhone] = useState("");

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [success, setSuccess] = useFlag(false);

	const email = useParam("email");

	const {level, percentage} = usePasswordStrength(password);

	function validatePassword() {
		if (percentage < 60) {
			return t("signup.weakPassword");
		}
	}

	function validatePasswordConfirm(value: string) {
		if (value != password) {
			return t("signup.passwordMismatch");
		}
	}

	const {loading, error, execute} = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();

		await signup({
			legalForm,
			company,
			vat,
			companyNr,
			street,
			zip,
			city,
			country,
			title,
			firstName,
			lastName,
			email,
			phone,
			password,
		});
		setSuccess();
	});

	return (
		<View maxWidth="sm">
			<ViewHeader
				title={t("signup.title")}
				subTitle={!success && t("signup.subTitle")}
			/>
			<Collapse in={!success}>
				<form onSubmit={execute}>
					<Stack spacing={2}>
						<Paper sx={{width: "100%"}} variant="outlined">
							<Typography
								sx={{
									py: 1,
									px: 2,
									width: "100%",
									backgroundColor: "#eaeaea",
									borderBottom: "1px solid #ddd",
								}}
								startAdornment={<HomeIcon />}
							>
								{t("signup.companyName")}
							</Typography>

							<Box p={2}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Select
											label={t("signup.legalForm")}
											value={legalForm}
											onChange={setLegalForm}
											options={COMPANY_FORMS}
											renderLabel={s("signup.legalForms")}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											required
											label={t("signup.companyName")}
											value={company}
											onChange={setCompany}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											label={t("signup.vatId")}
											value={vat}
											onChange={setVat}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											fullWidth
											label={t("signup.commercialRegisterNumber")}
											value={companyNr}
											onChange={setCompanyNr}
										/>
									</Grid>
								</Grid>
							</Box>
						</Paper>

						<Paper sx={{width: "100%"}} variant="outlined">
							<Typography
								sx={{
									py: 1,
									px: 2,
									width: "100%",
									backgroundColor: "#eaeaea",
									borderBottom: "1px solid #ddd",
								}}
								startAdornment={<LocationOnIcon />}
							>
								{t("signup.companyAddress")}
							</Typography>

							<Box p={2}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField
											fullWidth
											label={t("signup.street")}
											value={street}
											onChange={setStreet}
										/>
									</Grid>
									<Grid item xs={12} sm={4}>
										<TextField
											fullWidth
											label={t("signup.zip")}
											value={zip}
											onChange={setZip}
										/>
									</Grid>
									<Grid item xs={12} sm={8}>
										<TextField
											fullWidth
											label={t("signup.city")}
											value={city}
											onChange={setCity}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											label={t("signup.country")}
											value={country}
											onChange={setCountry}
										/>
									</Grid>
								</Grid>
							</Box>
						</Paper>

						<Paper sx={{width: "100%"}} variant="outlined">
							<Typography
								sx={{
									py: 1,
									px: 2,
									width: "100%",
									backgroundColor: "#eaeaea",
									borderBottom: "1px solid #ddd",
								}}
								startAdornment={<PhoneAndroidIcon />}
							>
								{t("signup.contactInfo")}
							</Typography>

							<Box p={2}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={2}>
										<TextField
											fullWidth
											label={t("common.title")}
											value={title}
											onChange={setTitle}
										/>
									</Grid>
									<Grid item xs={12} sm={5}>
										<TextField
											fullWidth
											required
											label={t("common.firstName")}
											value={firstName}
											onChange={setFirstName}
										/>
									</Grid>
									<Grid item xs={12} sm={5}>
										<TextField
											fullWidth
											required
											label={t("common.lastName")}
											value={lastName}
											onChange={setLastName}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											required
											label={t("common.email")}
											value={email}
											fullWidth
											disabled
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											label={t("signup.phone")}
											value={phone}
											onChange={setPhone}
											fullWidth
										/>
									</Grid>
								</Grid>
							</Box>
						</Paper>

						<Paper sx={{width: "100%"}} variant="outlined">
							<Typography
								sx={{
									py: 1,
									px: 2,
									width: "100%",
									backgroundColor: "#eaeaea",
									borderBottom: "1px solid #ddd",
								}}
								startAdornment={<LockIcon />}
							>
								{t("signup.password")}
							</Typography>

							<Box p={2}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="body2" gutterBottom>
											{t("signup.passwordHint")}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											required
											fullWidth
											label={t("signup.password")}
											type="password"
											value={password}
											onChange={setPassword}
											validate={validatePassword}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<TextField
											required
											fullWidth
											label={t("signup.confirmPassword")}
											type="password"
											value={confirmPassword}
											onChange={setConfirmPassword}
											validate={validatePasswordConfirm}
										/>
									</Grid>
									<Grid item xs={12}>
										<LinearProgress
											variant="determinate"
											value={percentage}
											color={percentage < 60
												? "error"
												: (percentage > 79 ? "success" : "warning")}
										/>
										{level !== undefined && (
											<Typography
												sx={{
													color: percentage < 60
														? "red"
														: (percentage > 79 ? "green" : "orange"),
												}}
											>
												<strong>{level}</strong>
											</Typography>
										)}
									</Grid>
								</Grid>
							</Box>
						</Paper>

						{error && <ErrorAlert error={error} />}

						<Button variant="contained" color="primary" type="submit" disabled={loading}>
							{t("actions.next")}
						</Button>
					</Stack>
				</form>
			</Collapse>

			<Collapse in={success}>
				<Stack spacing={2}>
					<Alert severity="success">
						<AlertTitle>{t("signup.success.title")}</AlertTitle>
						<Typography paragraph>{t("signup.success.message")}</Typography>
					</Alert>
					<Alert severity="info">
						<AlertTitle>{t("signup.confirmEmail.title")}</AlertTitle>
						<Typography paragraph>{t("signup.confirmEmail.message", {email})}</Typography>
						<Typography paragraph>{t("signup.confirmEmail.spamHint")}</Typography>
					</Alert>
					<Box>
						<Typography variant="h3" align="center">{t("signup.greeting.title")}</Typography>
						<Typography align="center">{t("signup.greeting.signature")}</Typography>
					</Box>
				</Stack>
			</Collapse>
		</View>
	);
}
