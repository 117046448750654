import {DeleteOutline} from "@mui/icons-material";
import {
	Button,
	Card,
	CardActions,
	CardHeader,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import {Checkbox, ConfirmIconButton, useFlag} from "@variocube/app-ui";
import React, {FormEvent, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {Inbox, InboxQuery, useInboxesApi} from "../../api";
import {useLocalization} from "../../i18n";

interface InboxTableProps {
	title: string;
	inboxes: Inbox[];
	onAdd: (ids: number[]) => Promise<void>;
	onRemove: (id: number) => Promise<void>;
	assignLabel: string;
	filter?: InboxQuery["filter"];
}

export function InboxTable({title, inboxes, filter, onAdd, onRemove, assignLabel}: InboxTableProps) {
	const {t} = useLocalization();

	const [selected, setSelected] = useState<number[]>([]);
	const [editMode, setEditMode, clearEditMode] = useFlag(false);

	const {queryInboxes} = useInboxesApi();
	const query = useMemo(() => ({filter}), [filter]);
	const {result: options} = useAsync(queryInboxes, [query]);

	function handleCheckbox(id: number) {
		if (!selected.includes(id)) {
			setSelected([...selected, id]);
		}
		else {
			setSelected(selected.filter(i => i !== id));
		}
	}

	async function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		await onAdd(selected);
		clearEditMode();
		setSelected([]);
	}

	return (
		<>
			<Card>
				<CardHeader title={title} />
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Mailbox</TableCell>
							<TableCell>BCA</TableCell>
							<TableCell width={10}>&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{inboxes.map(i => (
							<TableRow key={i.id}>
								<TableCell>{i.name}</TableCell>
								<TableCell>{i.businessCenterAddress}</TableCell>
								<TableCell>
									<ConfirmIconButton
										title={t("users.mailboxes.revoke.title")}
										cancel={t("actions.cancel")}
										onConfirm={() => onRemove(i.id)}
										icon={<DeleteOutline />}
										color="error"
									>
										{t("users.mailboxes.revoke.prompt")}
									</ConfirmIconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<CardActions>
					<Button color="secondary" onClick={setEditMode}>{assignLabel}</Button>
				</CardActions>
			</Card>
			<Dialog open={editMode} onClose={clearEditMode} fullWidth maxWidth="sm">
				<form onSubmit={handleSubmit}>
					<DialogTitle>{assignLabel}</DialogTitle>
					<Divider />
					<List sx={{maxHeight: 440, overflowY: "scroll"}}>
						{options?.map(o => (
							<ListItemButton
								key={o.id}
								onClick={() => handleCheckbox(o.id)}
								selected={selected.includes(o.id)}
							>
								<ListItemIcon>
									<Checkbox
										checked={selected.includes(o.id)}
										tabIndex={-1}
										disableRipple
									/>
								</ListItemIcon>
								<ListItemText primary={o.name} />
							</ListItemButton>
						))}
					</List>
					<Divider />
					<DialogActions>
						<Button color="inherit" onClick={clearEditMode}>{t("actions.cancel")}</Button>
						<Button type="submit">{assignLabel}</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
}
