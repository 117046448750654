import {Alert, AlertTitle, Button, Card, CardContent, CardHeader, Collapse, Stack} from "@mui/material";
import {ErrorAlert, View} from "@variocube/app-ui";
import React, {FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Link, useSearchParams} from "react-router-dom";
import {usePasswordResetApi} from "../api";
import {PasswordInput} from "../component/password-input";
import {useLocalization} from "../i18n";
import {useParam} from "../utils/useParam";

export function ResetPassword() {
	const {t} = useLocalization();
	const [password, setPassword] = useState("");
	const [strength, setStrength] = useState(0);
	const {resetPassword} = usePasswordResetApi();

	const email = useParam("email");
	const [search] = useSearchParams();
	const token = search.get("token");

	if (!token) {
		return <ErrorAlert error="Invalid URL" />;
	}

	const submit = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();

		await resetPassword(email, {
			token,
			password,
		});
		return true;
	});

	return (
		<View maxWidth="xs">
			<Collapse in={!submit.result}>
				<Card component="form" onSubmit={submit.execute}>
					<CardHeader title={t("reset-password.title")} />
					<CardContent>
						{t("reset-password.resetMessage")}
					</CardContent>
					<CardContent>
						<Stack spacing={2}>
							<PasswordInput value={password} onChange={setPassword} onStrengthChange={setStrength} />
							<Button
								type="submit"
								variant="contained"
								disabled={strength < 60 || submit.loading || submit.result}
							>
								{t("reset-password.title")}
							</Button>
						</Stack>
					</CardContent>

					<Collapse in={Boolean(submit.error)}>
						<ErrorAlert error={submit.error} />
					</Collapse>
				</Card>
			</Collapse>
			<Collapse in={Boolean(submit.result)}>
				<Stack spacing={2}>
					<Alert severity="success">
						<AlertTitle>{t("reset-password.resetSuccess.title")}</AlertTitle>
						{t("reset-password.resetSuccess.message")}
					</Alert>
					<Button
						variant="contained"
						component={Link}
						to="/auth"
					>
						{t("signup.backToLogin")}
					</Button>
				</Stack>
			</Collapse>
		</View>
	);
}
