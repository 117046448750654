import React from "react";
import {isCreditor, isOebb, useUser} from "../../context/auth";
import {CreditorUserSettings} from "./creditor-user-settings";
import {OebbUserSettings} from "./oebb-user-settings";

export function UserSettings() {
	const user = useUser();

	if (isOebb(user)) {
		return <OebbUserSettings />;
	}
	else if (isCreditor(user)) {
		return <CreditorUserSettings />;
	}
	return null;
}
