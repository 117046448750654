import {Snackbar} from "@mui/material";
import {ErrorAlert} from "@variocube/app-ui";
import React, {createContext, type PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {
	Info,
	InfoDisplayType,
	InvoiceCount,
	Language,
	UiSettings,
	useIndexesApi,
	useInfoApi,
	User,
	UserRole,
	useUiSettingsApi,
} from "../api";
import {FullScreenLoading} from "../component/FullScreenLoading";
import {useLocalization} from "../i18n";

interface IAuthContext {
	user?: User;
	invoiceCount?: InvoiceCount;
	settings?: UiSettings;
	infos?: Info[];
	reloadAuth: () => Promise<any>;
	reloadInvoiceCount: () => Promise<any>;
}

const AuthContext = createContext<IAuthContext>({
	user: undefined,
	invoiceCount: undefined,
	settings: undefined,
	infos: undefined,
	reloadAuth: () => Promise.resolve(),
	reloadInvoiceCount: () => Promise.resolve(),
});

export function AuthProvider({children}: Readonly<PropsWithChildren<unknown>>) {
	const {language} = useLocalization();

	const {getCurrentUser, getInvoiceCount} = useIndexesApi();
	const {getUiSettings} = useUiSettingsApi();
	const {queryInfos} = useInfoApi();

	const [wasLoaded, setWasLoaded] = useState(false);
	const [invoiceCount, setInvoiceCount] = useState<InvoiceCount>();
	const [user, setUser] = useState<User>();

	const {
		loading,
		execute: reloadAuth,
	} = useAsync(async () => {
		try {
			const user = await getCurrentUser();
			setUser(user);
		}
		catch (error) {
			console.error("Error fetching current user", error);
			return undefined;
		}
	}, []);

	const {
		loading: uiSettingsLoading,
		error: uiSettingsError,
		result: settings,
	} = useAsync(getUiSettings, []);

	const {
		loading: invoiceCountLoading,
		error: invoiceCountError,
		result: newInvoiceCount,
		execute: reloadInvoiceCount,
	} = useAsync(getInvoiceCount, []);

	useEffect(() => {
		if (newInvoiceCount) {
			setInvoiceCount(newInvoiceCount);
		}
	}, [newInvoiceCount]);

	const query = useMemo(() => ({
		language: language as Language,
		current: true,
	} as const), [language]);

	const {error: infosError, result: infos} = useAsync(queryInfos, [query]);

	useEffect(() => {
		if (!loading && !invoiceCountLoading && !uiSettingsLoading) {
			setWasLoaded(true);
		}
	}, [loading, invoiceCountLoading]);

	const value = useMemo(() => ({
		user,
		invoiceCount,
		settings,
		infos,
		reloadAuth: () => Promise.all([reloadAuth(), reloadInvoiceCount()]),
		reloadInvoiceCount,
	}), [user, invoiceCount, settings, infos, reloadAuth, reloadInvoiceCount]);

	const showLoading = loading && !wasLoaded;

	return (
		<AuthContext.Provider
			value={value}
		>
			{showLoading && <FullScreenLoading />}
			{!showLoading && children}
			{invoiceCountError && (
				<Snackbar>
					<ErrorAlert error={invoiceCountError} />
				</Snackbar>
			)}
			{infosError && (
				<Snackbar>
					<ErrorAlert error={infosError} />
				</Snackbar>
			)}
			{uiSettingsError && (
				<Snackbar>
					<ErrorAlert error={uiSettingsError} />
				</Snackbar>
			)}
		</AuthContext.Provider>
	);
}

export function useAuthContext() {
	return useContext(AuthContext);
}

export function useUser() {
	const {user} = useAuthContext();
	if (!user) {
		throw new Error(
			"User not found in AuthContext. The route element should be wrapped in an <Authenticated> component.",
		);
	}
	return user;
}

export function useSetting<K extends keyof UiSettings>(key: K): UiSettings[K] {
	const {settings} = useAuthContext();
	return settings && settings[key];
}

export function useInvoiceCount() {
	const {invoiceCount} = useAuthContext();
	return invoiceCount;
}

export function useReloadInvoiceCount() {
	const {reloadInvoiceCount} = useAuthContext();
	return reloadInvoiceCount;
}

function filterInfo(info: Info, displayType: "LoginForm" | "Dashboard", user?: User) {
	return info.displayType == displayType
		&& (!info.audience || info.audience == "All" || (isOebb(user) && info.audience == "Oebb")
			|| (isCreditor(user) && info.audience == "Creditor"));
}

export function useInfos(displayType: InfoDisplayType) {
	const {infos, user} = useAuthContext();
	if (infos) {
		return infos.filter(info => filterInfo(info, displayType, user));
	}
	return [];
}

export function hasRoles(user: User | undefined, roles: UserRole[]) {
	if (!user) {
		return false;
	}
	for (const role of roles) {
		if (user.role == role) return true;
	}
	return false;
}

export function isOebb(user?: User) {
	return hasRoles(user, ["SuperUser", "OebbAdministrator", "OebbUser", "OebbApiUser"]);
}

export function isCreditor(user?: User) {
	return hasRoles(user, ["CreditorAdministrator", "CreditorUser"]);
}
