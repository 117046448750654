import {CheckCircle} from "@mui/icons-material";
import {Button, Card, CardActions, CardContent, CircularProgress, Stack} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View} from "@variocube/app-ui";
import React, {useEffect, useMemo, useState} from "react";
import {Link as RouterLink, useSearchParams} from "react-router-dom";
import {useInvoicesApi} from "../../api";
import {Typography} from "../../component/typography";
import {useLocalization} from "../../i18n";
import {notBlank} from "../../util";
import {useInvoiceFilter} from "./filter";

export function InvoiceExport() {
	const [parameters] = useSearchParams();
	const {t} = useLocalization();
	const {exportInvoices} = useInvoicesApi();

	const [filter] = useInvoiceFilter();

	const total = useMemo(() => parameters.get("total") ?? 0, [parameters]);

	const [objectUrl, setObjectUrl] = useState<string>();

	useEffect(
		() => {
			exportInvoices({
				...filter,
				tags: undefined, // tags property is not compatible; fix later when switching export to client side
			})
				.then(blob => {
					if (blob) {
						setObjectUrl(URL.createObjectURL(blob));
					}
				})
				.catch(console.error);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbLink component={RouterLink} to="/invoices">{t("invoices.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{t("invoices.excelExport.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography variant="h1">{t("invoices.excelExport.title")}</Typography>
			</Stack>
			<Card>
				<CardContent>
					<Stack
						direction="column"
						gap={2}
					>
						<Typography
							startAdornment={notBlank(objectUrl)
								? <CheckCircle color="success" />
								: <CircularProgress size={20} />}
							endAdornment={notBlank(objectUrl)
								? (
									<Button
										disableElevation
										size="small"
										variant="contained"
										color="primary"
										component="a"
										download={`invoices-${getDateTimeString()}`}
										href={objectUrl}
									>
										Download
									</Button>
								)
								: undefined}
						>
							{t("invoices.excelExport.message", {total})}
						</Typography>
						<Typography>
							{t("invoices.excelExport.hint", {email: "email"})}
						</Typography>
					</Stack>
				</CardContent>
				<CardActions>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						component={RouterLink}
						to="/invoices"
					>
						Back
					</Button>
				</CardActions>
			</Card>
		</View>
	);
}

function getDateTimeString() {
	const date = new Date();
	return `${date.getFullYear()}-${
		date.getMonth() + 1
	}-${date.getDate()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
}
