import {Autocomplete} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import React from "react";
import {useLocalization} from "../i18n";

interface TagSelectProps {
	value: string[];
	onChange: (value: string[]) => any;
	fullWidth?: boolean;
}

const TAGS = [
	"API",
	"CreditorInvoice",
	"EDIFACT",
	"EntschaedigungszahlungenMitKontierungsfile",
	"EXCEL",
	"ExpensesInvoice",
	"PersonenverkehrMitKontierungsfile",
	"VOXEL",
];

export function TagSelect({value, onChange, fullWidth}: Readonly<TagSelectProps>) {
	const {t} = useLocalization();
	return (
		<Autocomplete
			multiple
			renderInput={props => <TextField label={t("common.tags")} {...props} />}
			options={TAGS}
			value={value}
			onChange={(_, value) => onChange(value)}
			fullWidth={fullWidth}
		/>
	);
}
