import { z } from "zod";

export type UserRole = z.infer<typeof UserRole>;
export const UserRole = z.union([
  z.literal("SuperUser"),
  z.literal("OebbAdministrator"),
  z.literal("OebbUser"),
  z.literal("CreditorAdministrator"),
  z.literal("CreditorUser"),
  z.literal("OebbApiUser"),
]);

export type UserMutation = z.infer<typeof UserMutation>;
export const UserMutation = z.object({
  lastname: z.union([z.string(), z.undefined()]).optional(),
  firstname: z.union([z.string(), z.undefined()]).optional(),
  email: z.string(),
  phone: z.union([z.string(), z.undefined()]).optional(),
  password: z.union([z.string(), z.undefined()]).optional(),
  role: UserRole,
  canManageAuditors: z.boolean(),
});

export type CreditorType = z.infer<typeof CreditorType>;
export const CreditorType = z.union([z.literal("Creditor"), z.literal("ServiceCreditor")]);

export type CreditorRef = z.infer<typeof CreditorRef>;
export const CreditorRef = z.object({
  id: z.number().optional(),
  companyName: z.string().optional(),
  creditorType: CreditorType.optional(),
  sapCreditorNumber: z.string().optional(),
  enabled: z.boolean().optional(),
});

export type ContactPerson = z.infer<typeof ContactPerson>;
export const ContactPerson = z.object({
  id: z.number(),
  firstname: z.union([z.string(), z.undefined()]).optional(),
  lastname: z.union([z.string(), z.undefined()]).optional(),
  email: z.union([z.string(), z.undefined()]).optional(),
  phone: z.union([z.string(), z.undefined()]).optional(),
  emailConfirmed: z.union([z.boolean(), z.undefined()]).optional(),
  creditor: z.union([CreditorRef, z.undefined()]).optional(),
});

export type InboxRef = z.infer<typeof InboxRef>;
export const InboxRef = z.object({
  id: z.number(),
  name: z.string(),
  businessCenterAddress: z.union([z.string(), z.undefined()]).optional(),
  availableForExpenses: z.boolean(),
  availableForWorksAt: z.boolean(),
  hidden: z.boolean(),
  hiddenForCreditors: z.boolean(),
});

export type RepresentedUser = z.infer<typeof RepresentedUser>;
export const RepresentedUser = z.object({
  id: z.number(),
  firstname: z.union([z.string(), z.undefined()]).optional(),
  lastname: z.union([z.string(), z.undefined()]).optional(),
  email: z.union([z.string(), z.undefined()]).optional(),
  phone: z.union([z.string(), z.undefined()]).optional(),
  creditCards: z.array(z.string()),
  displayName: z.union([z.string(), z.undefined()]).optional(),
});

export type UserVacation = z.infer<typeof UserVacation>;
export const UserVacation = z.object({
  from: z.string().optional(),
  until: z.string().optional(),
});

export type User = z.infer<typeof User>;
export const User = z.object({
  id: z.number(),
  firstname: z.union([z.string(), z.undefined()]).optional(),
  lastname: z.union([z.string(), z.undefined()]).optional(),
  contactPerson: z.union([ContactPerson, z.undefined()]).optional(),
  email: z.union([z.string(), z.undefined()]).optional(),
  phone: z.union([z.string(), z.undefined()]).optional(),
  localUser: z.boolean(),
  role: UserRole,
  failedLogins: z.union([z.number(), z.undefined()]).optional(),
  locked: z.boolean(),
  canManageAuditors: z.boolean(),
  lastKnownLocale: z.union([z.string(), z.undefined()]).optional(),
  vacation: z.union([UserVacation, z.undefined()]).optional(),
  represents: z.array(RepresentedUser),
  creditCards: z.array(z.string()),
  inboxes: z.array(InboxRef),
  worksAt: z.array(InboxRef),
  auditsInboxes: z.array(InboxRef),
  displayName: z.union([z.string(), z.undefined()]).optional(),
});

export type DocumentType = z.infer<typeof DocumentType>;
export const DocumentType = z.object({
  id: z.number(),
  displayName: z.string(),
  fileExtension: z.string(),
  mimeType: z.string(),
});

export type ScanService = z.infer<typeof ScanService>;
export const ScanService = z.union([z.literal("ScanPoint"), z.literal("Exela"), z.literal("SkipOCR")]);

export type Inbox = z.infer<typeof Inbox>;
export const Inbox = z.object({
  id: z.number(),
  name: z.string(),
  contact: z.union([z.string(), z.undefined()]).optional(),
  contactEmail: z.union([z.string(), z.undefined()]).optional(),
  contactPhone: z.union([z.string(), z.undefined()]).optional(),
  scanService: z.union([ScanService, z.undefined()]).optional(),
  requireManualApproval: z.boolean(),
  allowAttachments: z.boolean(),
  allowAddingAttachments: z.boolean(),
  hidden: z.boolean(),
  hiddenForCreditors: z.boolean(),
  onlyAvailableForManagers: z.boolean(),
  availableForExpenses: z.boolean(),
  availableForWorksAt: z.boolean(),
  businessCenterAddress: z.union([z.string(), z.undefined()]).optional(),
  companyCode: z.union([z.string(), z.undefined()]).optional(),
  approvalIncludesInvoiceCheck: z.boolean(),
  requiresAudit: z.boolean(),
  inboxLabels: z.array(z.string()),
  assignedInvoiceDocumentTypes: z.array(DocumentType),
  assignedAttachmentDocumentTypes: z.array(DocumentType),
  technicalName: z.union([z.string(), z.undefined()]).optional(),
  autoRoutable: z.union([z.boolean(), z.undefined()]).optional(),
});

export type UserProxyMutation = z.infer<typeof UserProxyMutation>;
export const UserProxyMutation = z.object({
  proxyUser: z.string().optional(),
  proxiedByUser: z.string().optional(),
});

export type UserVacationMutation = z.infer<typeof UserVacationMutation>;
export const UserVacationMutation = z.object({
  from: z.string(),
  until: z.string(),
});

export type ContactPersonMutation = z.infer<typeof ContactPersonMutation>;
export const ContactPersonMutation = z.object({
  firstname: z.string().optional(),
  lastname: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional(),
});

export type PasswordReset = z.infer<typeof PasswordReset>;
export const PasswordReset = z.object({
  token: z.string().optional(),
  password: z.string().optional(),
});

export type InvoiceForwarding = z.infer<typeof InvoiceForwarding>;
export const InvoiceForwarding = z.object({
  inboxId: z.number(),
  additionalInformation: z.union([z.string(), z.undefined()]).optional(),
});

export type Audience = z.infer<typeof Audience>;
export const Audience = z.union([z.literal("All"), z.literal("Oebb"), z.literal("Creditor")]);

export type InfoDisplayType = z.infer<typeof InfoDisplayType>;
export const InfoDisplayType = z.union([z.literal("LoginForm"), z.literal("Dashboard")]);

export type Language = z.infer<typeof Language>;
export const Language = z.union([z.literal("en"), z.literal("de"), z.literal("hu")]);

export type InfoMutation = z.infer<typeof InfoMutation>;
export const InfoMutation = z.object({
  displayType: InfoDisplayType,
  audience: z.union([Audience, z.undefined()]).optional(),
  start: z.string(),
  end: z.union([z.string(), z.undefined()]).optional(),
  language: z.union([Language, z.undefined()]).optional(),
  subject: z.string(),
  body: z.union([z.string(), z.undefined()]).optional(),
});

export type Info = z.infer<typeof Info>;
export const Info = z.object({
  id: z.number().optional(),
  displayType: InfoDisplayType.optional(),
  audience: Audience.optional(),
  start: z.string().optional(),
  end: z.string().optional(),
  language: Language.optional(),
  subject: z.string().optional(),
  body: z.string().optional(),
});

export type InboxMutation = z.infer<typeof InboxMutation>;
export const InboxMutation = z.object({
  name: z.string(),
  businessCenterAddress: z.union([z.string(), z.undefined()]).optional(),
  companyCode: z.union([z.string(), z.undefined()]).optional(),
  scanService: ScanService,
  inboxLabels: z.array(z.string()),
  allowAttachments: z.boolean(),
  allowAddingAttachments: z.boolean(),
  requireManualApproval: z.boolean(),
  approvalIncludesInvoiceCheck: z.boolean(),
  requiresAudit: z.boolean(),
  availableForWorksAt: z.boolean(),
  availableForExpenses: z.boolean(),
  contact: z.union([z.string(), z.undefined()]).optional(),
  contactEmail: z.union([z.string(), z.undefined()]).optional(),
  contactPhone: z.union([z.string(), z.undefined()]).optional(),
  hiddenForCreditors: z.boolean(),
  hidden: z.boolean(),
  onlyAvailableForManagers: z.boolean(),
});

export type OutboxMutation = z.infer<typeof OutboxMutation>;
export const OutboxMutation = z.object({
  name: z.string(),
  flag: z.union([z.string(), z.undefined()]).optional(),
  bca: z.union([z.string(), z.undefined()]).optional(),
  attachmentsRequireOcr: z.boolean(),
});

export type Outbox = z.infer<typeof Outbox>;
export const Outbox = z.object({
  id: z.number(),
  name: z.string(),
  bca: z.union([z.string(), z.undefined()]).optional(),
  flag: z.union([z.string(), z.undefined()]).optional(),
  attachmentsRequireOcr: z.boolean(),
});

export type HelpTextMutation = z.infer<typeof HelpTextMutation>;
export const HelpTextMutation = z.object({
  helpKey: z.string(),
  language: Language,
  subject: z.string(),
  body: z.string(),
});

export type HelpText = z.infer<typeof HelpText>;
export const HelpText = z.object({
  id: z.number(),
  helpKey: z.string(),
  language: Language,
  subject: z.union([z.string(), z.undefined()]).optional(),
  body: z.union([z.string(), z.undefined()]).optional(),
});

export type DocumentTypeMutation = z.infer<typeof DocumentTypeMutation>;
export const DocumentTypeMutation = z.object({
  name: z.string(),
  fileExtension: z.string(),
  mimeType: z.string(),
});

export type CreditorMutation = z.infer<typeof CreditorMutation>;
export const CreditorMutation = z.object({
  companyName: z.string(),
  street: z.union([z.string(), z.undefined()]).optional(),
  houseNo: z.union([z.string(), z.undefined()]).optional(),
  zipCode: z.union([z.string(), z.undefined()]).optional(),
  city: z.union([z.string(), z.undefined()]).optional(),
  country: z.union([z.string(), z.undefined()]).optional(),
  checkManually: z.boolean(),
  enabled: z.boolean(),
  sapCreditorNumber: z.union([z.string(), z.undefined()]).optional(),
  voxelSupportsReverseCharge: z.boolean(),
});

export type Address = z.infer<typeof Address>;
export const Address = z.object({
  street: z.string().optional(),
  houseNo: z.string().optional(),
  zip: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),
});

export type CompanyForm = z.infer<typeof CompanyForm>;
export const CompanyForm = z.union([
  z.literal("SinglePerson"),
  z.literal("OpenOrganisation"),
  z.literal("LimitedPartnership"),
  z.literal("ConstitutedCivil"),
  z.literal("LimitedLiability"),
  z.literal("IncorporatedCompany"),
  z.literal("Cooperative"),
  z.literal("PublicCorporation"),
]);

export type Company = z.infer<typeof Company>;
export const Company = z.object({
  companyName: z.string().optional(),
  form: CompanyForm.optional(),
  commercialRegister: z.string().optional(),
  vatIdentification: z.string().optional(),
});

export type Email = z.infer<typeof Email>;
export const Email = z.object({
  email: z.string().optional(),
});

export type Phone = z.infer<typeof Phone>;
export const Phone = z.object({
  countryCode: z.string().optional(),
  prefix: z.string().optional(),
  number: z.string().optional(),
  extension: z.string().optional(),
  added: z.string().optional(),
});

export type Website = z.infer<typeof Website>;
export const Website = z.object({
  url: z.string().optional(),
  added: z.string().optional(),
});

export type CreditorInbox = z.infer<typeof CreditorInbox>;
export const CreditorInbox = z.object({
  inbox: Inbox,
  debitorNumber: z.union([z.string(), z.undefined()]).optional(),
});

export type Creditor = z.infer<typeof Creditor>;
export const Creditor = z.object({
  id: z.number(),
  checkManually: z.boolean(),
  enabled: z.boolean(),
  creditorType: z.union([CreditorType, z.undefined()]).optional(),
  company: Company,
  address: z.union([Address, z.undefined()]).optional(),
  email: z.union([Email, z.undefined()]).optional(),
  phone: z.union([Phone, z.undefined()]).optional(),
  website: z.union([Website, z.undefined()]).optional(),
  sapCreditorNumber: z.union([z.string(), z.undefined()]).optional(),
  voxelSupportsReverseCharge: z.boolean(),
  inboxes: z.union([z.array(CreditorInbox), z.undefined()]).optional(),
  autoRoutable: z.union([z.boolean(), z.undefined()]).optional(),
});

export type CreditorInboxMutation = z.infer<typeof CreditorInboxMutation>;
export const CreditorInboxMutation = z.object({
  inboxId: z.number(),
  debitorNumber: z.union([z.string(), z.undefined()]).optional(),
});

export type CreditorRequest = z.infer<typeof CreditorRequest>;
export const CreditorRequest = z.object({
  companyName: z.string(),
  legalForm: CompanyForm,
  vatIdNumber: z.union([z.string(), z.undefined()]).optional(),
  commercialRegisterNumber: z.union([z.string(), z.undefined()]).optional(),
  street: z.union([z.string(), z.undefined()]).optional(),
  houseNo: z.union([z.string(), z.undefined()]).optional(),
  zip: z.union([z.string(), z.undefined()]).optional(),
  city: z.union([z.string(), z.undefined()]).optional(),
  country: z.union([z.string(), z.undefined()]).optional(),
});

export type IdentifyEmailRequest = z.infer<typeof IdentifyEmailRequest>;
export const IdentifyEmailRequest = z.object({
  email: z.string(),
});

export type SignupDomain = z.infer<typeof SignupDomain>;
export const SignupDomain = z.union([z.literal("Oebb"), z.literal("Creditor")]);

export type SignupFlow = z.infer<typeof SignupFlow>;
export const SignupFlow = z.object({
  federatedUrl: z.string().optional(),
  domain: SignupDomain.optional(),
});

export type Signup = z.infer<typeof Signup>;
export const Signup = z.object({
  legalForm: CompanyForm,
  company: z.string(),
  vat: z.union([z.string(), z.undefined()]).optional(),
  companyNr: z.union([z.string(), z.undefined()]).optional(),
  street: z.union([z.string(), z.undefined()]).optional(),
  zip: z.union([z.string(), z.undefined()]).optional(),
  city: z.union([z.string(), z.undefined()]).optional(),
  country: z.union([z.string(), z.undefined()]).optional(),
  title: z.union([z.string(), z.undefined()]).optional(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.union([z.string(), z.undefined()]).optional(),
  password: z.string(),
});

export type InvoiceRouting = z.infer<typeof InvoiceRouting>;
export const InvoiceRouting = z.object({
  outboxId: z.number(),
  auditor: z.union([z.string(), z.undefined()]).optional(),
  orderNumbers: z.union([z.array(z.string()), z.undefined()]).optional(),
  additionalInformation: z.union([z.string(), z.undefined()]).optional(),
});

export type InvoiceRejection = z.infer<typeof InvoiceRejection>;
export const InvoiceRejection = z.object({
  rejectReason: z.string().optional(),
});

export type InvoiceApproval = z.infer<typeof InvoiceApproval>;
export const InvoiceApproval = z.object({
  carType: z.string().optional(),
  carIfnr: z.string().optional(),
});

export type AttachmentSubmission = z.infer<typeof AttachmentSubmission>;
export const AttachmentSubmission = z.object({
  filename: z.string(),
  checksum: z.string(),
  contentType: z.string(),
});

export type InvoiceAdministrationData = z.infer<typeof InvoiceAdministrationData>;
export const InvoiceAdministrationData = z.object({
  action: z.string(),
  justification: z.string(),
});

export type BusinessCaseSubmission = z.infer<typeof BusinessCaseSubmission>;
export const BusinessCaseSubmission = z.object({
  id: z.number(),
  description: z.union([z.string(), z.undefined()]).optional(),
  amount: z.number(),
  tip: z.union([z.number(), z.undefined()]).optional(),
});

export type CarSubmission = z.infer<typeof CarSubmission>;
export const CarSubmission = z.object({
  inputTaxDeductible: z.boolean(),
  licensePlate: z.string(),
  carType: z.union([z.string(), z.undefined()]).optional(),
  mileageKm: z.number(),
  refuelLiter: z.union([z.number(), z.undefined()]).optional(),
});

export type ParticipantSubmission = z.infer<typeof ParticipantSubmission>;
export const ParticipantSubmission = z.object({
  name: z.string(),
  company: z.string(),
});

export type ExpenseInvoiceSubmission = z.infer<typeof ExpenseInvoiceSubmission>;
export const ExpenseInvoiceSubmission = z.object({
  checksum: z.string(),
  contentType: z.string(),
  filename: z.string(),
  creditCardNumber: z.union([z.string(), z.undefined()]).optional(),
  currency: z.string(),
  tipCurrency: z.union([z.string(), z.undefined()]).optional(),
  inboxId: z.number(),
  onBehalfOfUserId: z.union([z.number(), z.undefined()]).optional(),
  auditors: z.array(z.string()),
  profitCenter: z.union([z.string(), z.undefined()]).optional(),
  participants: z.union([z.array(ParticipantSubmission), z.undefined()]).optional(),
  participantAttachment: z.union([AttachmentSubmission, z.undefined()]).optional(),
  numberOfParticipants: z.union([z.number(), z.undefined()]).optional(),
  businessCases: z.array(BusinessCaseSubmission),
  countryCode: z.string(),
  emergencyJustification: z.union([z.string(), z.undefined()]).optional(),
  tripId: z.union([z.string(), z.undefined()]).optional(),
  caseNumber: z.union([z.string(), z.undefined()]).optional(),
  car: z.union([CarSubmission, z.undefined()]).optional(),
  qrCode: z.union([z.string(), z.undefined()]).optional(),
  cashTip: z.union([z.boolean(), z.undefined()]).optional(),
});

export type Attachment = z.infer<typeof Attachment>;
export const Attachment = z.object({
  id: z.number(),
  originalFilename: z.string(),
  mimeType: z.string(),
  size: z.number(),
  storageId: z.string(),
  allowDelete: z.boolean(),
});

export type Person = z.infer<typeof Person>;
export const Person = z.object({
  title: z.string().optional(),
  firstname: z.string().optional(),
  lastname: z.string().optional(),
  postTitle: z.string().optional(),
});

export type ContactPersonSummary = z.infer<typeof ContactPersonSummary>;
export const ContactPersonSummary = z.object({
  id: z.number().optional(),
  person: Person.optional(),
  email: Email.optional(),
  phone: Phone.optional(),
});

export type InvoiceType = z.infer<typeof InvoiceType>;
export const InvoiceType = z.union([
  z.literal("CreditorInvoice"),
  z.literal("CreditorCreditNote"),
  z.literal("ExpensesInvoice"),
  z.literal("CreditCardInvoice"),
]);

export type InvoiceState = z.infer<typeof InvoiceState>;
export const InvoiceState = z.union([
  z.literal("SUBMITTED"),
  z.literal("CHECKED"),
  z.literal("AUDITED"),
  z.literal("OEBB_REJECTED"),
  z.literal("ROUTED"),
  z.literal("UPLOADED"),
  z.literal("ERROR"),
  z.literal("OCR_SUCCESSFUL"),
  z.literal("OCR_FAILED"),
  z.literal("PROCESSED"),
  z.literal("PAYED_OUT"),
  z.literal("ARCHIVED"),
]);

export type OcrProcessing = z.infer<typeof OcrProcessing>;
export const OcrProcessing = z.union([z.literal("QRCode"), z.literal("NotScanned")]);

export type UserRef = z.infer<typeof UserRef>;
export const UserRef = z.object({
  id: z.number(),
  firstname: z.union([z.string(), z.undefined()]).optional(),
  lastname: z.union([z.string(), z.undefined()]).optional(),
  email: z.union([z.string(), z.undefined()]).optional(),
  phone: z.union([z.string(), z.undefined()]).optional(),
  displayName: z.union([z.string(), z.undefined()]).optional(),
});

export type InvoiceCreditor = z.infer<typeof InvoiceCreditor>;
export const InvoiceCreditor = z.object({
  number: z.string().optional(),
  name: z.string().optional(),
  streetAndHouseNo: z.string().optional(),
  zip: z.string().optional(),
  city: z.string().optional(),
  countryCode: z.string().optional(),
  vat: z.string().optional(),
  iban: z.string().optional(),
  bic: z.string().optional(),
  bank: z.string().optional(),
  bankAccountNumber: z.string().optional(),
  bankCode: z.string().optional(),
});

export type InvoiceDebitor = z.infer<typeof InvoiceDebitor>;
export const InvoiceDebitor = z.object({
  number: z.string().optional(),
  name: z.string().optional(),
  streetAndHouseNo: z.string().optional(),
  zip: z.string().optional(),
  city: z.string().optional(),
  countryCode: z.string().optional(),
  vat: z.string().optional(),
});

export type InvoiceBusinessCase = z.infer<typeof InvoiceBusinessCase>;
export const InvoiceBusinessCase = z.object({
  id: z.number().optional(),
  code: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  gross: z.number().optional(),
  tip: z.number().optional(),
  additionalFieldName: z.string().optional(),
  additionalFieldValue: z.string().optional(),
  carInputTaxDeductible: z.boolean().optional(),
  carType: z.string().optional(),
  licensePlate: z.string().optional(),
  mileageKm: z.number().optional(),
  refueledLiter: z.number().optional(),
  ifnr: z.string().optional(),
});

export type InvoiceParticipant = z.infer<typeof InvoiceParticipant>;
export const InvoiceParticipant = z.object({
  name: z.string().optional(),
  company: z.string().optional(),
});

export type InvoiceDate = z.infer<typeof InvoiceDate>;
export const InvoiceDate = z.object({
  label: z.string().optional(),
  line: z.number().optional(),
  date: z.string().optional(),
});

export type InvoiceReference = z.infer<typeof InvoiceReference>;
export const InvoiceReference = z.object({
  label: z.string().optional(),
  line: z.number().optional(),
  reference: z.string().optional(),
});

export type InvoiceText = z.infer<typeof InvoiceText>;
export const InvoiceText = z.object({
  label: z.string().optional(),
  line: z.number().optional(),
  pos: z.number().optional(),
  text: z.string().optional(),
});

export type PaymentTerms = z.infer<typeof PaymentTerms>;
export const PaymentTerms = z.literal("Days");

export type TaxedAmount = z.infer<typeof TaxedAmount>;
export const TaxedAmount = z.object({
  net: z.number().optional(),
  taxRate: z.number().optional(),
  tax: z.number().optional(),
  gross: z.number().optional(),
  zero: z.boolean().optional(),
});

export type InvoiceLine = z.infer<typeof InvoiceLine>;
export const InvoiceLine = z.object({
  position: z.union([z.number(), z.undefined()]).optional(),
  articleNo: z.union([z.string(), z.undefined()]).optional(),
  text: z.union([z.string(), z.undefined()]).optional(),
  qty: z.union([z.number(), z.undefined()]).optional(),
  units: z.union([z.string(), z.undefined()]).optional(),
  catalogPrice: z.union([z.number(), z.undefined()]).optional(),
  net: z.union([z.number(), z.undefined()]).optional(),
  taxRate: z.union([z.number(), z.undefined()]).optional(),
  tax: z.union([z.number(), z.undefined()]).optional(),
  gross: z.union([z.number(), z.undefined()]).optional(),
  discount: z.union([z.number(), z.undefined()]).optional(),
  orderNumber: z.union([z.string(), z.undefined()]).optional(),
  singleUnit: TaxedAmount,
});

export type Invoice = {
  id: number;
  invoiceType: "CreditorInvoice" | "CreditorCreditNote" | "ExpensesInvoice" | "CreditCardInvoice";
  invoiceState:
    | "SUBMITTED"
    | "CHECKED"
    | "AUDITED"
    | "OEBB_REJECTED"
    | "ROUTED"
    | "UPLOADED"
    | "ERROR"
    | "OCR_SUCCESSFUL"
    | "OCR_FAILED"
    | "PROCESSED"
    | "PAYED_OUT"
    | "ARCHIVED";
  ocrProcessing?: ("QRCode" | "NotScanned") | undefined;
  submitted?: string | undefined;
  oebbRejected?: string | undefined;
  oebbRejectReason?: string | undefined;
  rejectedBy?: string | undefined;
  rejectedByUser?:
    | {
        id: number;
        firstname?: string | undefined;
        lastname?: string | undefined;
        email?: string | undefined;
        phone?: string | undefined;
        displayName?: string | undefined;
      }
    | undefined;
  routed?: string | undefined;
  routedBy?: string | undefined;
  routedByUser?: UserRef | undefined;
  uploaded?: string | undefined;
  ocrSuccessful?: string | undefined;
  ocrFailed?: string | undefined;
  processed?: string | undefined;
  payedOut?: string | undefined;
  filesDeleted?: string | undefined;
  audited?: string | undefined;
  checked?: string | undefined;
  ocrErrorMsg?: string | undefined;
  checksum?: string | undefined;
  creditor?:
    | {
        id?: number;
        companyName?: string;
        creditorType?: "Creditor" | "ServiceCreditor";
        sapCreditorNumber?: string;
        enabled?: boolean;
      }
    | undefined;
  invoiceCreditor?:
    | {
        number?: string;
        name?: string;
        streetAndHouseNo?: string;
        zip?: string;
        city?: string;
        countryCode?: string;
        vat?: string;
        iban?: string;
        bic?: string;
        bank?: string;
        bankAccountNumber?: string;
        bankCode?: string;
      }
    | undefined;
  invoiceDebitor?:
    | {
        number?: string;
        name?: string;
        streetAndHouseNo?: string;
        zip?: string;
        city?: string;
        countryCode?: string;
        vat?: string;
      }
    | undefined;
  person?:
    | {
        id?: number;
        person?: {
          title?: string;
          firstname?: string;
          lastname?: string;
          postTitle?: string;
        };
        email?: {
          email?: string;
        };
        phone?: {
          countryCode?: string;
          prefix?: string;
          number?: string;
          extension?: string;
          added?: string;
        };
      }
    | undefined;
  user?: UserRef | undefined;
  submittedBy?: UserRef | undefined;
  originalFilename?: string | undefined;
  mimetype?: string | undefined;
  size?: number | undefined;
  storageId?: string | undefined;
  barcode?: string | undefined;
  inbox: {
    id: number;
    name: string;
    contact?: string | undefined;
    contactEmail?: string | undefined;
    contactPhone?: string | undefined;
    scanService?: ("ScanPoint" | "Exela" | "SkipOCR") | undefined;
    requireManualApproval: boolean;
    allowAttachments: boolean;
    allowAddingAttachments: boolean;
    hidden: boolean;
    hiddenForCreditors: boolean;
    onlyAvailableForManagers: boolean;
    availableForExpenses: boolean;
    availableForWorksAt: boolean;
    businessCenterAddress?: string | undefined;
    companyCode?: string | undefined;
    approvalIncludesInvoiceCheck: boolean;
    requiresAudit: boolean;
    inboxLabels: Array<string>;
    assignedInvoiceDocumentTypes: Array<{
      id: number;
      displayName: string;
      fileExtension: string;
      mimeType: string;
    }>;
    assignedAttachmentDocumentTypes: Array<DocumentType>;
    technicalName?: string | undefined;
    autoRoutable?: boolean | undefined;
  };
  outbox?:
    | {
        id: number;
        name: string;
        bca?: string | undefined;
        flag?: string | undefined;
        attachmentsRequireOcr: boolean;
      }
    | undefined;
  contactOebb?: string | undefined;
  contactCreditor?: string | undefined;
  qrCode?: string | undefined;
  countryCode?: string | undefined;
  currency?: string | undefined;
  businessCases?:
    | Array<{
        id?: number;
        code?: string;
        name?: string;
        description?: string;
        gross?: number;
        tip?: number;
        additionalFieldName?: string;
        additionalFieldValue?: string;
        carInputTaxDeductible?: boolean;
        carType?: string;
        licensePlate?: string;
        mileageKm?: number;
        refueledLiter?: number;
        ifnr?: string;
      }>
    | undefined;
  net?: number | undefined;
  gross?: number | undefined;
  tax?: number | undefined;
  tip?: number | undefined;
  participants?:
    | Array<{
        name?: string;
        company?: string;
      }>
    | undefined;
  numberOfParticipants?: number | undefined;
  profitCenter?: string | undefined;
  emergencyJustification?: string | undefined;
  auditors?: Array<string> | undefined;
  auditorUsers?: Array<UserRef> | undefined;
  auditedBy?: string | undefined;
  orderNumbers: Array<string>;
  duplicateOf?: Invoice | undefined;
  invoiceDate?:
    | {
        label?: string;
        line?: number;
        date?: string;
      }
    | undefined;
  dates?: Array<InvoiceDate> | undefined;
  invoiceNumber?:
    | {
        label?: string;
        line?: number;
        reference?: string;
      }
    | undefined;
  references?: Array<InvoiceReference> | undefined;
  texts?:
    | Array<{
        label?: string;
        line?: number;
        pos?: number;
        text?: string;
      }>
    | undefined;
  paymentTermsType?: "Days" | undefined;
  paymentTerms?: number | undefined;
  deliveryTerms?: string | undefined;
  additionalInformation?: string | undefined;
  lines?:
    | Array<{
        position?: number | undefined;
        articleNo?: string | undefined;
        text?: string | undefined;
        qty?: number | undefined;
        units?: string | undefined;
        catalogPrice?: number | undefined;
        net?: number | undefined;
        taxRate?: number | undefined;
        tax?: number | undefined;
        gross?: number | undefined;
        discount?: number | undefined;
        orderNumber?: string | undefined;
        singleUnit: {
          net?: number;
          taxRate?: number;
          tax?: number;
          gross?: number;
          zero?: boolean;
        };
      }>
    | undefined;
  paragraph11Errors?: Array<string> | undefined;
  tags?: Array<string> | undefined;
  attachments?:
    | Array<{
        id: number;
        originalFilename: string;
        mimeType: string;
        size: number;
        storageId: string;
        allowDelete: boolean;
      }>
    | undefined;
};
export const Invoice: z.ZodType<Invoice> = z.lazy(() =>
  z.object({
    id: z.number(),
    invoiceType: InvoiceType,
    invoiceState: InvoiceState,
    ocrProcessing: z.union([OcrProcessing, z.undefined()]).optional(),
    submitted: z.union([z.string(), z.undefined()]).optional(),
    oebbRejected: z.union([z.string(), z.undefined()]).optional(),
    oebbRejectReason: z.union([z.string(), z.undefined()]).optional(),
    rejectedBy: z.union([z.string(), z.undefined()]).optional(),
    rejectedByUser: z.union([UserRef, z.undefined()]).optional(),
    routed: z.union([z.string(), z.undefined()]).optional(),
    routedBy: z.union([z.string(), z.undefined()]).optional(),
    routedByUser: z.union([UserRef, z.undefined()]).optional(),
    uploaded: z.union([z.string(), z.undefined()]).optional(),
    ocrSuccessful: z.union([z.string(), z.undefined()]).optional(),
    ocrFailed: z.union([z.string(), z.undefined()]).optional(),
    processed: z.union([z.string(), z.undefined()]).optional(),
    payedOut: z.union([z.string(), z.undefined()]).optional(),
    filesDeleted: z.union([z.string(), z.undefined()]).optional(),
    audited: z.union([z.string(), z.undefined()]).optional(),
    checked: z.union([z.string(), z.undefined()]).optional(),
    ocrErrorMsg: z.union([z.string(), z.undefined()]).optional(),
    checksum: z.union([z.string(), z.undefined()]).optional(),
    creditor: z.union([CreditorRef, z.undefined()]).optional(),
    invoiceCreditor: z.union([InvoiceCreditor, z.undefined()]).optional(),
    invoiceDebitor: z.union([InvoiceDebitor, z.undefined()]).optional(),
    person: z.union([ContactPersonSummary, z.undefined()]).optional(),
    user: z.union([UserRef, z.undefined()]).optional(),
    submittedBy: z.union([UserRef, z.undefined()]).optional(),
    originalFilename: z.union([z.string(), z.undefined()]).optional(),
    mimetype: z.union([z.string(), z.undefined()]).optional(),
    size: z.union([z.number(), z.undefined()]).optional(),
    storageId: z.union([z.string(), z.undefined()]).optional(),
    barcode: z.union([z.string(), z.undefined()]).optional(),
    inbox: Inbox,
    outbox: z.union([Outbox, z.undefined()]).optional(),
    contactOebb: z.union([z.string(), z.undefined()]).optional(),
    contactCreditor: z.union([z.string(), z.undefined()]).optional(),
    qrCode: z.union([z.string(), z.undefined()]).optional(),
    countryCode: z.union([z.string(), z.undefined()]).optional(),
    currency: z.union([z.string(), z.undefined()]).optional(),
    businessCases: z.union([z.array(InvoiceBusinessCase), z.undefined()]).optional(),
    net: z.union([z.number(), z.undefined()]).optional(),
    gross: z.union([z.number(), z.undefined()]).optional(),
    tax: z.union([z.number(), z.undefined()]).optional(),
    tip: z.union([z.number(), z.undefined()]).optional(),
    participants: z.union([z.array(InvoiceParticipant), z.undefined()]).optional(),
    numberOfParticipants: z.union([z.number(), z.undefined()]).optional(),
    profitCenter: z.union([z.string(), z.undefined()]).optional(),
    emergencyJustification: z.union([z.string(), z.undefined()]).optional(),
    auditors: z.union([z.array(z.string()), z.undefined()]).optional(),
    auditorUsers: z.union([z.array(UserRef), z.undefined()]).optional(),
    auditedBy: z.union([z.string(), z.undefined()]).optional(),
    orderNumbers: z.array(z.string()),
    duplicateOf: z.union([Invoice, z.undefined()]).optional(),
    invoiceDate: z.union([InvoiceDate, z.undefined()]).optional(),
    dates: z.union([z.array(InvoiceDate), z.undefined()]).optional(),
    invoiceNumber: z.union([InvoiceReference, z.undefined()]).optional(),
    references: z.union([z.array(InvoiceReference), z.undefined()]).optional(),
    texts: z.union([z.array(InvoiceText), z.undefined()]).optional(),
    paymentTermsType: z.union([PaymentTerms, z.undefined()]).optional(),
    paymentTerms: z.union([z.number(), z.undefined()]).optional(),
    deliveryTerms: z.union([z.string(), z.undefined()]).optional(),
    additionalInformation: z.union([z.string(), z.undefined()]).optional(),
    lines: z.union([z.array(InvoiceLine), z.undefined()]).optional(),
    paragraph11Errors: z.union([z.array(z.string()), z.undefined()]).optional(),
    tags: z.union([z.array(z.string()), z.undefined()]).optional(),
    attachments: z.union([z.array(Attachment), z.undefined()]).optional(),
  }),
);
export type CreditorInvoiceSubmission = z.infer<typeof CreditorInvoiceSubmission>;
export const CreditorInvoiceSubmission = z.object({
  checksum: z.string(),
  filename: z.string(),
  contentType: z.string(),
  inboxId: z.number(),
  contactOebb: z.union([z.string(), z.undefined()]).optional(),
  orderNumbers: z.array(z.string()),
  additionalInformation: z.union([z.string(), z.undefined()]).optional(),
  attachments: z.union([z.array(AttachmentSubmission), z.undefined()]).optional(),
});

export type InvoiceSummary = z.infer<typeof InvoiceSummary>;
export const InvoiceSummary = z.object({
  id: z.number(),
  invoiceType: InvoiceType,
  invoiceState: InvoiceState,
  submitted: z.string(),
  creditor: z.union([CreditorRef, z.undefined()]).optional(),
  invoiceCreditor: z.union([InvoiceCreditor, z.undefined()]).optional(),
  user: UserRef,
  inbox: InboxRef,
  originalFilename: z.string(),
  attachments: z.array(Attachment),
  businessCases: z.array(InvoiceBusinessCase),
  currency: z.union([z.string(), z.undefined()]).optional(),
  gross: z.union([z.number(), z.undefined()]).optional(),
});

export type TempFile = z.infer<typeof TempFile>;
export const TempFile = z.object({
  checksum: z.string().optional(),
  duplicates: z.array(InvoiceSummary).optional(),
});

export type CreditCard = z.infer<typeof CreditCard>;
export const CreditCard = z.object({
  id: z.number(),
  cardNumber: z.string(),
  user: UserRef,
});

export type UserPatch = z.infer<typeof UserPatch>;
export const UserPatch = z.object({
  phone: z.string().optional(),
  worksAt: z.number().optional(),
});

export type SortObject = z.infer<typeof SortObject>;
export const SortObject = z.object({
  sorted: z.boolean().optional(),
  unsorted: z.boolean().optional(),
  empty: z.boolean().optional(),
});

export type PageableObject = z.infer<typeof PageableObject>;
export const PageableObject = z.object({
  paged: z.boolean().optional(),
  pageNumber: z.number().optional(),
  pageSize: z.number().optional(),
  unpaged: z.boolean().optional(),
  offset: z.number().optional(),
  sort: SortObject.optional(),
});

export type PageUser = z.infer<typeof PageUser>;
export const PageUser = z.object({
  totalElements: z.number().optional(),
  totalPages: z.number().optional(),
  pageable: PageableObject.optional(),
  first: z.boolean().optional(),
  last: z.boolean().optional(),
  size: z.number().optional(),
  content: z.array(User).optional(),
  number: z.number().optional(),
  sort: SortObject.optional(),
  numberOfElements: z.number().optional(),
  empty: z.boolean().optional(),
});

export type UiSettings = z.infer<typeof UiSettings>;
export const UiSettings = z.object({
  orderNumbers: z.boolean().optional(),
  invoiceAdditionalInformation: z.boolean().optional(),
  edifactFillXml: z.boolean().optional(),
  eingangsKanaeleNeu: z.boolean().optional(),
  rejectMinimumCharacters: z.number().optional(),
  emergencyMinimumCharacters: z.number().optional(),
});

export type Setting = z.infer<typeof Setting>;
export const Setting = z.object({
  key: z.string(),
  value: z.union([z.string(), z.undefined()]).optional(),
  description: z.union([z.string(), z.undefined()]).optional(),
  html: z.union([z.boolean(), z.undefined()]).optional(),
});

export type TimeFrame = z.infer<typeof TimeFrame>;
export const TimeFrame = z.union([
  z.literal("Last24h"),
  z.literal("LastWeek"),
  z.literal("LastMonth"),
  z.literal("LastYear"),
  z.literal("LastTwoYears"),
  z.literal("AllTime"),
]);

export type Metrics = z.infer<typeof Metrics>;
export const Metrics = z.object({
  logins: z.number().optional(),
  submittedInvoices: z.number().optional(),
  routedInvoices: z.number().optional(),
  rejectedInvoices: z.number().optional(),
  processedInvoices: z.number().optional(),
  failedInvoices: z.number().optional(),
});

export type GroupMetrics = z.infer<typeof GroupMetrics>;
export const GroupMetrics = z.object({
  group: z.string().optional(),
  metrics: Metrics.optional(),
});

export type PageInvoiceSummary = z.infer<typeof PageInvoiceSummary>;
export const PageInvoiceSummary = z.object({
  totalElements: z.number().optional(),
  totalPages: z.number().optional(),
  pageable: PageableObject.optional(),
  first: z.boolean().optional(),
  last: z.boolean().optional(),
  size: z.number().optional(),
  content: z.array(InvoiceSummary).optional(),
  number: z.number().optional(),
  sort: SortObject.optional(),
  numberOfElements: z.number().optional(),
  empty: z.boolean().optional(),
});

export type InvoiceXmlResponse = z.infer<typeof InvoiceXmlResponse>;
export const InvoiceXmlResponse = z.object({
  content: z.string().optional(),
});

export type InvoiceViewHistoryItem = z.infer<typeof InvoiceViewHistoryItem>;
export const InvoiceViewHistoryItem = z.object({
  date: z.union([z.string(), z.undefined()]).optional(),
  labelKey: z.string(),
  message: z.union([z.string(), z.undefined()]).optional(),
});

export type InvoiceFilterView = z.infer<typeof InvoiceFilterView>;
export const InvoiceFilterView = z.union([
  z.literal("Mine"),
  z.literal("AwaitingRouting"),
  z.literal("Audit"),
  z.literal("AwaitingAudit"),
  z.literal("All"),
]);

export type InvoiceCount = z.infer<typeof InvoiceCount>;
export const InvoiceCount = z.object({
  mine: z.number(),
  awaitingRouting: z.number(),
  awaitingAudit: z.number(),
  all: z.number(),
});

export type InboxFilter = z.infer<typeof InboxFilter>;
export const InboxFilter = z.union([
  z.literal("CREDITOR_INVOICE_SUBMISSION"),
  z.literal("EXPENSE_INVOICE_SUBMISSION"),
  z.literal("ROUTING"),
  z.literal("WORKS_AT_ASSIGNMENT"),
  z.literal("MANAGEMENT_ASSIGNMENT"),
  z.literal("FAVORITE_ASSIGNMENT"),
]);

export type InboxAuditor = z.infer<typeof InboxAuditor>;
export const InboxAuditor = z.object({
  id: z.number(),
  email: z.string(),
});

export type PageCreditor = z.infer<typeof PageCreditor>;
export const PageCreditor = z.object({
  totalElements: z.number().optional(),
  totalPages: z.number().optional(),
  pageable: PageableObject.optional(),
  first: z.boolean().optional(),
  last: z.boolean().optional(),
  size: z.number().optional(),
  content: z.array(Creditor).optional(),
  number: z.number().optional(),
  sort: SortObject.optional(),
  numberOfElements: z.number().optional(),
  empty: z.boolean().optional(),
});

export type BusinessCaseCategory = z.infer<typeof BusinessCaseCategory>;
export const BusinessCaseCategory = z.union([
  z.literal("Catering"),
  z.literal("ExternalCatering"),
  z.literal("Car"),
  z.literal("Default"),
]);

export type BusinessCase = z.infer<typeof BusinessCase>;
export const BusinessCase = z.object({
  id: z.number(),
  category: BusinessCaseCategory,
  name: z.union([z.string(), z.undefined()]).optional(),
  code: z.union([z.string(), z.undefined()]).optional(),
  description: z.union([z.string(), z.undefined()]).optional(),
  terms: z.union([z.string(), z.undefined()]).optional(),
  allowTip: z.boolean(),
  emergency: z.boolean(),
  trip: z.boolean(),
  car: z.boolean(),
  rentalCar: z.boolean(),
  caseNumber: z.boolean(),
  fixedAuditors: z.union([z.string(), z.undefined()]).optional(),
  warningMessage: z.union([z.string(), z.undefined()]).optional(),
  fixedInbox: z.union([z.number(), z.undefined()]).optional(),
});

export type LoginFlow = z.infer<typeof LoginFlow>;
export const LoginFlow = z.union([z.literal("Local"), z.literal("Federated")]);

export type Login = z.infer<typeof Login>;
export const Login = z.object({
  loginFlow: LoginFlow,
  redirectUrl: z.union([z.string(), z.undefined()]).optional(),
});

export type AuditLogType = z.infer<typeof AuditLogType>;
export const AuditLogType = z.union([
  z.literal("CreditorSignup"),
  z.literal("UserCreated"),
  z.literal("UserEdited"),
  z.literal("UserActivated"),
  z.literal("UserDeactivated"),
  z.literal("UserDeleted"),
  z.literal("UserLogin"),
  z.literal("UserLogout"),
  z.literal("UserSessionTimeout"),
  z.literal("LoginFailed"),
  z.literal("InboxCreated"),
  z.literal("InboxEdited"),
  z.literal("InboxDeleted"),
  z.literal("OutboxCreated"),
  z.literal("OutboxEdited"),
  z.literal("OutboxDeleted"),
  z.literal("ExpenseReportUploaded"),
  z.literal("InvoiceUploaded"),
  z.literal("InvoiceDuplicated"),
  z.literal("InvoiceRouted"),
  z.literal("InvoiceForwarded"),
  z.literal("InvoiceOcrSuccess"),
  z.literal("InvoiceOcrFailure"),
  z.literal("InvoiceProcessed"),
  z.literal("InvoiceRejected"),
  z.literal("InvoicePayedOut"),
  z.literal("InvoiceRetentionDeleted"),
  z.literal("EmailConfirmed"),
  z.literal("MaintenanceTask"),
]);

export type AuditLog = z.infer<typeof AuditLog>;
export const AuditLog = z.object({
  id: z.number(),
  type: AuditLogType,
  created: z.union([z.string(), z.undefined()]).optional(),
  user: z.union([UserRef, z.undefined()]).optional(),
  subject: z.string(),
  body: z.union([z.string(), z.undefined()]).optional(),
});

export type PageAuditLog = z.infer<typeof PageAuditLog>;
export const PageAuditLog = z.object({
  totalElements: z.number().optional(),
  totalPages: z.number().optional(),
  pageable: PageableObject.optional(),
  first: z.boolean().optional(),
  last: z.boolean().optional(),
  size: z.number().optional(),
  content: z.array(AuditLog).optional(),
  number: z.number().optional(),
  sort: SortObject.optional(),
  numberOfElements: z.number().optional(),
  empty: z.boolean().optional(),
});

export type get_GetUser = typeof get_GetUser;
export const get_GetUser = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/users/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: User,
};

export type put_UpdateUser = typeof put_UpdateUser;
export const put_UpdateUser = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/users/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: UserMutation,
  }),
  response: User,
};

export type delete_DeleteUser = typeof delete_DeleteUser;
export const delete_DeleteUser = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/users/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetUserWorksAt = typeof get_GetUserWorksAt;
export const get_GetUserWorksAt = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/users/{id}/works-at"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(Inbox),
};

export type put_UpdateUserWorksAt = typeof put_UpdateUserWorksAt;
export const put_UpdateUserWorksAt = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/users/{id}/works-at"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: z.array(z.number()),
  }),
  response: z.array(Inbox),
};

export type get_GetUserProxies = typeof get_GetUserProxies;
export const get_GetUserProxies = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/users/{id}/proxies"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(User),
};

export type put_AddUserProxy = typeof put_AddUserProxy;
export const put_AddUserProxy = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/users/{id}/proxies"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: UserProxyMutation,
  }),
  response: z.unknown(),
};

export type get_GetUserInboxes = typeof get_GetUserInboxes;
export const get_GetUserInboxes = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/users/{id}/inboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(Inbox),
};

export type put_UpdateUserInboxes = typeof put_UpdateUserInboxes;
export const put_UpdateUserInboxes = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/users/{id}/inboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: z.array(z.number()),
  }),
  response: z.array(Inbox),
};

export type put_UpdateUserVacation = typeof put_UpdateUserVacation;
export const put_UpdateUserVacation = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/user/vacation"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: UserVacationMutation,
  }),
  response: z.unknown(),
};

export type delete_DeleteUserVacation = typeof delete_DeleteUserVacation;
export const delete_DeleteUserVacation = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/user/vacation"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type put_UpdatePassword = typeof put_UpdatePassword;
export const put_UpdatePassword = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/user/password"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      password: z.string(),
    }),
  }),
  response: z.unknown(),
};

export type put_UpdateContactPerson = typeof put_UpdateContactPerson;
export const put_UpdateContactPerson = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/user/contact-person"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: ContactPersonMutation,
  }),
  response: z.unknown(),
};

export type put_UpdateSetting = typeof put_UpdateSetting;
export const put_UpdateSetting = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/settings/{key}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      value: z.string(),
    }),
    path: z.object({
      key: z.string(),
    }),
  }),
  response: z.unknown(),
};

export type put_ResetPassword = typeof put_ResetPassword;
export const put_ResetPassword = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/password-reset/{email}/password"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      email: z.string(),
    }),
    body: PasswordReset,
  }),
  response: z.unknown(),
};

export type put_UploadScanpointFiles = typeof put_UploadScanpointFiles;
export const put_UploadScanpointFiles = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/maintenance/upload-scanpoint-files"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type put_RemindAuditors = typeof put_RemindAuditors;
export const put_RemindAuditors = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/maintenance/remind-auditors"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type put_ReadScanpointFiles = typeof put_ReadScanpointFiles;
export const put_ReadScanpointFiles = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/maintenance/read-scanpoint-files"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type put_EdifactSftpRead = typeof put_EdifactSftpRead;
export const put_EdifactSftpRead = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/maintenance/read-edifact-sftp"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type put_DeleteStaleDocuments = typeof put_DeleteStaleDocuments;
export const put_DeleteStaleDocuments = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/maintenance/delete-stale-documents"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type put_CleanupInvoices = typeof put_CleanupInvoices;
export const put_CleanupInvoices = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/maintenance/cleanup-invoices"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type put_CleanupAuditlog = typeof put_CleanupAuditlog;
export const put_CleanupAuditlog = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/maintenance/cleanup-auditlog"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type put_ForwardInvoice = typeof put_ForwardInvoice;
export const put_ForwardInvoice = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/invoices/{id}/inbox"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: InvoiceForwarding,
  }),
  response: z.unknown(),
};

export type get_GetInfo = typeof get_GetInfo;
export const get_GetInfo = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/infos/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: Info,
};

export type put_UpdateInfo = typeof put_UpdateInfo;
export const put_UpdateInfo = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/infos/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: InfoMutation,
  }),
  response: Info,
};

export type delete_DeleteInfo = typeof delete_DeleteInfo;
export const delete_DeleteInfo = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/infos/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetInbox = typeof get_GetInbox;
export const get_GetInbox = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/inboxes/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: Inbox,
};

export type put_UpdateInbox = typeof put_UpdateInbox;
export const put_UpdateInbox = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/inboxes/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: InboxMutation,
  }),
  response: Inbox,
};

export type delete_DeleteInbox = typeof delete_DeleteInbox;
export const delete_DeleteInbox = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/inboxes/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type put_UpdateInboxOutbox = typeof put_UpdateInboxOutbox;
export const put_UpdateInboxOutbox = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/inboxes/{id}/outboxes/{outboxId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      outboxId: z.number(),
    }),
    body: OutboxMutation,
  }),
  response: Outbox,
};

export type delete_DeleteInboxOutbox = typeof delete_DeleteInboxOutbox;
export const delete_DeleteInboxOutbox = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/inboxes/{id}/outboxes/{outboxId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      outboxId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type put_UpdateInboxInvoicesDocTypes = typeof put_UpdateInboxInvoicesDocTypes;
export const put_UpdateInboxInvoicesDocTypes = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/inboxes/{id}/invoices-doc-types"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: z.array(z.number()),
  }),
  response: z.array(DocumentType),
};

export type put_AddInboxInvoiceManager = typeof put_AddInboxInvoiceManager;
export const put_AddInboxInvoiceManager = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/inboxes/{id}/invoice-managers/{userId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      userId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteInboxManager = typeof delete_DeleteInboxManager;
export const delete_DeleteInboxManager = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/inboxes/{id}/invoice-managers/{userId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      userId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type put_AddInboxAuditor = typeof put_AddInboxAuditor;
export const put_AddInboxAuditor = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/inboxes/{id}/auditors/{userId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      userId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteInboxAuditor = typeof delete_DeleteInboxAuditor;
export const delete_DeleteInboxAuditor = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/inboxes/{id}/auditors/{userId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      userId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type put_UpdateInboxAttachmentDocTypes = typeof put_UpdateInboxAttachmentDocTypes;
export const put_UpdateInboxAttachmentDocTypes = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/inboxes/{id}/attachments-doc-types"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: z.array(z.number()),
  }),
  response: z.array(DocumentType),
};

export type get_GetHelpText = typeof get_GetHelpText;
export const get_GetHelpText = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/help-texts/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: HelpText,
};

export type put_UpdateHelpText = typeof put_UpdateHelpText;
export const put_UpdateHelpText = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/help-texts/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: HelpTextMutation,
  }),
  response: HelpText,
};

export type delete_DeleteHelpText = typeof delete_DeleteHelpText;
export const delete_DeleteHelpText = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/help-texts/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetDocumentType = typeof get_GetDocumentType;
export const get_GetDocumentType = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/document-types/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: DocumentType,
};

export type put_UpdateDocumentType = typeof put_UpdateDocumentType;
export const put_UpdateDocumentType = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/document-types/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: DocumentTypeMutation,
  }),
  response: DocumentType,
};

export type delete_DeleteDocumentType = typeof delete_DeleteDocumentType;
export const delete_DeleteDocumentType = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/document-types/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetCreditor = typeof get_GetCreditor;
export const get_GetCreditor = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/creditors/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: Creditor,
};

export type put_UpdateCreditor = typeof put_UpdateCreditor;
export const put_UpdateCreditor = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/creditors/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: CreditorMutation,
  }),
  response: Creditor,
};

export type put_UpdateCreditorInbox = typeof put_UpdateCreditorInbox;
export const put_UpdateCreditorInbox = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/creditors/{id}/inboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: z.array(CreditorInboxMutation),
  }),
  response: z.array(CreditorInbox),
};

export type post_AddCreditorInbox = typeof post_AddCreditorInbox;
export const post_AddCreditorInbox = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/creditors/{id}/inboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: CreditorInboxMutation,
  }),
  response: z.unknown(),
};

export type get_GetCreditor_1 = typeof get_GetCreditor_1;
export const get_GetCreditor_1 = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/creditor"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: Creditor,
};

export type put_UpdateCreditor_1 = typeof put_UpdateCreditor_1;
export const put_UpdateCreditor_1 = {
  method: z.literal("PUT"),
  path: z.literal("/api/v2/creditor"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: CreditorRequest,
  }),
  response: Creditor,
};

export type get_QueryUsers = typeof get_QueryUsers;
export const get_QueryUsers = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/users"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      name: z.string().optional(),
      email: z.string().optional(),
      role: z
        .union([
          z.literal("SuperUser"),
          z.literal("OebbAdministrator"),
          z.literal("OebbUser"),
          z.literal("CreditorAdministrator"),
          z.literal("CreditorUser"),
          z.literal("OebbApiUser"),
        ])
        .optional(),
      creditorId: z.number().optional(),
      page: z.number().optional(),
      size: z.number().optional(),
      sort: z.array(z.string()).optional(),
    }),
  }),
  response: PageUser,
};

export type post_CreateUser = typeof post_CreateUser;
export const post_CreateUser = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/users"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: UserMutation,
  }),
  response: User,
};

export type post_ImpersonateUser = typeof post_ImpersonateUser;
export const post_ImpersonateUser = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/users/{id}/impersonate"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetProxiedUsers = typeof get_GetProxiedUsers;
export const get_GetProxiedUsers = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/user/proxied"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(User),
};

export type post_AddProxiedUser = typeof post_AddProxiedUser;
export const post_AddProxiedUser = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/user/proxied"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      email: z.string(),
    }),
  }),
  response: z.unknown(),
};

export type post_IdentifyEmail = typeof post_IdentifyEmail;
export const post_IdentifyEmail = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/signup"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: IdentifyEmailRequest,
  }),
  response: SignupFlow,
};

export type post_ConfirmEmail = typeof post_ConfirmEmail;
export const post_ConfirmEmail = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/signup/{token}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      token: z.string(),
    }),
  }),
  response: z.unknown(),
};

export type post_SignupProcess = typeof post_SignupProcess;
export const post_SignupProcess = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/signup/creditor"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: Signup,
  }),
  response: z.unknown(),
};

export type post_RequestPasswordReset = typeof post_RequestPasswordReset;
export const post_RequestPasswordReset = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/password-reset/{email}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      email: z.string(),
    }),
  }),
  response: z.unknown(),
};

export type post_UploadIndexFile = typeof post_UploadIndexFile;
export const post_UploadIndexFile = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/maintenance/upload-index-file"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      date: z.string(),
    }),
  }),
  response: z.unknown(),
};

export type post_RouteInvoice = typeof post_RouteInvoice;
export const post_RouteInvoice = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/invoices/{id}/route"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: InvoiceRouting,
  }),
  response: z.unknown(),
};

export type post_AuditRejection = typeof post_AuditRejection;
export const post_AuditRejection = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/invoices/{id}/audit-rejection"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: InvoiceRejection,
  }),
  response: z.unknown(),
};

export type post_AuditApproval = typeof post_AuditApproval;
export const post_AuditApproval = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/invoices/{id}/audit-approval"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: InvoiceApproval,
  }),
  response: z.unknown(),
};

export type post_AddInvoiceAttachment = typeof post_AddInvoiceAttachment;
export const post_AddInvoiceAttachment = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/invoices/{id}/attachments"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: z.array(AttachmentSubmission),
  }),
  response: z.unknown(),
};

export type post_Administrate = typeof post_Administrate;
export const post_Administrate = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/invoices/{id}/administration"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: InvoiceAdministrationData,
  }),
  response: z.unknown(),
};

export type post_SubmitExpenseInvoice = typeof post_SubmitExpenseInvoice;
export const post_SubmitExpenseInvoice = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/invoices/expense-invoice"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: ExpenseInvoiceSubmission,
  }),
  response: z.array(Invoice),
};

export type post_SubmitCreditorInvoices = typeof post_SubmitCreditorInvoices;
export const post_SubmitCreditorInvoices = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/invoices/creditor-invoices"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: z.array(CreditorInvoiceSubmission),
  }),
  response: z.array(Invoice),
};

export type get_QueryInfos = typeof get_QueryInfos;
export const get_QueryInfos = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/infos"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      language: z.union([z.literal("en"), z.literal("de"), z.literal("hu")]).optional(),
      displayType: z.union([z.literal("LoginForm"), z.literal("Dashboard")]).optional(),
      audience: z.union([z.literal("All"), z.literal("Oebb"), z.literal("Creditor")]).optional(),
      current: z.boolean().optional(),
    }),
  }),
  response: z.array(Info),
};

export type post_CreateInfo = typeof post_CreateInfo;
export const post_CreateInfo = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/infos"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: InfoMutation,
  }),
  response: Info,
};

export type get_QueryInboxes = typeof get_QueryInboxes;
export const get_QueryInboxes = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/inboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      filter: z
        .union([
          z.literal("CREDITOR_INVOICE_SUBMISSION"),
          z.literal("EXPENSE_INVOICE_SUBMISSION"),
          z.literal("ROUTING"),
          z.literal("WORKS_AT_ASSIGNMENT"),
          z.literal("MANAGEMENT_ASSIGNMENT"),
          z.literal("FAVORITE_ASSIGNMENT"),
        ])
        .optional(),
      userId: z.number().optional(),
    }),
  }),
  response: z.array(Inbox),
};

export type post_CreateInbox = typeof post_CreateInbox;
export const post_CreateInbox = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/inboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: InboxMutation,
  }),
  response: Inbox,
};

export type get_GetOutboxes = typeof get_GetOutboxes;
export const get_GetOutboxes = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/inboxes/{id}/outboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(Outbox),
};

export type post_CreateInboxOutbox = typeof post_CreateInboxOutbox;
export const post_CreateInboxOutbox = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/inboxes/{id}/outboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: OutboxMutation,
  }),
  response: Outbox,
};

export type get_QueryInboxAuditors = typeof get_QueryInboxAuditors;
export const get_QueryInboxAuditors = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/inboxes/{id}/inbox-auditors"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(InboxAuditor),
};

export type post_ImportInboxAuditors = typeof post_ImportInboxAuditors;
export const post_ImportInboxAuditors = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/inboxes/{id}/inbox-auditors"),
  requestFormat: z.literal("form-data"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
    body: z.object({
      file: z.string().optional(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteInboxAuditors = typeof delete_DeleteInboxAuditors;
export const delete_DeleteInboxAuditors = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/inboxes/{id}/inbox-auditors"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type get_QueryHelpTexts = typeof get_QueryHelpTexts;
export const get_QueryHelpTexts = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/help-texts"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      language: z.string().optional(),
    }),
  }),
  response: z.array(HelpText),
};

export type post_CreateHelpText = typeof post_CreateHelpText;
export const post_CreateHelpText = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/help-texts"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: HelpTextMutation,
  }),
  response: HelpText,
};

export type post_CreateTempFile = typeof post_CreateTempFile;
export const post_CreateTempFile = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/files"),
  requestFormat: z.literal("form-data"),
  parameters: z.object({
    body: z.object({
      file: z.string(),
    }),
  }),
  response: TempFile,
};

export type get_QueryDocumentTypes = typeof get_QueryDocumentTypes;
export const get_QueryDocumentTypes = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/document-types"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(DocumentType),
};

export type post_CreateDocumentType = typeof post_CreateDocumentType;
export const post_CreateDocumentType = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/document-types"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: DocumentTypeMutation,
  }),
  response: DocumentType,
};

export type get_QueryAvailableInboxesForCreditor = typeof get_QueryAvailableInboxesForCreditor;
export const get_QueryAvailableInboxesForCreditor = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/creditor/inboxes"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(Inbox),
};

export type post_UpdateCreditorInboxes = typeof post_UpdateCreditorInboxes;
export const post_UpdateCreditorInboxes = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/creditor/inboxes"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: z.array(z.number()),
  }),
  response: z.unknown(),
};

export type post_ImportCreditCards = typeof post_ImportCreditCards;
export const post_ImportCreditCards = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/credit-cards/import"),
  requestFormat: z.literal("form-data"),
  parameters: z.object({
    body: z.object({
      file: z.string(),
    }),
  }),
  response: z.array(CreditCard),
};

export type post_ImportBusinessCases = typeof post_ImportBusinessCases;
export const post_ImportBusinessCases = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/business-cases/import"),
  requestFormat: z.literal("form-data"),
  parameters: z.object({
    body: z.object({
      file: z.string().optional(),
    }),
  }),
  response: z.unknown(),
};

export type post_ProcessToken = typeof post_ProcessToken;
export const post_ProcessToken = {
  method: z.literal("POST"),
  path: z.literal("/api/v2/auth/process-token"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      code: z.string(),
      origin: z.string(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetCurrentUser = typeof get_GetCurrentUser;
export const get_GetCurrentUser = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/user"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: User,
};

export type patch_PatchCurrentUser = typeof patch_PatchCurrentUser;
export const patch_PatchCurrentUser = {
  method: z.literal("PATCH"),
  path: z.literal("/api/v2/user"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    body: UserPatch,
  }),
  response: User,
};

export type patch_AssignAuditor = typeof patch_AssignAuditor;
export const patch_AssignAuditor = {
  method: z.literal("PATCH"),
  path: z.literal("/api/v2/invoices/{id}/assign-auditor"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      auditorEmail: z.string(),
    }),
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetProxiedUsers_1 = typeof get_GetProxiedUsers_1;
export const get_GetProxiedUsers_1 = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/users/{id}/proxied"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(User),
};

export type get_GetUserProxies_1 = typeof get_GetUserProxies_1;
export const get_GetUserProxies_1 = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/user/proxies"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(User),
};

export type get_GetUiSettings = typeof get_GetUiSettings;
export const get_GetUiSettings = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/ui-settings"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: UiSettings,
};

export type get_ListSettings = typeof get_ListSettings;
export const get_ListSettings = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/settings"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(Setting),
};

export type get_QueryReportingData = typeof get_QueryReportingData;
export const get_QueryReportingData = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/reporting/data"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      timeFrame: z.union([
        z.literal("Last24h"),
        z.literal("LastWeek"),
        z.literal("LastMonth"),
        z.literal("LastYear"),
        z.literal("LastTwoYears"),
        z.literal("AllTime"),
      ]),
      creditorId: z.union([z.number(), z.undefined()]),
    }),
  }),
  response: z.array(GroupMetrics),
};

export type get_ReadSftpFolder = typeof get_ReadSftpFolder;
export const get_ReadSftpFolder = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/maintenance/read-sftp-folder"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      service: z.union([z.literal("ScanPoint"), z.literal("Exela"), z.literal("SkipOCR")]),
    }),
  }),
  response: z.array(z.string()),
};

export type get_QueryAwaitingUploadInvoices = typeof get_QueryAwaitingUploadInvoices;
export const get_QueryAwaitingUploadInvoices = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/maintenance/awaiting-upload-invoices"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(Invoice),
};

export type get_QueryInvoices = typeof get_QueryInvoices;
export const get_QueryInvoices = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      invoiceType: z
        .union([
          z.literal("CreditorInvoice"),
          z.literal("CreditorCreditNote"),
          z.literal("ExpensesInvoice"),
          z.literal("CreditCardInvoice"),
        ])
        .optional(),
      invoiceState: z
        .union([
          z.literal("SUBMITTED"),
          z.literal("CHECKED"),
          z.literal("AUDITED"),
          z.literal("OEBB_REJECTED"),
          z.literal("ROUTED"),
          z.literal("UPLOADED"),
          z.literal("ERROR"),
          z.literal("OCR_SUCCESSFUL"),
          z.literal("OCR_FAILED"),
          z.literal("PROCESSED"),
          z.literal("PAYED_OUT"),
          z.literal("ARCHIVED"),
        ])
        .optional(),
      invoiceIds: z.string().optional(),
      submittedAfter: z.string().optional(),
      submittedBefore: z.string().optional(),
      amountFrom: z.number().optional(),
      amountTo: z.number().optional(),
      filename: z.string().optional(),
      inboxId: z.number().optional(),
      creditorName: z.string().optional(),
      email: z.string().optional(),
      tags: z.array(z.string()).optional(),
      submittedOrOwnedBy: z.number().optional(),
      auditRequestedFrom: z.string().optional(),
      awaitingAudit: z.boolean().optional(),
      awaitingRouting: z.boolean().optional(),
      submittedByCreditorId: z.number().optional(),
      "assignedToUser.userId": z.number().optional(),
      "assignedToUser.email": z.string().optional(),
      "inboxOrAuditedBy.inboxIds": z.array(z.number()).optional(),
      "inboxOrAuditedBy.auditedBy": z.string().optional(),
      page: z.number().optional(),
      size: z.number().optional(),
      sort: z.array(z.string()).optional(),
    }),
  }),
  response: PageInvoiceSummary,
};

export type get_GetInvoice = typeof get_GetInvoice;
export const get_GetInvoice = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices/{id}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: Invoice,
};

export type get_GetXml = typeof get_GetXml;
export const get_GetXml = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices/{id}/xml"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: InvoiceXmlResponse,
};

export type get_GetIfnr = typeof get_GetIfnr;
export const get_GetIfnr = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices/{id}/ifnr"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.string(),
};

export type get_GetHistory = typeof get_GetHistory;
export const get_GetHistory = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices/{id}/history"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(InvoiceViewHistoryItem),
};

export type get_GetDuplicates = typeof get_GetDuplicates;
export const get_GetDuplicates = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices/{id}/duplicates"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(Invoice),
};

export type get_ListCostCenterHistory = typeof get_ListCostCenterHistory;
export const get_ListCostCenterHistory = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices/profit-center-history"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(z.string()),
};

export type get_ExportInvoices = typeof get_ExportInvoices;
export const get_ExportInvoices = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices/export"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      exportType: z.string().optional(),
      page: z.number().optional(),
      size: z.number().optional(),
      sort: z.array(z.string()).optional(),
      filename: z.string().optional(),
      view: z
        .union([
          z.literal("Mine"),
          z.literal("AwaitingRouting"),
          z.literal("Audit"),
          z.literal("AwaitingAudit"),
          z.literal("All"),
        ])
        .optional(),
      invoiceIds: z.string().optional(),
      submittedAfter: z.string().optional(),
      submittedBefore: z.string().optional(),
      amountFrom: z.number().optional(),
      amountTo: z.number().optional(),
      invoiceType: z
        .union([
          z.literal("CreditorInvoice"),
          z.literal("CreditorCreditNote"),
          z.literal("ExpensesInvoice"),
          z.literal("CreditCardInvoice"),
        ])
        .optional(),
      invoiceState: z
        .union([
          z.literal("SUBMITTED"),
          z.literal("CHECKED"),
          z.literal("AUDITED"),
          z.literal("OEBB_REJECTED"),
          z.literal("ROUTED"),
          z.literal("UPLOADED"),
          z.literal("ERROR"),
          z.literal("OCR_SUCCESSFUL"),
          z.literal("OCR_FAILED"),
          z.literal("PROCESSED"),
          z.literal("PAYED_OUT"),
          z.literal("ARCHIVED"),
        ])
        .optional(),
      inboxId: z.number().optional(),
      creditorName: z.string().optional(),
      email: z.string().optional(),
      tags: z.string().optional(),
    }),
  }),
  response: z.unknown(),
};

export type get_ListAuditorHistory = typeof get_ListAuditorHistory;
export const get_ListAuditorHistory = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoices/auditor-history"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(z.string()),
};

export type get_GetInvoiceCount = typeof get_GetInvoiceCount;
export const get_GetInvoiceCount = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/invoice-count"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: InvoiceCount,
};

export type get_GetInboxManagers = typeof get_GetInboxManagers;
export const get_GetInboxManagers = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/inboxes/{id}/managers"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(User),
};

export type get_ExportInboxAuditors = typeof get_ExportInboxAuditors;
export const get_ExportInboxAuditors = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/inboxes/{id}/inbox-auditors/export"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type get_GetInboxAuditors = typeof get_GetInboxAuditors;
export const get_GetInboxAuditors = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/inboxes/{id}/auditors"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
    }),
  }),
  response: z.array(User),
};

export type get_QueryCreditors = typeof get_QueryCreditors;
export const get_QueryCreditors = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/creditors"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      companyName: z.string().optional(),
      page: z.number().optional(),
      size: z.number().optional(),
      sort: z.array(z.string()).optional(),
    }),
  }),
  response: PageCreditor,
};

export type get_GetCurrentCreditor = typeof get_GetCurrentCreditor;
export const get_GetCurrentCreditor = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/creditors/current"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: Creditor,
};

export type get_QueryCreditCards = typeof get_QueryCreditCards;
export const get_QueryCreditCards = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/credit-cards"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.array(CreditCard),
};

export type delete_DeleteAllCreditCards = typeof delete_DeleteAllCreditCards;
export const delete_DeleteAllCreditCards = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/credit-cards"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type get_ExportCreditCards = typeof get_ExportCreditCards;
export const get_ExportCreditCards = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/credit-cards/export"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type get_QueryBusinessCases = typeof get_QueryBusinessCases;
export const get_QueryBusinessCases = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/business-cases"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      category: z
        .union([z.literal("Catering"), z.literal("ExternalCatering"), z.literal("Car"), z.literal("Default")])
        .optional(),
    }),
  }),
  response: z.array(BusinessCase),
};

export type delete_DeleteAllBusinessCases = typeof delete_DeleteAllBusinessCases;
export const delete_DeleteAllBusinessCases = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/business-cases"),
  requestFormat: z.literal("json"),
  parameters: z.never(),
  response: z.unknown(),
};

export type get_GetLogin = typeof get_GetLogin;
export const get_GetLogin = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/auth/login/{email}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      next: z.union([z.string(), z.undefined()]),
      origin: z.string(),
    }),
    path: z.object({
      email: z.string(),
    }),
  }),
  response: Login,
};

export type get_QueryAuditLogs = typeof get_QueryAuditLogs;
export const get_QueryAuditLogs = {
  method: z.literal("GET"),
  path: z.literal("/api/v2/audit-logs"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    query: z.object({
      userEmail: z.string().optional(),
      creditorId: z.number().optional(),
      createdBefore: z.string().optional(),
      createdAfter: z.string().optional(),
      types: z.array(AuditLogType).optional(),
      page: z.number().optional(),
      size: z.number().optional(),
      sort: z.array(z.string()).optional(),
    }),
  }),
  response: PageAuditLog,
};

export type delete_DeleteUserWorksAt = typeof delete_DeleteUserWorksAt;
export const delete_DeleteUserWorksAt = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/users/{id}/works-at/{inboxId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      inboxId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteUserProxy = typeof delete_DeleteUserProxy;
export const delete_DeleteUserProxy = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/users/{id}/proxies/{proxyId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      proxyId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteUserInbox = typeof delete_DeleteUserInbox;
export const delete_DeleteUserInbox = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/users/{id}/inboxes/{inboxId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      inboxId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteProxiedUser = typeof delete_DeleteProxiedUser;
export const delete_DeleteProxiedUser = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/user/proxied/{proxyId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      proxyId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteInboxInvoicesDocTypes = typeof delete_DeleteInboxInvoicesDocTypes;
export const delete_DeleteInboxInvoicesDocTypes = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/inboxes/{id}/invoices-doc-types/{documentTypeId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      documentTypeId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteInboxAttachmentDocTypes = typeof delete_DeleteInboxAttachmentDocTypes;
export const delete_DeleteInboxAttachmentDocTypes = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/inboxes/{id}/attachments-doc-types/{documentTypeId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      documentTypeId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteCreditorInbox = typeof delete_DeleteCreditorInbox;
export const delete_DeleteCreditorInbox = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/creditors/{id}/inboxes/{inboxId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      id: z.number(),
      inboxId: z.number(),
    }),
  }),
  response: z.unknown(),
};

export type delete_DeleteCreditorInboxes = typeof delete_DeleteCreditorInboxes;
export const delete_DeleteCreditorInboxes = {
  method: z.literal("DELETE"),
  path: z.literal("/api/v2/creditor/inboxes/{inboxId}"),
  requestFormat: z.literal("json"),
  parameters: z.object({
    path: z.object({
      inboxId: z.number(),
    }),
  }),
  response: z.unknown(),
};

// <EndpointByMethod>
export const EndpointByMethod = {
  get: {
    "/api/v2/users/{id}": get_GetUser,
    "/api/v2/users/{id}/works-at": get_GetUserWorksAt,
    "/api/v2/users/{id}/proxies": get_GetUserProxies,
    "/api/v2/users/{id}/inboxes": get_GetUserInboxes,
    "/api/v2/infos/{id}": get_GetInfo,
    "/api/v2/inboxes/{id}": get_GetInbox,
    "/api/v2/help-texts/{id}": get_GetHelpText,
    "/api/v2/document-types/{id}": get_GetDocumentType,
    "/api/v2/creditors/{id}": get_GetCreditor,
    "/api/v2/creditor": get_GetCreditor_1,
    "/api/v2/users": get_QueryUsers,
    "/api/v2/user/proxied": get_GetProxiedUsers,
    "/api/v2/infos": get_QueryInfos,
    "/api/v2/inboxes": get_QueryInboxes,
    "/api/v2/inboxes/{id}/outboxes": get_GetOutboxes,
    "/api/v2/inboxes/{id}/inbox-auditors": get_QueryInboxAuditors,
    "/api/v2/help-texts": get_QueryHelpTexts,
    "/api/v2/document-types": get_QueryDocumentTypes,
    "/api/v2/creditor/inboxes": get_QueryAvailableInboxesForCreditor,
    "/api/v2/user": get_GetCurrentUser,
    "/api/v2/users/{id}/proxied": get_GetProxiedUsers_1,
    "/api/v2/user/proxies": get_GetUserProxies_1,
    "/api/v2/ui-settings": get_GetUiSettings,
    "/api/v2/settings": get_ListSettings,
    "/api/v2/reporting/data": get_QueryReportingData,
    "/api/v2/maintenance/read-sftp-folder": get_ReadSftpFolder,
    "/api/v2/maintenance/awaiting-upload-invoices": get_QueryAwaitingUploadInvoices,
    "/api/v2/invoices": get_QueryInvoices,
    "/api/v2/invoices/{id}": get_GetInvoice,
    "/api/v2/invoices/{id}/xml": get_GetXml,
    "/api/v2/invoices/{id}/ifnr": get_GetIfnr,
    "/api/v2/invoices/{id}/history": get_GetHistory,
    "/api/v2/invoices/{id}/duplicates": get_GetDuplicates,
    "/api/v2/invoices/profit-center-history": get_ListCostCenterHistory,
    "/api/v2/invoices/export": get_ExportInvoices,
    "/api/v2/invoices/auditor-history": get_ListAuditorHistory,
    "/api/v2/invoice-count": get_GetInvoiceCount,
    "/api/v2/inboxes/{id}/managers": get_GetInboxManagers,
    "/api/v2/inboxes/{id}/inbox-auditors/export": get_ExportInboxAuditors,
    "/api/v2/inboxes/{id}/auditors": get_GetInboxAuditors,
    "/api/v2/creditors": get_QueryCreditors,
    "/api/v2/creditors/current": get_GetCurrentCreditor,
    "/api/v2/credit-cards": get_QueryCreditCards,
    "/api/v2/credit-cards/export": get_ExportCreditCards,
    "/api/v2/business-cases": get_QueryBusinessCases,
    "/api/v2/auth/login/{email}": get_GetLogin,
    "/api/v2/audit-logs": get_QueryAuditLogs,
  },
  put: {
    "/api/v2/users/{id}": put_UpdateUser,
    "/api/v2/users/{id}/works-at": put_UpdateUserWorksAt,
    "/api/v2/users/{id}/proxies": put_AddUserProxy,
    "/api/v2/users/{id}/inboxes": put_UpdateUserInboxes,
    "/api/v2/user/vacation": put_UpdateUserVacation,
    "/api/v2/user/password": put_UpdatePassword,
    "/api/v2/user/contact-person": put_UpdateContactPerson,
    "/api/v2/settings/{key}": put_UpdateSetting,
    "/api/v2/password-reset/{email}/password": put_ResetPassword,
    "/api/v2/maintenance/upload-scanpoint-files": put_UploadScanpointFiles,
    "/api/v2/maintenance/remind-auditors": put_RemindAuditors,
    "/api/v2/maintenance/read-scanpoint-files": put_ReadScanpointFiles,
    "/api/v2/maintenance/read-edifact-sftp": put_EdifactSftpRead,
    "/api/v2/maintenance/delete-stale-documents": put_DeleteStaleDocuments,
    "/api/v2/maintenance/cleanup-invoices": put_CleanupInvoices,
    "/api/v2/maintenance/cleanup-auditlog": put_CleanupAuditlog,
    "/api/v2/invoices/{id}/inbox": put_ForwardInvoice,
    "/api/v2/infos/{id}": put_UpdateInfo,
    "/api/v2/inboxes/{id}": put_UpdateInbox,
    "/api/v2/inboxes/{id}/outboxes/{outboxId}": put_UpdateInboxOutbox,
    "/api/v2/inboxes/{id}/invoices-doc-types": put_UpdateInboxInvoicesDocTypes,
    "/api/v2/inboxes/{id}/invoice-managers/{userId}": put_AddInboxInvoiceManager,
    "/api/v2/inboxes/{id}/auditors/{userId}": put_AddInboxAuditor,
    "/api/v2/inboxes/{id}/attachments-doc-types": put_UpdateInboxAttachmentDocTypes,
    "/api/v2/help-texts/{id}": put_UpdateHelpText,
    "/api/v2/document-types/{id}": put_UpdateDocumentType,
    "/api/v2/creditors/{id}": put_UpdateCreditor,
    "/api/v2/creditors/{id}/inboxes": put_UpdateCreditorInbox,
    "/api/v2/creditor": put_UpdateCreditor_1,
  },
  delete: {
    "/api/v2/users/{id}": delete_DeleteUser,
    "/api/v2/user/vacation": delete_DeleteUserVacation,
    "/api/v2/infos/{id}": delete_DeleteInfo,
    "/api/v2/inboxes/{id}": delete_DeleteInbox,
    "/api/v2/inboxes/{id}/outboxes/{outboxId}": delete_DeleteInboxOutbox,
    "/api/v2/inboxes/{id}/invoice-managers/{userId}": delete_DeleteInboxManager,
    "/api/v2/inboxes/{id}/auditors/{userId}": delete_DeleteInboxAuditor,
    "/api/v2/help-texts/{id}": delete_DeleteHelpText,
    "/api/v2/document-types/{id}": delete_DeleteDocumentType,
    "/api/v2/inboxes/{id}/inbox-auditors": delete_DeleteInboxAuditors,
    "/api/v2/credit-cards": delete_DeleteAllCreditCards,
    "/api/v2/business-cases": delete_DeleteAllBusinessCases,
    "/api/v2/users/{id}/works-at/{inboxId}": delete_DeleteUserWorksAt,
    "/api/v2/users/{id}/proxies/{proxyId}": delete_DeleteUserProxy,
    "/api/v2/users/{id}/inboxes/{inboxId}": delete_DeleteUserInbox,
    "/api/v2/user/proxied/{proxyId}": delete_DeleteProxiedUser,
    "/api/v2/inboxes/{id}/invoices-doc-types/{documentTypeId}": delete_DeleteInboxInvoicesDocTypes,
    "/api/v2/inboxes/{id}/attachments-doc-types/{documentTypeId}": delete_DeleteInboxAttachmentDocTypes,
    "/api/v2/creditors/{id}/inboxes/{inboxId}": delete_DeleteCreditorInbox,
    "/api/v2/creditor/inboxes/{inboxId}": delete_DeleteCreditorInboxes,
  },
  post: {
    "/api/v2/creditors/{id}/inboxes": post_AddCreditorInbox,
    "/api/v2/users": post_CreateUser,
    "/api/v2/users/{id}/impersonate": post_ImpersonateUser,
    "/api/v2/user/proxied": post_AddProxiedUser,
    "/api/v2/signup": post_IdentifyEmail,
    "/api/v2/signup/{token}": post_ConfirmEmail,
    "/api/v2/signup/creditor": post_SignupProcess,
    "/api/v2/password-reset/{email}": post_RequestPasswordReset,
    "/api/v2/maintenance/upload-index-file": post_UploadIndexFile,
    "/api/v2/invoices/{id}/route": post_RouteInvoice,
    "/api/v2/invoices/{id}/audit-rejection": post_AuditRejection,
    "/api/v2/invoices/{id}/audit-approval": post_AuditApproval,
    "/api/v2/invoices/{id}/attachments": post_AddInvoiceAttachment,
    "/api/v2/invoices/{id}/administration": post_Administrate,
    "/api/v2/invoices/expense-invoice": post_SubmitExpenseInvoice,
    "/api/v2/invoices/creditor-invoices": post_SubmitCreditorInvoices,
    "/api/v2/infos": post_CreateInfo,
    "/api/v2/inboxes": post_CreateInbox,
    "/api/v2/inboxes/{id}/outboxes": post_CreateInboxOutbox,
    "/api/v2/inboxes/{id}/inbox-auditors": post_ImportInboxAuditors,
    "/api/v2/help-texts": post_CreateHelpText,
    "/api/v2/files": post_CreateTempFile,
    "/api/v2/document-types": post_CreateDocumentType,
    "/api/v2/creditor/inboxes": post_UpdateCreditorInboxes,
    "/api/v2/credit-cards/import": post_ImportCreditCards,
    "/api/v2/business-cases/import": post_ImportBusinessCases,
    "/api/v2/auth/process-token": post_ProcessToken,
  },
  patch: {
    "/api/v2/user": patch_PatchCurrentUser,
    "/api/v2/invoices/{id}/assign-auditor": patch_AssignAuditor,
  },
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type GetEndpoints = EndpointByMethod["get"];
export type PutEndpoints = EndpointByMethod["put"];
export type DeleteEndpoints = EndpointByMethod["delete"];
export type PostEndpoints = EndpointByMethod["post"];
export type PatchEndpoints = EndpointByMethod["patch"];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
  body?: unknown;
  query?: Record<string, unknown>;
  header?: Record<string, unknown>;
  path?: Record<string, unknown>;
};

export type MutationMethod = "post" | "put" | "patch" | "delete";
export type Method = "get" | "head" | "options" | MutationMethod;

type RequestFormat = "json" | "form-data" | "form-url" | "binary" | "text";

export type DefaultEndpoint = {
  parameters?: EndpointParameters | undefined;
  response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
  operationId: string;
  method: Method;
  path: string;
  requestFormat: RequestFormat;
  parameters?: TConfig["parameters"];
  meta: {
    alias: string;
    hasParameters: boolean;
    areParametersRequired: boolean;
  };
  response: TConfig["response"];
};

type Fetcher = (
  method: Method,
  url: string,
  parameters?: EndpointParameters | undefined,
) => Promise<Endpoint["response"]>;

type RequiredKeys<T> = {
  [P in keyof T]-?: undefined extends T[P] ? never : P;
}[keyof T];

type MaybeOptionalArg<T> = RequiredKeys<T> extends never ? [config?: T] : [config: T];

// </ApiClientTypes>

// <ApiClient>
export class ApiClient {
  baseUrl: string = "";

  constructor(public fetcher: Fetcher) {}

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
    return this;
  }

  // <ApiClient.get>
  get<Path extends keyof GetEndpoints, TEndpoint extends GetEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("get", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.get>

  // <ApiClient.put>
  put<Path extends keyof PutEndpoints, TEndpoint extends PutEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("put", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.put>

  // <ApiClient.delete>
  delete<Path extends keyof DeleteEndpoints, TEndpoint extends DeleteEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("delete", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.delete>

  // <ApiClient.post>
  post<Path extends keyof PostEndpoints, TEndpoint extends PostEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("post", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.post>

  // <ApiClient.patch>
  patch<Path extends keyof PatchEndpoints, TEndpoint extends PatchEndpoints[Path]>(
    path: Path,
    ...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
  ): Promise<z.infer<TEndpoint["response"]>> {
    return this.fetcher("patch", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
  }
  // </ApiClient.patch>
}

export function createApiClient(fetcher: Fetcher, baseUrl?: string) {
  return new ApiClient(fetcher).setBaseUrl(baseUrl ?? "");
}

/**
 Example usage:
 const api = createApiClient((method, url, params) =>
   fetch(url, { method, body: JSON.stringify(params) }).then((res) => res.json()),
 );
 api.get("/users").then((users) => console.log(users));
 api.post("/users", { body: { name: "John" } }).then((user) => console.log(user));
 api.put("/users/:id", { path: { id: 1 }, body: { name: "John" } }).then((user) => console.log(user));
*/

// </ApiClient
