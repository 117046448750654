import {DeleteOutline} from "@mui/icons-material";
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardHeader,
	Dialog,
	DialogTitle,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import {
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumbs,
	ConfirmIconButton,
	EditForm,
	TextField,
	useFlag,
	View,
	ViewHeader,
} from "@variocube/app-ui";
import React, {useState} from "react";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {User, UserMutation, useUserApi} from "../../api";
import {DeleteButton} from "../../component/delete-button";
import {hasRoles, useAuthContext} from "../../context/auth";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {getUserDisplayName} from "../../utils/user";
import {UserForm} from "./form";
import {InboxTable} from "./inbox-table";

export function UserEdit() {
	const navigate = useNavigate();
	const {t, s} = useLocalization();
	const {
		getUser,
		updateUser,
		deleteUser,
		queryUserInboxes,
		queryUserWorksAt,
		updateUserInboxes,
		updateUserWorksAt,
		deleteUserInbox,
		deleteUserWorksAt,
		queryUserProxies,
		queryProxiedUser,
		addUserProxy,
		deleteUserProxy,
		impersonateUser,
	} = useUserApi();
	const {user} = useAuthContext();
	const id = useNumericParam("id");
	const {reloadAuth} = useAuthContext();

	const {loading, error, result} = useAsync(getUser, [id]);
	const {result: inboxes, execute: refreshInboxes} = useAsync(queryUserInboxes, [id]);
	const {result: worksAt, execute: refreshWorksAt} = useAsync(queryUserWorksAt, [id]);
	const {result: proxies, execute: refreshProxies} = useAsync(queryUserProxies, [id]);
	const {result: proxied, execute: refreshProxied} = useAsync(queryProxiedUser, [id]);

	async function handleDelete() {
		await deleteUser(id);
		navigate("../users", {replace: true});
	}

	async function handleSave(mutation: UserMutation) {
		await updateUser(id, mutation);
		navigate("../users");
	}

	async function handleAddInboxes(ids: number[]) {
		if (inboxes) {
			await updateUserInboxes(id, [...inboxes.map(i => i.id), ...ids]);
			await refreshInboxes(id);
		}
	}

	async function handleAddWorksAt(ids: number[]) {
		if (worksAt) {
			await updateUserWorksAt(id, [...worksAt.map(i => i.id), ...ids]);
			await refreshWorksAt(id);
		}
	}

	async function handleRemoveInbox(inboxId: number) {
		await deleteUserInbox(id, inboxId);
		await refreshInboxes(id);
	}

	async function handleRemoveWorksAt(inboxId: number) {
		await deleteUserWorksAt(id, inboxId);
		await refreshWorksAt(id);
	}

	async function handleDeleteProxy(id: number) {
		if (result !== undefined) {
			await deleteUserProxy(result.id, id);
			await refreshProxied(result.id);
		}
	}

	async function handleDeleteProxied(id: number) {
		if (result !== undefined) {
			await deleteUserProxy(id, result.id);
			await refreshProxies(result.id);
		}
	}

	const impersonate = useAsyncCallback(async () => {
		await impersonateUser(id);
		await reloadAuth();
		navigate("/");
	});

	const [proxyUser, setProxyUser] = useState("");
	const [proxiedByUser, setProxiedByUser] = useState("");
	const [proxyEdit, setProxyEdit, clearProxyEdit] = useFlag(false);

	async function handleAddUserProxy() {
		if (result !== undefined) {
			await addUserProxy(result.id, {proxyUser, proxiedByUser});
			await refreshProxies(result.id);
			await refreshProxied(result.id);
			clearProxyEdit();
			setProxyUser("");
			setProxiedByUser("");
		}
	}

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.admin")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`/users`}>{t("users.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{getUserDisplayName(result)}</BreadcrumbItem>
				<BreadcrumbItem>{t("common.edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={getUserDisplayName(result)}
				actions={
					<ButtonGroup>
						{hasRoles(user, ["SuperUser"]) && (
							<Button
								variant="outlined"
								color="inherit"
								onClick={impersonate.execute}
								disabled={impersonate.loading}
							>
								In Vertretung einloggen
							</Button>
						)}
						{hasRoles(user, ["SuperUser", "OebbAdministrator", "CreditorAdministrator"]) && (
							<DeleteButton
								title={t("users.delete.title")}
								onDelete={handleDelete}
								confirmMessage={t("users.delete.message")}
							/>
						)}
					</ButtonGroup>
				}
			/>
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<UserForm
							loading={loading}
							user={result}
							onSave={handleSave}
						/>
					</Grid>
					{["OebbAdministrator", "OebbUser"].includes(result?.role ?? "") && (
						<>
							<Grid item xs={12} md={6}>
								<InboxTable
									title={t("users.mailboxes.inboxes.title")}
									inboxes={inboxes ?? []}
									filter="MANAGEMENT_ASSIGNMENT"
									onAdd={handleAddInboxes}
									onRemove={handleRemoveInbox}
									assignLabel={t("users.mailboxes.inboxes.assign")}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<InboxTable
									title={t("users.mailboxes.worksAt.title")}
									inboxes={worksAt ?? []}
									filter="WORKS_AT_ASSIGNMENT"
									onAdd={handleAddWorksAt}
									onRemove={handleRemoveWorksAt}
									assignLabel={t("users.mailboxes.worksAt.assign")}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<ProxyTable
									title={t("users.proxies.proxiedBy")}
									subheader={t("users.proxies.proxiedByHint")}
									users={proxied ?? []}
									onDelete={handleDeleteProxy}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<ProxyTable
									title={t("users.proxies.title")}
									subheader={t("users.proxies.hint")}
									users={proxies ?? []}
									onDelete={handleDeleteProxied}
								/>
								<Button sx={{mt: 1}} variant="outlined" color="secondary" onClick={setProxyEdit}>
									{t("users.proxies.assign")}
								</Button>
								<Dialog open={proxyEdit} fullWidth maxWidth="sm">
									<DialogTitle>{t("users.proxies.assign")}</DialogTitle>
									<EditForm
										loading={false}
										onSave={handleAddUserProxy}
										onCancel={clearProxyEdit}
										labels={s("actions")}
									>
										<Grid container spacing={2} padding={2}>
											<Grid item xs={12} sm={6}>
												<TextField
													fullWidth
													required={!proxiedByUser}
													disabled={!!proxiedByUser}
													value={proxyUser}
													onChange={setProxyUser}
													type="email"
													label={t("users.proxies.title")}
													helperText={t("users.proxies.hint")}
												/>
											</Grid>
											<Grid item xs={12} sm={6}>
												<TextField
													fullWidth
													required={!proxyUser}
													disabled={!!proxyUser}
													value={proxiedByUser}
													onChange={setProxiedByUser}
													type="email"
													label={t("users.proxies.proxiedBy")}
													helperText={t("users.proxies.proxiedByHint")}
												/>
											</Grid>
										</Grid>
									</EditForm>
								</Dialog>
							</Grid>
						</>
					)}
				</Grid>
			</Box>
		</View>
	);
}

interface ProxyTableProps {
	title: string;
	subheader: string;
	users: User[];
	onDelete: (id: number) => Promise<void>;
}

function ProxyTable({title, subheader, users, onDelete}: ProxyTableProps) {
	const {t} = useLocalization();

	return (
		<Card>
			<CardHeader title={title} subheader={subheader} />
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>{t("common.name")}</TableCell>
						<TableCell>{t("common.email")}</TableCell>
						<TableCell>{t("common.phone")}</TableCell>
						<TableCell width={10}>&nbsp;</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{users.map(u => (
						<TableRow key={u.id}>
							<TableCell>{u.lastname}, {u.firstname}</TableCell>
							<TableCell>{u.email}</TableCell>
							<TableCell>{u.phone}</TableCell>
							<TableCell>
								<ConfirmIconButton
									title={t("users.proxies.revoke.title")}
									cancel={t("actions.cancel")}
									onConfirm={() => onDelete(u.id)}
									icon={<DeleteOutline />}
									color="error"
								>
									{t("users.proxies.revoke.prompt")}
								</ConfirmIconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Card>
	);
}
