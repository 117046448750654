import React, {type ReactNode} from 'react';
import {Typography} from './typography';

export function LabeledText({label, text}: { label: ReactNode, text: ReactNode }) {
    return (
        <div>
            <Typography variant="overline">{label}</Typography>
            <Typography>{text}</Typography>
        </div>
    );
}
