import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {HelpTextMutation} from "../../api";
import {useHelpTextApi} from "../../api/help-texts";
import {DeleteButton} from "../../component/delete-button";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {HelpTextForm} from "./form";

export function HelpTextEdit() {
	const {t} = useLocalization();
	const id = useNumericParam("id");
	const {getHelpText, updateHelpText, deleteHelpText} = useHelpTextApi();
	const navigate = useNavigate();

	const {loading, error, result} = useAsync(getHelpText, [id]);

	async function handleDelete() {
		await deleteHelpText(id);
		navigate(-1);
	}

	async function handleSave(helpText: HelpTextMutation) {
		await updateHelpText(id, helpText);
	}

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to="/help-texts">{t("helpTexts.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{result?.subject}</BreadcrumbItem>
				<BreadcrumbItem>{t("common.edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={result?.subject}
				actions={
					<DeleteButton
						title={t("helpTexts.delete.title")}
						confirmMessage={t("helpTexts.delete.message")}
						onDelete={handleDelete}
					/>
				}
			/>
			<HelpTextForm loading={loading} helpText={result} onSave={handleSave} />
		</View>
	);
}
