import {Autocomplete, ListItem, ListItemText} from "@mui/material";
import {ErrorAlert, TextField} from "@variocube/app-ui";
import React, {ReactNode, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {BusinessCase, BusinessCaseCategory, useBusinessCasesApi} from "../api";
import {useLocalization} from "../i18n";
import {createFilterOptions} from "@mui/base";

interface BusinessCaseSelectProps {
	label?: ReactNode;
	value: BusinessCase[];
	onChange: (value: BusinessCase[]) => any;
	required?: boolean;
	fullWidth?: boolean;
	disabled?: boolean;
	category: BusinessCaseCategory;
}

export function BusinessCaseSelect(props: Readonly<BusinessCaseSelectProps>) {
	const {label, value, onChange, required, fullWidth, category, disabled} = props;
	const {t} = useLocalization();
	const {queryBusinessCases} = useBusinessCasesApi();

	const query = useMemo(() => ({category}), [category]);
	const {loading, error, result} = useAsync(queryBusinessCases, [query]);

	const selectedCases = useMemo(() => {
		const selectedIds = value.map(businessCase => businessCase.id);
		return result?.filter(businessCase => selectedIds.includes(businessCase.id)) ?? [];
	}, [result, value]);

	if (error) {
		return <ErrorAlert error={error}/>;
	}

	const multiple = category == "Car" || category == "Default";

	return (
		<Autocomplete
			loading={loading}
			multiple={multiple}
			disabled={disabled}
			options={result ?? []}
			renderInput={(parameters) => (
				<TextField
					placeholder={t("hint.select")}
					required={required}
					label={label}
					{...parameters}
					inputProps={{
						...parameters.inputProps,
						required: multiple ? (required && value.length == 0) : required,
					}}
				/>
			)}
			value={multiple ? selectedCases : selectedCases[0]}
			onChange={(_, value) => {
				if (!value) {
					onChange([]);
				} else if (Array.isArray(value)) {
					onChange(value.filter(value => value != null));
				} else {
					onChange([value]);
				}
			}}
			fullWidth={fullWidth}
			isOptionEqualToValue={(option, value) => option?.id == value?.id}
			getOptionLabel={businessCase => businessCase.name ?? ""}
			renderOption={(props, businessCase) => (
				<ListItem {...props}>
					<ListItemText primary={businessCase.name} secondary={businessCase.description}/>
				</ListItem>
			)}
			filterOptions={filterOptions}
			autoSelect
			autoHighlight
		/>
	);
}

const filterOptions = createFilterOptions<BusinessCase>({
	stringify: option => option.name + " " + option.description,
});
