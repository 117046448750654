import {CardContent} from "@mui/material";
import {RadioGroup} from "@variocube/app-ui";
import React, {useEffect} from "react";
import {useUser} from "../../../../../context/auth";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

export function CreditCardStep({active, completed, invoice, onChange}: Readonly<StepProps>) {
	const user = useUser();
	const representedUser = user.represents.find(r => r.id == invoice.onBehalfOfUserId) ?? user;

	useEffect(() => {
		if (representedUser.creditCards.length == 1) {
			onChange({creditCardNumber: representedUser.creditCards[0]});
		}
		else if (representedUser.creditCards.length == 0) {
			onChange({creditCardNumber: undefined});
		}
	}, [representedUser]);

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title="Kreditkarte"
			activeChildren={
				<CardContent>
					<RadioGroup
						label="Kreditkarte"
						required
						options={representedUser.creditCards}
						value={invoice.creditCardNumber}
						onChange={creditCardNumber => onChange({creditCardNumber})}
					/>
				</CardContent>
			}
			completedChildren={<CardContent>{invoice.creditCardNumber}</CardContent>}
		/>
	);
}
