import {Invoice, User} from "../api";
import {hasRoles} from "../context/auth";

export function isInvoiceManager(user?: User, invoice?: Invoice) {
	return Boolean(
		// invoice must be defined
		invoice
			// user must be defined
			&& user
			// user must be SuperUser or OebbAdministrator, or an OebbUser that is a manager of the invoice's inbox
			&& (hasRoles(user, ["SuperUser", "OebbAdministrator"])
				|| hasRoles(user, ["OebbUser"]) && user.inboxes.find(inbox => inbox.id == invoice.inbox.id)),
	);
}
