import sanitize from "sanitize-html";

export function sanitizeHtml(html: string) {
	return sanitize(html, {
		allowedTags: ["b", "i", "em", "strong", "a", "p", "br", "ul", "ol", "li"],
		allowedAttributes: {
			"a": ["href"],
		},
	});
}
