import {Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {DataTable, DataTableColumn, useFlag, View, ViewHeader} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {BusinessCase, useBusinessCasesApi} from "../../../api";
import {DeleteButton} from "../../../component/delete-button";
import {FileUploadButton} from "../../../component/file-upload-button";
import {TooltipChip} from "../../../component/tooltip-chip";
import {useLocalization} from "../../../i18n";

export function BusinessCaseList() {
	const {t} = useLocalization();
	const {queryBusinessCases, deleteAllBusinessCases} = useBusinessCasesApi();

	const {result, loading, error, execute} = useAsync(async () => queryBusinessCases({}), []);
	const [importError, setImportError, clearImportError] = useFlag(false);

	const columns = useMemo<DataTableColumn<BusinessCase>[]>(() => [
		{
			field: "id",
			label: "Nr. GF",
			component: ({row}) => <Typography>{row.id}</Typography>,
		},
		{
			field: "caseNumber",
			label: t("businessCases.type"),
			component: ({row}) => <Typography>{row.category}</Typography>,
		},
		{
			field: "caseNumber",
			label: t("common.name"),
			component: ({row}) => <Typography>{row.name}</Typography>,
		},
		{
			field: "code",
			label: t("businessCases.code"),
			component: ({row}) => <Typography>{row.code}</Typography>,
		},
		{
			field: "badge",
			label: t("businessCases.flags"),
			component: ({row}) => (
				<Stack
					direction="row"
					gap={1}
				>
					{row.allowTip && <TooltipChip label="T" tooltip={t("businessCases.allowTip")} />}
					{row.emergency && <TooltipChip label="G" tooltip={t("businessCases.emergency")} />}
					{row.trip && <TooltipChip label="R" tooltip={t("businessCases.trip")} />}
					{row.car && <TooltipChip label="K" tooltip={t("businessCases.car")} />}
					{row.rentalCar && <TooltipChip label="KM" tooltip={t("businessCases.rentalCar")} />}
				</Stack>
			),
		},
	], [t]);

	async function handleImportBusinessCases(file: File) {
		clearImportError();

		const formData = new FormData();
		formData.append("file", file);

		const response = await fetch(`/api/v2/business-cases/import`, {
			method: "POST",
			body: formData,
		});

		if (response.status !== 201) {
			setImportError();
		}

		await execute();
	}

	async function handleDelete() {
		await deleteAllBusinessCases();
		await execute();
	}

	return (
		<View>
			<ViewHeader
				title={t("navigation.settingsGroup.businessCases")}
				actions={
					<ButtonGroup variant="outlined" disableElevation>
						<FileUploadButton
							label={t("businessCases.import")}
							onUpload={handleImportBusinessCases}
							variant="outlined"
							color="inherit"
						/>
						<DeleteButton
							title={t("actions.delete")}
							confirmMessage={t("businessCases.delete.message")}
							onDelete={handleDelete}
						/>
					</ButtonGroup>
				}
			/>
			<DataTable
				columns={columns}
				rows={result ?? []}
				loading={loading}
				error={error}
			/>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={importError}
				onClose={clearImportError}
			>
				<DialogTitle>Error</DialogTitle>
				<DialogContent>Failed to import data.</DialogContent>
				<DialogActions>
					<Button onClick={clearImportError}>{t("actions.close")}</Button>
				</DialogActions>
			</Dialog>
		</View>
	);
}
