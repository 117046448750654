import {SpringPageable} from "@variocube/app-ui";
import {TypeOf} from "zod";
import {get_QueryAuditLogs, useApiClient} from "./client";

export type AuditLogQuery = TypeOf<get_QueryAuditLogs["parameters"]>["query"];
export type AuditLogFilter = Omit<AuditLogQuery, keyof SpringPageable>;

export function useAuditLogsApi() {
	const api = useApiClient();

	return {
		queryAuditLogs: (query: AuditLogQuery) => api.get("/api/v2/audit-logs", {query}),
	};
}
