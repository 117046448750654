import {Card, LinearProgress} from "@mui/material";
import {LineChart} from "@mui/x-charts";
import {ErrorAlert, useStorage, View, ViewHeader} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {ReportingSetting, useReportingApi} from "../../../api/reporting";
import {useLocalization} from "../../../i18n";
import {ReportingSettingBar} from "./setting";

const DefaultSetting: ReportingSetting = {
	timeFrame: "Last24h",
	creditorId: undefined,
};

function useReportingSetting() {
	return useStorage<ReportingSetting>("ReportingSetting", DefaultSetting);
}

export function StatisticsView() {
	const {t} = useLocalization();
	const {queryReportingData} = useReportingApi();

	const [setting, setSetting] = useReportingSetting();

	const {loading, error, result} = useAsync(queryReportingData, [setting]);

	const {xAxis, series} = useMemo(() => {
		if (result) {
			return {
				series: [
					{label: "Logins", data: result?.map(group => group.metrics?.logins ?? null)},
					{label: "Hochgeladen", data: result?.map(group => group.metrics?.submittedInvoices ?? null)},
					{label: "Zugeordnet", data: result?.map(group => group.metrics?.routedInvoices ?? null)},
					{label: "Abgelehnt", data: result?.map(group => group.metrics?.rejectedInvoices ?? null)},
					{label: "Verarbeitet", data: result?.map(group => group.metrics?.processedInvoices ?? null)},
					{label: "Fehlgeschlagen", data: result?.map(group => group.metrics?.failedInvoices ?? null)},
				],
				xAxis: [{scaleType: "point", data: result?.map(group => group.group) ?? []} as const],
			};
		}
		else {
			return {
				series: [],
				xAxis: [],
			};
		}
	}, [result]);

	return (
		<View>
			<ViewHeader
				title={t("reporting.title")}
			/>
			<ReportingSettingBar
				value={setting}
				onChange={setSetting}
			/>
			{error && <ErrorAlert error={error} />}
			<Card>
				{loading && <LinearProgress />}
				<LineChart
					xAxis={xAxis}
					series={series}
					height={500}
				/>
			</Card>
		</View>
	);
}
