import {AssignmentOutlined} from "@mui/icons-material";
import {Card, CardContent, CardHeader, Link, List, ListItem, ListItemText, Stack} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Invoice, Person} from "../../../api";
import {LabeledText} from "../../../component/labeled-text";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {getPersonDisplayString, notBlank, notNull} from "../../../util";
import {getDuplicateDocumentType} from "../displays";

export function Overview({invoice, duplicates}: Readonly<{ invoice: Invoice; duplicates?: Invoice[] }>) {
	const {t} = useLocalization();

	const submittedByProxy = invoice.submittedBy?.id !== invoice.user?.id;

	return (
		<Card sx={{height: "100%"}}>
			<CardHeader
				title={<Typography startAdornment={<AssignmentOutlined />}>{t("invoices.details.title")}</Typography>}
			/>
			<CardContent>
				<Stack
					direction="column"
					gap={2}
				>
					{notNull(invoice.duplicateOf) && (
						<LabeledText
							label={t("invoices.details.duplicateOf", {
								documentType: getDuplicateDocumentType(invoice),
							})}
							text={
								<Link component={RouterLink} to={`/invoices/${invoice.duplicateOf?.id}`}>
									{invoice.duplicateOf?.id}
								</Link>
							}
						/>
					)}
					{(duplicates !== undefined && duplicates.length > 0) && (
						<LabeledText
							label={t("invoices.details.duplicates", {
								documentType: getDuplicateDocumentType(invoice),
							})}
							text={
								<List dense disablePadding>
									{duplicates.map(d => (
										<ListItem disablePadding key={d.id}>
											<Link component={RouterLink} to={`/invoices/${d.id}`}>{d.id}</Link>
										</ListItem>
									))}
								</List>
							}
						/>
					)}
					<LabeledText
						label="Web-ID"
						text={invoice.id}
					/>
					{notNull(invoice.creditor) && (
						<LabeledText
							label={t("invoices.details.creditor")}
							text={invoice.creditor.companyName}
						/>
					)}
					{invoice.contactOebb && (
						<LabeledText label={t("invoices.details.contactOebb")} text={invoice.contactOebb} />
					)}
					{notNull(invoice.person) && (
						<LabeledText
							label={t("invoices.details.contactPerson")}
							text={
								<List dense disablePadding>
									{notNull(invoice.person?.person) && (
										<ListItem disablePadding>
											{getPersonDisplayString(invoice.person?.person as Person)}
										</ListItem>
									)}
									{notNull(invoice.person?.email) && (
										<ListItem disablePadding>
											<Link
												href={`mailto:${invoice.person?.email?.email as string}`}
											>
												{invoice.person?.email?.email as string}
											</Link>
										</ListItem>
									)}
									{notNull(invoice.person?.phone) && (
										<ListItem disablePadding>
											<Link
												href={`tel:${invoice.person?.phone?.number as string}`}
											>
												{invoice.person?.phone?.number as string}
											</Link>
										</ListItem>
									)}
								</List>
							}
						/>
					)}
					{notNull(invoice.user) && (
						<LabeledText
							label={t("invoices.submitter")}
							text={
								<List dense disablePadding>
									<ListItem disablePadding>
										{invoice.user?.lastname}, {invoice.user?.firstname}
									</ListItem>
									<ListItem disablePadding>
										<Link
											href={`mailto:${invoice.user?.email}`}
										>
											{invoice.user?.email}
										</Link>
									</ListItem>
								</List>
							}
						/>
					)}
					{submittedByProxy && (
						<LabeledText
							label={t("invoices.details.proxy")}
							text={
								<List dense disablePadding>
									<ListItem disablePadding>
										{invoice.submittedBy?.lastname}, {invoice.submittedBy?.firstname}
									</ListItem>
									<ListItem disablePadding>
										<Link
											href={`mailto:${invoice.submittedBy?.email}`}
										>
											{invoice.submittedBy?.email}
										</Link>
									</ListItem>
								</List>
							}
						/>
					)}
					{notNull(invoice.routedBy) && (
						<LabeledText
							label={t("invoices.details.routedBy")}
							text={
								<List dense disablePadding>
									<ListItem disablePadding>
										{invoice.routedByUser?.firstname} {invoice.routedByUser?.lastname}
									</ListItem>
									<ListItem disablePadding>
										<Link
											href={`mailto:${invoice.routedByUser?.email}`}
										>
											{invoice.routedByUser?.email}
										</Link>
									</ListItem>
								</List>
							}
						/>
					)}
					{notNull(invoice.rejectedBy) && (
						<LabeledText
							label={t("invoices.details.rejectedBy")}
							text={
								<List dense disablePadding>
									<ListItem disablePadding>
										{invoice.rejectedByUser?.firstname} {invoice.rejectedByUser?.lastname}
									</ListItem>
									<ListItem disablePadding>
										<Link
											href={`mailto:${invoice.rejectedByUser?.email}`}
										>
											{invoice.rejectedByUser?.email}
										</Link>
									</ListItem>
								</List>
							}
						/>
					)}
					{notNull(invoice.auditors) && (invoice.auditors ?? []).length > 0 && (
						<LabeledText
							label={t("invoices.details.auditedBy")}
							text={
								<List dense disablePadding>
									{invoice.auditorUsers?.map(a => (
										<ListItem disablePadding key={a.email}>
											<ListItemText
												primary={<Typography>{a.firstname} {a.lastname}</Typography>}
												secondary={<Link href={`mailto:${a.email}`}>{a.email}</Link>}
											/>
										</ListItem>
									))}
								</List>
							}
						/>
					)}
					{notBlank(invoice.profitCenter) && (
						<LabeledText
							label={t("invoices.details.costCenter")}
							text={invoice.profitCenter}
						/>
					)}
					{notNull(invoice.orderNumbers) && (invoice.orderNumbers ?? []).length > 0 && (
						<LabeledText
							label={t("invoices.details.orderNumbers")}
							text={
								<List dense disablePadding>
									{invoice.orderNumbers?.map(o => (
										<ListItem disablePadding key={o}>
											{o}
										</ListItem>
									))}
								</List>
							}
						/>
					)}
					{notBlank(invoice.additionalInformation) && (
						<LabeledText
							label={t("invoices.details.additionalInformation")}
							text={invoice.additionalInformation}
						/>
					)}
					<LabeledText label={t("invoices.details.actualInbox")} text={invoice.inbox?.name} />
					{notNull(invoice.outbox) && (
						<LabeledText label={t("invoices.details.actualOutbox")} text={invoice.outbox?.name} />
					)}
				</Stack>
			</CardContent>
		</Card>
	);
}
