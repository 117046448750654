import {createApiFetcher, createQueryString} from "@variocube/app-ui";
import {useMemo} from "react";
import {createApiClient as createGeneratedApiClient, EndpointParameters, Method} from "./client.gen";

export function createApiClient() {
	const {fetch} = createApiFetcher("");

	return createGeneratedApiClient(async (method: Method, url: string, params: EndpointParameters | undefined) => {
		// build query string
		const qs = params?.query ? "?" + createQueryString(params.query) : "";

		// resolve path variables
		const resolvedUrl = url.replaceAll(/{[a-zA-Z]+}/g, (pathVariable) => {
			const pathVariableName = pathVariable.substring(1, pathVariable.length - 1);
			const value = params?.path && params?.path[pathVariableName];
			if (!value) {
				throw new Error(`Missing path variable ${pathVariableName}.`);
			}
			return encodeURIComponent(`${value}`);
		});

		const headers: HeadersInit = {
			"X-Requested-With": "XMLHttpRequest",
			...(params?.body ? {"Content-Type": "application/json"} : undefined),
		};

		const response = await fetch(resolvedUrl + qs, {
			method: method.toUpperCase(),
			body: params?.body ? JSON.stringify(params.body) : undefined,
			headers,
		});

		const contentType = response.headers.get("content-type");

		if (contentType && /application\/.*json/.test(contentType)) {
			return await response.json();
		}
	});
}

export function useApiClient() {
	return useMemo(() => createApiClient(), []);
}

export * from "./client.gen";
