import {Link} from "@mui/icons-material";
import {Alert, IconButton, Snackbar} from "@mui/material";
import {useFlag} from "@variocube/app-ui";
import React, {Fragment} from "react";
import {useLocalization} from "../i18n";

export function CopyLinkIconButton() {
	const {t} = useLocalization();
	const [copied, setCopied, clearCopied] = useFlag(false);

	async function copyLink() {
		await navigator.clipboard.writeText(window.location.href);
		setCopied();
	}

	return (
		<Fragment>
			<IconButton onClick={copyLink} title={t("common.copyLink")}>
				<Link />
			</IconButton>
			<Snackbar
				open={copied}
				autoHideDuration={6000}
				onClose={clearCopied}
			>
				<Alert severity="success" onClose={clearCopied}>
					{t("common.linkCopied")}
				</Alert>
			</Snackbar>
		</Fragment>
	);
}
