import {getName} from "i18n-iso-countries";
import React, {Fragment} from "react";
import {useLocalization} from "../i18n";

interface CountryProps {
	countryCode?: string;
}

export function Country({countryCode}: Readonly<CountryProps>) {
	const {language} = useLocalization();
	const name = countryCode && getName(countryCode, language);
	return <Fragment>{name}</Fragment>;
}
