import {createApiFetcher} from "@variocube/app-ui";
import {CreditCard, useApiClient} from "./client";

export function useCreditCardApi() {
	const api = useApiClient();
	const {fetch} = createApiFetcher("");

	return {
		importCreditCards: async (file: File) => {
			const formData = new FormData();
			formData.append("file", file);

			const response = await fetch(`/api/v2/credit-cards/import`, {
				method: "POST",
				body: formData,
			});

			return await response.json() as CreditCard[];
		},
		exportCreditCards: async () => {
			location.href = "/api/v2/credit-cards/export";
		},
		queryCreditCards: () => api.get("/api/v2/credit-cards"),
	};
}
