import {InsertLink} from "@mui/icons-material";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {TemporalFormat, tryParsePlainDate} from "@variocube/app-ui";
import React from "react";
import {Invoice, type InvoiceDate, type InvoiceReference, type InvoiceText, type PaymentTerms} from "../../../api";
import {LabeledText} from "../../../component/labeled-text";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {notBlank, notNull} from "../../../util";

interface ReferencesProperties {
	invoice: Invoice;
	defaultDates: InvoiceDate[];
	defaultReferences: InvoiceReference[];
	defaultTexts: InvoiceText[];
}

export function References({invoice, defaultDates, defaultReferences, defaultTexts}: ReferencesProperties) {
	const {t, e} = useLocalization();
	return (
		<Card>
			<CardHeader
				title={<Typography startAdornment={<InsertLink/>}>{t("invoices.details.headerData")}</Typography>}
			/>
			<CardContent>
				<Grid container spacing={2}>
					{notNull(invoice.invoiceNumber) && (
						<Grid item xs={12} md={4}>
							<LabeledText
								label={t("invoices.details.invoiceNumber")}
								text={invoice.invoiceNumber?.reference}
							/>
						</Grid>
					)}
					{notNull(invoice.invoiceDate) && (
						<Grid item xs={12} md={4}>
							<LabeledText
								label={t("invoices.details.invoiceDate")}
								text={<TemporalFormat value={tryParsePlainDate(invoice.invoiceDate?.date)}/>}
							/>
						</Grid>
					)}
					{defaultDates.map(d => (
						<Grid key={d.label} item xs={12} md={4}>
							<LabeledText label={d.label} text={<TemporalFormat value={tryParsePlainDate(d.date)}/>}/>
						</Grid>
					))}
					{defaultReferences.map(r => (
						<Grid key={r.label} item xs={12} md={4}>
							<LabeledText label={r.label} text={r.reference}/>
						</Grid>
					))}
					{defaultTexts.map(text => (
						<Grid key={text.label} item xs={12} md={4}>
							<LabeledText label={text.label} text={text.text}/>
						</Grid>
					))}
					{notBlank(invoice.deliveryTerms) && (
						<Grid item xs={12} sm={6} md={8}>
							<LabeledText label={t("invoices.details.deliveryTerms")} text={invoice.deliveryTerms}/>
						</Grid>
					)}
					{notNull(invoice.paymentTermsType) && (
						<Grid item xs={12} sm={6} md={4}>
							<LabeledText
								label={t("invoices.details.paymentTerms")}
								text={`${invoice.paymentTerms} ${
									e("chronoUnit", invoice.paymentTermsType as PaymentTerms)
								}`}
							/>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
}
