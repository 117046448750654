import {Alert, AlertTitle, Box, Grid} from "@mui/material";
import React from "react";
import {useLocalization} from "../../../../i18n";

export function SubmitExpenseInvoiceInfo() {
	const {t} = useLocalization();
	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Alert severity="info">
						<AlertTitle>{t("invoices.new.expense.info.title")}</AlertTitle>
						<ol>
							<li>
								{t("invoices.new.expense.info.step1")}
							</li>
							<li>
								{t("invoices.new.expense.info.step2")}
							</li>
							<li>
								{t("invoices.new.expense.info.step3")}
							</li>
							<li>
								{t("invoices.new.expense.info.step4")}
							</li>
						</ol>
					</Alert>
				</Grid>
				<Grid item xs={12} md={4}>
					<Alert severity="warning" sx={{height: "100%"}}>
						<AlertTitle>{t("invoices.new.expense.warning1.title")}</AlertTitle>
						{t("invoices.new.expense.warning1.message")}
					</Alert>
				</Grid>
				<Grid item xs={12} md={4}>
					<Alert severity="warning" sx={{height: "100%"}}>
						<AlertTitle>{t("invoices.new.expense.warning2.title")}</AlertTitle>
						{t("invoices.new.expense.warning2.message")}
					</Alert>
				</Grid>
				<Grid item xs={12} md={4}>
					<Alert severity="warning" sx={{height: "100%"}}>
						<AlertTitle>{t("invoices.new.expense.warning3.title")}</AlertTitle>
						{t("invoices.new.expense.warning3.message")}
					</Alert>
				</Grid>
			</Grid>
		</Box>
	);
}
