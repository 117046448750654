import AddIcon from "@mui/icons-material/Add";
import {Button, ButtonGroup, Link, Typography} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, DataTable, DataTableColumn, View, ViewHeader} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {Info, useInfoApi} from "../../../api";
import {useLocalization} from "../../../i18n";
import {useInfoFilter} from "./filter";

export function InfoBoxList() {
	const {t} = useLocalization();
	const {queryInfos} = useInfoApi();

	const [filter] = useInfoFilter();

	const {error, loading, result} = useAsync(queryInfos, [filter]);

	const columns = useMemo<DataTableColumn<Info>[]>(() => [
		{
			label: t("infoboxes.topic"),
			field: "subject",
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/admin/infobox/${row.id}`} color="secondary">
						{row.subject}
					</Link>
				</Typography>
			),
		},
		{
			label: t("infoboxes.audience"),
			field: "audience",
		},
		{
			label: t("infoboxes.displayType"),
			field: "displayType",
		},
		{
			label: t("infoboxes.start"),
			field: "start",
		},
		{
			label: t("infoboxes.end"),
			field: "end",
		},
		{
			label: t("infoboxes.language"),
			field: "language",
		},
	], [t]);

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbItem>{t("infoboxes.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={t("infoboxes.title")}
				actions={
					<ButtonGroup variant="outlined" disableElevation>
						<Button
							component={RouterLink}
							to="/admin/infobox/new"
							startIcon={<AddIcon />}
							color="inherit"
						>
							{t("infoboxes.create.title")}
						</Button>
					</ButtonGroup>
				}
			/>
			<DataTable
				loading={loading}
				error={error}
				rows={result ?? []}
				columns={columns}
			/>
		</View>
	);
}
