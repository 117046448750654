import {defined, useStorage} from "@variocube/app-ui";
import {useCallback} from "react";
import {useInvoicesApi} from "../../api";
import {useInvoiceFilter} from "./filter";
import {useInvoicePage} from "./list";

export function useInvoiceNavigator() {
	const {listInvoices} = useInvoicesApi();
	const [filter] = useInvoiceFilter();
	const {sortField, sortDirection, pageSize} = useInvoicePage();
	const [navigator, setNavigator] = useStorage("InvoiceNavigator", {
		page: 0,
		ids: [] as number[],
		last: true,
	});

	const list = useCallback((page: number) => {
		return listInvoices({
			...filter,
			page,
			size: pageSize ?? 10,
			sort: sortField ? [`${sortField},${sortDirection || "asc"}`] : [],
		});
	}, [filter, pageSize, sortField, sortDirection]);

	async function getInvoiceNavigator(id: number) {
		try {
			const current = await determinateCurrentPage(id);
			if (current === undefined || current.ids.length < 3) return undefined;

			// index is guaranteed to be larger than -1, otherwise determinateCurrentPage would have return undefined
			const index = current.ids.indexOf(id);

			let prevId: number | undefined;
			// invoice is first in the current list
			// - page is first in list => prev is undefined
			// - else jump back 1 page => prev is last element
			if (index === 0) {
				if (current.page > 0) {
					const page = current.page - 1;
					const {content} = await list(page);
					prevId = content?.pop()?.id;
				}
			}
			else {
				prevId = current.ids[index - 1];
			}

			let nextId: number | undefined;
			// invoice is last in the current list
			// - page is last in list => next is undefined
			// - else jump to next page => next is first element
			if (index === current.ids.length - 1) {
				if (!current.last) {
					const page = current.page + 1;
					const {content} = await list(page);
					nextId = content?.shift()?.id;
				}
			}
			else {
				nextId = current.ids[index + 1];
			}

			return {prevId, nextId};
		}
		catch (err) {
			console.error("Failed to retrieve previous invoice", err);
		}
		return undefined;
	}

	async function findInvoiceOnPage(page: number, id: number) {
		const {content, last = false} = await list(page);
		if (content?.find(i => i.id === id)) {
			return {page, ids: content.map(i => i.id).filter(defined), last};
		}
	}

	async function determinateCurrentPage(id: number) {
		// in case the invoice is within the page
		const currentIndex = navigator.ids.indexOf(id);
		if (currentIndex > -1) {
			return navigator;
		}

		// otherwise, look up for the page that contains the id
		// only consider the previous and following page to avoid
		// many lookups
		const data = await findInvoiceOnPage(navigator.page, id)
			|| await findInvoiceOnPage(navigator.page + 1, id)
			|| await findInvoiceOnPage(navigator.page - 1, id);

		if (data) {
			setNavigator(data);
			return data;
		}

		return undefined;
	}

	return {
		setNavigator,
		getInvoiceNavigator,
	};
}
