import {styled} from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {Button, ButtonProps} from "@mui/material";
import React, {ChangeEvent} from "react";

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

interface FileUploadButtonProps extends Pick<ButtonProps, 'variant'|'color'>{
	label: string;
	onUpload: (file: File) => void;
	accept?: string;
	disabled?: boolean;
}

export function FileUploadButton({label, onUpload, accept, disabled, ...props}: FileUploadButtonProps) {

	function handleChange(ev: ChangeEvent<HTMLInputElement>) {
		const files = ev.target.files;
		if (files !== null && files.length > 0) {
			onUpload(files[0]);
		}
	}

	return (
		<Button
			component="label"
			role={undefined}
			tabIndex={-1}
			startIcon={<CloudUploadIcon />}
			disabled={disabled}
			{...props}
		>
			{label}
			<VisuallyHiddenInput
				type="file"
				onChange={handleChange}
				accept={accept}
			/>
		</Button>
	)
}
