import {AttachFile} from "@mui/icons-material";
import {CurrencyFormat, DataTableColumn, TemporalFormat, tryParseInstant} from "@variocube/app-ui";
import React, {Fragment, useMemo} from "react";
import {Invoice, InvoiceSummary} from "../../api";
import {InvoiceLinkChip} from "../../component/invoice-link-chip";
import {TFunc, useLocalization} from "../../i18n";
import {notNull} from "../../util";
import {getInvoiceDisplayString, getSubmitterEmail, getSubmitterName} from "./displays";
import {InvoiceStateChip} from "./invoice-state-chip";

function createIdColumn(t: TFunc) {
	return {
		field: "id",
		label: t("invoices.slip"),
		sortable: true,
		default: true,
		component: ({row}: { row: Invoice | InvoiceSummary }) => (
			<InvoiceLinkChip id={row.id} invoiceType={row.invoiceType}/>
		),
	};
}

function createStateColumn(t: TFunc) {
	return {
		field: "state",
		label: t("common.status"),
		sortable: true,
		default: true,
		component: ({row}: { row: Invoice | InvoiceSummary }) => (
			<InvoiceStateChip
				invoiceState={row.invoiceState}
			/>
		),
	};
}

function createSubmitterColumn(t: TFunc) {
	return {
		field: "submitter",
		label: t("invoices.submitter"),
		default: true,
		component: ({row}: { row: Invoice | InvoiceSummary }) => (
			<Fragment>
				<div>{getSubmitterName(row)}</div>
				<div>{getSubmitterEmail(row)}</div>
			</Fragment>
		),
	};
}

function createRecipientColumn(t: TFunc) {
	return {
		field: "inbox.name",
		label: t("invoices.recipient"),
		default: true,
		component: ({row}: { row: Invoice | InvoiceSummary }) => <Fragment>{row.inbox?.name}</Fragment>,
	};
}

function createInvoiceColumn(t: TFunc) {
	return {
		field: "invoice",
		label: t("invoices.singular"),
		default: true,
		component: ({row}: { row: Invoice | InvoiceSummary }) => (
			<Fragment>
				{getInvoiceDisplayString(row)}
				{Boolean(row.attachments?.length) && (
					<AttachFile
						fontSize="small"
						sx={{verticalAlign: "text-bottom"}}
					/>
				)}
			</Fragment>
		),
	};
}

function createAmountColumn(t: TFunc) {
	return {
		field: "amount",
		label: t("common.amount"),
		default: true,
		component: ({row}: { row: Invoice | InvoiceSummary }) => (
			notNull(row.gross)
				? <CurrencyFormat value={row.gross} currency={row.currency ?? "EUR"}/>
				: <Fragment>laut Beleg</Fragment>
		),
	};
}

function createSubmittedColumn(t: TFunc) {
	return {
		field: "submitted",
		label: t("invoices.submittedDate"),
		default: true,
		component: ({row}: { row: Invoice | InvoiceSummary }) => (
			<TemporalFormat
				value={tryParseInstant(row.submitted)}
			/>
		),
	};
}

export function useInvoiceColumns(): ReadonlyArray<DataTableColumn<InvoiceSummary>> {
	const {t} = useLocalization();
	return useMemo(() => [
		createIdColumn(t),
		createStateColumn(t),
		createSubmitterColumn(t),
		createRecipientColumn(t),
		createInvoiceColumn(t),
		createAmountColumn(t),
		createSubmittedColumn(t),
	], [t]);
}

export function useDuplicateInvoiceColumns(): ReadonlyArray<DataTableColumn<Invoice | InvoiceSummary>> {
	const {t} = useLocalization();
	return useMemo(() => [
		createIdColumn(t),
		createSubmitterColumn(t),
		createInvoiceColumn(t),
		createSubmittedColumn(t),
	], [t]);
}

export function useSubmittedInvoiceColumns(): ReadonlyArray<DataTableColumn<Invoice | InvoiceSummary>> {
	const {t} = useLocalization();
	return useMemo(() => [
		createIdColumn(t),
		createStateColumn(t),
		createRecipientColumn(t),
		createInvoiceColumn(t),
		createSubmittedColumn(t),
	], [t]);
}
