import {Alert, AlertTitle, Stack, Typography} from "@mui/material";
import {DataTable} from "@variocube/app-ui";
import React from "react";
import {Invoice} from "../../../../api";
import {useSubmittedInvoiceColumns} from "../../columns";

interface SubmitExpenseInvoiceSuccessProps {
	created: Invoice[];
}

export function SubmitExpenseInvoiceSuccess({created}: Readonly<SubmitExpenseInvoiceSuccessProps>) {
	const columns = useSubmittedInvoiceColumns();

	return (
		<Stack spacing={2}>
			<Alert>
				<AlertTitle>Beleg wurde eingereicht</AlertTitle>
				<Typography paragraph>
					Vielen Dank! Wir haben Ihren Beleg erhalten und zur weiteren Verarbeitung weitergeleitet.
				</Typography>
			</Alert>
			<DataTable columns={columns} rows={created} />
		</Stack>
	);
}
