import {createQueryString, SpringPageable} from "@variocube/app-ui";
import {TypeOf} from "zod";
import {
	AttachmentSubmission,
	CreditorInvoiceSubmission,
	ExpenseInvoiceSubmission,
	get_ExportInvoices,
	get_QueryInvoices,
	InvoiceAdministrationData,
	InvoiceApproval,
	InvoiceForwarding,
	InvoiceRejection,
	InvoiceRouting,
	InvoiceState,
	InvoiceType,
	useApiClient,
} from "./client";

export type InvoiceQuery = TypeOf<get_QueryInvoices["parameters"]>["query"];
export type InvoiceFilter = Omit<InvoiceQuery, keyof SpringPageable>;
export type ExportInvoiceQuery = TypeOf<get_ExportInvoices["parameters"]>["query"];

export const INVOICE_TYPES: InvoiceType[] = [
	"CreditCardInvoice",
	"CreditorInvoice",
	"ExpensesInvoice",
	"CreditorCreditNote",
];

export const INVOICE_STATES: InvoiceState[] = [
	"ARCHIVED",
	"AUDITED",
	"ERROR",
	"CHECKED",
	"OCR_FAILED",
	"OCR_SUCCESSFUL",
	"OEBB_REJECTED",
	"PAYED_OUT",
	"PROCESSED",
	"ROUTED",
	"SUBMITTED",
	"UPLOADED",
];

export function useInvoicesApi() {
	const api = useApiClient();
	return {
		listInvoices: (query: InvoiceQuery) =>
			api.get(`/api/v2/invoices`, {
				query,
			}),

		exportInvoices: async (filter: ExportInvoiceQuery) => {
			const response = await window.fetch(`/api/v2/invoices/export?${createQueryString(filter)}`);
			if (!response.ok) {
				throw new Error("Request failed.");
			}
			return await response.blob();
		},

		listProfitCenterHistory: () => api.get("/api/v2/invoices/profit-center-history"),

		listAuditorHistory: () => api.get("/api/v2/invoices/auditor-history"),

		getInvoice: (id: number) => api.get(`/api/v2/invoices/{id}`, {path: {id}}),

		getInvoiceDuplicates: (id: number) => api.get(`/api/v2/invoices/{id}/duplicates`, {path: {id}}),

		getIfNr: (id: number) => api.get(`/api/v2/invoices/{id}/ifnr`, {path: {id}}),

		getHistory: (id: number) => api.get(`/api/v2/invoices/{id}/history`, {path: {id}}),

		addInvoiceAttachments: (id: number, attachments: AttachmentSubmission[]) =>
			api.post("/api/v2/invoices/{id}/attachments", {
				path: {id},
				body: attachments,
			}),

		approveInvoice: (id: number, approval: InvoiceApproval) =>
			api.post("/api/v2/invoices/{id}/audit-approval", {
				path: {id},
				body: approval,
			}),

		rejectInvoice: (id: number, rejection: InvoiceRejection) =>
			api.post("/api/v2/invoices/{id}/audit-rejection", {
				path: {id},
				body: rejection,
			}),

		routeInvoice: (id: number, request: InvoiceRouting) =>
			api.post(`/api/v2/invoices/{id}/route`, {
				path: {id},
				body: request,
			}),

		forwardInvoice: (id: number, forwarding: InvoiceForwarding) =>
			api.put("/api/v2/invoices/{id}/inbox", {
				path: {id},
				body: forwarding,
			}),

		administrateInvoice: (id: number, request: InvoiceAdministrationData) =>
			api.post(`/api/v2/invoices/{id}/administration`, {
				path: {id},
				body: request,
			}),
		assignAuditor: (id: number, auditorEmail: string) =>
			api.patch(`/api/v2/invoices/{id}/assign-auditor`, {
				path: {id},
				query: {auditorEmail},
			}),
		getInvoiceXml: (id: number) => api.get(`/api/v2/invoices/{id}/xml`, {path: {id}}),

		submitExpenseInvoice: (request: ExpenseInvoiceSubmission) =>
			api.post(`/api/v2/invoices/expense-invoice`, {body: request}),

		submitCreditorInvoices: (request: CreditorInvoiceSubmission[]) =>
			api.post("/api/v2/invoices/creditor-invoices", {body: request}),
	};
}
