import {CardContent, FormLabel, Grid} from "@mui/material";
import {EditForm, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {HelpText, HelpTextMutation, Language} from "../../api";
import {LanguageSelect} from "../../component/language-select";
import {NoteEditor} from "../../component/note-editor";
import {useLocalization} from "../../i18n";

interface HelpTextFormProps {
	loading?: boolean;
	helpText?: Partial<HelpText>;
	onSave: (helpTextMutation: HelpTextMutation) => Promise<any>;
}

export function HelpTextForm({loading, helpText, onSave}: Readonly<HelpTextFormProps>) {
	const {s, t} = useLocalization();
	const navigate = useNavigate();

	const [subject, setSubject] = useState("");
	const [language, setLanguage] = useState<Language>("de");
	const [helpKey, setHelpKey] = useState("");
	const [body, setBody] = useState("");

	useEffect(() => {
		if (helpText) {
			setSubject(helpText.subject ?? "");
			setBody(helpText.body ?? "");
			setHelpKey(helpText.helpKey ?? "");
			setLanguage(helpText.language ?? "de");
		}
	}, [helpText]);

	function handleSave() {
		return onSave({
			subject,
			helpKey,
			body,
			language,
		});
	}

	return (
		<EditForm loading={loading ?? false} onSave={handleSave} onCancel={() => navigate(-1)} labels={s("actions")}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							label={t("helpTexts.helpKey")}
							fullWidth
							value={helpKey}
							onChange={setHelpKey}
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<LanguageSelect value={language} onChange={setLanguage} fullWidth />
					</Grid>
					<Grid item xs={12}>
						<TextField
							label={t("helpTexts.subject")}
							fullWidth
							value={subject}
							onChange={setSubject}
							required
						/>
					</Grid>
					<Grid item xs={12}>
						<FormLabel>{t("helpTexts.body")}</FormLabel>
						<NoteEditor text={body} onChange={setBody} />
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}
