import {createTheme, CssBaseline, responsiveFontSizes, ThemeProvider as MuiThemeProvider} from "@mui/material";
import {JetbrainsMonoFont, RobotoFont} from "@variocube/app-ui";
import React, {PropsWithChildren} from "react";

export function ThemeProvider({children}: PropsWithChildren<any>) {
	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<RobotoFont />
			<JetbrainsMonoFont />
			{children}
		</MuiThemeProvider>
	);
}

const theme = responsiveFontSizes(createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#e2002a",
		},
		secondary: {
			main: "#00A1E3",
		},
		error: {
			main: "#951600",
		},
		background: {
			default: "#f6f6f6",
		},
	},
	shape: {
		borderRadius: 0,
	},
	typography: {
		h1: {
			fontSize: 56,
		},
		h2: {
			fontSize: 44,
		},
		h3: {
			fontSize: 32,
		},
	},
}));
