import {Settings} from "@mui/icons-material";
import {Button, Card, CardContent, CardHeader, Divider, FormLabel, Grid, List, ListItem, Stack} from "@mui/material";
import {TextField, useFlag} from "@variocube/app-ui";
import React, {type FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {Invoice, useInvoicesApi, UserRef} from "../../../api";
import {ModalForm} from "../../../component/modal-form";
import {Typography} from "../../../component/typography";
import {notNull} from "../../../util";

function reloadInvoice() {
	window.location.reload();
}

export function Administration({invoice, superUserCanAudit}: { invoice: Invoice; superUserCanAudit: boolean }) {
	const [reset, setReset, clearReset] = useFlag(false);
	const [assignAuditor, setAssignAuditor, clearAssignAuditor] = useFlag(false);
	const [ocrFailed, setOcrFailed, clearOcrFailed] = useFlag(false);
	const [duplication, setDuplication, clearDuplication] = useFlag(false);
	const [deletion, setDeletion, clearDeletion] = useFlag(false);

	return (
		<>
			<Card>
				<CardHeader title={<Typography startAdornment={<Settings />}>Administrative Aufgaben</Typography>} />
				<CardContent>
					<Stack spacing={2}>
						<Typography color="textSecondary">
							Nur Benutzer mit der Rolle SuperUser können diese administrativen Aufgaben ausführen.
						</Typography>
						{!notNull(invoice.filesDeleted) && (
							<>
								<Button
									variant="outlined"
									color="warning"
									fullWidth
									onClick={setDuplication}
								>
									Duplizieren
								</Button>
								<Button
									variant="outlined"
									color="warning"
									fullWidth
									onClick={setOcrFailed}
								>
									Verarbeitung fehlgeschlagen
								</Button>
								<Button
									variant="outlined"
									color="warning"
									fullWidth
									onClick={setReset}
								>
									Status zurücksetzen
								</Button>
							</>
						)}
						{superUserCanAudit && (
							<Button
								variant="outlined"
								color="warning"
								fullWidth
								onClick={setAssignAuditor}
							>
								Änderung Allgemeiner Prüfer
							</Button>
						)}
						<Button
							variant="outlined"
							color="info"
							fullWidth
							component={RouterLink}
							to={`/invoices/${invoice.id}/xml`}
						>
							XML Generieren
						</Button>
						<Button
							variant="outlined"
							color="error"
							fullWidth
							onClick={setDeletion}
						>
							Löschen
						</Button>
					</Stack>
				</CardContent>
			</Card>

			<ResetForm
				open={reset}
				invoiceId={invoice.id}
				onCancel={clearReset}
				onSubmit={reloadInvoice}
			/>

			<AssignAuditorForm
				open={assignAuditor}
				invoiceId={invoice.id}
				auditors={invoice.auditorUsers ?? []}
				onCancel={clearAssignAuditor}
				onSubmit={reloadInvoice}
			/>

			<ConfirmOcrFailureForm
				open={ocrFailed}
				invoiceId={invoice.id}
				onCancel={clearOcrFailed}
				onSubmit={reloadInvoice}
			/>

			<ConfirmDuplicateForm
				open={duplication}
				invoiceId={invoice.id}
				onCancel={clearDuplication}
			/>

			<ConfirmDeletionForm
				open={deletion}
				invoiceId={invoice.id}
				onCancel={clearDeletion}
			/>
		</>
	);
}

interface ModalFormProperties {
	open: boolean;
	invoiceId: number;
	onCancel: () => void;
	onSubmit: () => void;
}

function ResetForm({open, invoiceId, onCancel, onSubmit}: ModalFormProperties) {
	const {administrateInvoice} = useInvoicesApi();

	const {loading, execute} = useAsyncCallback(administrateInvoice);

	const [justification, setJustification] = useState("");

	async function handleSubmit(event_: FormEvent) {
		event_.preventDefault();

		await execute(invoiceId, {action: "reset", justification});
		onSubmit();
	}

	return (
		<ModalForm
			open={open}
			title="Rechnung zurücksetzen"
			onSubmit={handleSubmit}
			onCancel={onCancel}
			submitLabel="Status zurücksetzen"
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>
						Wenn eine Rechnung zurückgesetzt wird, dann ist sie im gleichen Status wie direkt nach dem
						Upload durch den Kreditor oder den Bedarfsträger. Die Rechnung wird erneut verarbeitet.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<FormLabel required>Begründung</FormLabel>
					<TextField
						fullWidth
						multiline
						required
						rows={4}
						value={justification}
						onChange={setJustification}
						disabled={loading}
					/>
				</Grid>
			</Grid>
		</ModalForm>
	);
}

function AssignAuditorForm({open, invoiceId, onCancel, onSubmit, auditors}: ModalFormProperties & {
	auditors: UserRef[];
}) {
	const {assignAuditor} = useInvoicesApi();

	const {loading, execute} = useAsyncCallback(assignAuditor);

	const [auditorEmail, setAuditorEmail] = useState("");

	async function handleSubmit(event_: FormEvent) {
		event_.preventDefault();

		await execute(invoiceId, auditorEmail);
		onSubmit();
	}

	return (
		<ModalForm
			open={open}
			title="Rechnung zurücksetzen"
			onSubmit={handleSubmit}
			onCancel={onCancel}
			submitLabel="Ändern"
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography gutterBottom>
						Aktuell sind folgende Allgemeine Prüfer zugewiesen:
					</Typography>
					<List>
						{auditors.map(a => <ListItem key={a.email}>{a.email}</ListItem>)}
					</List>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<FormLabel required>E-Mail Adresse Allgemeiner Prüfer</FormLabel>
					<TextField
						fullWidth
						required
						value={auditorEmail}
						onChange={setAuditorEmail}
						disabled={loading}
					/>
				</Grid>
			</Grid>
		</ModalForm>
	);
}

function ConfirmOcrFailureForm({open, invoiceId, onCancel, onSubmit}: ModalFormProperties) {
	const {administrateInvoice} = useInvoicesApi();

	const {loading, execute} = useAsyncCallback(administrateInvoice);

	const [justification, setJustification] = useState("");

	async function handleSubmit(event_: FormEvent) {
		event_.preventDefault();

		await execute(invoiceId, {action: "ocrFailed", justification});
		onSubmit();
	}

	return (
		<ModalForm
			open={open}
			title="Verarbeitung fehlgeschlagen"
			onSubmit={handleSubmit}
			onCancel={onCancel}
			submitLabel="Verarbeitung fehlgeschlagen"
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>
						Mit dieser Funktion setzen Sie die Rechnung auf &quot;Verarbeitung fehlgeschlagen&quot;, es wird
						der gleiche Prozess ausgelöst, als ob diese Rechnung im Prozess beim Scan-Dienstleister
						ausgeschieden wäre.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<FormLabel required>Begründung</FormLabel>
					<TextField
						fullWidth
						multiline
						required
						rows={4}
						value={justification}
						onChange={setJustification}
						disabled={loading}
					/>
				</Grid>
			</Grid>
		</ModalForm>
	);
}

function ConfirmDuplicateForm({open, invoiceId, onCancel}: Omit<ModalFormProperties, "onSubmit">) {
	const {administrateInvoice} = useInvoicesApi();

	const {loading, execute} = useAsyncCallback(administrateInvoice);

	const [justification, setJustification] = useState("");

	async function handleSubmit(event_: FormEvent) {
		event_.preventDefault();

		const id = await execute(invoiceId, {action: "duplicate", justification});
		if (id === undefined) {
			onCancel();
		}
		else {
			window.location.href = `/invoices/${id}`;
		}
	}

	return (
		<ModalForm
			open={open}
			title="Rechnung duplizieren"
			onSubmit={handleSubmit}
			onCancel={onCancel}
			submitLabel="Duplizieren"
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>
						Rechnungen die z.B. seitens Scanpoint nicht verarbeitet werden können, kann man mit diesem Tool
						duplizieren und erneut in den Workflow einkippen. Die duplizierte Rechnung ist mit (dieser)
						Originalrechnung verknüpft und wird mit der Dokumentenklasse RXQ ausgezeichnet. Das Duplikat
						wird auch mit Verarbeitungsfehlern in OESAP importiert und muss dort manuell bearbeitet werden.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<FormLabel required>Begründung</FormLabel>
					<TextField
						fullWidth
						multiline
						required
						rows={4}
						value={justification}
						onChange={setJustification}
						disabled={loading}
					/>
				</Grid>
			</Grid>
		</ModalForm>
	);
}

function ConfirmDeletionForm({open, invoiceId, onCancel}: Omit<ModalFormProperties, "onSubmit">) {
	const navigate = useNavigate();
	const {administrateInvoice} = useInvoicesApi();

	const {loading, execute} = useAsyncCallback(administrateInvoice);

	const [justification, setJustification] = useState("");

	async function handleSubmit(event_: FormEvent) {
		event_.preventDefault();

		await execute(invoiceId, {action: "delete", justification});

		navigate("/invoices", {replace: true});
	}

	return (
		<ModalForm
			open={open}
			title="Rechnung löschen"
			onSubmit={handleSubmit}
			onCancel={onCancel}
			submitLabel="Rechnung löschen"
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>
						Wenn eine Rechnung gelöscht wird, dann werden sowohl alle damit im Zusammenhang stehenden
						Datensätze, als auch die Rechnungsbelege (sofern noch vorhanden) aus der Webplattform gelöscht.
						Alle zur Rechnung gehörenden Audit-Log-Einträge bleiben bestehen, ebenso wird ein weiterer
						Audit-Log-Eintrag über den Löschvorgang aufgezeichnet.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<FormLabel required>Begründung</FormLabel>
					<TextField
						fullWidth
						multiline
						required
						rows={4}
						value={justification}
						onChange={setJustification}
						disabled={loading}
					/>
				</Grid>
			</Grid>
		</ModalForm>
	);
}
