import {Alert, AlertTitle, CardContent, List, ListItem, ListItemText, Stack} from "@mui/material";
import {RadioGroup} from "@variocube/app-ui";
import React, {useEffect} from "react";
import {BusinessCaseSelect} from "../../../../../component/business-case-select";
import {useLocalization} from "../../../../../i18n";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

export function BusinessCaseStep({active, completed, invoice, onChange}: Readonly<StepProps>) {
	const {t} = useLocalization();

	useEffect(() => {
		onChange({businessCases: []});
	}, [invoice.businessCaseCategory]);

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title={"Geschäftsfall"}
			activeChildren={
				<CardContent>
					<Stack spacing={2}>
						<RadioGroup
							row
							label="Kategorie"
							value={invoice.businessCaseCategory}
							required
							onChange={businessCaseCategory => onChange({businessCaseCategory})}
							options={["Catering", "ExternalCatering", "Car", "Default"]}
							renderLabel={category => (
								<ListItemText
									primary={t(`invoices.details.businessCaseCategories.${category}.title`)}
									secondary={t(`invoices.details.businessCaseCategories.${category}.description`)}
									sx={{display: "inline-block"}}
								/>
							)}
						/>
						<BusinessCaseSelect
							label="Geschäftsfall"
							value={invoice.businessCases ?? []}
							onChange={businessCases => onChange({businessCases})}
							category={invoice.businessCaseCategory ?? "Default"}
							fullWidth
							required
							disabled={!invoice.businessCaseCategory}
						/>
						{invoice.businessCases?.filter(businessCase => businessCase.warningMessage).map(
							businessCase => (
								<Alert key={businessCase.id} severity="warning">
									<AlertTitle>{businessCase.warningMessage}</AlertTitle>
								</Alert>
							),
						)}
						{invoice.businessCases?.filter(businessCase => businessCase.terms).map(businessCase => (
							<Alert key={businessCase.id} severity="info">
								<AlertTitle>{businessCase.terms}</AlertTitle>
							</Alert>
						))}
					</Stack>
				</CardContent>
			}
			completedChildren={
				<List>
					{invoice.businessCases?.map(businessCase => (
						<ListItem key={businessCase.id}>
							<ListItemText
								primary={`${businessCase.name} (Sachkonto: ${businessCase.code})`}
								secondary={businessCase.description}
							/>
						</ListItem>
					))}
				</List>
			}
		/>
	);
}
