import {Alert, AlertTitle, Button} from "@mui/material";
import {ErrorAlert, View} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link, useSearchParams} from "react-router-dom";
import {useSignupApi} from "../../api";
import {FullScreenLoading} from "../../component/FullScreenLoading";
import {useLocalization} from "../../i18n";

export function ConfirmSignup() {
	const {t} = useLocalization();
	const {confirm} = useSignupApi();

	const [search] = useSearchParams();
	const token = search.get("token");

	const {loading, error} = useAsync(() => token ? confirm(token) : Promise.reject(new Error("Missing token")), [
		token,
	]);

	if (loading) {
		return <FullScreenLoading />;
	}

	if (error) {
		return (
			<View>
				<ErrorAlert error={error} />
			</View>
		);
	}

	return (
		<View maxWidth="sm">
			<Alert>
				<AlertTitle>{t("signup.confirmSuccess.title")}</AlertTitle>
				{t("signup.confirmSuccess.message")}
			</Alert>

			<Button
				variant="outlined"
				component={Link}
				to="/"
			>
				{t("auth.login")}
			</Button>
		</View>
	);
}
