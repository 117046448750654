import React from "react";
import {Alert, Card, CardContent, CardHeader} from "@mui/material";
import {useLocalization} from "../../i18n";
import {useAsyncCallback} from "react-async-hook";
import {FileUploadButton} from "../../component/file-upload-button";

interface InboxAuditorImportFormProps {
	onFileUpload: (file: File) => Promise<void>;
}

export function InboxAuditorImportForm({onFileUpload}: InboxAuditorImportFormProps) {
	const {t} = useLocalization();

	const {loading, error, execute} = useAsyncCallback(onFileUpload);

	return (
		<Card>
			<CardHeader title={t("inboxes.inboxAuditors.import.title")}/>
			<CardContent>
				<FileUploadButton
					label={t("inboxes.inboxAuditors.import.upload")}
					onUpload={execute}
					accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					disabled={loading}
				/>
				{error && (
					<Alert severity="error" sx={{ mt: 1 }}>{error.message ?? "Error"}</Alert>
				)}
			</CardContent>
		</Card>
	)
}
