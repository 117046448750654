import {Invoice, InvoiceSummary} from "../../api";
import {notBlank} from "../../util";

export function getSubmitterName(invoice: Invoice | InvoiceSummary) {
	return invoice.invoiceCreditor?.name
		|| (invoice.creditor?.creditorType != "ServiceCreditor" && invoice.creditor?.companyName)
		|| [invoice.user?.lastname, invoice.user?.firstname].filter(notBlank).join(", ");
}

export function getSubmitterEmail(invoice: Invoice | InvoiceSummary) {
	return invoice.user?.email;
}

export function getInvoiceDisplayString(invoice: Invoice | InvoiceSummary) {
	if (invoice.invoiceType === "CreditorInvoice" || invoice.invoiceType == "CreditorCreditNote") {
		return invoice.originalFilename;
	}
	if (invoice.invoiceType === "ExpensesInvoice" || invoice.invoiceType == "CreditCardInvoice") {
		return invoice.businessCases
			?.map(c => c.description)
			.filter(notBlank)
			.join(", ");
	}
	return "";
}

export function getDuplicateDocumentType(invoice: Invoice | InvoiceSummary) {
	if (invoice.invoiceType === "ExpensesInvoice") return "RXQ";
	return "RXY";
}
