import {CreditCard, Receipt, ReceiptLong, Savings} from "@mui/icons-material";
import React from "react";
import {InvoiceType} from "../../api";

interface InvoiceTypeIconProperties {
	invoiceType: InvoiceType;
}

export function InvoiceTypeIcon(properties: InvoiceTypeIconProperties) {
	const {invoiceType} = properties;

	switch (invoiceType) {
		case "CreditorInvoice": {
			return <Receipt />;
		}
		case "CreditorCreditNote": {
			return <ReceiptLong />;
		}
		case "ExpensesInvoice": {
			return <Savings />;
		}
		case "CreditCardInvoice": {
			return <CreditCard />;
		}
		default: {
			throw new Error(`Unknown invoice type: ${invoiceType}`);
		}
	}
}
