import {Alert, Card, CardHeader, List, ListItem} from "@mui/material";
import React from "react";
import {InboxAuditor} from "../../api";
import {useLocalization} from "../../i18n";

interface InboxAuditorEmailListProps {
	auditors: InboxAuditor[];
}

export function InboxAuditorEmailList({auditors}: InboxAuditorEmailListProps) {
	const {t} = useLocalization();

	return (
		<Card>
			<CardHeader title={t("common.email")}/>
			<List disablePadding>
				{auditors.length === 0 && (
					<ListItem>
						<Alert sx={{ width: "100%" }} severity="warning">{t("inboxes.inboxAuditors.empty")}</Alert>
					</ListItem>
				)}
				{auditors.map(e => (
					<ListItem key={e.email}>{e.email}</ListItem>
				))}
			</List>
		</Card>
	)
}
