import {Grid} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {InboxMutation, OutboxMutation, useInboxesApi} from "../../../api";
import {useDocumentTypesApi} from "../../../api/document-types";
import {DeleteButton} from "../../../component/delete-button";
import {useLocalization} from "../../../i18n";
import {useNumericParam} from "../../../utils/useParam";
import {DocumentTypeTable} from "./document-type-table";
import {InboxForm} from "./form";
import {InboxUserTable} from "./inbox-user-table";
import {OutboxTable} from "./outbox-table";

export function InboxEdit() {
	const navigate = useNavigate();
	const {t} = useLocalization();
	const {
		getInbox,
		updateInbox,
		deleteInbox,
		updateInboxAttachmentDocTypes,
		updateInboxInvoicesDocTypes,
		deleteInboxAttachmentDocTypes,
		deleteInboxInvoicesDocTypes,
		getInboxManagers,
		getInboxAuditors,
		getInboxOutboxes,
		addInboxInvoiceManager,
		addInboxAuditor,
		deleteInboxManager,
		deleteInboxAuditor,
		createInboxOutbox,
		updateInboxOutbox,
		deleteInboxOutbox,
	} = useInboxesApi();
	const {queryDocumentTypes} = useDocumentTypesApi();

	const id = useNumericParam("id");

	const {loading, error, result, execute: refresh} = useAsync((id: number) =>
		Promise.all([
			getInbox(id),
			getInboxOutboxes(id),
			getInboxAuditors(id),
			getInboxManagers(id),
		]), [id]);
	const {result: allDocumentTypes} = useAsync(queryDocumentTypes, []);

	const [inbox, outboxes, auditors, managers] = result ?? [];

	async function handleSave(mutation: InboxMutation) {
		await updateInbox(id, mutation);
		navigate("../superuser/mailbox");
	}

	async function handleDelete() {
		await deleteInbox(id);
		navigate("../superuser/mailbox");
	}

	async function handleAddInvoiceDocumentTypes(ids: number[]) {
		if (inbox !== undefined) {
			await updateInboxInvoicesDocTypes(id, [...inbox.assignedInvoiceDocumentTypes.map(t => t.id), ...ids]);
			await refresh(id);
		}
	}

	async function handleAddAttachmentDocumentTypes(ids: number[]) {
		if (inbox !== undefined) {
			await updateInboxAttachmentDocTypes(id, [...inbox.assignedAttachmentDocumentTypes.map(t => t.id), ...ids]);
			await refresh(id);
		}
	}

	async function handleDeleteInvoiceDocumentType(documentTypeId: number) {
		await deleteInboxInvoicesDocTypes(id, documentTypeId);
		await refresh(id);
	}

	async function handleDeleteAttachmentDocumentType(documentTypeId: number) {
		await deleteInboxAttachmentDocTypes(id, documentTypeId);
		await refresh(id);
	}

	async function handleAddInvoiceManager(userId: number) {
		await addInboxInvoiceManager(id, userId);
		await refresh(id);
	}

	async function handleDeleteInvoiceManager(userId: number) {
		await deleteInboxManager(id, userId);
		await refresh(id);
	}

	async function handleAddAuditor(userId: number) {
		await addInboxAuditor(id, userId);
		await refresh(id);
	}

	async function handleDeleteAuditor(userId: number) {
		await deleteInboxAuditor(id, userId);
		await refresh(id);
	}

	async function handleCreateOutbox(mutation: OutboxMutation) {
		await createInboxOutbox(id, mutation);
		await refresh(id);
	}

	async function handleUpdateOutbox(outboxId: number, mutation: OutboxMutation) {
		await updateInboxOutbox(id, outboxId, mutation);
		await refresh(id);
	}

	async function handleDeleteOutbox(outboxId: number) {
		await deleteInboxOutbox(id, outboxId);
		await refresh(id);
	}

	return (
		<View
			loading={loading}
			error={error}
		>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../superuser/mailbox`}>{t("inboxes.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{inbox?.name}</BreadcrumbItem>
				<BreadcrumbItem>{t("common.edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={inbox?.name}
				actions={
					<DeleteButton
						title={t("inboxes.delete.title")}
						confirmMessage={t("inboxes.delete.prompt")}
						onDelete={handleDelete}
					/>
				}
			/>
			<InboxForm
				loading={loading}
				inbox={inbox}
				onSave={handleSave}
			/>
			<div>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<DocumentTypeTable
							title={t("inboxes.invoiceDocumentTypes")}
							types={inbox?.assignedInvoiceDocumentTypes ?? []}
							options={allDocumentTypes ?? []}
							onAdd={handleAddInvoiceDocumentTypes}
							onRemove={handleDeleteInvoiceDocumentType}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DocumentTypeTable
							title={t("inboxes.attachmentDocumentTypes")}
							types={inbox?.assignedAttachmentDocumentTypes ?? []}
							options={allDocumentTypes ?? []}
							onAdd={handleAddAttachmentDocumentTypes}
							onRemove={handleDeleteAttachmentDocumentType}
						/>
					</Grid>
				</Grid>
			</div>
			<OutboxTable
				outboxes={outboxes ?? []}
				onCreate={handleCreateOutbox}
				onUpdate={handleUpdateOutbox}
				onRemove={handleDeleteOutbox}
			/>
			<InboxUserTable
				users={managers ?? []}
				onAdd={handleAddInvoiceManager}
				onRemove={handleDeleteInvoiceManager}
				title={t("inboxes.managers")}
				assignLabel={t("inboxes.users.assign.manager")}
			/>
			<InboxUserTable
				users={auditors ?? []}
				onAdd={handleAddAuditor}
				onRemove={handleDeleteAuditor}
				title={t("inboxes.auditors")}
				assignLabel={t("inboxes.users.assign.auditor")}
			/>
		</View>
	);
}
