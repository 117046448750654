import {CreditFilter} from "../../../api";
import {useLocalization} from "../../../i18n";
import React, {useState} from "react";
import {Filter, TextField} from "@variocube/app-ui";
import {Chip, Grid} from "@mui/material";
import {FilterForm} from "../../../component/filter-form";

interface CreditorFilterBarProps {
	value: CreditFilter;
	onChange: (value: CreditFilter) => void;
}

export function CreditorFilterBar({value, onChange}: CreditorFilterBarProps) {
	const {s, t} = useLocalization();

	const [companyName, setCompanyName] = useState(value.companyName ?? '');

	async function handleSubmit() {
		onChange({ companyName });
	}

	function handleReset() {
		onChange({
			companyName: ''
		});
	}

	return (
		<Filter
			labels={s('filter')}
			label={t('filter.title')}
			onClear={handleReset}
			active={[
				value.companyName && (
					<Chip
						label={`${t('common.name')}: ${value.companyName}`}
						onDelete={() => onChange({...value, companyName: ''})}
					/>
				),
			]}
		>
			<FilterForm onApply={handleSubmit} onReset={handleReset}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label={t("common.company")}
							fullWidth
							value={companyName}
							onChange={setCompanyName}
						/>
					</Grid>
				</Grid>
			</FilterForm>
		</Filter>
	)
}
