import AddIcon from "@mui/icons-material/Add";
import {Button, ButtonGroup, Chip, Link, Typography} from "@mui/material";
import {
	DataTable,
	DataTableColumn,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
	View,
	ViewHeader,
} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {User, UserFilter, useUserApi} from "../../api";
import {useLocalization} from "../../i18n";
import {getUserDisplayName} from "../../utils/user";
import {UserFilterBar} from "./filter";

const DefaultFilter: UserFilter = {
	name: "",
	email: "",
};

function useUserFilter() {
	return useStorage<UserFilter>("UserFilter", DefaultFilter);
}

function useUserPage() {
	return useDataTableStorage("UserList", {
		sortField: "lastname,firstname",
		sortDirection: "asc",
	});
}

export function UserList() {
	const {t} = useLocalization();
	const {queryUsers} = useUserApi();

	const [filter, setFilter] = useUserFilter();
	const storage = useUserPage();
	const pageable = useSpringPageable(storage);

	const query = useMemo(() => ({...filter, ...pageable}), [filter, pageable]);
	const {result, loading, error} = useAsync(queryUsers, [query]);

	const {rows, page} = useSpringPage(result);

	const columns = useMemo<DataTableColumn<User>[]>(() => [
		{
			field: "lastname,firstname",
			label: t("common.name"),
			sortable: true,
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/users/${row.id}`} color="secondary">
						{getUserDisplayName(row)}
					</Link>
				</Typography>
			),
		},
		{
			field: "email",
			label: t("common.email"),
			sortable: true,
			component: ({row}) => <Link href={"mailto:" + row.email} color="secondary">{row.email}</Link>,
		},
		{
			field: "role",
			label: t("users.role"),
			component: ({row}) => <>{row.role}</>,
		},
		{
			field: "locked",
			label: t("common.active"),
			component: ({row}) => (
				<Chip
					color={row.locked ? "error" : "success"}
					label={row.locked ? t("common.no") : t("common.yes")}
				/>
			),
		},
	], [t]);

	return (
		<View>
			<ViewHeader
				title={t("users.plural")}
				actions={
					<ButtonGroup variant="outlined" disableElevation>
						<Button
							component={RouterLink}
							to="/users/new"
							startIcon={<AddIcon />}
							color="primary"
						>
							{t("users.create.title")}
						</Button>
					</ButtonGroup>
				}
			/>
			<UserFilterBar
				value={filter}
				onChange={setFilter}
			/>
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				{...storage}
			/>
		</View>
	);
}
