import {Autocomplete} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import React, {ReactNode} from "react";
import {useAsync} from "react-async-hook";
import {useInvoicesApi} from "../api";

interface ProfitCenterFieldProps {
	label?: ReactNode;
	value: string | undefined;
	onChange: (value: string | undefined) => any;
	required?: boolean;
	fullWidth?: boolean;
}

export function ProfitCenterField({label, value, onChange, required, fullWidth}: Readonly<ProfitCenterFieldProps>) {
	const {listProfitCenterHistory} = useInvoicesApi();

	const {loading, result: history} = useAsync(listProfitCenterHistory, []);

	return (
		<Autocomplete
			loading={loading}
			options={history ?? []}
			renderInput={(parameters) => (
				<TextField
					required={required}
					label={label}
					{...parameters}
					inputProps={{
						...parameters.inputProps,
						pattern: "([0-9]{12}|[a-zA-Z]{2}[0-9]{8})",
					}}
				/>
			)}
			freeSolo
			autoSelect
			value={value}
			onChange={(_, value) => onChange(value ?? undefined)}
			fullWidth={fullWidth}
		/>
	);
}
