import {PasswordReset, useApiClient} from "./client";

export function usePasswordResetApi() {
	const api = useApiClient();
	return {
		requestPasswordReset: (email: string) => api.post("/api/v2/password-reset/{email}", {path: {email}}),
		resetPassword: (email: string, passwordReset: PasswordReset) =>
			api.put("/api/v2/password-reset/{email}/password", {path: {email}, body: passwordReset}),
	};
}
