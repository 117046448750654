import {useLocalization} from "../../i18n";
import {isOebb, useUser} from "../../context/auth";
import {Link as RouterLink} from "react-router-dom";
import {CreditCard, DocumentScanner, ExpandLess, ExpandMore, Savings} from "@mui/icons-material";
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import React, {Fragment} from "react";

export function SubmitInvoiceButton() {
	const {t} = useLocalization();
	const user = useUser();
	const oebb = isOebb(user);
	const creditCard = oebb && user.creditCards.length > 0;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};


	if (!oebb) {
		return (
			<Button
				component={RouterLink}
				to="/invoices/new/creditor"
				startIcon={<DocumentScanner/>}
				color="inherit"
			>
				{t("invoices.new.creditor.title")}
			</Button>
		);

	} else {
		return (
			<Fragment>
				<Button
					onClick={handleClick}
					color="inherit"
					endIcon={open ? <ExpandLess/> : <ExpandMore/>}
				>
					{t("invoices.submit")}
				</Button>
				<Menu
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
				>
					<MenuItem
						component={RouterLink}
						to="/invoices/new/creditor"
					>
						<ListItemIcon><DocumentScanner/></ListItemIcon>
						<ListItemText primary={t(`dashboard.creditorInvoice.title.oebb`)}/>
					</MenuItem>
					<MenuItem
						component={RouterLink}
						to="/invoices/new/expense"
					>
						<ListItemIcon><Savings/></ListItemIcon>
						<ListItemText primary={t(`dashboard.expenseInvoice.title`)}/>
					</MenuItem>
					{creditCard && (
						<MenuItem
							component={RouterLink}
							to="/invoices/new/credit-card"
						>
							<ListItemIcon><CreditCard/></ListItemIcon>
							<ListItemText primary={t(`dashboard.creditCardInvoice.title`)}/>
						</MenuItem>
					)}
				</Menu>
			</Fragment>
		)
	}
}
