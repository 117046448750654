import {ContactPersonMutation, CreditorRequest, useApiClient, UserPatch, UserVacationMutation} from "./index";

export function useIndexesApi() {
	const api = useApiClient();
	return {
		getInvoiceCount: () => api.get("/api/v2/invoice-count"),
		getCurrentUser: () => api.get("/api/v2/user"),
		patchCurrentUser: (patch: UserPatch) => api.patch("/api/v2/user", {body: patch}),
		getUserProxies: () => api.get("/api/v2/user/proxies"),
		getProxiedUsers: () => api.get("/api/v2/user/proxied"),
		addProxiedUser: (email: string) => api.post("/api/v2/user/proxied", {query: {email}}),
		deleteProxiedUser: (proxyId: number) => api.delete("/api/v2/user/proxied/{proxyId}", {path: {proxyId}}),
		updateUserVacation: (mutation: UserVacationMutation) => api.put("/api/v2/user/vacation", {body: mutation}),
		deleteUserVacation: () => api.delete("/api/v2/user/vacation"),
		updateContactPerson: (mutation: ContactPersonMutation) =>
			api.put("/api/v2/user/contact-person", {body: mutation}),
		updatePassword: (password: string) => api.put("/api/v2/user/password", {query: {password}}),
		getCurrentCreditor: () => api.get("/api/v2/creditor"),
		updateCreditor: (request: CreditorRequest) => api.put("/api/v2/creditor", {body: request}),
		queryCreditorInboxes: () => api.get("/api/v2/creditor/inboxes"),
		updateCreditorInboxes: (inboxIds: number[]) => api.post("/api/v2/creditor/inboxes", {body: inboxIds}),
		deleteCreditorInboxes: (inboxId: number) => api.delete("/api/v2/creditor/inboxes/{inboxId}", {path: {inboxId}}),
	};
}
