import {Autocomplete} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Inbox, InboxQuery, useInboxesApi} from "../api";
import {useLocalization} from "../i18n";

interface BcaSelectProperties {
	label?: string;
	required?: boolean;
	value: number | undefined;
	onChange: (value: number | undefined, inbox: Inbox | null) => void;
	filter?: InboxQuery["filter"];
	userId?: InboxQuery["userId"];
	fullWidth?: boolean;
	disabled?: boolean;
}

export function BcaSelect(props: Readonly<BcaSelectProperties>) {
	const {label, required, value, onChange, filter, userId, fullWidth, disabled} = props;
	const {t} = useLocalization();
	const {queryInboxes} = useInboxesApi();
	const query = useMemo(() => ({filter, userId}), [filter, userId]);
	const {result: inboxes} = useAsync(queryInboxes, [query]);

	function handleChange(inbox: Inbox | null) {
		onChange(inbox?.id, inbox);
	}

	return (
		<Autocomplete
			renderInput={(parameters) => (
				<TextField placeholder={t("hint.select")} required={required} label={label} {...parameters} />
			)}
			fullWidth={fullWidth}
			disabled={disabled}
			options={inboxes ?? []}
			getOptionLabel={inbox => inbox.name ?? ""}
			value={inboxes?.find(inbox => inbox.id == value) ?? null}
			getOptionKey={inbox => inbox.id}
			onChange={(_, v) => {
				handleChange(v);
			}}
			autoSelect
			autoHighlight
		/>
	);
}
