import {Close, Help, QuestionMark} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {Box, Button, Fab, IconButton, Paper, Stack, Typography, useTheme} from "@mui/material";
import {ErrorAlert} from "@variocube/app-ui";
import React, {PropsWithChildren, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {HelpText} from "./api";
import {useHelpTextApi} from "./api/help-texts";
import {hasRoles, useAuthContext} from "./context/auth";
import {useLocalization} from "./i18n";
import {sanitizeHtml} from "./utils/sanitizeHtml";
import {useHelpStorage} from "./utils/useHelpStorage";
import worldMap from "./world-map-gray.svg";

function sanitizeHelpText(helpText: HelpText) {
	return {
		...helpText,
		body: sanitizeHtml(helpText.body ?? ""),
	};
}

export function HelpTextContainer({showWorldMap, children}: PropsWithChildren<{ showWorldMap: boolean }>) {
	const {t, language} = useLocalization();
	const location = useLocation();
	const {queryHelpTexts} = useHelpTextApi();
	const theme = useTheme();
	const {user} = useAuthContext();
	const [help, setHelp] = useHelpStorage();

	const worldMapSx = {
		backgroundImage: `url(${worldMap})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundAttachment: "fixed",
	};

	const query = useMemo(() => ({
		language,
	}), [language]);

	const {error, result} = useAsync(queryHelpTexts, [query]);

	const sanitized = useMemo(() => result?.map(helpText => sanitizeHelpText(helpText)) ?? [], [result]);

	const helpTexts = sanitized.filter(helpText => location.pathname == helpText.helpKey) ?? [];

	const hasHelp = helpTexts.length > 0;

	const showHelp = help && hasHelp;

	return (
		<Box flex={1} display="flex" flexDirection="row" position="relative">
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: showHelp ? "calc(100% - 320px)" : "100%",
					transition: "0.5s width",
					flex: 1,
					...(showWorldMap ? worldMapSx : undefined),
				}}
			>
				{children}
			</Box>
			<Box
				sx={{
					width: showHelp ? 320 : 0,
					overflowX: "hidden",
					height: showHelp ? undefined : "calc(100vh - 130px)",
					transition: "0.5s width",
					flex: "0 1 auto",
				}}
			>
				{!help && (
					<Fab
						variant="extended"
						color="info"
						disabled={!hasHelp}
						sx={{
							position: "absolute",
							top: 24,
							right: 0,
							borderTopRightRadius: 0,
							borderBottomRightRadius: 0,
						}}
						onClick={() => setHelp(true)}
						aria-label="help"
					>
						<QuestionMark />
						{t("common.help")}
					</Fab>
				)}
				<Paper
					sx={{
						width: 320,
						height: "100%",
						transform: help ? "none" : "translateX(100%)",
						transition: "0.5s transform",
						p: 2,
						border: 0,
						borderLeft: `1px solid ${theme.palette.divider}`,
					}}
					variant="outlined"
				>
					<Stack direction="row" spacing={1} alignItems="center" mb={2}>
						<Help />
						<Typography variant="overline" sx={{flex: 1}}>
							{t("common.help")}
						</Typography>
						<IconButton onClick={() => setHelp(false)}>
							<Close />
						</IconButton>
					</Stack>
					{error && <ErrorAlert error={error} />}
					{helpTexts.map(helpText => (
						<Box key={helpText.id} py={2}>
							<Stack direction="row" spacing={2} alignItems="center">
								<Typography variant="h5" gutterBottom>
									{helpText.subject}
								</Typography>

								{hasRoles(user, ["SuperUser"]) && (
									<IconButton component={Link} to={`/help-texts/${helpText.id}`}>
										<EditIcon />
									</IconButton>
								)}
							</Stack>
							<Typography variant="body1">
								<div dangerouslySetInnerHTML={{__html: helpText.body}} />
							</Typography>
						</Box>
					))}
					<Box textAlign="center" py={2}>
						{hasRoles(user, ["SuperUser"]) && (
							<Button
								color="inherit"
								component={Link}
								to={`/help-texts/new?helpKey=${location.pathname}`}
							>
								{t("helpTexts.create.title")}
							</Button>
						)}
					</Box>
				</Paper>
			</Box>
		</Box>
	);
}
