import {Alert, AlertTitle, Button, Card, CardContent, CardHeader, Collapse, Stack} from "@mui/material";
import {ErrorAlert, TextField, View} from "@variocube/app-ui";
import React, {FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {usePasswordResetApi} from "../api";
import {useLocalization} from "../i18n";

export function LostPassword() {
	const {t} = useLocalization();
	const [email, setEmail] = useState("");
	const {requestPasswordReset} = usePasswordResetApi();

	const submit = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();

		await requestPasswordReset(email);
		return true;
	});

	return (
		<View maxWidth="xs">
			<Card component="form" onSubmit={submit.execute}>
				<CardHeader title={t("reset-password.title")} />
				<CardContent>
					{t("reset-password.message")}
				</CardContent>
				<CardContent>
					<Stack spacing={2}>
						<TextField
							label={t("common.email")}
							value={email}
							onChange={setEmail}
							type="email"
							required
							disabled={submit.loading || submit.result}
						/>
						<Button type="submit" variant="contained" disabled={submit.loading || submit.result}>
							{t("reset-password.request-link")}
						</Button>
					</Stack>
				</CardContent>
				<Collapse in={Boolean(submit.result)}>
					<Alert severity="success">
						<AlertTitle>{t("reset-password.submitSuccess.title")}</AlertTitle>
						{t("reset-password.submitSuccess.message")}
					</Alert>
				</Collapse>
				<Collapse in={Boolean(submit.error)}>
					<ErrorAlert error={submit.error} />
				</Collapse>
			</Card>
		</View>
	);
}
