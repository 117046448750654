import {ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuthApi} from "../api/auth";
import {FullScreenLoading} from "../component/FullScreenLoading";
import {useAuthContext} from "../context/auth";

export function ProcessToken() {
	const {processToken} = useAuthApi();
	const navigate = useNavigate();
	const {reloadAuth} = useAuthContext();
	const [search] = useSearchParams();

	const code = search.get("code");
	const state = search.get("state");

	const {loading, error} = useAsync(async () => {
		if (code) {
			await processToken(code, location.origin);
			await reloadAuth();
			navigate(state || "/");
		}
	}, []);

	if (loading) {
		return <FullScreenLoading />;
	}

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return null;
}
