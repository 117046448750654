import {UserRef} from "../api";

export function getUserDisplayName(user?: UserRef) {
	if (!user) {
		return "";
	}
	if (user.lastname) {
		return `${user.lastname}, ${user.firstname}`.trim();
	}
	return user.email;
}
