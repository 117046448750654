import {Link, useNavigate} from "react-router-dom";
import {useLocalization} from "../../../i18n";
import {useDocumentTypesApi} from "../../../api/document-types";
import {useNumericParam} from "../../../utils/useParam";
import {useAsync} from "react-async-hook";
import {DocumentTypeMutation} from "../../../api";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import {DeleteButton} from "../../../component/delete-button";
import React from "react";
import {DocumentTypeForm} from "./form";

export function DocumentTypeEdit() {
	const navigate = useNavigate();
	const {t} = useLocalization();
	const {getDocumentType, updateDocumentType, deleteDocumentType} = useDocumentTypesApi();

	const id = useNumericParam("id");

	const {loading, error, result} = useAsync(getDocumentType, [id]);

	async function handleSave(mutation: DocumentTypeMutation) {
		await updateDocumentType(id, mutation);
		navigate("../superuser/doctype");
	}

	async function handleDelete() {
		await deleteDocumentType(id);
		navigate("../superuser/doctype");
	}

	return (
		<View
			loading={loading}
			error={error}
		>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../superuser/doctype`}>{t("navigation.settingsGroup.documentTypes")}</BreadcrumbLink>
				<BreadcrumbItem>{result?.displayName}</BreadcrumbItem>
				<BreadcrumbItem>{t("common.edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={result?.displayName}
				actions={
					<DeleteButton
						title={t("documentTypes.delete.title")}
						confirmMessage={t("documentTypes.delete.message")}
						onDelete={handleDelete}
					/>
				}
			/>
			<DocumentTypeForm
				loading={loading}
				type={result}
				onSave={handleSave}
			/>
		</View>
	)
}
