import {Chip, Link, Typography} from "@mui/material";
import {DataTable, DataTableColumn, View, ViewHeader} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {Inbox, useInboxesApi} from "../../api";
import {useLocalization} from "../../i18n";

export function InboxAuditorList() {
	const {t} = useLocalization();
	const {queryInboxes} = useInboxesApi();
	const query = useMemo(() => ({}), []);
	const {result, loading, error} = useAsync(queryInboxes, [query]);

	const columns = useMemo<DataTableColumn<Inbox>[]>(() => [
		{
			field: "name",
			label: t("inboxes.singular"),
			component: ({row}) => (
				<Typography variant="body1">
					<Link component={RouterLink} to={`/admin/inbox-auditors/${row.id}`} color="secondary">
						{row.name}
					</Link>
				</Typography>
			),
		},
		{
			field: "businessCenterAddress",
			label: "BCA",
			component: ({row}) => <Chip label={row.businessCenterAddress} />,
		},
		{
			field: "companyCode",
			label: t("inboxes.companyCode"),
			component: ({row}) => <Chip label={row.companyCode} />,
		},
	], [t]);

	return (
		<View>
			<ViewHeader
				title={t("navigation.adminGroup.auditors")}
			/>
			<DataTable
				columns={columns}
				rows={result ?? []}
				loading={loading}
				error={error}
			/>
		</View>
	);
}
