import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PersonIcon from "@mui/icons-material/Person";
import {AppBar, Button, Chip, Divider, Link, Menu, MenuItem, Stack, Toolbar, useTheme} from "@mui/material";
import React, {type PropsWithChildren, type ReactElement, useEffect} from "react";
import {useLocation} from "react-router";
import {Link as RouterLink} from "react-router-dom";
import {hasRoles, useAuthContext} from "../context/auth";
import {useLocalization} from "../i18n";
import {getUserDisplayString} from "../util";
import {canAudit} from "../utils/canAudit";
import {canRoute} from "../utils/canRoute";
import {Logo} from "./logo";

export function Navigation() {
	const {t} = useLocalization();
	const {user, invoiceCount} = useAuthContext();
	const theme = useTheme();

	const canRouteInvoices = canRoute(user);
	const canAuditInvoices = canAudit(user);

	return (
		<AppBar
			position="static"
			sx={{
				boxShadow: "none",
				borderBottom: `1px solid ${theme.palette.divider}`,
				background: theme.palette.background.paper,
			}}
		>
			<Toolbar sx={{alignItems: "stretch"}}>
				<Link component={RouterLink} to="/" display="flex" alignItems="center">
					<Logo />
				</Link>
				<Stack
					direction="row"
					flexGrow={1}
					alignItems="stretch"
					gap={1}
					ml={2}
				>
					{user && (
						<>
							{hasRoles(user, ["CreditorAdministrator", "CreditorUser"])
								&& (
									<LinkItem to="/dashboard">
										{t("navigation.dashboard")}
									</LinkItem>
								)}
							{hasRoles(user, [
								"CreditorAdministrator",
								"CreditorUser",
								"OebbUser",
								"OebbAdministrator",
								"SuperUser",
							]) && (
								<LinkItem to="/invoices?view=Mine">
									{t("navigation.myInvoices")} <Chip label={invoiceCount?.mine} size="small" />
								</LinkItem>
							)}
							{canRouteInvoices && (
								<LinkItem to="/invoices?view=AwaitingRouting">
									{t("navigation.pendingInvoices")}{" "}
									<Chip label={invoiceCount?.awaitingRouting} size="small" />
								</LinkItem>
							)}
							{canAuditInvoices && (
								<LinkItem to="/invoices?view=AwaitingAudit">
									{t("navigation.auditingInvoices")}{" "}
									<Chip label={invoiceCount?.awaitingAudit} size="small" />
								</LinkItem>
							)}
							{hasRoles(user, [
								"OebbUser",
								"OebbAdministrator",
								"SuperUser",
							]) && (
								<LinkItem to="/invoices?view=All">
									{t("navigation.allInvoices")} <Chip label={invoiceCount?.all} size="small" />
								</LinkItem>
							)}
							{hasRoles(user, ["CreditorAdministrator"]) && (
								<LinkItem to="/users">
									{t("navigation.adminGroup.users")}
								</LinkItem>
							)}
							{hasRoles(user, ["OebbAdministrator", "SuperUser"]) && (
								<DropdownMenu
									label={t("navigation.admin")}
									items={[
										{
											label: t("navigation.adminGroup.statistics"),
											path: "/admin/statistics",
										},
										{label: t("navigation.adminGroup.users"), path: "/users"},
										{label: t("navigation.adminGroup.creditors"), path: "/admin/creditor"},
										{label: t("navigation.adminGroup.auditors"), path: "/admin/inbox-auditors"},
									]}
								/>
							)}
							{hasRoles(user, ["SuperUser"]) && (
								<DropdownMenu
									label={t("navigation.settings")}
									items={[
										{
											label: t("navigation.settingsGroup.mailboxes"),
											path: "/superuser/mailbox",
										},
										{
											label: t("navigation.settingsGroup.documentTypes"),
											path: "/superuser/doctype",
										},
										{
											label: t("navigation.settingsGroup.businessCases"),
											path: "/superuser/business-cases",
										},
										{
											label: t("navigation.settingsGroup.creditCards"),
											path: "/superuser/credit-cards",
										},
										{divider: true},
										{
											label: t("navigation.settingsGroup.helpText"),
											path: "/superuser/help-texts",
										},
										{label: t("navigation.settingsGroup.infobox"), path: "/admin/infobox"},
										{divider: true},
										{label: t("navigation.settingsGroup.audit"), path: "/superuser/auditlogs"},
										{divider: true},
										{label: t("navigation.settingsGroup.settings"), path: "/admin/settings"},
										{
											label: t("navigation.settingsGroup.maintenance"),
											path: "/superuser/maintenance",
										},
									]}
								/>
							)}
						</>
					)}
				</Stack>
				{user && (
					<DropdownMenu
						startIcon={<PersonIcon />}
						label={getUserDisplayString(user)}
						items={[
							{label: t("navigation.userSettings"), path: "/user-settings"},
							{divider: true},
							{label: t("navigation.logout"), path: "/logout"},
						]}
					/>
				)}
				{!user && (
					<LinkItem to="/signup">
						{t("signup.title")}
					</LinkItem>
				)}
			</Toolbar>
		</AppBar>
	);
}

function LinkItem({children, to}: PropsWithChildren<{ to: string }>) {
	return (
		<Button
			component={RouterLink}
			to={to}
			sx={theme => ({
				textDecoration: "none",
				color: theme.palette.text.secondary,
				gap: 1,
			})}
		>
			{children}
		</Button>
	);
}

interface DropDownMenuProperties {
	label: string;
	items: Array<{
		label?: string;
		path?: string;
		divider?: boolean;
	}>;
	border?: boolean;
	startIcon?: ReactElement;
}

function DropdownMenu({label, items, border, startIcon}: Readonly<DropDownMenuProperties>) {
	const [anchorElement, setAnchorElement] = React.useState<HTMLElement>();
	const open = Boolean(anchorElement);
	const location = useLocation();

	// close dropdown when navigating
	useEffect(() => {
		setAnchorElement(undefined);
	}, [location]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElement(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorElement(undefined);
	};

	return (
		<>
			<Button
				onClick={handleClick}
				sx={theme => ({
					color: theme.palette.text.secondary,
				})}
				startIcon={startIcon}
				endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownRoundedIcon />}
				variant={border ? "outlined" : "text"}
			>
				{label}
			</Button>
			<Menu
				anchorEl={anchorElement}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{items.map((element, index) => {
					if (element.divider === true) return <Divider key={`item-${index}`} />;
					return (
						<MenuItem
							key={`item-${index}`}
							component={RouterLink}
							to={element.path ?? ""}
						>
							{element.label}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
}
