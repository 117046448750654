import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Card,
	Collapse,
	Grid,
	LinearProgress,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import {alpha} from "@mui/system/colorManipulator";
import {
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumbs,
	Checkbox,
	defined,
	ErrorAlert,
	View,
	ViewHeader
} from "@variocube/app-ui";
import React, {FormEvent, useMemo, useState} from "react";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {useDropzone} from "react-dropzone";
import {Link as RouterLink} from "react-router-dom";
import {useInvoicesApi} from "../../../../api";
import {useFileApi} from "../../../../api/files";
import {FilePreview} from "../../../../component/file-preview";
import {UnsupportedFileTypeAlert} from "../../../../component/unsupported-file-type-alert";
import {useReloadInvoiceCount, useUser} from "../../../../context/auth";
import {useLocalization} from "../../../../i18n";
import {notNull} from "../../../../util";
import {isValidAttachmentType, isValidDocumentType} from "../../../../utils/isValidFileType";
import {InvoiceDuplicates} from "../duplicates";
import {AttachmentFile, ExpenseInvoiceFile} from "../types";
import {detectQrCode} from "./detect-qr-code";
import {AmountStep} from "./steps/amount-step";
import {BusinessCaseStep} from "./steps/business-case-step";
import {CarStep} from "./steps/car-step";
import {CreditCardStep} from "./steps/credit-card-step";
import {DetailStep} from "./steps/detail-step";
import {EmergencyStep} from "./steps/emergency-step";
import {ParticipantStep} from "./steps/participant-step";
import {RecipientStep} from "./steps/recipient-step";
import {SubmitOnBehalfStep} from "./steps/submit-on-behalf-step";
import {SubmitExpenseInvoiceInfo} from "./submit-expense-invoice-info";
import {SubmitExpenseInvoiceSuccess} from "./submit-expense-invoice-success";

enum Steps {
	UPLOAD,
	SUBMIT_ON_BEHALF,
	CREDIT_CARD,
	BUSINESS_CASE,
	RECIPIENT,
	DETAIL,
	PARTICIPANT,
	CAR,
	EMERGENCY,
	AMOUNT,
	SUMMARY,
}

const USE_INBOX_FILE_TYPES = false;

interface ExpenseInvoiceCreateProps {
	type: "expense" | "credit-card";
}

export function ExpenseInvoiceCreate({type}: Readonly<ExpenseInvoiceCreateProps>) {
	const {t} = useLocalization();
	const {uploadFile} = useFileApi();
	const {submitExpenseInvoice} = useInvoicesApi();
	const reloadInvoiceCount = useReloadInvoiceCount();

	const user = useUser();
	const canRepresent = user.represents.length > 0;

	const creditCard = type == "credit-card";

	const [invoice, setInvoice] = useState<ExpenseInvoiceFile>({});
	const [stepIndex, setStepIndex] = useState(0);

	function updateInvoice(update: Partial<ExpenseInvoiceFile>) {
		setInvoice(prev => validateFileTypes({...prev, ...update}));
	}

	function updateParticipantFile(file: File, update: Omit<AttachmentFile, "file">) {
		setInvoice(prev => validateFileTypes({...prev, participantFile: {...prev.participantFile, ...update, file}}));
	}

	function uploadInvoice(files: File[]) {
		const [file] = files;

		if (step < Steps.PARTICIPANT) {
			// if the user is on one of the first steps, this is the invoice file
			setInvoice({file});
			uploadFile(file, {
				onProgress: progress => updateInvoice({progress}),
				onSuccess: tempFile => updateInvoice({...tempFile}),
				onError: error => updateInvoice({error}),
			});
			navigate(1);
		} else if (step == Steps.PARTICIPANT) {
			// user is on the participant step, so this is a participant file
			updateInvoice({participantFile: {file}});
			uploadFile(file, {
				onProgress: progress => updateParticipantFile(file, {progress}),
				onSuccess: tempFile => updateParticipantFile(file, {...tempFile}),
				onError: error => updateParticipantFile(file, {error}),
			});
		}
	}

	const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
		useFsAccessApi: false,
		multiple: false,
		onDrop: uploadInvoice,
		noClick: true,
	});

	const theme = useTheme();

	// auto detect QR-code
	useAsync(async (file: File | undefined) => {
		if (file) {
			console.info("Trying to detect QR-code");
			const qrCode = await detectQrCode(file);
			console.info("Detected QR-code");
			updateInvoice({qrCode});
		}
		return Promise.resolve();
	}, [invoice.file]);

	const isCatering = invoice?.businessCaseCategory == "Catering"
		|| invoice?.businessCaseCategory == "ExternalCatering";

	const isEmergency = invoice?.businessCases?.some(businessCase => businessCase.emergency);

	const isCar = invoice?.businessCaseCategory == "Car";

	const unsupportedFileType = (defined(invoice.supported) && !invoice.supported)
		|| (defined(invoice.participantFile?.supported) && !invoice.participantFile?.supported);

	const total = invoice?.businessCases?.map(businessCase => businessCase.amount).filter(defined).filter(notNull)
		.reduce((a, b) => a + b, 0);

	const aboveComplianceThreshold = defined(total) && total > 2000;
	const aboveUsualThreshold = defined(total) && total > 400;
	const notAboveZero = defined(total) && total <= 0;

	const steps = useMemo(() => {
		const steps = [Steps.UPLOAD];
		if (canRepresent) {
			steps.push(Steps.SUBMIT_ON_BEHALF);
		}
		if (creditCard) {
			steps.push(Steps.CREDIT_CARD);
		}
		steps.push(Steps.BUSINESS_CASE, Steps.RECIPIENT, Steps.DETAIL);
		if (isCatering) {
			steps.push(Steps.PARTICIPANT);
		}
		if (isCar) {
			steps.push(Steps.CAR);
		}
		if (isEmergency) {
			steps.push(Steps.EMERGENCY);
		}
		steps.push(Steps.AMOUNT, Steps.SUMMARY);
		return steps;
	}, [canRepresent, creditCard, isCatering, isCar, isEmergency]);

	const step = useMemo(() => steps[stepIndex], [steps, stepIndex]);

	function navigate(offset: number) {
		setStepIndex(prev => prev + offset);
	}

	const submit = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();
		if (step != Steps.SUMMARY) {
			navigate(1);
		} else {
			// form validation should ensure that the relevant fields are defined
			const created = await submitExpenseInvoice({
				checksum: invoice.checksum!,
				filename: invoice.file!.name,
				contentType: invoice.file!.type,
				inboxId: invoice.inbox!.id,
				auditors: invoice.auditors!,
				countryCode: invoice.countryCode!,
				currency: invoice.currency ?? "EUR",
				tipCurrency: invoice.tipCurrency,
				creditCardNumber: invoice.creditCardNumber,
				numberOfParticipants: invoice.numberOfParticipants,
				participants: invoice.participants,
				participantAttachment: invoice.participantFile && {
					checksum: invoice.participantFile.checksum!,
					filename: invoice.participantFile.file.name,
					contentType: invoice.participantFile.file.type,
				},
				profitCenter: invoice.profitCenter,
				caseNumber: invoice.caseNumber,
				tripId: invoice.tripId,
				onBehalfOfUserId: invoice.onBehalfOfUserId,
				emergencyJustification: invoice.emergencyJustification,
				car: invoice.car && {
					inputTaxDeductible: invoice.car.inputTaxDeductible ?? false,
					licensePlate: invoice.car.licensePlate!,
					carType: invoice.car.carType,
					mileageKm: invoice.car.mileageKm!,
					refuelLiter: invoice.car.refuelLiter ?? undefined,
				},
				businessCases: invoice.businessCases!.map(businessCase => ({
					id: businessCase.id,
					description: businessCase.userDescription,
					amount: businessCase.amount!,
					tip: businessCase.tip ?? undefined,
				})),
				cashTip: invoice.cashTip,
			});
			await reloadInvoiceCount();
			return created;
		}
	});

	function handlePrev() {
		navigate(-1);
	}

	const title = t(`invoices.new.expense.title.${creditCard ? "creditCard" : "expense"}`);

	return (
		<Box
			{...getRootProps()}
			flex={1}
			border={`1px solid ${isDragActive ? theme.palette.secondary.main : "transparent"}`}
			bgcolor={isDragActive
				? alpha(theme.palette.secondary.main, theme.palette.action.selectedOpacity)
				: undefined}
		>
			<View>
				<Breadcrumbs>
					<BreadcrumbLink component={RouterLink} to="/invoices">{t("invoices.plural")}</BreadcrumbLink>
					<BreadcrumbItem>{title}</BreadcrumbItem>
				</Breadcrumbs>
				<ViewHeader title={title}/>
				<Collapse in={Boolean(submit.result)}>
					<SubmitExpenseInvoiceSuccess created={submit.result ?? []}/>
				</Collapse>
				<Collapse in={!submit.result}>
					<Stack spacing={2}>
						<Collapse in={!invoice.file}>
							<SubmitExpenseInvoiceInfo/>
						</Collapse>

						<form>
							<input {...getInputProps()} />
						</form>
						{!invoice.file && (
							<Stack spacing={2}>
								<Button
									color="primary"
									variant="outlined"
									onClick={open}
									size="large"
									sx={{height: 240}}
								>
									{t("invoices.new.expense.uploadInvoice")}
								</Button>
							</Stack>
						)}
						{invoice.file && (
							<Box>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6} md={4}>
										<Stack spacing={2}>
											<Card>
												<FilePreview file={invoice.file}/>
												<LinearProgress
													variant="determinate"
													value={(invoice.progress ?? 0) * 100}
													color={getInvoiceColor(invoice)}
												/>
											</Card>
											{invoice.participantFile && step > Steps.PARTICIPANT && (
												<Card>
													<FilePreview file={invoice.participantFile.file}/>
												</Card>
											)}
										</Stack>
									</Grid>

									<Grid item xs={12} sm={6} md={8}>
										{invoice.duplicates && (
											<Stack spacing={2}>
												<InvoiceDuplicates duplicates={invoice.duplicates}/>
												<Button
													color="primary"
													variant="outlined"
													onClick={open}
												>
													Anderen Beleg auswählen
												</Button>
											</Stack>
										)}
										{!invoice.duplicates && (
											<Stack spacing={2} component="form" onSubmit={submit.execute}>
												{canRepresent && (
													<SubmitOnBehalfStep
														active={step == Steps.SUBMIT_ON_BEHALF}
														completed={step > Steps.SUBMIT_ON_BEHALF}
														invoice={invoice}
														onChange={updateInvoice}
													/>
												)}
												{creditCard && (
													<CreditCardStep
														active={step == Steps.CREDIT_CARD}
														completed={step > Steps.CREDIT_CARD}
														invoice={invoice}
														onChange={updateInvoice}
													/>
												)}
												<BusinessCaseStep
													active={step == Steps.BUSINESS_CASE}
													completed={step > Steps.BUSINESS_CASE}
													invoice={invoice}
													onChange={updateInvoice}
												/>
												<RecipientStep
													active={step == Steps.RECIPIENT}
													completed={step > Steps.RECIPIENT}
													invoice={invoice}
													onChange={updateInvoice}
												/>
												<DetailStep
													active={step == Steps.DETAIL}
													completed={step > Steps.DETAIL}
													invoice={invoice}
													onChange={updateInvoice}
												/>
												{isCatering && (
													<ParticipantStep
														active={step == Steps.PARTICIPANT}
														completed={step > Steps.PARTICIPANT}
														invoice={invoice}
														onChange={updateInvoice}
														onSelectParticipantFile={open}
													/>
												)}
												{isCar && (
													<CarStep
														active={step == Steps.CAR}
														completed={step > Steps.CAR}
														invoice={invoice}
														onChange={updateInvoice}
													/>
												)}
												{isEmergency && (
													<EmergencyStep
														active={step == Steps.EMERGENCY}
														completed={step > Steps.EMERGENCY}
														invoice={invoice}
														onChange={updateInvoice}
													/>
												)}
												<AmountStep
													active={step == Steps.AMOUNT}
													completed={step > Steps.AMOUNT}
													invoice={invoice}
													onChange={updateInvoice}
												/>
												<Collapse in={step == Steps.SUMMARY}>
													<Alert severity="info">
														<AlertTitle>Prüfen und Einreichen</AlertTitle>
														<Typography paragraph>
															Bitte überprüfen Sie Ihre Angaben, bestätigen Sie die
															sachliche
															Richtigkeit des Belegs und reichen Sie den Beleg
															dann ein.
														</Typography>
													</Alert>
												</Collapse>

												<Collapse in={step == Steps.SUMMARY}>
													<Checkbox
														required={step == Steps.SUMMARY}
														label="Ich bestätige die sachliche Richtigkeit des Belegs."
													/>
												</Collapse>

												{defined(invoice.supported) && !invoice.supported && (
													<UnsupportedFileTypeAlert
														supported={invoice.inbox?.assignedInvoiceDocumentTypes ?? []}
													/>
												)}

												{defined(invoice.participantFile?.supported)
													&& !invoice.participantFile?.supported && (
														<UnsupportedFileTypeAlert
															supported={invoice.inbox?.assignedAttachmentDocumentTypes ?? []}
														/>
													)}

												{aboveComplianceThreshold && (
													<Alert severity="error">
														<AlertTitle>Gesamtbetrag zu hoch</AlertTitle>
														Laut Compliance-Abteilung dürfen nur Barrechnungen bis zu einem
														Maximalbetrag von € 2.000,- brutto eingereicht werden.
													</Alert>
												)}

												{aboveUsualThreshold && (
													<Alert severity="warning">
														<AlertTitle>Gesamtbetrag hoch</AlertTitle>
														Laut Compliance-Abteilung dürfen Barrechnungen über € 400,- nur
														in Ausnahmefällen über die Bürokassa eingereicht werden.
													</Alert>
												)}

												{submit.error && <ErrorAlert error={submit.error}/>}

												<Stack direction="row" justifyContent="space-between">
													<Button
														variant="outlined"
														color="inherit"
														onClick={handlePrev}
														disabled={stepIndex <= 1}
													>
														{t("actions.back")}
													</Button>
													<Button
														type="submit"
														variant="contained"
														color="primary"
														disabled={submit.loading
															|| unsupportedFileType
															|| aboveComplianceThreshold
															|| (step == Steps.SUMMARY && notAboveZero)}
													>
														{step == Steps.SUMMARY
															? title
															: t("actions.next")}
													</Button>
												</Stack>
											</Stack>
										)}
									</Grid>
								</Grid>
							</Box>
						)}
					</Stack>
				</Collapse>
			</View>
		</Box>
	);
}

function getInvoiceColor(invoice: ExpenseInvoiceFile) {
	if (
		invoice.error
		|| invoice.duplicates
		|| (defined(invoice.supported) && !invoice.supported)
	) {
		return "error";
	}
	if (invoice.checksum) {
		return "success";
	}
	return "secondary";
}

function validateFileTypes(invoice: ExpenseInvoiceFile) {
	return {
		...invoice,
		supported: USE_INBOX_FILE_TYPES
			? isValidDocumentType(invoice.inbox ?? undefined, invoice.file)
			: isValidExpenseFileType(invoice.file),
		participantFile: invoice.participantFile
			? {
				...invoice.participantFile,
				supported: USE_INBOX_FILE_TYPES
					? isValidAttachmentType(invoice.inbox ?? undefined, invoice.participantFile.file)
					: isValidExpenseFileType(invoice.file),
			}
			: undefined,
	};
}

function isValidExpenseFileType(file?: File) {
	if (file) {
		const type = file.type;
		return (type.startsWith("application/") && type.endsWith("pdf"))
			|| (type.startsWith("image/") && (type.endsWith("jpeg") || type.endsWith("gif") || type.endsWith("png")));
	}
}
