import React from "react";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import {Link, useNavigate} from "react-router-dom";
import {useLocalization} from "../../../i18n";
import {InboxMutation, InfoMutation, useInboxesApi} from "../../../api";
import {InboxForm} from "./form";

export function InboxCreator() {
	const navigate = useNavigate();
	const {t} = useLocalization();
	const {createInbox} = useInboxesApi();

	async function handleSave(mutation: InboxMutation) {
		const {id} = await createInbox(mutation);
		navigate(`../superuser/mailbox/${id}`);
	}

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../superuser/mailbox`}>{t("inboxes.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{t("inboxes.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("inboxes.create.title")} />
			<InboxForm
				onSave={handleSave}
			/>
		</View>
	)
}
