import {alpha, Box, Link, Stack, Toolbar, Typography} from "@mui/material";
import {LanguageSwitcher} from "@variocube/app-ui";
import React from "react";
import {SUPPORTED_LANGUAGES, useLocalization} from "../i18n";

export function Footer() {
	const {language, setLanguage} = useLocalization();
	return (
		<Box
			position="static"
			sx={{
				borderTop: `1px solid ${alpha("#000", 0.1)}`,
				background: "white",
			}}
		>
			<Toolbar sx={{alignItems: "stretch"}}>
				<Box alignSelf="center">
					<Typography variant="body2" color="textSecondary">
						ÖBB Business Competence Center<br />
						onlinegroup.at © 2018 - {new Date().getFullYear()}
					</Typography>
				</Box>
				<Stack direction="row" spacing={2} ml={4} alignItems="center">
					<Link
						color="textSecondary"
						sx={{textDecoration: "none"}}
						href="/documents/oebb_edoc-invoices_nutzungsbedingungen.pdf"
						target="_blank"
					>
						Nutzungsbedingungen
					</Link>
					<Link
						color="textSecondary"
						sx={{textDecoration: "none"}}
						href="https://konzern.oebb.at/de/impressum"
						target="_blank"
					>
						Impressum
					</Link>
				</Stack>

				<Box flexGrow={1} />
				<LanguageSwitcher
					language={language}
					setLanguage={setLanguage}
					languages={SUPPORTED_LANGUAGES}
				/>
			</Toolbar>
		</Box>
	);
}
