import {CreditCard, Savings} from "@mui/icons-material";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import {View} from "@variocube/app-ui";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Infoboxes} from "../component/infoboxes";
import {isOebb, useInvoiceCount, useUser} from "../context/auth";
import {useLocalization} from "../i18n";
import {getUserDisplayString} from "../util";
import {canAudit} from "../utils/canAudit";
import {canRoute} from "../utils/canRoute";

export function Dashboard() {
	const {t} = useLocalization();
	const user = useUser();
	const {mine, awaitingRouting, awaitingAudit} = useInvoiceCount() ?? {};
	const oebb = isOebb(user);
	const creditCard = oebb && user.creditCards.length > 0;
	const canRouteInvoices = canRoute(user);
	const canAuditInvoices = canAudit(user);

	const theme = useTheme();
	const lg = useMediaQuery(theme.breakpoints.up("lg"));

	return (
		<View maxWidth="xl">
			<Typography variant="h1" gutterBottom>
				{t("dashboard.greeting", {name: user !== undefined ? getUserDisplayString(user) : ""})}
			</Typography>
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Stack spacing={2}>
							<Card>
								<CardHeader
									title={t(`dashboard.creditorInvoice.title.${oebb ? "oebb" : "creditor"}`)}
								/>
								<CardContent>
									<Typography>
										{t(`dashboard.creditorInvoice.message.${oebb ? "oebb" : "creditor"}`)}
									</Typography>
								</CardContent>
								<CardContent>
									<Button
										variant="contained"
										component={RouterLink}
										to="/invoices/new/creditor"
										startIcon={<DocumentScannerIcon />}
										fullWidth
									>
										{t(`dashboard.creditorInvoice.title.${oebb ? "oebb" : "creditor"}`)}
									</Button>
								</CardContent>
							</Card>
							{oebb && (
								<Card>
									<CardHeader title={t(`dashboard.expenseInvoice.title`)} />
									<CardContent>
										<Typography>{t(`dashboard.expenseInvoice.message`)}</Typography>
									</CardContent>
									<CardContent>
										<Button
											variant="contained"
											component={RouterLink}
											to="/invoices/new/expense"
											startIcon={<Savings />}
											fullWidth
										>
											{t(`dashboard.expenseInvoice.title`)}
										</Button>
									</CardContent>
								</Card>
							)}
							{creditCard && (
								<Card>
									<CardHeader title={t(`dashboard.creditCardInvoice.title`)} />
									<CardContent>
										<Typography>{t(`dashboard.creditCardInvoice.message`)}</Typography>
									</CardContent>
									<CardContent>
										<Button
											variant="contained"
											component={RouterLink}
											to="/invoices/new/credit-card"
											startIcon={<CreditCard />}
											fullWidth
										>
											{t(`dashboard.creditCardInvoice.title`)}
										</Button>
									</CardContent>
								</Card>
							)}
						</Stack>
					</Grid>

					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Stack spacing={2}>
							<Card>
								<CardContent sx={{pb: 0}}>
									<Typography variant="overline">
										{t("invoices.invoiceViews.Mine")}
									</Typography>
									<Typography variant="h2" component="div">
										{mine}
									</Typography>
								</CardContent>
								<CardActions>
									<Button
										color="secondary"
										component={RouterLink}
										to="/invoices?view=Mine"
									>
										{t("navigation.myInvoices")}
									</Button>
								</CardActions>
							</Card>
							{canRouteInvoices && (
								<Card sx={{height: "100%"}}>
									<CardContent sx={{pb: 0}}>
										<Typography variant="overline">
											{t("invoices.invoiceViews.AwaitingRouting")}
										</Typography>
										<Typography variant="h2" component="div">
											{awaitingRouting}
										</Typography>
									</CardContent>
									<CardActions>
										<Button
											color="secondary"
											component={RouterLink}
											to="/invoices?view=AwaitingRouting"
										>
											{t("navigation.pendingInvoices")}
										</Button>
									</CardActions>
								</Card>
							)}
							{canAuditInvoices && (
								<Card>
									<CardContent sx={{pb: 0}}>
										<Typography variant="overline">
											{t("invoices.invoiceViews.AwaitingAudit")}
										</Typography>
										<Typography variant="h2" component="div">
											{awaitingAudit}
										</Typography>
									</CardContent>

									<CardActions>
										<Button
											color="secondary"
											component={RouterLink}
											to="/invoices?view=AwaitingAudit"
										>
											{t("navigation.auditingInvoices")}
										</Button>
									</CardActions>
								</Card>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={6}>
						<Infoboxes displayType="Dashboard" columns={lg ? 2 : 1} />
					</Grid>
				</Grid>
			</Box>
		</View>
	);
}
