import {BinaryBitmap, DecodeHintType, HybridBinarizer, QRCodeReader, RGBLuminanceSource} from "@zxing/library";

/**
 * Detects QR codes from an image file.
 * @param file The image file to analyze
 * @returns A promise that resolves to the QR code text, or null if no QR code was found
 */
export async function detectQrCode(file: File) {
	try {
		// Create a reader instance for QR code detection
		const reader = new QRCodeReader();

		// Read the file as an ArrayBuffer
		const buffer = await readFileAsArrayBuffer(file);

		// Convert the file to an image and prepare it for QR code detection
		const image = await createImageFromArrayBuffer(buffer);

		// Get the dimensions and pixel data from the image
		const {width, height, data} = getImageData(image);

		// Create a luminance source from the RGB data
		// convert to luminance
		const luminance = new Uint8ClampedArray(width * height);
		for (let i = 0; i < data.length; i += 4) {
			const r = data[i];
			const g = data[i + 1];
			const b = data[i + 2];
			luminance[i / 4] = (r + g + b) / 3;
		}

		const luminanceSource = new RGBLuminanceSource(luminance, width, height);

		// Create a binary bitmap from the luminance source
		const binaryBitmap = new BinaryBitmap(new HybridBinarizer(luminanceSource));

		// Attempt to decode the QR code
		const hints = new Map<DecodeHintType, any>();
		hints.set(DecodeHintType.TRY_HARDER, true);
		const result = reader.decode(binaryBitmap, hints);

		// Return the decoded text
		return result.getText();
	}
	catch (error) {
		console.error("Could not detect QR-code in file", error);
	}
}

/**
 * Reads a file as an ArrayBuffer
 */
function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result as ArrayBuffer);
		reader.onerror = reject;
		reader.readAsArrayBuffer(file);
	});
}

/**
 * Creates an image element from an ArrayBuffer
 */
function createImageFromArrayBuffer(buffer: ArrayBuffer): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		// Convert ArrayBuffer to Blob
		const blob = new Blob([buffer]);

		// Create URL from the Blob
		const url = URL.createObjectURL(blob);

		// Create an image element
		const image = document.getElementById("qr-code-debug-image") as HTMLImageElement || new Image();
		image.onload = () => {
			// Clean up the URL object after image is loaded
			URL.revokeObjectURL(url);
			resolve(image);
		};
		image.onerror = () => {
			URL.revokeObjectURL(url);
			reject(new Error("Failed to load image"));
		};
		image.src = url;
	});
}

/**
 * Extracts image data (pixels) from an HTML Image element
 */
function getImageData(image: HTMLImageElement): { width: number; height: number; data: Uint8ClampedArray } {
	// Create a canvas element to draw the image
	const canvas = document.getElementById("qr-code-debug-canvas") as HTMLCanvasElement
		|| document.createElement("canvas");
	const context = canvas.getContext("2d");

	if (!context) {
		throw new Error("Could not create canvas context");
	}

	// Set canvas dimensions to match the image
	canvas.width = image.naturalWidth;
	canvas.height = image.naturalHeight;

	// Draw the image onto the canvas
	context.drawImage(image, 0, 0);

	// Get the pixel data from the canvas
	const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

	// Return width, height, and the raw pixel data
	return {
		width: imageData.width,
		height: imageData.height,
		data: new Uint8ClampedArray(imageData.data.buffer),
	};
}
