import {TypeOf} from "zod";
import {get_QueryBusinessCases, useApiClient} from "./client";

export type BusinessCaseQuery = TypeOf<get_QueryBusinessCases["parameters"]>["query"];

export function useBusinessCasesApi() {
	const api = useApiClient();

	return {
		queryBusinessCases: (query: BusinessCaseQuery) => api.get("/api/v2/business-cases", {query}),
		deleteAllBusinessCases: () => api.delete("/api/v2/business-cases"),
	};
}
