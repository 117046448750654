import {CircularProgress} from "@mui/material";
import {ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../context/auth";

export function Logout() {
	const navigate = useNavigate();
	const {reloadAuth} = useAuthContext();

	const {loading, error} = useAsync(async () => {
		const response = await fetch("/logout", {
			method: "post",
			headers: {
				accept: "application/json",
			},
		});
		if (!response.ok) {
			throw new Error("Logout failed");
		}
		await reloadAuth();
		navigate("/", {replace: true});
	}, []);

	if (loading) {
		return <CircularProgress />;
	}
	if (error) {
		return <ErrorAlert error={error} />;
	}
	return null;
}
