import {Alert, AlertTitle, Typography} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, DataTable, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Invoice} from "../../../../api";
import {useLocalization} from "../../../../i18n";
import {useSubmittedInvoiceColumns} from "../../columns";

interface UploadSuccessViewProps {
	created: Invoice[];
}

export function UploadSuccessView({created}: Readonly<UploadSuccessViewProps>) {
	const {t} = useLocalization();

	const columns = useSubmittedInvoiceColumns();

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbLink component={RouterLink} to="/invoices">{t("invoices.plural")}</BreadcrumbLink>
				<BreadcrumbItem>{t("invoices.new.creditor.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={t("invoices.new.creditor.title")}
				subTitle={t("invoices.new.creditor.success")}
			/>
			<Alert severity="success">
				<AlertTitle>{t("invoices.new.creditor.successMessage.title")}</AlertTitle>
				<Typography paragraph>
					{t("invoices.new.creditor.successMessage.message")}
				</Typography>
			</Alert>
			<DataTable columns={columns} rows={created} />
		</View>
	);
}
