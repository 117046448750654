import {VerifiedUser} from "@mui/icons-material";
import {Alert, Button, Card, CardContent, CardHeader, Collapse, Divider, Grid, Stack} from "@mui/material";
import {ErrorAlert, RadioGroup, TextField, useFlag} from "@variocube/app-ui";
import React, {type FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Invoice, useInvoicesApi} from "../../../api";
import {LabeledText} from "../../../component/labeled-text";
import {Typography} from "../../../component/typography";
import {useReloadInvoiceCount, useSetting, useUser} from "../../../context/auth";
import {useLocalization} from "../../../i18n";
import {notBlank} from "../../../util";

interface InvoiceAuditProperties {
	invoice: Invoice;
	ifnr: string;
}

export function InvoiceAudit({
	invoice,
	ifnr: pIfnr,
}: Readonly<InvoiceAuditProperties>) {
	const {s, t} = useLocalization();
	const {approveInvoice, rejectInvoice} = useInvoicesApi();
	const user = useUser();
	const reloadInvoiceCount = useReloadInvoiceCount();

	const [success, setSuccess] = useFlag(false);

	const rejectMinimumCharacters = useSetting("rejectMinimumCharacters") ?? 20;

	const showAuditCarIfnr = invoice.businessCases?.some(c => notBlank(c.licensePlate));
	const superUserCanAudit = invoice.auditorUsers?.find(a => a.email === user.email) === undefined;
	const auditors = invoice.auditorUsers
		?.map(a => {
			return `${a.firstname} ${a.lastname} (${a.email})`;
		})
		.join(", ");
	const defaultCarType = invoice.businessCases?.find(c => notBlank(c.licensePlate))?.carType ?? "";

	const [ifnr, setIfnr] = useState(pIfnr);
	const [carType, setCarType] = useState(defaultCarType);
	const [rejectReason, setRejectReason] = useState("");
	const [decision, setDecision] = useState<"Approve" | "Reject" | undefined>();

	const {loading, error, execute} = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();

		if (decision == "Approve") {
			await approveInvoice(invoice.id, {
				carType,
				carIfnr: ifnr,
			});
		}
		else if (decision == "Reject") {
			await rejectInvoice(invoice.id, {
				rejectReason,
			});
		}
		await reloadInvoiceCount();
		setSuccess();
	});

	return (
		<Card
			component="form"
			onSubmit={execute}
		>
			<CardHeader
				title={<Typography startAdornment={<VerifiedUser />}>{t("invoices.details.audit")}</Typography>}
			/>
			<CardContent>
				{!success && (
					<Stack spacing={2}>
						{notBlank(invoice.profitCenter) && (
							<LabeledText label={t("invoices.details.costCenter")} text={invoice.profitCenter} />
						)}
						<RadioGroup
							value={decision}
							onChange={setDecision}
							options={["Approve", "Reject"]}
							renderLabel={s("invoices.details.auditDecisions")}
						/>
						<Collapse in={decision == "Approve" && showAuditCarIfnr}>
							<Stack spacing={2}>
								<TextField
									label="Interne Fahrzeug Nummer (IFNR)"
									fullWidth
									value={ifnr}
									onChange={setIfnr}
									disabled={loading}
								/>
								<TextField
									label={t("invoices.details.carTypeModel")}
									fullWidth
									value={carType}
									onChange={setCarType}
									disabled={loading}
								/>
							</Stack>
						</Collapse>
						<Collapse in={decision == "Reject"}>
							<TextField
								label={t("invoices.details.rejectReason", {minChar: rejectMinimumCharacters})}
								fullWidth
								multiline
								required={decision == "Reject"}
								rows={8}
								value={rejectReason}
								onChange={setRejectReason}
								disabled={loading}
								inputProps={{
									minLength: rejectMinimumCharacters,
								}}
							/>
						</Collapse>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							disabled={loading}
							type="submit"
						>
							{t("actions.save")}
						</Button>

						{superUserCanAudit && (
							<>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									{/* eslint-disable react/no-danger */}
									<span
										dangerouslySetInnerHTML={{
											__html: t("invoices.details.superUserCanAuditHint", {auditors}),
										}}
									/>
								</Grid>
							</>
						)}

						{error && <ErrorAlert error={error} />}
					</Stack>
				)}
				{success && (
					<Alert severity="success">
						{decision === "Approve" && <Typography>{t("invoices.details.auditAcceptMessage")}</Typography>}
						{decision === "Reject" && <Typography>{t("invoices.details.auditRejectMessage")}</Typography>}
					</Alert>
				)}
			</CardContent>
		</Card>
	);
}
