import {TypeOf} from "zod";
import {get_QueryHelpTexts, HelpTextMutation, useApiClient} from "./client";

export type HelpTextQuery = TypeOf<get_QueryHelpTexts["parameters"]>["query"];

export function useHelpTextApi() {
	const api = useApiClient();
	return {
		getHelpText: (id: number) => api.get("/api/v2/help-texts/{id}", {path: {id}}),
		queryHelpTexts: (query: HelpTextQuery) => api.get("/api/v2/help-texts", {query}),
		createHelpText: (mutation: HelpTextMutation) => api.post("/api/v2/help-texts", {body: mutation}),
		updateHelpText: (id: number, mutation: HelpTextMutation) =>
			api.put("/api/v2/help-texts/{id}", {path: {id}, body: mutation}),
		deleteHelpText: (id: number) => api.delete("/api/v2/help-texts/{id}", {path: {id}}),
	};
}
