import {DocumentType, DocumentTypeMutation} from "../../../api";
import {useLocalization} from "../../../i18n";
import {useEffect, useState} from "react";
import {EditForm, TextField} from "@variocube/app-ui";
import React from "react";
import {CardContent, Grid} from "@mui/material";

interface DocumentTypeFormProps {
	loading?: boolean;
	type?: DocumentType;
	onSave: (data: DocumentTypeMutation) => Promise<void>;
}

export function DocumentTypeForm({loading, type, onSave}: DocumentTypeFormProps) {
	const {s, t} = useLocalization();

	const [name, setName] = useState("");
	const [fileExtension, setFileExtension] = useState("");
	const [mimeType, setMimeType] = useState("");

	useEffect(() => {
		if (type !== undefined) {
			setName(type.displayName);
			setFileExtension(type.fileExtension);
			setMimeType(type.mimeType);
		}
	}, [type]);

	function handleSave() {
		return onSave({
			name,
			fileExtension,
			mimeType
		});
	}

	return (
		<EditForm
			loading={loading ?? false}
			onSave={handleSave}
			labels={s("actions")}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth required
							label={t("common.name")}
							value={name}
							onChange={setName}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							fullWidth required
							label={t("documentTypes.fileExtension")}
							value={fileExtension}
							onChange={setFileExtension}
						/>
					</Grid>
					<Grid item xs={12} sm={8}>
						<TextField
							fullWidth required
							label="MIME-Type"
							value={mimeType}
							onChange={setMimeType}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	)
}
