import {Checkbox, EditForm, Select, TextField} from "@variocube/app-ui";
import {useLocalization} from "../../../i18n";
import {Inbox, InboxMutation, Info, InfoMutation, ScanService} from "../../../api";
import {useEffect, useState} from "react";
import React from "react";
import {CardContent, Divider, Grid, Stack} from "@mui/material";

interface InboxFormProps {
	loading?: boolean;
	inbox?: Inbox;
	onSave: (data: InboxMutation) => Promise<void>;
}

export function InboxForm({loading, inbox, onSave}: InboxFormProps) {
	const {s, t} = useLocalization();

	const [name, setName] = useState("");
	const [businessCenterAddress, setBusinessCenterAddress] = useState("");
	const [companyCode, setCompanyCode] = useState("");
	const [scanService, setScanService] = useState<ScanService>("Exela");
	const [inboxLabels, setInboxLabels] = useState<string[]>([]);
	const [allowAttachments, setAllowAttachments] = useState(false);
	const [allowAddingAttachments, setAllowAddingAttachments] = useState(false);
	const [requireManualApproval, setRequireManualApproval] = useState(false);
	const [approvalIncludesInvoiceCheck, setApprovalIncludesInvoiceCheck] = useState(false);
	const [requiresAudit, setRequiresAudit] = useState(false);
	const [availableForWorksAt, setAvailableForWorksAt] = useState(false);
	const [availableForExpenses, setAvailableForExpenses] = useState(false);
	const [contact, setContact] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactPhone, setContactPhone] = useState("");
	const [hiddenForCreditors, setHiddenForCreditors] = useState(false);
	const [hidden, setHidden] = useState(false);
	const [onlyAvailableForManagers, setOnlyAvailableForManagers] = useState(false);

	useEffect(() => {
		if (inbox !== undefined) {
			setName(inbox.name ?? "");
			setBusinessCenterAddress(inbox.businessCenterAddress ?? "");
			setCompanyCode(inbox.companyCode ?? "");
			setScanService(inbox.scanService ?? "Exela");
			setInboxLabels(inbox.inboxLabels ?? "");
			setAllowAttachments(inbox.allowAttachments ?? false);
			setAllowAddingAttachments(inbox.allowAddingAttachments ?? false);
			setRequireManualApproval(inbox.requireManualApproval ?? false);
			setApprovalIncludesInvoiceCheck(inbox.approvalIncludesInvoiceCheck ?? false);
			setRequiresAudit(inbox.requiresAudit ?? false);
			setAvailableForWorksAt(inbox.availableForWorksAt ?? false);
			setAvailableForExpenses(inbox.availableForExpenses ?? false);
			setContact(inbox.contact ?? "");
			setContactEmail(inbox.contactEmail ?? "");
			setContactPhone(inbox.contactPhone ?? "");
			setHiddenForCreditors(inbox.hiddenForCreditors ?? false);
			setHidden(inbox.hidden ?? false);
			setOnlyAvailableForManagers(inbox.onlyAvailableForManagers ?? false);
		}
	}, [inbox]);

	function handleSave() {
		return onSave({
			name,
			businessCenterAddress,
			companyCode,
			scanService,
			inboxLabels,
			allowAttachments,
			allowAddingAttachments,
			requireManualApproval,
			approvalIncludesInvoiceCheck,
			requiresAudit,
			availableForWorksAt,
			availableForExpenses,
			contact,
			contactEmail,
			contactPhone,
			hiddenForCreditors,
			hidden,
			onlyAvailableForManagers,
		})
	}

	return (
		<EditForm
			loading={loading ?? false}
			onSave={handleSave}
			labels={s("actions")}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth required
							label={t('common.name')}
							value={name}
							onChange={setName}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							fullWidth
							label="BCA"
							value={businessCenterAddress}
							onChange={setBusinessCenterAddress}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							fullWidth
							label={t("inboxes.companyCode")}
							value={companyCode}
							onChange={setCompanyCode}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<Select
							fullWidth
							label={t("inboxes.scanService")}
							value={scanService}
							onChange={setScanService}
							options={["Exela", "ScanPoint", "SkipOCR"]}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						{/*<TextField*/}
						{/*	fullWidth*/}
						{/*	label={t("inboxes.labels")}*/}
						{/*	value={companyCode}*/}
						{/*	onChange={setCompanyCode}*/}
						{/*/>*/}
					</Grid>
					<Grid item xs={12}><Divider /></Grid>
					<Grid item xs={12}>
						<Stack
							direction="column"
							gap={1}
						>
							<Checkbox
								label={t("inboxes.allowAttachments")}
								value={allowAttachments}
								onChange={setAllowAttachments}
							/>
							<Checkbox
								label={t("inboxes.allowAddingAttachments")}
								value={allowAddingAttachments}
								onChange={setAllowAddingAttachments}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12}><Divider /></Grid>
					<Grid item xs={12}>
						<Stack
							direction="column"
							gap={1}
						>
							<Checkbox
								label={t("inboxes.requireManualApproval")}
								value={requireManualApproval}
								onChange={setRequireManualApproval}
							/>
							<Checkbox
								label={t("inboxes.approvalIncludesInvoiceCheck")}
								value={approvalIncludesInvoiceCheck}
								onChange={setApprovalIncludesInvoiceCheck}
							/>
							<Checkbox
								label={t("inboxes.requiresAudit")}
								value={requiresAudit}
								onChange={setRequiresAudit}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12}><Divider /></Grid>
					<Grid item xs={12}>
						<Stack
							direction="column"
							gap={1}
						>
							<Checkbox
								label={t("inboxes.availableForWorksAt")}
								value={availableForWorksAt}
								onChange={setAvailableForWorksAt}
							/>
							<Checkbox
								label={t("inboxes.availableForExpenses")}
								value={availableForExpenses}
								onChange={setAvailableForExpenses}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12}><Divider /></Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label={t('inboxes.contact')}
							value={contact}
							onChange={setContact}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label={t('inboxes.contactEmail')}
							value={contactEmail}
							onChange={setContactEmail}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							label={t('inboxes.contactPhone')}
							value={contactPhone}
							onChange={setContactPhone}
						/>
					</Grid>
					<Grid item xs={12}><Divider /></Grid>
					<Grid item xs={12}>
						<Stack
							direction="column"
							gap={1}
						>
							<Checkbox
								label={t("inboxes.hiddenForCreditors")}
								value={hiddenForCreditors}
								onChange={setHiddenForCreditors}
							/>
							<Checkbox
								label={t("inboxes.hidden")}
								value={hidden}
								onChange={setHidden}
							/>
							<Checkbox
								label={t("inboxes.onlyAvailableForManagers")}
								value={onlyAvailableForManagers}
								onChange={setOnlyAvailableForManagers}
							/>
						</Stack>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	)
}
