import {History} from "@mui/icons-material";
import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {TemporalFormat, tryParsePlainDateTime} from "@variocube/app-ui";
import React, {Fragment} from "react";
import {type InvoiceViewHistoryItem} from "../../../api";
import {Typography} from "../../../component/typography";
import {useLocalization} from "../../../i18n";
import {notBlank} from "../../../util";

export function InvoiceHistory({history}: { history?: InvoiceViewHistoryItem[] }) {
	const {t, e} = useLocalization();
	return (
		<Card>
			<CardHeader title={<Typography startAdornment={<History />}>{t("invoices.details.history")}</Typography>} />
			<CardContent>
				<Grid container spacing={1}>
					{history?.map(index => (
						<Fragment key={index.labelKey}>
							<Grid item xs={6}>{e("invoices.invoiceStates", index.labelKey)}</Grid>
							<Grid item xs={6} textAlign="right">
								<TemporalFormat value={tryParsePlainDateTime(index.date)} />
							</Grid>
							{notBlank(index.message) && (
								<Grid item xs={12}>
									<Typography>{index.message}</Typography>
								</Grid>
							)}
						</Fragment>
					))}
				</Grid>
			</CardContent>
		</Card>
	);
}
