import {Autocomplete} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import {getAlpha2Codes, getName} from "i18n-iso-countries";
import React, {useMemo} from "react";
import {useLocalization} from "../i18n";

interface CountrySelectProperties {
	label: string;
	value?: string;
	required?: boolean;
	onChange: (value: string | undefined) => void;
}

export function CountrySelect(properties: Readonly<CountrySelectProperties>) {
	const {label, value, required, onChange} = properties;
	const {t, language} = useLocalization();

	const countries = useMemo(() =>
		Object.keys(getAlpha2Codes()).map(code => ({
			code,
			name: getName(code, language),
			group: getGroup(code),
		} as const)).sort((c1, c2) => c1.group - c2.group), [language]);

	const selected = value ? countries.find(country => country.code == value) : null;

	return (
		<Autocomplete
			options={countries}
			value={selected}
			groupBy={country => getGroupName(country.group)}
			getOptionLabel={country => country.name ?? ""}
			renderInput={(parameters) => (
				<TextField
					placeholder={t("hint.select")}
					required={required}
					label={label}
					{...parameters}
				/>
			)}
			onChange={(_, value) => onChange(value?.code ?? undefined)}
			autoSelect
			autoHighlight
		/>
	);
}

function getGroup(code: string) {
	switch (code) {
		case "AT":
			return 0;
		case "DE":
		case "HU":
		case "CH":
		case "CZ":
		case "SK":
		case "LI":
		case "SI":
		case "IT":
			return 1;
		default:
			return 2;
	}
}

function getGroupName(group: 0 | 1 | 2) {
	switch (group) {
		case 0:
			return "Meistens";
		case 1:
			return "Manchmal";
		case 2:
			return "Selten";
	}
}
