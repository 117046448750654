import {Button, Card, CardContent, Stack} from "@mui/material";
import {ErrorAlert, TextField, View, ViewHeader} from "@variocube/app-ui";
import React, {FormEvent, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {useNavigate} from "react-router-dom";
import {useIndexesApi} from "../api";
import {BcaSelect} from "../component/bca-select";
import {useAuthContext, useUser} from "../context/auth";
import {useLocalization} from "../i18n";

export function MissingUserData() {
	const {t} = useLocalization();
	const user = useUser();
	const navigate = useNavigate();
	const {patchCurrentUser} = useIndexesApi();
	const {reloadAuth} = useAuthContext();

	const [phone, setPhone] = useState("");
	const [worksAt, setWorksAt] = useState<number>();

	const {loading, error, execute} = useAsyncCallback(async (e: FormEvent) => {
		e.preventDefault();
		await patchCurrentUser({phone: phone ?? undefined, worksAt});
		await reloadAuth();
		navigate("/");
	});

	return (
		<View maxWidth="sm">
			<ViewHeader title={t("missing-user-data.title")} subTitle={t("missing-user-data.subTitle")} />
			<Card component="form" onSubmit={execute}>
				<CardContent>
					<Stack spacing={2}>
						{!user.phone && (
							<TextField label={t("common.phone")} value={phone} onChange={setPhone} required />
						)}
						{user.worksAt.length == 0 && (
							<BcaSelect
								label={t("users.mailboxes.worksAt.title")}
								value={worksAt}
								onChange={setWorksAt}
								filter="WORKS_AT_ASSIGNMENT"
								required
							/>
						)}
						{error && <ErrorAlert error={error} />}
						<Button type="submit" variant="contained" disabled={loading} fullWidth>
							{t("actions.save")}
						</Button>
					</Stack>
				</CardContent>
			</Card>
		</View>
	);
}
