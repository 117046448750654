import {Link, useNavigate} from "react-router-dom";
import {useLocalization} from "../../../i18n";
import {DocumentTypeMutation} from "../../../api";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, View, ViewHeader} from "@variocube/app-ui";
import React from "react";
import {useDocumentTypesApi} from "../../../api/document-types";
import {DocumentTypeForm} from "./form";

export function DocumentTypeCreator() {
	const navigate = useNavigate();
	const {t} = useLocalization();
	const {createDocumentType} = useDocumentTypesApi();

	async function handleSave(mutation: DocumentTypeMutation) {
		const {id} = await createDocumentType(mutation);
		navigate(`../superuser/doctype/${id}`);
	}

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbItem>{t("navigation.settings")}</BreadcrumbItem>
				<BreadcrumbLink component={Link} to={`../superuser/mailbox`}>{t("navigation.settingsGroup.documentTypes")}</BreadcrumbLink>
				<BreadcrumbItem>{t("documentTypes.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("documentTypes.create.title")} />
			<DocumentTypeForm
				onSave={handleSave}
			/>
		</View>
	)
}
