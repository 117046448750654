import {DocumentTypeMutation, useApiClient} from "./client";

export function useDocumentTypesApi() {
	const api = useApiClient();
	return {
		queryDocumentTypes: () => api.get("/api/v2/document-types"),
		getDocumentType: (id: number) => api.get("/api/v2/document-types/{id}", {path: {id}}),
		createDocumentType: (mutation: DocumentTypeMutation) => api.post("/api/v2/document-types", {body: mutation}),
		updateDocumentType: (id: number, mutation: DocumentTypeMutation) =>
			api.put("/api/v2/document-types/{id}", {path: {id}, body: mutation}),
		deleteDocumentType: (id: number) => api.delete("/api/v2/document-types/{id}", {path: {id}}),
	};
}
