import React, {useMemo, useState} from "react";
import {
	Button,
	Card,
	CardActions,
	CardHeader, Dialog,	DialogTitle,
	List, ListItemButton, ListItemIcon, ListItemText,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow, Typography
} from "@mui/material";
import {DocumentType} from "../../../api";
import {useLocalization} from "../../../i18n";
import {DeleteOutline} from "@mui/icons-material";
import {Checkbox, ConfirmIconButton, EditForm, useFlag} from "@variocube/app-ui";

interface DocumentTypeTableProps {
	title: string;
	types: DocumentType[];
	options: DocumentType[];
	onAdd: (ids: number[]) => Promise<void>;
	onRemove: (id: number) => Promise<void>;
}

export function DocumentTypeTable({title, types, options, onAdd, onRemove}: DocumentTypeTableProps) {
	const {t, s} = useLocalization();

	const [selected, setSelected] = useState<number[]>([]);
	const [editMode, setEditMode, clearEditMode] = useFlag(false);

	function handleCheckbox(id: number) {
		if (!selected.includes(id)) {
			setSelected([...selected, id]);
		}
		else {
			setSelected(selected.filter(i => i !== id));
		}
	}

	async function handleSubmit() {
		await onAdd(selected);
		clearEditMode();
		setSelected([]);
	}

	const filteredOptions = useMemo(() =>
		options
			.filter(o =>
				types
					.find(t => t.id === o.id) === undefined
			)
	, [options, types])

	return (
		<>
			<Card>
				<CardHeader title={title} />
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>{t('common.name')}</TableCell>
							<TableCell>{t('documentTypes.fileExtension')}</TableCell>
							<TableCell>MIME-Type</TableCell>
							<TableCell width={10}>&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{types
							.sort((t1, t2) => t1.displayName.localeCompare(t2.displayName))
							.map(type => (
							<TableRow key={type.id}>
								<TableCell>{type.displayName}</TableCell>
								<TableCell>{type.fileExtension}</TableCell>
								<TableCell>
									<Typography variant="body2" width={80} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{type.mimeType}</Typography>
								</TableCell>
								<TableCell>
									<ConfirmIconButton
										title={t("inboxes.documentTypes.delete.title")}
										cancel={t("actions.cancel")}
										onConfirm={() => onRemove(type.id)}
										icon={<DeleteOutline />}
										color="error"
									>
										{t("inboxes.documentTypes.delete.prompt")}
									</ConfirmIconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<CardActions>
					<Button color="secondary" onClick={setEditMode}>{t("inboxes.documentTypes.assign.title")}</Button>
				</CardActions>
			</Card>
			<Dialog open={editMode} onClose={clearEditMode} fullWidth maxWidth="sm">
				<DialogTitle>{t("inboxes.documentTypes.assign.title")}</DialogTitle>
				<EditForm
					loading={false}
					onSave={handleSubmit}
					onCancel={clearEditMode}
					labels={s("actions")}
				>
					<List sx={{maxHeight: 440, overflowY: "scroll"}}>
						{filteredOptions
							.map(o => (
							<ListItemButton
								key={o.id}
								onClick={() => handleCheckbox(o.id)}
								selected={selected.includes(o.id)}
							>
								<ListItemIcon>
									<Checkbox
										checked={selected.includes(o.id)}
										tabIndex={-1}
										disableRipple
									/>
								</ListItemIcon>
								<ListItemText primary={o.displayName} />
							</ListItemButton>
						))}
					</List>
				</EditForm>
			</Dialog>
		</>
	)
}
