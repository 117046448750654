import {useMemo} from "react";
import zxcvbn from "zxcvbn";
import {useLocalization} from "../i18n";

export function usePasswordStrength(password: string) {
	const {t} = useLocalization();
	return useMemo(
		() => {
			if (!password) return {percentage: 0};
			switch (zxcvbn(password).score) {
				case 0: {
					return {level: t("passwordStrength.risky"), percentage: 20};
				}
				case 1: {
					return {level: t("passwordStrength.weak"), percentage: 40};
				}
				case 2: {
					return {level: t("passwordStrength.medium"), percentage: 60};
				}
				case 3: {
					return {level: t("passwordStrength.strong"), percentage: 80};
				}
				case 4: {
					return {level: t("passwordStrength.secured"), percentage: 100};
				}
				default: {
					return {percentage: 0};
				}
			}
		},
		[password],
	);
}
