import {CardContent, Grid, Stack} from "@mui/material";
import React, {useEffect} from "react";
import {useAsync} from "react-async-hook";
import {useInboxesApi} from "../../../../../api";
import {BcaSelect} from "../../../../../component/bca-select";
import {InboxAuditorSingleSelect} from "../../../../../component/inbox-auditor-select";
import {LabeledText} from "../../../../../component/labeled-text";
import {ProfitCenterField} from "../../../../../component/profit-center-field";
import {StepProps} from "./step-props";
import {WizardStepCard} from "./wizard-step-card";

export function RecipientStep({active, completed, invoice, onChange}: Readonly<StepProps>) {
	const {getInbox} = useInboxesApi();

	const [first] = invoice.businessCases ?? [];
	const {fixedInbox, fixedAuditors} = first ?? {};

	useEffect(() => {
		if (fixedAuditors) {
			onChange({auditors: fixedAuditors.split(",").map(auditor => auditor.trim())});
		}
	}, [fixedAuditors]);

	useAsync(async (fixedInbox: number | undefined) => {
		if (fixedInbox) {
			onChange({inbox: await getInbox(fixedInbox)});
		}
		return Promise.resolve();
	}, [fixedInbox]);

	const hasFixedAuditors = invoice.businessCases?.some(businessCase => Boolean(businessCase.fixedAuditors?.trim()));
	const hasFixedInbox = invoice.businessCases?.some(businessCase => Boolean(businessCase.fixedInbox));

	return (
		<WizardStepCard
			active={active}
			completed={completed}
			title="Empfänger"
			activeChildren={
				<CardContent>
					<Stack spacing={2}>
						<BcaSelect
							label={"Gesellschaft"}
							value={invoice.inbox?.id}
							onChange={(_, inbox) => onChange({inbox})}
							filter="EXPENSE_INVOICE_SUBMISSION"
							userId={invoice.onBehalfOfUserId}
							fullWidth
							required
							disabled={hasFixedInbox}
						/>
						{/* ÖBB wants single select for now. Could be changed to InboxAuditorMultiSelect easily */}
						<InboxAuditorSingleSelect
							label={"Allgemeiner Prüfer"}
							value={(invoice?.auditors && [...invoice.auditors].pop()) ?? null}
							inboxId={invoice.inbox?.id}
							onChange={auditor => onChange({auditors: auditor ? [auditor] : []})}
							fullWidth
							required
							disabled={hasFixedAuditors || !invoice.inbox}
						/>
						<ProfitCenterField
							label="Kostenstelle, Auftrag oder Immo.Obj."
							value={invoice.profitCenter}
							onChange={profitCenter => onChange({profitCenter})}
							required
						/>
					</Stack>
				</CardContent>
			}
			completedChildren={
				<CardContent>
					<Grid container spacing={2}>
						<Grid item>
							<LabeledText label={"Gesellschaft"} text={invoice.inbox?.name} />
						</Grid>
						<Grid item>
							<LabeledText label={"Allgemeine Prüfer"} text={invoice.auditors?.join(", ")} />
						</Grid>
						{invoice.profitCenter && (
							<Grid item>
								<LabeledText
									label={"Kostenstelle, Auftrag oder Immo.Obj."}
									text={invoice.profitCenter}
								/>
							</Grid>
						)}
					</Grid>
				</CardContent>
			}
		/>
	);
}
